import { Pipe, PipeTransform } from '@angular/core';
import { MonthFlags } from '@tpa/shared';

@Pipe({
  name: 'monthPipe',
})
export class MonthPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const None = value == (value | MonthFlags.None);
    const January = value == (value | MonthFlags.January);
    const February = value == (value | MonthFlags.February);
    const March = value == (value | MonthFlags.March);
    const Aprvaluel = value == (value | MonthFlags.April);
    const May = value == (value | MonthFlags.May);
    const June = value == (value | MonthFlags.June);
    const July = value == (value | MonthFlags.July);
    const August = value == (value | MonthFlags.August);
    const September = value == (value | MonthFlags.September);
    const October = value == (value | MonthFlags.October);
    const November = value == (value | MonthFlags.November);
    const December = value == (value | MonthFlags.December);

    const arr = [
      January,
      February,
      March,
      Aprvaluel,
      May,
      June,
      July,
      August,
      September,
      October,
      November,
      December,
    ];

    let returnText = '';
    let start = -1;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        if (start == -1) {
          start = i + 1;
        }
      } else {
        if (start != -1) {
          if (returnText != '') {
            returnText += ', ';
          }
          returnText += `${start}-${i}`;
        }
        start = -1;
      }
    }

    if (start != -1) {
      if (returnText != '') {
        returnText += ', ';
      }
      returnText += `${start}-12`;
    }

    return returnText;
  }
}
