<ral-card class="tip rounded-sm" [attr.data-cy]="to['label']">
  <div class="e-card-header" style="width: 100%" style="background: var(--surface-b)">
    <div class="p4-grid p4-grid--with-bottom">
      <div class="p-col-12" *ngIf="to?.['tagIds']">
        <h3 class="h3">Tip</h3>
      </div>
      <div class="p-col-12">
        <p style="font-weight: normal; font-size: 15px">
          <span class="e-header-bottom-space" *ngIf="!to?.['tagIds']">
            Tip:
          </span>

          {{ to.label }}

          <a *ngIf="to['link']" class="link" [href]="to['link']" download>{{
            to['linkText']
            }}</a>
        </p>
        <div class="e-header-bottom-space" *ngIf="!to?.['tagIds']"></div>
      </div>
    </div>
  </div>
  <div class="e-card-content" *ngIf="to['tagIds']" style="background: var(--surface-b)">
    <p *ngIf="to.description" class="description">
      {{ to.description }}
    </p>
    <h3 class="h3">
      {{ 'TPA.ProvidedDocuments' | transloco }}
    </h3>
    <ng-container #fieldComponent></ng-container>
    <div *ngIf="to['tagIds'] && to['userId']; noOwnerId">
      <div *ngFor="let tagId of to['tagIds']">
        <h3 class="h4">{{ 'TPA.' + tagId | transloco | uppercase }}</h3>
        <!-- TODO: pridat document LIST !!!!! -->
        <tpa-documents-tips-list #documentsList 
        class="block pb-1"
        [hideHeading]="false" [userId]="to.userId" [validFrom]="to.validFrom"
          [tagId]="tagId" (linkClick)="navigateToDocument($event)">
        </tpa-documents-tips-list >
        <tpa-feature-employee-document-upload [tagId]="tagId"
          (uploadCompleted)="documentsList.state.fetchData()"></tpa-feature-employee-document-upload>
      </div>
    </div>
    <ng-template #noOwnerId> Chybějící owner id </ng-template>
  </div>
</ral-card>