import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import { TaskListDto, TenancyRequestDto } from '../dto';

@Injectable({ providedIn: 'root' })
export class ArtifactTaskHttpApiService {
  constructor(private rest: RestService) {}

  public getList(
    params: TenancyRequestDto<EntityDto & { taskState?: number }>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/artifact-task/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<TaskListDto[]>>(request);
  }

  public getCount(params: TenancyRequestDto<{ taskState?: number }>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/artifact-task/get/count',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<number>>(request);
  }

  public markAsCompleted(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'PUT',
      url: '/api/artifact-task/mark-as-completed',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EntityDto>,
      Rest.AbpResponse<null>
    >(request);
  }
}
