import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  DocumentDto,
  DocumentTagDto,
  TenancyRequestDto,
  TenancyRequestDtoOfApproveDocumentDto,
  TenancyRequestDtoOfEntityDto,
  TenancyRequestDtoOfUpdateDocumentTagDto,
  UploadDocument,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageDocumentHttpApiService {
  constructor(private rest: RestService) {}

  public uploadDocument(body: TenancyRequestDto<UploadDocument>) {
    const request: Rest.Request<TenancyRequestDto<UploadDocument>> = {
      method: 'POST',
      url: '/api/manage/document/upload-document',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<UploadDocument>,
      Rest.AbpResponse<null>
    >(request);
  }

  public getDocument(params: TenancyRequestDto<{ Id?: number }>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/document/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<DocumentDto>>(request);
  }

  public getDocuments(
    params: TenancyRequestDto<{
      tagId: string | undefined;
      ownerId: number;
      IsDeleted: boolean;
      validTo?: string;
      validFrom?: string;
    }>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/document/get-list',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<DocumentDto[]>>(request);
  }

  public deleteDocument(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: '/api/manage/document/delete',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<null>>(request);
  }

  public getDocumentsTags(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/document/get-tags',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<DocumentTagDto[]>>(request);
  }

  public recoveryDocumentTag(body: TenancyRequestDtoOfEntityDto) {
    const request: Rest.Request<TenancyRequestDtoOfEntityDto> = {
      method: 'PUT',
      url: '/api/manage/document/recover-document',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfUpdateDocumentTagDto,
      Rest.AbpResponse<DocumentDto>
    >(request);
  }

  public duplicateDocumentTag(body: TenancyRequestDtoOfUpdateDocumentTagDto) {
    const request: Rest.Request<TenancyRequestDtoOfUpdateDocumentTagDto> = {
      method: 'POST',
      url: '/api/manage/document/duplicate-document',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfUpdateDocumentTagDto,
      Rest.AbpResponse<DocumentDto>
    >(request);
  }

  public approveDocument(body: TenancyRequestDtoOfApproveDocumentDto) {
    const request: Rest.Request<TenancyRequestDtoOfApproveDocumentDto> = {
      method: 'PUT',
      url: '/api/manage/document/approve-document',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfEntityDto,
      Rest.AbpResponse<DocumentDto>
    >(request);
  }
}
