<ng-container *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card">
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <!--<p>{{ model.mesto }}, {{ model.psc }}</p> -->
    <p>
      {{ model?.exekuce || false | yesOrNo }}
    </p>
  </div>
</ng-template>
<!-- <pre>
    {{ this.model | json }}
  </pre
> -->
