import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree,
  Route,
  UrlSegment,
} from '@angular/router';
import { PermissionChecker } from '../services';
import { Observable, of } from 'rxjs';
import { tap, retry, map } from 'rxjs/operators';
@Injectable()
export class AuthorizationGurad
  implements CanActivate, CanActivateChild, CanLoad
{
  constructor(
    private permissionChecker: PermissionChecker,
    private router: Router
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Promise<boolean> | Observable<boolean> {
    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const authorization = route.data.authorization;
    if (authorization !== null && authorization !== undefined) {
      return this.permissionChecker.isGranted$(
        authorization.permissionName,
        authorization.requireAll
      );
    } else {
      return of(true);
    }
  }
}
