import { InjectionToken, Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { filter, tap } from 'rxjs/operators';
export const MODULE_URL_TOKEN = new InjectionToken<string>(
  'ModuleRootUrlToken'
);

@Injectable({
  providedIn: 'root',
})
export class BackNavigationService {
  previousUrl: string;
  constructor(private router: Router, private location: Location) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap((e: NavigationStart) => {
          this.previousUrl = e.url;
        })
      )
      .subscribe();
  }

  goBackOrDefault(defaultHref = '/', useDefault = false) {
    if (this.previousUrl && !useDefault) {
      this.location.back();
    } else {
      this.router.navigate([defaultHref]);
    }
  }
}
