import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <ng-container *transloco="let t">
      <div class="e-card">
        <div class="e-card-header">
          <h3 *ngIf="to.label || to.labelT" class="h3">
            {{
              to.labelT ? t(to.labelT['key'], to.labelT['params']) : to.label
            }}
          </h3>
          <p *ngIf="to.description || to.descriptionT" class="description">
            {{
              to.descriptionT
                ? t(to.descriptionT['key'], to.descriptionT['params'])
                : to.description
            }}
          </p>
        </div>
        <div class="e-card-content">
          <ng-container #fieldComponent></ng-container>
        </div>
      </div>
    </ng-container>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyPanelWrapperComponent extends FieldWrapper {}
