import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { UserTenancyActions } from '../actions';
import { UserTenanciesSelectors } from '../selectors';
import { checkUserIdentifer, TenancyFeatureChecker } from '../utils';

@Injectable()
export class TenancyGetGuard implements CanActivate {
  /**
   *
   */
  constructor(private store$: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.store$.pipe(
      select(UserTenanciesSelectors.selecetGuard),
      filter((x) => x.loaded),
      tap((x) =>
        this.store$.dispatch(UserTenancyActions.checkTenancyAccount())
      ),
      map((x) => x.loaded)
    );
  }
}

@Injectable()
export class TenancyGuard implements CanActivate, CanActivateChild {
  /**
   *
   */
  constructor(private store$: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // this.store$.dispatch(UserTenancyActions.checkTenancyAccount());

    const feature = route.data.feature;
    return this.store$.pipe(
      select(UserTenanciesSelectors.selecetGuard),
      filter(
        ({ userIdentifer, accounts, loaded }) =>
          loaded && checkUserIdentifer(userIdentifer, accounts)
      ),
      map(({ features }) => {
        if (feature !== null && feature !== undefined) {
          if (TenancyFeatureChecker.isEnabled(feature, features)) {
            return true;
          } else {
            // TODO: Unauthorized URL?
            return this.router.createUrlTree(['/', 'tenancy']);
          }
        } else {
          return true;
        }
      })
    );
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}

@Injectable()
export class TenancyLoadedGuard implements CanActivate {
  /**
   *
   */
  constructor(private store$: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    // this.store$.dispatch(UserTenancyActions.checkTenancyAccount());

    return this.store$.pipe(
      select(UserTenanciesSelectors.isLoaded),
      filter((p) => p)
    );
  }
}
