import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CoreSelectors,
  PermissionChecker,
  selectRouteParam,
} from '@ralba/core';
import { AsyncState } from '@ralba/shared';
import { userTenancies } from '../reducers';
import { TenancyFeatureChecker } from '../utils';
import { selecetUserTenancies } from './features.selectors';

const selectAccounts = createSelector(
  selecetUserTenancies,
  userTenancies.selecetAccounts
);

const selectSortedAccounts = createSelector(
  selectAccounts,
  (p) =>
    p &&
    p.length > 0 &&
    JSON.parse(JSON.stringify(p)).sort((a, b) =>
      a?.tenancyName > b?.tenancyName ? 1 : -1
    )
);

const selectAccountsCount = createSelector(
  selectAccounts,
  (p) => p && p.length
);

const selectHostUserId = createSelector(
  selectAccounts,
  (p) => (p && p.length > 0 && p.find((k) => k.tenantId == 1)?.id) || null
);

const selectAccountsIsSingle = createSelector(
  selectAccountsCount,
  (p) => p == 1
);

const isLoaded = createSelector(
  selecetUserTenancies,
  AsyncState.selectIsLoaded
);

const selectUserId = createSelector(
  selecetUserTenancies,
  userTenancies.selecetUserId
);

const selectTenantId = createSelector(
  selectRouteParam('tenantId'),
  (value) => +value
);

const selectCurrentTenancy = createSelector(
  selectTenantId,
  selectAccounts,
  (id, tenancy) => tenancy.find((p) => p.tenantId == id)
);

const selectCurrentTenancyName = createSelector(
  selectCurrentTenancy,
  (tenancy) => tenancy && tenancy.tenancyName
);

const selectCurrentTenancyHeliosId = createSelector(
  selectCurrentTenancy,
  (tenancy) => tenancy && tenancy.heliosId
);

const selectUserIdentifer = createSelector(
  selectUserId,
  selectTenantId,
  (userId, tenantId) => ({
    userId: (tenantId && userId) || undefined,
    tenantId: tenantId || undefined,
  })
);

const side = createSelector(selectUserIdentifer, (identifer) =>
  identifer.tenantId ? 'TENANT' : 'HOST'
);

const isTenancySide = createSelector(side, (_) => _ === 'TENANT');

const isHostSide = createSelector(side, (_) => _ === 'HOST');

const selectPermissions = createSelector(
  selecetUserTenancies,
  userTenancies.selecetPermissions
);

const selecetFeatures = createSelector(
  selecetUserTenancies,
  userTenancies.selecetFeatures
);

const hasEmployeeCard = createSelector(
  selecetUserTenancies,
  userTenancies.ownEmployeeCard
);

const employeeCardId = createSelector(
  selecetUserTenancies,
  userTenancies.employeeCardId
);

const employeeCardSigned = createSelector(
  selecetUserTenancies,
  userTenancies.signedEmployeeCard
);

const selecetGuard = createSelector(
  selectUserIdentifer,
  selectAccounts,
  isLoaded,
  selecetFeatures,
  (userIdentifer, accounts, loaded, features) => ({
    userIdentifer,
    accounts,
    loaded,
    features,
  })
);

const authorizationGuard = createSelector(
  isHostSide,
  CoreSelectors.selectAuth,
  selectPermissions,
  selecetFeatures,
  (isHost, hostAuth, tenantPermissions, tenantFeatures) => ({
    isHost,
    host: hostAuth,
    tenant: {
      permissions: tenantPermissions,
      features: tenantFeatures,
    },
  })
);

const selecetIsTenantGratned = (
  permissions: string[],
  requreireAll: boolean = false
) =>
  createSelector(selectPermissions, (state) =>
    PermissionChecker.isGranted(
      permissions,
      state.allPermissions,
      state.grantedPermissions,
      requreireAll
    )
  );

const selecetIsFeatureEnabled = (featureNAme: string) =>
  createSelector(selecetFeatures, (features) =>
    TenancyFeatureChecker.isEnabled(featureNAme, features)
  );
const navInformations = createSelector(
  selectPermissions,
  selecetFeatures,
  selectUserIdentifer,
  isLoaded,
  hasEmployeeCard,
  (permissions, features, identifer, loaded, employeeCard) => ({
    permissions,
    features,
    identifer,
    loaded,
    employeeCard,
  })
);

const employeeCardSignedCheck = createSelector(
  employeeCardSigned,
  isLoaded,
  selecetFeatures,
  (signed, loaded, features) => ({ signed, loaded, features })
);

export const UserTenanciesSelectors = {
  selectPermissions,
  selectAccounts,
  selectSortedAccounts,
  selectAccountsCount,
  selectAccountsIsSingle,
  selectUserId,
  selectTenantId,
  selectCurrentTenancy,
  selectCurrentTenancyName,
  selectUserIdentifer,
  selecetGuard,
  selecetIsTenantGratned,
  selecetIsFeatureEnabled,
  selecetFeatures,
  side,
  isTenancySide,
  isHostSide,
  navInformations,
  employeeCardId,
  hasEmployeeCard,
  authorizationGuard,
  isLoaded,
  employeeCardSignedCheck,
  selectHostUserId,
  selectCurrentTenancyHeliosId,
};
