import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  ApproveOrReturnEmployeeCard,
  EmployeeCardDto,
  EmployeeCardHistoryDto,
  EmployeeCardMetadataDto,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class EmployeeEmployeeCardHttpApiService {
  constructor(private rest: RestService) {}

  public get(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/employee-card/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<EmployeeCardDto>>(request);
  }

  public getSummary(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/employee-card/get-summary',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<any>>(request);
  }

  public getMetadata(
    params: TenancyRequestDto<{ versionId?: number | undefined }>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/employee-card/get/metadata',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<EmployeeCardMetadataDto>>(
      request
    );
  }

  public getDoIHaveAnEmployeeSignedCard(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/employee-card/do-i-have-signed-employee-card',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<boolean>>(request);
  }

  public update(body: TenancyRequestDto<EmployeeCardHistoryDto>) {
    const request: Rest.Request<TenancyRequestDto<EmployeeCardHistoryDto>> = {
      method: 'PUT',
      url: '/api/employee/employee-card/update',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EmployeeCardHistoryDto>,
      Rest.AbpResponse<EmployeeCardDto>
    >(request);
  }

  public taxDeclarationPDFRequest(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'POST',
      url: '/api/employee/employee-card/request-pdf-tax-declaration',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<ApproveOrReturnEmployeeCard>,
      Rest.AbpResponse<null>
    >(request);
  }
}
