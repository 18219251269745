import { CoreActions } from '../actions';
import { createReducer, Action, on, createFeatureSelector } from '@ngrx/store';

export const FEATURE_KEY = 'NXG Core';

export interface NGXCoreState {
  hover: boolean;
  width: number;
  height: number;
}

const initialState: NGXCoreState = {
  hover: false,
  width: 0,
  height: 0,
};

const r = createReducer(
  initialState,
  on(CoreActions.hoverState, (state, { hover }) => ({
    ...state,
    hover,
  })),
  on(CoreActions.dimensions, (state, { width, height }) => ({
    ...state,
    width,
    height,
  }))
);

export function reducer(state: NGXCoreState, action: Action) {
  return r(state, action);
}

export const hover = (state: NGXCoreState) => state.hover;
export const width = (state: NGXCoreState) => state.width;
export const height = (state: NGXCoreState) => state.height;
