import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  TenancyRequestDto,
  UpdateUserCanManageDepartmentsInput,
  UpdateUserDepartmentInput,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class UserDepartmentHttpApiService {
  constructor(private rest: RestService) { }

  public getLookup(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user-department/get/department-lookup',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<string[]>>(request);
  }

  public getUserDepartment(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user-department/get/user-department',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<string>>(request);
  }

  public getUserCanManageDepartment(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user-department/get/user-can-mange-department',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<string[]>>(request);
  }

  public updateUserDepartment(
    body: TenancyRequestDto<UpdateUserDepartmentInput>
  ) {
    const request: Rest.Request<TenancyRequestDto<UpdateUserDepartmentInput>> =
    {
      method: 'PUT',
      url: '/api/user-department/update/user-department',
      body
    };
    return this.rest.request<
      TenancyRequestDto<UpdateUserDepartmentInput>,
      Rest.AbpResponse<void>
    >(request);
  }

  public updateUserCanManageDepartment(
    body: TenancyRequestDto<UpdateUserCanManageDepartmentsInput>
  ) {
    const request: Rest.Request<
      TenancyRequestDto<UpdateUserCanManageDepartmentsInput>
    > = {
      method: 'PUT',
      url: '/api/user-department/update/can-manage-department',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<UpdateUserCanManageDepartmentsInput>,
      Rest.AbpResponse<void>
    >(request);
  }
}
