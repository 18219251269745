import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AdminContainerBase } from '@ralba/shared';
import { Fields } from '@syncfusion/ej2-angular-dropdowns';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ChangeLanguageStore } from './change-language.state';

@Component({
  selector: 'tpa-change-language',
  templateUrl: 'change-language.component.html',
  providers: [ChangeLanguageStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLangaugeComponent
  extends AdminContainerBase
  implements OnInit
{
  @Input() set type(value: 'DROPDOWN' | 'CARD') {
    this.state.setType({ type: value });
  }

  fields: Partial<Fields> = { text: 'displayName', value: 'name' };
  changeLanguageControl: UntypedFormControl;

  constructor(public state: ChangeLanguageStore) {
    super(state.store$);
  }

  ngOnInit() {
    this.changeLanguageControl = new UntypedFormControl();
    this.state.currentLanguage$
      .pipe(
        tap((value) => this.changeLanguageControl.patchValue(value.name)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.changeLanguageControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value) =>
          this.state.changeLanguage({
            languageName: value,
          })
        ),
        first()
      )
      .subscribe();
  }
}
