import { FormlyMultipleCheckFieldInput } from './fields/multiple-check-box/multiple-check-box.component';
import { FormlyReadOnlyFieldInput } from './fields/read-only/read-only.component';
import { FormlyRepeatCheckFieldInput } from './fields/repeat/reapat.component';
import { FormlyTreeCheckboxFieldInput } from './fields/tree-selecet/tree-selecet.component';
// import { FormlyTriCheckFieldInput } from './fields/tri-check-box/tri-check-box.component';

import { FormlyFieldCheckbox } from './fields/checkbox/checkbox.component';
import { ColorField } from './fields/color/color-filed.component';
import { ImageUploadFieldTypeComponent } from './fields/image-upload/image-upload-field.componnet';
import { FormlyFieldInput } from './fields/input/input.component';
import { FormlyFieldRadio } from './fields/radio/radio.component';
import { FormlyFieldSelect } from './fields/select/select.component';
import { FormlyFieldTextArea } from './fields/textarea/textarea.component';
import { FormlyWrapperFormField } from './wrappers/form-field/form-field.component';
import { FormlyPanelWrapperComponent } from './wrappers/panel-wrapper/panel-wrapper.component';
import { YesOrNoFormlyWrapperFormField } from './wrappers/yes-or-no-form-field/yes-or-no-form-field.component';

import { FormlyFieldButtonTrigger } from './fields/button-trigger/button-trigger.field';
import { FormlyFieldDatePicker } from './fields/date-picker/date-picker.component';
import { HeadingComponent } from './fields/heading/heading.component';
import { MonthSelectComponent } from './fields/month-select/month-select.component';
import { FormlyFieldMonthlySelect } from './fields/month-select/month-select.field';
import { FormlyMultiselectFieldInput } from './fields/multiselect/multiselect.component';
import { FormlyNumberFieldInput } from './fields/number/number.field';
import { FormlyFieldYesOrNo } from './fields/yes-or-no/yes-or-no.component';
import { FormlyPanelWrapperPinkComponent } from './wrappers/panel-wrapper-pink/panel-wrapper.component';

export const RAL_FORMLY_INPUTS = [
  // FormlyTriCheckFieldInput,
  FormlyMultipleCheckFieldInput,
  FormlyMultiselectFieldInput,
  FormlyTreeCheckboxFieldInput,
  FormlyRepeatCheckFieldInput,
  FormlyReadOnlyFieldInput,

  FormlyPanelWrapperComponent,
  FormlyFieldInput,
  FormlyFieldCheckbox,
  FormlyFieldTextArea,
  FormlyFieldRadio,
  FormlyFieldSelect,
  FormlyWrapperFormField,
  ImageUploadFieldTypeComponent,
  ColorField,
  YesOrNoFormlyWrapperFormField,
  FormlyFieldDatePicker,
  HeadingComponent,
  FormlyFieldYesOrNo,
  FormlyNumberFieldInput,
  MonthSelectComponent,
  FormlyFieldMonthlySelect,
  FormlyFieldButtonTrigger,
  FormlyPanelWrapperPinkComponent,
];
export {
  FormlyMultiselectFieldInput,
  FormlyMultipleCheckFieldInput,
  FormlyTreeCheckboxFieldInput,
  FormlyRepeatCheckFieldInput,
  FormlyReadOnlyFieldInput,
  FormlyPanelWrapperComponent,
  FormlyFieldInput,
  FormlyFieldCheckbox,
  FormlyFieldTextArea,
  FormlyFieldRadio,
  FormlyFieldSelect,
  FormlyWrapperFormField,
  YesOrNoFormlyWrapperFormField,
  ImageUploadFieldTypeComponent,
  ColorField,
  FormlyFieldDatePicker,
  HeadingComponent,
  FormlyFieldYesOrNo,
  FormlyNumberFieldInput,
  MonthSelectComponent,
  FormlyFieldMonthlySelect,
  FormlyFieldButtonTrigger,
  FormlyPanelWrapperPinkComponent,
};

export const FORMLY_CORE_TYPES = [
  {
    name: 'tree-check-box',
    component: FormlyTreeCheckboxFieldInput,
    wrappers: ['form-field'],
  },
  {
    name: 'multicheckbox',
    component: FormlyMultipleCheckFieldInput,
    wrappers: ['form-field'],
  },
  {
    name: 'multiselect',
    component: FormlyMultiselectFieldInput,
    wrappers: ['form-field'],
  },
  { name: 'read-only', component: FormlyReadOnlyFieldInput },
  { name: 'repeat', component: FormlyRepeatCheckFieldInput },
  { name: 'image-upload', component: ImageUploadFieldTypeComponent },
  {
    name: 'input',
    component: FormlyFieldInput,
    wrappers: ['form-field'],
  },
  {
    name: 'number',
    component: FormlyNumberFieldInput,
    wrappers: ['form-field'],
  },
  {
    name: 'date-picker',
    component: FormlyFieldDatePicker,
    wrappers: ['form-field'],
  },
  {
    name: 'color',
    component: ColorField,
    wrappers: ['form-field'],
  },
  {
    name: 'checkbox',
    component: FormlyFieldCheckbox,
    wrappers: ['form-field'],
    className: 'p-col-align-center',
  },
  {
    name: 'textarea',
    component: FormlyFieldTextArea,
    wrappers: ['form-field'],
  },
  {
    name: 'radio',
    component: FormlyFieldRadio,
    wrappers: ['form-field'],
  },
  {
    name: 'select',
    component: FormlyFieldSelect,
    wrappers: ['form-field'],
  },
  {
    name: 'heading',
    component: HeadingComponent,
  },
  {
    name: 'yes-or-no',
    component: FormlyFieldYesOrNo,
    wrappers: ['yes-or-no-form-field'],
  },
  {
    name: 'month-select',
    component: FormlyFieldMonthlySelect,
    wrappers: ['form-field'],
  },
  {
    name: 'tigger-button',
    component: FormlyFieldButtonTrigger,
  },
];

export const FORMLY_CORE_WRAPPERS = [
  { name: 'panel-wrapper', component: FormlyPanelWrapperComponent },
  { name: 'form-field', component: FormlyWrapperFormField },
  { name: 'yes-or-no-form-field', component: YesOrNoFormlyWrapperFormField },
  { name: 'panel-wrapper-pink', component: FormlyPanelWrapperPinkComponent },
];
