<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div *ngIf="emptyModel; else notEmpty">
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <div *ngIf="!to['sameAsAnotherAddress'] && !emptyModel">
        <p>
          {{ model?.ulice }}
          {{ model?.popCislo }}
          <span *ngIf="model?.orCislo"> / {{ model?.orCislo }} </span>
        </p>
        <p>{{ model?.mesto }}, {{ model?.psc }}</p>
      </div>
      <div *ngIf="!emptyModel && to?.['sameAsAnotherAddress']">
        <p>
          {{ to['actionText'] }}
        </p>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #same> </ng-template>
