import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { AccountActions } from '../actions';

@Injectable()
export class AccountEffects {
  logOut$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AccountActions.logOut),
        map(() => this.oidcSecurityService.logoff())
      ),
    { dispatch: false }
  );

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private actions: Actions
  ) {}
}
