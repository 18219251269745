<div #ChangePasswordCard class="e-card" *ngIf="state.isCard$ | ngrxPush else langaugeSelecetDropdown">
  <div class="e-card-header">
    <label>{{ 'TPA.LanguageSettings' | transloco | firstToUpperCase }}</label>
  </div>
  <div class="e-card-content">
    <ng-container *ngTemplateOutlet="langaugeSelecetDropdown"></ng-container>
  </div>
  <div class="e-card-footer">
  </div>
</div>

<ng-template #langaugeSelecetDropdown>
  <ejs-dropdownlist [formControl]="changeLanguageControl" [fields]="fields" [dataSource]="state.langauges$ | ngrxPush">
  </ejs-dropdownlist>
</ng-template>