import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SessionService } from './session.service';
import { Store } from '@ngrx/store';
import { AppConfig } from '@ralba/shared';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor() {}
}
