export interface IErrorResponse {
  message?: string;
  code?: any;
  details?: string;
  validationErrors?: any[];
}

export interface IAsyncState {
  loading: boolean;
  loaded: boolean;
  error: IErrorResponse | null;
}

export const DEFAULT_ASYNC_STATE: IAsyncState = {
  loading: false,
  loaded: false,
  error: null,
};

export const initialState: IAsyncState = {
  loading: false,
  loaded: false,
  error: null,
};
export const loadingState: IAsyncState = {
  loaded: false,
  loading: true,
  error: null,
};
export const loadedState: IAsyncState = {
  loaded: true,
  loading: false,
  error: null,
};

export const selectIsLoaded = (state: IAsyncState) =>
  !state.loading && state.loaded;

export const selectIsLoading = (state: IAsyncState) =>
  state.loading && !state.loaded;

export const selectLoadingState = (state: IAsyncState) => ({
  loading: state.loading,
  loaded: state.loaded,
});

export const selectErrorMessage = (state: IAsyncState) => state.error?.message;
