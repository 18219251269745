import { CommonModule } from '@angular/common';
import { ClaimPipe } from './claim.pipe';
import { EducationLevelPipe } from './educationLevel.pipe';
import { GenderPipe } from './gender.pipe';
import { HealthDisadvantagePipe } from './healthdisadvantage.pipe';
import { LoanPurposePipe } from './loanpurpose.pipe';
import { MaritalStatusPipe } from './maritalstatus.pipe';
import { MonthPipe } from './month-view.pipe';
import { NullToDashPipe } from './null-to-dash.pipe';
import { ResidentureEvaluationPipe } from './residenture-evaluation.pipe';
import { ResidenturePipe } from './residenture.pipe';
import { SalaryPipe } from './salary.pipe';
import { YesOrNoPipe } from './yes-or-no.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';


export const PIPES = [
  MonthPipe,
  EducationLevelPipe,
  MaritalStatusPipe,
  SalaryPipe,
  YesOrNoPipe,
  GenderPipe,
  ResidenturePipe,
  HealthDisadvantagePipe,
  ClaimPipe,
  LoanPurposePipe,
  ResidentureEvaluationPipe,
  NullToDashPipe
];


export {
  ClaimPipe, EducationLevelPipe, GenderPipe, HealthDisadvantagePipe, LoanPurposePipe, MaritalStatusPipe, MonthPipe, NullToDashPipe, ResidentureEvaluationPipe, ResidenturePipe, SalaryPipe,
  YesOrNoPipe
};


@NgModule({
  imports: [CommonModule, TranslocoModule],
  exports: [...PIPES],
  declarations: [...PIPES],
  providers: [],
})
export class EmployeesFormPipesModule { }
