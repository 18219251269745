import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
export { GridBuilder, GridService } from './grid.service';
export * from './models';

import { createNgrxDataActions, IGridActions } from './grid.actions';
import {
  createNgrxReducer,
  DataGridStateType,
  GridState,
} from './grid.reducer';
import { createSelectors } from './grid.selectors';
import { GridService } from './grid.service';

export function createGrid<T>(
  dataGridName: string,
  featureSelector: DataGridStateType,
  gridInitialState?: GridState
) {
  const adapter: EntityAdapter<T> = createEntityAdapter<T>();
  const actions = createNgrxDataActions(dataGridName);
  const reducer = createNgrxReducer(adapter, actions, gridInitialState);
  const selectors = createSelectors(featureSelector, adapter);

  return {
    actions,
    reducer,
    selectors,
    gridService: (store$: Store<never>) =>
      new GridService(store$, selectors, actions),
  };
}

export const createGridActions = (dataGridName: string) =>
  createNgrxDataActions(dataGridName);
export const createGridReducer = <T>(
  dataGridName: string,
  actions: IGridActions,
  gridInitialState?: GridState
) => {
  const adapter: EntityAdapter<T> = createEntityAdapter<T>();
  return createNgrxReducer(adapter, actions, gridInitialState);
};
export const createGridSelectors = <T>(
  featureSelector: DataGridStateType,
  adapter: EntityAdapter<T>
) => createSelectors(featureSelector, adapter);

// export namespace NgrxGrid {
//   export interface GridState  {
//     dataState: IDataState
//     pageSize?: number
//     pageSizes?: boolean
//   }

//   function reducerGetData(state: IDataGridState<any>, data: PagedResult<any> | any[], adapter: EntityAdapter<any>) {
//     if ("totalCount" in data) {
//       return adapter.setAll(data.items, {
//         ...state,
//         totalCount: data.totalCount
//       })
//     }
//     else if (data instanceof Array) {
//       return adapter.setAll(data, {
//         ...state,
//         totalCount: data.length
//       })
//     }
//   }

//   export function createNgrxDataActions<T>(dataGridName: string) {
//     return {
//       initialize: createAction(`[${dataGridName}] initialize`),
//       getFilterOptions: createAction(`[${dataGridName}] get filter options`),
//       getFilterOptionsSuccess: createAction(`[${dataGridName}] get filter options success`, props<{
//         result: any
//       }>()),
//       getDataSuccess: createAction(`[${dataGridName}] get data success`, props<{ result: any }>()),
//       dataStateChange: createAction(`[${dataGridName}] dataStateChange`, props<{
//         gridState: GridState
//       }>())
//     }
//   }

//   export interface IDataGridState<T = any> extends EntityState<T> {
//     totalCount: number;
//     gridState: GridState;
//     filterOptions: any;
//   }

//   type DataGridStateType = MemoizedSelector<object, IDataGridState, DefaultProjectorFn<IDataGridState>>;

//   export function createSelectors(featureSelector: DataGridStateType, adapter: EntityAdapter<any>) {
//     const { selectAll } = adapter.getSelectors();

//     const selectAllData = createSelector(
//       featureSelector,
//       selectAll
//     )

//     const selectGridState = createSelector(
//       featureSelector,
//       (x) => x.gridState
//     )

//     const selectTotalCount = createSelector(
//       featureSelector,
//       (s) => s.totalCount
//     )

//     const selectFilterOptions = createSelector(
//       featureSelector,
//       (s) => s.filterOptions
//     )
//     return {
//       selectData: createSelector(
//         selectAllData,
//         selectTotalCount,
//         selectGridState,
//         (result, count, grid) => ({
//           result: result,
//           count
//         })
//       ),
//       gridState: selectGridState,
//       selectFilterOptions
//     };
//   }

//   export function createNgrxData<T>(dataGridName: string, gridInitialState?: GridState) {
//     const adapter: EntityAdapter<T> = createEntityAdapter<T>();
//     const initialState: IDataGridState<T> = adapter.getInitialState({
//       totalCount: 0,
//       gridState: {
//         dataState: {},
//         // skip: gridInitialState.skip || 0,
//         // take: gridInitialState.take || 10,
//         pageSizes: gridInitialState.pageSizes === undefined ? false : gridInitialState.pageSizes,
//         // sorted: gridInitialState.sorted || []
//       },
//       filterOptions: {}
//     });
//     const actions = createNgrxDataActions<T>(dataGridName);

//     const reducer = createReducer(initialState,
//       on(actions.getDataSuccess, (state, { result }) => reducerGetData(state, result, adapter)),
//       on(actions.getFilterOptionsSuccess, (state, { result }) => ({
//         ...state,
//         filterOptions: result
//       })),
//       on(actions.dataStateChange, (state, { gridState }) => ({
//         ...state, gridState: {
//           ...state.gridState,
//           ...gridState
//         }
//       }))
//     );

//     return {
//       state: initialState,
//       reducer,
//       actions,
//       adapter,
//     }
//   }

// }
