import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'ral-value-icon',
  templateUrl: 'value-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueIconComponent implements OnInit {
  @Input() iconStyle = 'fal';
  @Input() trueIcon = 'fa-check';
  @Input() falseIcon = 'fa-times';
  @Input() value: boolean;

  constructor() {}

  ngOnInit() {}
}
