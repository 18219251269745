import { FormlyFieldConfig } from '@ngx-formly/core';

export const sharedAffidavit = (inGuide = false): FormlyFieldConfig[] => [
  // {
  //   // <h2>5. ${translate(
  //   //   'TPA.TaxpayerDeclaration'
  //   // )} ${new Date().getFullYear()}</h2>
  //   template: `

  //     <p>${translate('TPA.WantDeclaration')}</p>
  //   `,
  // },
  {
    key: 'chciProhlaseni',
    type: 'yes-or-no',
    className: 'p-col-12',
    wrappers: ['yes-or-no-form-field'],
    props: {
      required: true,
      translate: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      label: 'TPA.WantDeclaration',
      description: 'TPA.AffidavitDesc',
      // change: (field, event) => {
      //   if (field?.formControl && field.formControl.value == false) {
      //     field?.parent?.fieldGroup
      //       ?.find((p) => p.key == 'prohlaseniKProhlaseni')
      //       ?.formControl?.patchValue(undefined);
      //   }
      // },
    },
  },
  // {
  //   template: `
  //     <p>${translate('TPA.NoOtherDeclaration')}</p>
  //   `,
  //   hideExpression: '(model?.chciProhlaseni != true)',
  // },
  {
    key: 'prohlaseniKProhlaseni',
    type: 'yes-or-no',
    className: 'p-col-12',
    wrappers: ['yes-or-no-form-field'],
    props: {
      required: true,
      label: 'TPA.NoOtherDeclaration',
      ...(inGuide
        ? { description: 'TPA.TaxDeclarationSameMonthDescription' }
        : {}),
      translate: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
    },
    hideExpression: '(model?.chciProhlaseni != true)',
    validation: {
      show: true,
    },
    validators: {
      validation: [{ name: 'taxDeclarationRequiredTrue' }],
    },
  },
];
