<div class="task" *ngIf="task">
  <div class="p-grid p-grid--with-bottom">
    <div class="p-col">
      <time> {{ (task.creationTime + "") | translocoDate: { dateStyle: 'medium', timeStyle: 'short' } }}</time>
      <p>
        <strong>
          {{task.taskName}}
        </strong>
      </p>
    </div>
    <div class="p-col-fixed">
      <button class="e-btn e-lib e-primary" (click)="completBtnclick(task.id)">
        <span>
          {{'TPA.Completed' | transloco | firstToUpperCase}}
        </span>
      </button>
    </div>
  </div>
</div>