import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  GetNotificationsOutput,
  GetUserNotificationsInput,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class NotificationHttpApiService {
  constructor(private rest: RestService) {}

  public getUserNotifications(
    params: TenancyRequestDto<GetUserNotificationsInput>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/notification/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<GetNotificationsOutput>>(
      request
    );
  }

  public markAsRead(body: TenancyRequestDto<EntityDto<string>>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto<string>>> = {
      method: 'PUT',
      url: '/api/notification/mark-as-read',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EntityDto<string>>,
      Rest.AbpResponse<null>
    >(request);
  }

  public markAllAsRead(body: TenancyRequestDto) {
    const request: Rest.Request<TenancyRequestDto> = {
      method: 'PUT',
      url: '/api/notification/mark-all-as-read',
      body,
    };
    return this.rest.request<TenancyRequestDto, Rest.AbpResponse<null>>(
      request
    );
  }
}
