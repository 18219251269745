import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'tpa-yes-r-no-formly-wrapper-form-field',
  template: `
    <div
      *ngIf="(to.label || to['labelT']) && to['hideLabel'] !== true"
      class="label-wrapper mb-1"
    >
      <ng-container *transloco="let t">
        <div class="e-card-header p-0" style="padding: 0">
          <div class="p-grid p-nogutter p-justify-between">
            <div class="p-col-fixed">
              {{
                to['labelT'] ? t(to['labelT']['key'], to['labelT']['params']) : to.label
              }}

              <ejs-tooltip
                *ngIf="to.required && to['hideRequiredMarker'] !== true"
                [content]="'TPA.FieldRequired' | transloco"
                target=".tooltip-buble"
              >
                <span tooltipPosition="top"> * </span>
              </ejs-tooltip>
            </div>
            <div class="p-col-fixed">
              <ejs-tooltip
                *ngIf="to.tooltip || to.tooltipT"
                [content]="
                  to.tooltipT
                    ? t(to.descriptionT['key'], to.tooltipT['params'])
                    : to.tooltip
                "
                target=".tooltip-buble"
              >
                <i
                  class="fal fa-info-circle tooltip tooltip-buble"
                  *ngIf="to.tooltip"
                  tooltipPosition="top"
                ></i>
              </ejs-tooltip>
            </div>
          </div>
        </div>
        <p
          *ngIf="to.description || to.descriptionT"
          class="description"
        >
          {{
            to.descriptionT
              ? t(to.descriptionT['key'], to.descriptionT['params'])
              : to.description
          }}
        </p>
      </ng-container>
    </div>
    <ng-container #fieldComponent></ng-container>
    <div class="ui-messages-error" *ngIf="showError">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  styles: [
    `
      .tooltip {
        vertical-align: baseline;
        cursor: pointer;
      }
    `,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesOrNoFormlyWrapperFormField extends FieldWrapper {}
