import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, ITpaCoreState } from '../reducers';


export const tpaCoreFeatureSelector = createFeatureSelector<
  ITpaCoreState
>(FEATURE_KEY);

export const selecetUserTenancies = createSelector(
  tpaCoreFeatureSelector,
  s => s.userTenancies
);