import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  ChangePasswordInput,
  ChangeUserLanguageDto,
  DisableTwoFactorValidationInput,
  GetPasswordComplexitySettingOutput,
  RequestSmsVerificationCodeInput,
  TpaCurrentUserProfileEditDto,
  TpaUpdateGoogleAuthenticatorKeyOutput,
  TwoFactorValidationInput,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ProfileHttpApiService {
  constructor(private rest: RestService) {}

  changePassword(body: ChangePasswordInput) {
    const request: Rest.Request<ChangePasswordInput> = {
      method: 'POST',
      url: `/api/profile/change-password`,
      body,
    };

    return this.rest.request<ChangePasswordInput, Rest.AbpResponse<null>>(
      request
    );
  }

  changeLanguage(body: ChangeUserLanguageDto) {
    const request: Rest.Request<ChangeUserLanguageDto> = {
      method: 'POST',
      url: `/api/profile/change-language`,
      body,
    };

    return this.rest.request<ChangeUserLanguageDto, Rest.AbpResponse<null>>(
      request
    );
  }

  getPasswordComplexitySetting(params: {}) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `/api/profile/get-password-complexity-settings`,
      params,
    };

    return this.rest.request<
      null,
      Rest.AbpResponse<GetPasswordComplexitySettingOutput>
    >(request);
  }

  getCurrentUserProfile(params: {}) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `/api/profile/get-current-user-profile`,
      params,
    };

    return this.rest.request<
      null,
      Rest.AbpResponse<TpaCurrentUserProfileEditDto>
    >(request);
  }

  enableTwoFactorAuth(body: TwoFactorValidationInput) {
    const request: Rest.Request<TwoFactorValidationInput> = {
      method: 'POST',
      url: `/api/profile/enable-two-factor-auth`,
      body,
    };

    return this.rest.request<TwoFactorValidationInput, Rest.AbpResponse<null>>(
      request
    );
  }

  disableTwoFactorAuth(body: DisableTwoFactorValidationInput) {
    const request: Rest.Request<DisableTwoFactorValidationInput> = {
      method: 'POST',
      url: `/api/profile/disable-two-factor-auth`,
      body,
    };

    return this.rest.request<
      DisableTwoFactorValidationInput,
      Rest.AbpResponse<null>
    >(request);
  }

  sendVerificationSmsCode(body: RequestSmsVerificationCodeInput) {
    const request: Rest.Request<RequestSmsVerificationCodeInput> = {
      method: 'POST',
      url: `/api/profile/send-verification-sms-code`,
      body,
    };

    return this.rest.request<
      RequestSmsVerificationCodeInput,
      Rest.AbpResponse<null>
    >(request);
  }

  updateGoogleAuthenticatorKey() {
    const request: Rest.Request<null> = {
      method: 'PUT',
      url: `/api/profile/update-google-authentication-key`,
    };

    return this.rest.request<
      null,
      Rest.AbpResponse<TpaUpdateGoogleAuthenticatorKeyOutput>
    >(request);
  }
}
