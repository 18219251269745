import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { Injectable } from '@angular/core';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { NavMenuActions } from '@ralba/core';
import { NGXCoreSelectors } from '@ralba/toolbox';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AppEffects {
  onNavigeted$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ROUTER_NAVIGATED),
        withLatestFrom(this.store$.pipe(select(NGXCoreSelectors.selectWidth))),
        map((action) => NavMenuActions.closeSideNav())
      ),
    { dispatch: true }
  );

  constructor(
    private actions: Actions,
    private store$: Store<never> //private cookieService: CookieService
  ) {}
}
