import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'claim',
})
export class ClaimPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText;
    switch (value) {
      case 'firstchild': {
        returnedText = translate('TPA.ClaimAmount1');
        break;
      }
      case 'secondchild': {
        returnedText = translate('TPA.ClaimAmount2');
        break;
      }
      case 'thirdandmorechild': {
        returnedText = translate('TPA.ClaimAmount3');
        break;
      }
      case 'notapply': {
        returnedText = translate('TPA.ClaimAmountNo');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
