import { Component, OnDestroy, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { FieldType } from '@ngx-formly/core';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

@Component({
  selector: 'ral-formly-image-upload',
  template: `
    <ng-container *transloco="let t">
      <div style="max-width:100%">
        <img
          *ngIf="to.attributes.imageUrl"
          [src]="to.attributes.imageUrl"
          style="max-width:100%; max-height: 40vh;"
        />
        <div class="box">
          <label class="e-primary e-control e-btn e-lib">
            <input
              type="file"
              (change)="fileChangeEvent($event)"
              accept="image/*"
              class="inputfile inputfile-1"
            />
            <i class="fal fa-upload"></i>&nbsp;
            <span>
              {{
                text
                  ? text
                  : to.textT
                  ? t(to.textT['key'], to.textT['params'])
                  : to.text
              }}
            </span></label
          >
        </div>
      </div>
    </ng-container>
  `,
})
export class ImageUploadFieldTypeComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  get tmp(): string {
    return translate(this.to.tmp.toString());
  }
  text: string;

  defaultOptions = {
    props: {
      text: 'Choose a file...',
      tmp: 'Choose a file...',
      attributes: {
        imageUrl: null,
        uploadUrl: null,
      },
    },
  };

  public uploader: FileUploader;
  private _uploaderOptions: FileUploaderOptions = {};

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initFileUploader();
  }

  ngOnDestroy(): void {}

  fileChangeEvent(event: any): void {
    const file = event.target.files[0] as File;

    if (file) {
      this.text = event.target.value.split('\\').pop();
    } else {
      this.text = this.tmp;
      return;
    }

    if (!this.isImage(file)) {
      alert('This is not image');
      return;
    }

    if (file && file.size < 2097152) {
      //2MB
      this.uploader.clearQueue();
      this.uploader.addToQueue([file]);
      this.uploader.uploadAll();
    } else {
      alert('Image is larger then 2Mb');
    }
  }

  initFileUploader(): void {
    this.uploader = new FileUploader({
      url: this.to.attributes.uploadUrl.toString(),
    });
    this._uploaderOptions.autoUpload = false;
    this._uploaderOptions.removeAfterUpload = true;
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      form.append('FileType', fileItem.file.type);
      form.append('FileName', fileItem.file.name);
      form.append('FileToken', 'null');
    };

    this.uploader.onSuccessItem = (item, response, status) => {
      const res = JSON.parse(response);
      this.formControl.patchValue({
        fileName: res.result.fileName,
        fileType: res.result.fileType,
        fileToken: res.result.fileToken,
        tempUrl: res.result.tempUrl,
      });
      this.formControl.markAsDirty();
    };

    this.uploader.setOptions(this._uploaderOptions);
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }
}
