import { Component, Input } from '@angular/core';

@Component({
  selector: 'ral-side-menu-item',
  templateUrl: 'side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItemComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() routerLink: any;
  @Input() visible: boolean;
  @Input() disabled: boolean;
}
