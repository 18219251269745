import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-form-field',
  template: `
    <div
      *ngIf="(to.label || to.labelT) && to.hideLabel !== true"
      class="label-wrapper"
    >
      <ng-container *transloco="let t">
        <label [for]="id" class="field-label">
          <div class="p-grid p-nogutter p-justify-between">
            <div class="p-col-fixed">
              {{
                to.labelT ? t(to.labelT['key'], to.labelT['params']) : to.label
              }}

              <ejs-tooltip
                *ngIf="to.required && to['hideRequiredMarker'] !== true"
                [content]="'TPA.FieldRequired' | transloco"
                target=".star"
              >
                <span tooltipPosition="top" class="star"> * </span>
              </ejs-tooltip>
            </div>
            <div class="p-col-fixed">
              <ejs-tooltip
                *ngIf="to.tooltip || to.tooltipT"
                [content]="
                  to.tooltipT
                    ? t(to.descriptionT['key'], to.tooltipT['params'])
                    : to.tooltip
                "
                target=".tooltip-buble"
              >
                <i
                  class="fal fa-info-circle tooltip tooltip-buble"
                  *ngIf="to.tooltip"
                  tooltipPosition="top"
                ></i>
              </ejs-tooltip>
            </div>
          </div>
        </label>
        <p *ngIf="to.description || to.descriptionT" class="description">
          {{
            to.descriptionT
              ? t(to.descriptionT['key'], to.descriptionT['params'])
              : to.description
          }}
        </p>
      </ng-container>
    </div>
    <ng-container #fieldComponent></ng-container>
    <div class="ui-messages-error" *ngIf="showError">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  styles: [
    `
      .tooltip {
        vertical-align: baseline;
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyWrapperFormField extends FieldWrapper {}
