import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SessionSelectors } from '@ralba/core';
import { AdminContainerBase, Rest, RestService } from '@ralba/shared';
import {
  TPA_FEATURES,
  TPA_PERMISSIONS, TPA_PERMISSIONS_COMPOUND, UserTenanciesSelectors
} from '@tpa/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tpa-tenancy-navigation',
  templateUrl: 'tenancy-navigation.container.html',
})
export class TenancyNavigationContainer extends AdminContainerBase {
  identifer$ = this.store$.pipe(
    select(UserTenanciesSelectors.selectUserIdentifer)
  );
  loaded$ = this.store$.pipe(select(SessionSelectors.selectIsLoaded));

  hasEmployeeCard$ = this.store$.pipe(
    select(UserTenanciesSelectors.hasEmployeeCard)
  );

  isLoaded$ = this.store$.pipe(select(UserTenanciesSelectors.isLoaded));

  // TODO: pozdeji přesunout do servisy
  version$ = of(true).pipe(
    switchMap(() => this.rest.request<void, Rest.AbpResponse<{ result: string }>>({
      method: 'GET',
      url: `/api/version`,
    }).pipe(
      map((version) => version.result),
      catchError(() => of(null))
    ))
  )

  accountsNotSingle$ = this.store$.pipe(
    select(UserTenanciesSelectors.selectAccountsIsSingle),
    map((p) => !p)
  );

  constructor(private rest: RestService, store$: Store<never>) {
    super(store$)
  }


  compound_permissions = TPA_PERMISSIONS_COMPOUND;

  FEATURES = TPA_FEATURES;
  PERMISSIONS = TPA_PERMISSIONS;
}
