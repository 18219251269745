import {Injectable} from '@angular/core';
import {Rest, RestService} from '@ralba/shared';
import {
  EmailSettingsEditDto,
  GetLanguagesConfigurationDto,
  HrPortalLicenseDto,
  HrPortalSettingsDto,
  HrPortalSettingsInputDto,
  TpaSecuritySettingsEditDto,
  SendTestEmailRequest,
  SendTestSmsInput,
  SetDefaultLanguageInput,
  TwilioConfigurationDto,
  TwilioConfigurationInputDto
} from '../dto';


@Injectable({ providedIn: 'root' })
export class ConfigurationHttpApiService {
  constructor(private rest: RestService) {}


  public getApplicationLanguages() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/app-configuration/application-languages',
    };
    return this.rest.request<null, Rest.AbpResponse<GetLanguagesConfigurationDto>>(request);
  }

  public getTwilioConfiguration() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/app-configuration/twilio-configuration',
    };
    return this.rest.request<null, Rest.AbpResponse<TwilioConfigurationDto>>(request);
  }

  public getSmtpConfiguration() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/app-configuration/smtp-configuration',
    };
    return this.rest.request<null, Rest.AbpResponse<EmailSettingsEditDto>>(request);
  }

  public getSecurityConfiguration() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/app-configuration/security-configuration',
    };
    return this.rest.request<null, Rest.AbpResponse<TpaSecuritySettingsEditDto>>(request);
  }

  public getHrPortalConfiguration() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/app-configuration/hr-portal-configuration',
    };
    return this.rest.request<null, Rest.AbpResponse<HrPortalSettingsDto>>(request);
  }

  public getLicenseConfiguration() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/app-configuration/license-key',
    };
    return this.rest.request<null, Rest.AbpResponse<HrPortalLicenseDto>>(request);
  }

  public updateTwilioConfiguration(body: TwilioConfigurationInputDto) {
    const request: Rest.Request<TwilioConfigurationInputDto> = {
      method: 'PUT',
      url: '/api/app-configuration/twilio-configuration',
      body
    };
    return this.rest.request<TwilioConfigurationInputDto, Rest.AbpResponse<null>>(request);
  }

  public updateApplicationDefaultLanguage(body: SetDefaultLanguageInput) {
    const request: Rest.Request<SetDefaultLanguageInput> = {
      method: 'PUT',
      url: '/api/app-configuration/set-default-application-language',
      body
    };
    return this.rest.request<SetDefaultLanguageInput, Rest.AbpResponse<null>>(request);
  }

  public updateLicenseConfiguration(body: HrPortalLicenseDto) {
    const request: Rest.Request<HrPortalLicenseDto> = {
      method: 'PUT',
      url: '/api/app-configuration/license-key',
      body
    };
    return this.rest.request<HrPortalLicenseDto, Rest.AbpResponse<null>>(request);
  }

  public updateSmtpConfiguration(body: EmailSettingsEditDto) {
    const request: Rest.Request<EmailSettingsEditDto> = {
      method: 'PUT',
      url: '/api/app-configuration/smtp-configuration',
      body
    };
    return this.rest.request<EmailSettingsEditDto, Rest.AbpResponse<null>>(request);
  }

  public updateSecurityConfiguration(body: TpaSecuritySettingsEditDto) {
    const request: Rest.Request<TpaSecuritySettingsEditDto> = {
      method: 'PUT',
      url: '/api/app-configuration/security-configuration',
      body
    };
    return this.rest.request<TpaSecuritySettingsEditDto, Rest.AbpResponse<null>>(request);
  }

  public updateHrPortalConfiguration(body: HrPortalSettingsInputDto) {
    const request: Rest.Request<HrPortalSettingsInputDto> = {
      method: 'PUT',
      url: '/api/app-configuration/hr-portal-configuration',
      body
    };
    return this.rest.request<HrPortalSettingsInputDto, Rest.AbpResponse<null>>(request);
  }

  public sendTestSms(body: SendTestSmsInput) {
    const request: Rest.Request<SendTestSmsInput> = {
      method: 'POST',
      url: '/api/app-configuration/send-test-sms',
      body
    };
    return this.rest.request<SendTestSmsInput, Rest.AbpResponse<null>>(request);
  }

  public sendTestEmail(body: SendTestEmailRequest) {
    const request: Rest.Request<SendTestEmailRequest> = {
      method: 'POST',
      url: '/api/template-mailing/send-test-mailing-templates',
      body
    };
    return this.rest.request<SendTestEmailRequest, Rest.AbpResponse<null>>(request);
  }

}
