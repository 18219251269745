import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

export type CanDeactivateCallback = () => Observable<boolean>;

@Injectable()
export class CanDeactivateCallbackService {
  callbacks: Record<string, CanDeactivateCallback | undefined> = {};

  register(callback: CanDeactivateCallback) {
    const key = uuidv4().toString();
    this.callbacks = {
      ...this.callbacks,
      [key]: callback,
    };
    return key;
  }
  unregister(key: string) {
    this.callbacks = {
      ...this.callbacks,
      [key]: undefined,
    };
  }

  getAllCallbacks() {
    return Object.values(this.callbacks)
      .filter((p): p is CanDeactivateCallback => p !== undefined)
      .map((p) => p());
  }
}
