<div class="right-side-sticky" [class.right-side-sticky--open]="open">
  <div class="p-col height100" style="overflow-x: hidden; overflow-y: auto">
    <div class="p-grid p-nogutter p-dir-col height100">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="right-side-sticky__toggle" (click)="toggle()">
  <i *ngIf="!open" class="{{ sideToggleIconOpen }}"></i>
  <i *ngIf="open" class="{{ sideToggleIconClose }}"></i>
</div>
