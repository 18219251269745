import { AuthenticationGurad } from './authenticate.guard';
import { CanDeactivateUnsavedDataGuard } from './can-deactivate.guard';
import { InitGuard } from './loaded.guard';
import { RepeatGetAllGuard } from './repeat-get-all-call.guard';
import {
  TenancyGuard,
  TenancyGetGuard,
  TenancyLoadedGuard,
} from './tenancy.guard';
import { TpaAuthorizationGuard } from './tpaAuthorization.guard';

export const GUARDS = [
  TenancyGuard,
  InitGuard,
  TpaAuthorizationGuard,
  TenancyGetGuard,
  CanDeactivateUnsavedDataGuard,
  AuthenticationGurad,
  TenancyLoadedGuard,
  RepeatGetAllGuard,
];

export {
  TenancyGuard,
  InitGuard,
  TpaAuthorizationGuard,
  TenancyGetGuard,
  CanDeactivateUnsavedDataGuard,
  AuthenticationGurad,
  TenancyLoadedGuard,
  RepeatGetAllGuard,
};
