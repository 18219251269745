import { AbstractControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { isValid, parse } from 'date-fns';
import * as moment from 'moment';


/**
 * Validation phone number
 * +(6-15)d
 */
export function validateDate(control: AbstractControl) {
  const value: string = (control.value || '').replace(' ', '');

  // TODO take format
  const date = parse(value, 'dd.MM.yyyy', new Date());

  if (!value || !isValid(date)) {
    return {
      fieldMatch: {
        message: `${translate('TPA.ValidDate')}`,
      },
    };
  }
}

/**
 * Validation phone number
 * +(6-15)d
 */
export function validatePhoneNumber(control: AbstractControl) {
  const value: string = control.value;

  if (value == null || !value.match(/^[\+]?[0-9]{6,15}$/)) {
    return {
      fieldMatch: {
        message: `${translate('TPA.PhoneNumberValidation')}`,
      },
    };
  }
}

export function validateDiscountForInvalidityCollision(
  control: AbstractControl
) {
  const zakladniSlevaNaInvalidituMesice =
    control.value?.zakladniSlevaNaInvalidituMesice?.value;
  const rozsirenaSlevaNaInvalidituMesice =
    control.value?.rozsirenaSlevaNaInvalidituMesice?.value;
  if (
    zakladniSlevaNaInvalidituMesice == undefined ||
    rozsirenaSlevaNaInvalidituMesice == undefined
  )
    return null;
  const a = zakladniSlevaNaInvalidituMesice | rozsirenaSlevaNaInvalidituMesice;
  const b = zakladniSlevaNaInvalidituMesice + rozsirenaSlevaNaInvalidituMesice;

  if (isNaN(a) || isNaN(b) || a == b) {
    return null;
  }
  return {
    fieldMatch: {
      message: `${translate('TPA.CannotCombineInvalidities')}`,
    },
  };
}
export function validateUrokType(control: AbstractControl) {
  // console.log(control.value);
  const prohlaseniUver = control.value?.prohlaseniUver;
  const prohlaseniUver2 = control.value?.prohlaseniUver2;

  if (control.value?.uver != true || prohlaseniUver || prohlaseniUver2)
    return null;

  return {
    fieldMatch: {
      message: `${translate('TPA.AtLeastOneTypeOfUver')}`,
    },
  };
}

/**
 * Validace Rodného čísla
 * 1. spočti zbytek po dělení prvních devíti číslic a čísla 11
 * 2. je-li zbytek 10, musí být poslední číslice 0
 * 3. jinak poslední číslice musí být rovna zbytku
 */
export function validateRodneCislo(control: AbstractControl) {
  const value: string = control.value;

  if (value == null || !value.match(/(\d{6}\/\d{3,4})/)) {
    return {
      fieldMatch: {
        message: `${translate('TPA.BirthNumberFormatNotValid')}`,
      },
    };
  }

  const withoutSlash = value.replace('/', '');

  const syear = withoutSlash.slice(0, 2);
  const smonth = withoutSlash.slice(2, 4);
  const sday = withoutSlash.slice(4, 6);
  const sext = withoutSlash.slice(6, 9);
  const compose = `${syear}${smonth}${sday}${sext}`;
  let year = parseInt(withoutSlash.slice(0, 2));
  let month = parseInt(withoutSlash.slice(2, 4));
  const day = parseInt(withoutSlash.slice(4, 6));
  const ext = parseInt(withoutSlash.slice(6, 9));
  const c = withoutSlash.length == 10 ? parseInt(withoutSlash.slice(9, 10)) : 0;
  // if (withoutSlash.length == 9) {
  //   year += year < 54 ? 1900 : 1800;
  // }
  if (withoutSlash.length == 9) {
    if (year < 54) {
      year += 1900;
    } else {
      return {
        fieldMatch: {
          message: `${translate('TPA.BirthNumberNotValid')} (< 1900)`,
        },
      };
    }
  } else {
    let mod = parseInt(compose) % 11;
    if (mod == 10) mod = 0;
    if (mod != c) {
      return {
        fieldMatch: {
          message: `${translate('TPA.BirthNumberNotValid')}`,
        },
      };
    }
    year += year < 54 ? 2000 : 1900;
  }

  if (month > 70 && year > 2003) {
    month -= 70;
  } else if (month > 50) {
    month -= 50;
  } else if (month > 20 && year > 2003) {
    month -= 20;
  }

  // kontrola data
  //console.log(`${year}-${minTwoChar(month)}-${sday}`);
  if (!moment(`${year}-${minTwoChar(month)}-${sday}`).isValid()) {
    return {
      fieldMatch: {
        message: `${translate('TPA.BirthNumberNotValid')}`,
      },
    };
  }

  return null;
}

function minTwoChar(value: number) {
  const val = value.toString();
  if (val.length == 1) {
    return '0' + val;
  }
  return val;
}

//// helper fuxtion

export function requiredTrue(control: AbstractControl) {
  if (control.value === true) {
    return null;
  }
  return {
    fieldMatch: {
      message: `${translate('TPA.YouMustAggree')}`,
    },
  };
}

//// helper fuxtion

export function taxDeclarationRequiredTrue(control: AbstractControl) {
  if (control.value === true) {
    return null;
  }
  return {
    fieldMatch: {
      message: `${translate('TPA.TaxDeclarationRequiredTrue')}`,
    },
  };
}

///

/**
 *
 */
export function minlengthValidationMessage(err, field) {
  return `${translate('TPA.LeastCharacters{0}', {
    '0': field.props.minLength,
  })}`;
}

/**
 *
 */
export function maxlengthValidationMessage(err, field) {
  return `${translate('TPA.LeastValue{0}', {
    '0': field.props.maxLength,
  })}`;
}

/**
 *
 */
export function minValidationMessage(err, field) {
  return `${translate('TPA.MostValue{0}', { '0': field.props.min })}`;
}

/**
 *
 */
export function maxValidationMessage(err, field) {
  return `${translate('TPA.LessValue{0}', { '0': field.props.max })}`;
}

/**
 *
 */
export function requiredMessage(err, field) {
  return translate('TPA.FieldRequired');
}

/**

 */
export function validateDiscountsForNotRezidenci(control: AbstractControl) {
  const {
    zakladniSlevaNaInvalidituMesice,
    rozsirenaSlevaNaInvalidituMesice,
    mamdeti,
    rezidentura,
  } = control.value;

  if (rezidentura == 'true') {
    return null;
  } else if (
    zakladniSlevaNaInvalidituMesice > 0 ||
    rozsirenaSlevaNaInvalidituMesice > 0 ||
    mamdeti == true
  ) {
    return {
      fieldMatch: {
        message: `${translate('TPA.CannotGetDiscountsForNotRezidenci')}`,
      },
    };
  }
  return null;
}

export function RzdDateValidator(control: AbstractControl) {
  const { lastDateForFirstSign, lastDateForEdit } = control.value;

  // avoid displaying the message error when values are empty
  if (!lastDateForEdit || !lastDateForFirstSign) {
    return null;
  }

  if (moment(lastDateForEdit).isSameOrAfter(moment(lastDateForFirstSign))) {
    return null;
  }

  // ! Stejný klíč jako na serverz v RzdAppService, Metoda: UpdateRzdDates
  return { rzdDates: { message: translate('TPA.RZD_DATES_ERROR') } };
}
