<h3 class="h3" *ngIf="hideHeading">
  {{ 'TPA.UploadedAttachments' | transloco }}
</h3>
<div class="p4-grid files">
  <div *ngFor="let document of state.data$ | async" class="p-col-12">
    <div *ngIf="document" class="document-list-item" (click)="linkClick.emit(document)">
      <div class="p-grid p-grid--with-bottom p-justify-between">
        <div class="p-col-fixed">
          <i class="fal fa-file"></i>
          {{ document.name }}
        </div>
        <div class="p-col-fixed">
          <div class="p-grid p-grid--with-bottom">
            <div class="p-col-fixed">
              {{ 'TPA.Uploaded' | transloco }}:
              {{
              (document.creationTime + "") | translocoDate: { dateStyle: 'medium' }
              }}
            </div>

            <div class="p-col-fixed" *ngIf="document.validTo">
              {{ 'TPA.ValitTo' | transloco }}:
              {{( document.validTo+ "") | translocoDate: { dateStyle: 'medium' } }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(state.data$ | async)?.length === 0" class="p-col-12">
    <div class="document-list-item document-list-item--empty">
      <div class="p-grid p-grid--with-bottom p-justify-center">
        <div class="p-col-fixed">
          {{ 'TPA.NoDoc' | transloco }}
        </div>
      </div>
    </div>
  </div>
</div>