import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'formly-field-textarea',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <div class="multiline">
        <ejs-textbox
          #default
          [multiline]="true"
          [class.ng-dirty]="showError"
          [formControl]="$any(formControl)"
          [placeholder]="to.placeholderT ? to.placeholderT : to.placeholder"
          (onChange)="to.change && to.change(field, $event)"
          [attr.autocomplete]="to.attributes.autocomplete"
          (created)="to.attributes.autoresize && createHandler()"
          (input)="to.attributes.autoresize && inputHandler()"
        ></ejs-textbox>
      </div>
    </ng-container>
  `,
  styles: [
    `
      ::ng-deep ejs-textbox textarea {
        min-height: 5.8em;
        padding-top: 0.4em !important;
        padding-bottom: 0.4em !important;
      }
    `,
  ],
})
export class FormlyFieldTextArea extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        autocomplete: null,
        autoresize: 'yes',
      },
    },
  };

  @ViewChild('default')
  public textareaObj: TextBoxComponent;
  public createHandler(): void {
    if(this.textareaObj) {
    this.textareaObj.addAttributes({ rows: '1' });
    this.textareaObj.element.style.height = 'auto';
    this.textareaObj.element.style.height =
      this.textareaObj.element.scrollHeight + 'px';
    }
  }

  public inputHandler(): void {
    if(this.textareaObj) {
      this.textareaObj.element.style.height = 'auto';
      this.textareaObj.element.style.height =
        this.textareaObj.element.scrollHeight + 'px';
    }
  }
}
