import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  CreateGeneralArtifactForUserInputDto,
  GeneralArtifactDto,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageGeneralArtifactHttpApiService {
  constructor(private rest: RestService) {}

  public create(body: TenancyRequestDto<CreateGeneralArtifactForUserInputDto>) {
    const request: Rest.Request<
      TenancyRequestDto<CreateGeneralArtifactForUserInputDto>
    > = {
      method: 'POST',
      url: '/api/manage/general-artifact/create',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<CreateGeneralArtifactForUserInputDto>,
      Rest.AbpResponse<number>
    >(request);
  }

  public get(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/general-artifact/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<GeneralArtifactDto>>(
      request
    );
  }

  public setOpenState(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'PUT',
      url: '/api/manage/general-artifact/set-open-state',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EntityDto>,
      Rest.AbpResponse<GeneralArtifactDto>
    >(request);
  }

  public setClosedState(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'PUT',
      url: '/api/manage/general-artifact/set-closed-state',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EntityDto>,
      Rest.AbpResponse<GeneralArtifactDto>
    >(request);
  }
}
