import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
// import { RestOccurError } from '../actions/rest.actions';
import { AbpConsts } from '../consts';

const flatten = (obj, roots = [], sep = '.') =>
  Object
    // find props of given object
    .keys(obj)
    // return an object by iterating props
    .reduce(
      (memo, prop) =>
        Object.assign(
          // create a new object
          {},
          // include previously returned object
          memo,
          Object.prototype.toString.call(obj[prop]) === '[object Object]'
            ? // keep working if value is an object
              flatten(obj[prop], roots.concat([prop]))
            : // include current prop and value and prefix prop with the roots
              { [roots.concat([prop]).join(sep)]: obj[prop] }
        ),
      {}
    );

export namespace Rest {
  export interface AbpResponse<T> {
    result: T;
    targetUrl: any;
    success: boolean;
    error: any;
    unAuthorizedRequest: boolean;
    __abp: boolean;
  }

  export interface Config {
    skipHandleError?: boolean;
    observe?: Observe;
  }

  export const enum Observe {
    Body = 'body',
    Events = 'events',
    Response = 'response',
  }

  export const enum ResponseType {
    ArrayBuffer = 'arraybuffer',
    Blob = 'blob',
    JSON = 'json',
    Text = 'text',
  }

  export interface Request<T> {
    body?: T;
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    params?: {};
    reportProgress?: boolean;
    responseType?: ResponseType;
    url: string;
    withCredentials?: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private http: HttpClient) {}

  handleError(err: any): Observable<any> {
    // this.store.dispatch(new RestOccurError(err));
    console.error(err);
    return throwError(err);
  }

  request<T, R>(
    request: HttpRequest<T> | Rest.Request<T>,
    config?: Rest.Config,
    api?: string
  ): Observable<R> {
    config = config || ({} as Rest.Config);
    const { observe = Rest.Observe.Body, skipHandleError } = config;
    const url = api || AbpConsts.remoteServiceBaseUrl + request.url;
    const { method, params, ...options } = request;
    const flattenParams = flatten(params || {});
    let queryParams = new HttpParams();
    Object.keys(flattenParams).forEach((e) => {
      if (flattenParams[e] === undefined || flattenParams[e] === '') {
      } else queryParams = queryParams.set(e, flattenParams[e]);
    });

    return this.http
      .request<T>(method, url, {
        observe,
        params: queryParams,
        ...options,
      } as any)
      .pipe(
        observe === Rest.Observe.Body ? take(1) : tap(),
        catchError((err) => {
          if (skipHandleError) {
            return throwError(err);
          }

          return this.handleError(err);
        })
      );
  }
}
