import { FormlyFieldConfig } from '@ngx-formly/core';
import { countryOptions } from '@tpa/features/emp-data';
import { specialCharsParser } from './utils';

export const sharedAddress = (notCzeck: boolean = false): FormlyFieldConfig[] => [
  {
    key: 'ulice',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.Street',
      translate: true,
      required: true,
    },
  },
  {
    key: 'popCislo',
    type: 'input',
    className: 'p-col-12 p-sm-6 p-md-3',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.DescNumber',
      translate: true,
      required: true,
    },
  },
  {
    key: 'orCislo',
    type: 'input',
    className: 'p-col-12 p-sm-6 p-md-3',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.OrientNumber',
      translate: true,
    },
  },
  {
    key: 'mesto',
    type: 'input',
    className: 'p-col-12 p-sm-6 p-md-4',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.City',
      translate: true,
      required: true,
    },
  },
  {
    key: 'psc',
    type: 'input',
    className: 'p-col-12 p-sm-6 p-md-4',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.ZIP',
      translate: true,
      required: true,
    },
  },
  {
    key: 'zeme',
    type: 'select',
    className: 'p-col-12 p-md-4',
    props: {
      placeholder: 'TPA.SelectPlaceholder',
      translate: true,
      label: 'TPA.Country',
      translateOptions: true,
      required: true,
      options: countryOptions(),
    },
  },
];

export const contactAddress = (
  hideExpression:
    | boolean
    | string
    | ((model: any, formState: any, field?: FormlyFieldConfig) => boolean)
): FormlyFieldConfig => ({
  key: 'kontaktniAdresa',
  wrappers: ['panel-wrapper'],
  fieldGroup: sharedAddress(),
  props: {
    translate: true,
    label: 'TPA.ContactAddress',
    actionPathKey: 'permanentniAdresa',
    actionText: 'TPA.SetUpSameAsPermanentniAdresa',
    anotherAddress: 'TPA.SetUpSameAsPermanentniAdresa',
  },
  expressionProperties: {
    'props.sameAsAnotherAddress': (model, formState, field) => {
      const a = JSON.stringify(
        field?.parent?.fieldGroup?.find((p) => p.key == 'permanentniAdresa')
          ?.model
      );
      const m = JSON.stringify(model);
      return a == m;
    },
  },
  hideExpression: hideExpression,
});
