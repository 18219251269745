import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//
//  this funxtion replace this - it is shorter to translate options
//
// readonly typeOptions$ = combineLatest([
//   this.select((state) => state.typeOptions),
//   this.translate.selectTranslation(),
// ]).pipe(
//   map(([options, translations]) =>
//     options.map((p) => ({
//       label: translations[p.label] || p.label,
//       value: p.value,
//     }))
//   )
// );

export function translateOptions$(
  array: Observable<{ label: string; value: unknown }[]>,
  translation: Observable<Record<string, string>>
) {
  return combineLatest([array, translation]).pipe(
    map(([options, translations]) => translateOptions(options, translations))
  );
}

export function translateOptions(
  array: { label: string; value: unknown }[],
  translate: Record<string, string>
) {
  return array.map((x) => translateOption(x, translate));
}

function translateOption(
  { label, value }: { label: string; value: unknown },
  translations: Record<string, string>
) {
  return {
    label: translations[label] || label,
    value,
  };
}


export enum GeneralArtifactType {
  ConfirmationOfIncome = 'CategoryConfirmationOfIncome',
  // ChangeOfBankAccount = 'CategoryChangeOfBankAccount',
  ConfirmationNonApplicationOfTheChildTaxCredit = 'CategoryConfirmationNonApplicationOfTheChildTaxCredit',
  IssuingApplicationForAPension = 'CategoryIssuingApplicationForAPension',
  GeneralArtifact = 'CategoryGeneralArtifact',
}
export const GeneralArtifactOptions = [
  {
    label: 'TPA.CategoryConfirmationOfIncome',
    value: GeneralArtifactType.ConfirmationOfIncome,
  },
  // {
  //   label: 'TPA.CategoryChangeOfBankAccount',
  //   value: GeneralArtifactType.ChangeOfBankAccount,
  // },
  {
    label: 'TPA.CategoryConfirmationNonApplicationOfTheChildTaxCredit',
    value: GeneralArtifactType.ConfirmationNonApplicationOfTheChildTaxCredit,
  },
  {
    label: 'TPA.CategoryIssuingApplicationForAPension',
    value: GeneralArtifactType.IssuingApplicationForAPension,
  },
  { label: 'TPA.CategoryGeneralArtifact', value: GeneralArtifactType.GeneralArtifact },
];
