export * from './lib/api.module';
export * from './lib/dto';
export * from './lib/http-api';

import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { map } from 'rxjs/operators';

export const IDataStateSerializer = map(
  ({ take, skip, where, sorted, group }: DataStateChangeEventArgs) => ({
    take,
    skip,
    where,
    sorted,
    group,
  })
);

export const enum DataManagerUrls {
  ArtifactTask = '/api/artifact-task/datamanager',
  Companies = '/api/companies/datamanager',
  EmployeeArtifact = '/api/employee/artifact/datamanager',
  EmployeeDocument = '/api/employee/document/datamanager',
  EmployeeGeneralArtifact = '/api/employee/general-artifact/datamanager',
  EmployeePayroll = '/api/employee/payroll/datamanager',
  EmployeeTaxAnnualSettlement = '/api/employee/tax-annual-settlement/datamanager',
  ETL = '/api/etl/datamanager',
  ManageAnnouncment = '/api/manage/announcement/datamanager',
  ManageDocument = '/api/manage/document/datamanager',
  ManageEmployeeCard = '/api/manage/employee-card/datamanager',
  ManageGeneralArtifact = '/api/manage/general-artifact/datamanager',
  ManagePayroll = '/api/manage/payroll/datamanager',
  ManageAnnualTaxSettlement = '/api/manage/tax-annual-settlement/datamanager',
  PayrollRecap = '/api/payroll-recap/datamanager',
  Role = '/api/role/datamanager',
  User = '/api/user/datamanager',
  Department = '/api/user-department/datamanager',
  CanManageDepartments = '/api/user-department/{department}/datamanager/users-can-manage',
}
