import { FormlyFieldConfig } from '@ngx-formly/core';
import { RodneCislo, specialCharsParser } from './utils';

export const partnerForm = (guide = false): FormlyFieldConfig[] => [
  {
    key: 'dalsiPoplatnik',
    type: 'yes-or-no',
    className: 'p-col-12',
    wrappers: ['yes-or-no-form-field'],

    props: {
      translate: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      label: 'TPA.NextTaxpayer',
      required: true,
      description: 'TPA.ChildrenNextTaxpayerInfo',
    },
    expressionProperties: {
      'props.required':
        '(formState.hideBasedOnProhlaseniKProhlaseni() || formState.hideBasedOnChildrenDiscountQuestion())',
    },
  },
  {
    key: 'jmenoPoplatnika',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.NextTaxpayerFirstName',
      translate: true,
      required: true,
    },
    hideExpression: 'model?.dalsiPoplatnik != true',
  },
  {
    key: 'prijmeniPoplatnika',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.NextTaxpayerSurname',
      translate: true,
      required: true,
    },
    hideExpression: 'model?.dalsiPoplatnik != true',
  },
  {
    ...RodneCislo('TPA.NextTaxpayerBirthNumber'),
    key: 'rodneCisloPoplatnika',
    className: 'p-col-12 p-md-6',
    ...{
      props: {
        label: 'TPA.NextTaxpayerBirthNumber',
        translate: true,
        required: true,
        placeholder: 'XXXXXX/XXXX',
      },
    },
    hideExpression: 'model?.dalsiPoplatnik != true',
  },
  {
    key: 'trvalyPobytPoplatnika',
    type: 'input',
    className: 'p-col-12 p-md-6',
    modelOptions: {
      updateOn: 'blur',
    },
    parsers: [specialCharsParser],
    props: {
      label: 'TPA.NextTaxpayerPermanentAddress',
      translate: true,
      required: true,
    },
    hideExpression: 'model?.dalsiPoplatnik != true',
  },
  {
    key: 'uplatnujePoplatnik',
    type: 'yes-or-no',
    className: 'p-col-12 p-md-6',
    props: {
      translate: true,
      required: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      label: 'TPA.NextTaxpayerApplyDiscount',
    },
    hideExpression: 'model?.dalsiPoplatnik != true',
  },
  {
    key: 'zamestnaniPoplatnika',

    type: 'yes-or-no',
    className: 'p-col-12 p-md-6',
    props: {
      translate: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      required: true,
      label: 'TPA.NextTaxpayerIsEmployed',
      ...(guide
        ? {
            description: 'TPA.PartnerInfoDescrioption',
          }
        : {}),
    },
    hideExpression: 'model?.dalsiPoplatnik != true',
  },
  {
    key: 'platceDanePoplatnika',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.NextTaxpayerEmployer',
      translate: true,
      required: true,
    },
    hideExpression:
      'model?.zamestnaniPoplatnika != true || model?.dalsiPoplatnik != true',
  },
  {
    key: 'adresaPlatceDanePoplatnika',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.NextTaxpayerEmployerAddress',
      translate: true,
      required: true,
    },
    hideExpression:
      'model?.zamestnaniPoplatnika != true || model?.dalsiPoplatnik != true',
  },
];
