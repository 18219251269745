import { EventEmitter, Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store, select } from '@ngrx/store';
import { ToastActions } from '@ralba/core';
import { AsyncState } from '@ralba/shared';
import {
  ActionCompleteEventArgs,
  UploaderComponent,
  UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import { EmployeeDocumentHttpApiService, FileDto } from '@tpa/api';
import { UserTenanciesSelectors } from '@tpa/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, forkJoin } from 'rxjs';
import { exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';

export interface EmployeeDocumentUploadState {
  async: AsyncState.IAsyncState;
  tagId: string | undefined;
  files: FileDto[];
}
@Injectable()
export class FeatureEmployeeDocumentUploadStore extends ComponentStore<EmployeeDocumentUploadState> {
  public readonly uploadCompleted = new EventEmitter<void>();

  readonly selectFiles$ = this.select((s) => s.files);
  readonly isLoaded$ = this.select((s) => s.async.loaded);
  readonly loading$ = this.select((s) => s.async.loading);
  readonly asyncState$ = this.select((s) => s.async);
  readonly tagId$ = this.select((s) => s.tagId);
  public readonly setLoading = this.updater((state, input: boolean) => ({
    ...state,
    async: {
      ...state.async,
      loading: input,
    },
  }));
  public readonly setTagId = this.updater(
    (state, input: { tagId: string }) => ({
      ...state,
      ...input,
    })
  );

  public readonly appendFiles = this.updater((state, files: FileDto[]) => ({
    ...state,
    files: [...state.files, ...files],
  }));

  public readonly clearFiles = this.updater((state) => ({
    ...state,
    files: [],
  }));
  public readonly handleLoading = this.effect(() =>
    this.asyncState$.pipe(
      tap(({ loaded, loading }) => {
        if (!loaded && loading) {
          this.store$.dispatch(
            ToastActions.showToastSaved(
              translate('TPA.UploadingFiles'),
              translate('TPA.WaitUntilSuccessToast')
            )
          );
        }
      })
    )
  );
  public readonly handleUploading = this.effect(
    ($: Observable<UploadingEventArgs>) => {
      return $.pipe(
        tap(() => this.setLoading(true)),
        tap(({ currentRequest }) => {
          if (currentRequest) {
            currentRequest.setRequestHeader(
              'Authorization',
              `Bearer ${this.oidcSecurityService.getToken()}`
            );
          }
        })
      );
    }
  );
  public readonly handleOnUploadSuccess = this.effect(($: Observable<any>) => {
    return $.pipe(
      tap(() => this.setLoading(false)),
      tap((args: any) => {
        const response = (
          (args.e as ProgressEvent).currentTarget as XMLHttpRequest
        ).response;
        const { result } = JSON.parse(response);
        this.appendFiles(result);
      })
    );
  });

  private readonly uploadDocument = this.effect(
    (
      $: Observable<{
        uploader: UploaderComponent;
      }>
    ) => {
      return $.pipe(
        tap(() => this.setLoading(true)),
        withLatestFrom(
          this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer)),
          this.tagId$,
          this.selectFiles$
        ),
        map(([{ uploader }, identifier, tagId, files]) => ({
          uploader,
          identifier,
          tagId,
          files,
        })),
        exhaustMap(({ uploader, identifier, tagId, files }) => {
          return forkJoin(
            files.map((file) =>
              this.documentHttpApiService
                .uploadDocument({
                  body: { attachment: file, tagId: tagId },
                  ...identifier,
                })
                .pipe(
                  tapResponse(
                    () => {
                      this.store$.dispatch(ToastActions.showToastSaved());
                    },
                    (error: any) => {
                      this.store$.dispatch(
                        ToastActions.showToastApiError(error.error)
                      );
                    }
                  )
                )
            )
          ).pipe(
            tap((s) => {
              this.setState((state) => ({
                ...state,
                ...AsyncState.loadedState,
                files: [],
              }));
              uploader && uploader.clearAll();
              this.uploadCompleted.emit();
            })
          );
        })
      );
    }
  );
  public readonly handleActionComplete = this.effect(
    (
      $: Observable<{
        args: ActionCompleteEventArgs;
        uploader: UploaderComponent;
      }>
    ) => {
      return $.pipe(
        withLatestFrom(this.selectFiles$),
        map(([{ args, uploader }, files]) => ({ args, uploader, files })),
        filter(({ files }) => files.length > 0),
        tap(({ uploader }) => this.uploadDocument({ uploader }))
      );
    }
  );

  constructor(
    private readonly store$: Store<never>,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly documentHttpApiService: EmployeeDocumentHttpApiService
  ) {
    super({
      files: [],
      tagId: undefined,
      async: AsyncState.loadedState,
    });
  }
}
