import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  EmailNotificationConfigurationDto,
  TenancyRequestDto,
  UserPrefDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class UserConfigurationHttpApiService {
  constructor(private rest: RestService) {}

  public getUserPreferences() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user-configuration/get/user-preferences',
    };
    return this.rest.request<null, Rest.AbpResponse<UserPrefDto>>(request);
  }

  public updateUserPreferences(body: UserPrefDto) {
    const request: Rest.Request<UserPrefDto> = {
      method: 'PUT',
      url: '/api/user-configuration/update/user-preferences',
      body,
    };
    return this.rest.request<UserPrefDto, Rest.AbpResponse<null>>(request);
  }

  getEmailNotificationsSettings(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `/api/user-configuration/get/email-notifications-settings`,
      params,
    };

    return this.rest.request<
      null,
      Rest.AbpResponse<EmailNotificationConfigurationDto>
    >(request);
  }

  updateEmailNotificationSetting(
    body: TenancyRequestDto<EmailNotificationConfigurationDto>
  ) {
    const request: Rest.Request<
      TenancyRequestDto<EmailNotificationConfigurationDto>
    > = {
      method: 'PUT',
      url: `/api/user-configuration/update/email-notifications-settings`,
      body,
    };

    return this.rest.request<
      TenancyRequestDto<EmailNotificationConfigurationDto>,
      Rest.AbpResponse<null>
    >(request);
  }
}
