<div class="p-grid p-justify-between p-align-center top-menu">
  <div class="p-col">
    <div class="p-grid p-align-center">
      <div class="p-col-fixed hamburger">
        <button class="e-btn e-lib" (click)="toggle.emit()">
          <i class="fal fa-bars"></i>
        </button>
      </div>
      <div class="p-col app-title">
        <h1>{{ title }}</h1>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
  <div class="p-col-fixed">
    <div class="p-grid p-justify-end p-align-center">
      <ng-content> </ng-content>
      <div class="p-col-fixed">
        <a [routerLink]="profileUrl">
          <button class="e-btn e-lib avatar">
            <i class="fal fa-user"></i>
            <img
              *ngIf="accountImage"
              [src]="accountImage"
              class="avatar__img"
            />
          </button>
          <span class="username">
            {{ displayName }}
          </span>
        </a>
      </div>
      <div class="p-col-fixed">
        <button class="e-btn e-lib e-decent e-primary" (click)="logout.emit()">
          <i class="fal fa-power-off"></i>
        </button>
      </div>
    </div>
  </div>
</div>
