import { Component, HostBinding } from '@angular/core';
import {
  FieldType,
  FormlyExtension,
  FormlyFieldConfig
} from '@ngx-formly/core';
import { isValid, parseISO } from 'date-fns';
import {
  sharedCountOfEducableChildren,
  sharedPersonalInformation
} from '../../form-parts-definition';

@Component({
  selector: 'tpa-personal-info',
  templateUrl: 'personal.component.html',
  // TODO: TPA : Uncomment this
  // host: { class: 'tpa-wrapper__control' },
})
export class PersonalInfoComponent
  extends FieldType
  implements FormlyExtension
{
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      full: true,
      open: false,
    },
  };

  // get emptyModel() {
  //   return !this.model || JSON.stringify(this.model) === '{}';
  // }
  parseISO = parseISO;
  isValid = isValid;

  @HostBinding('class.p-grid') someField = true;

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = [
      ...sharedPersonalInformation(),
      sharedCountOfEducableChildren(),
    ];
  }
}
