import { Component, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
    APP_NAV_MENU,
    APP_TITLE,
    AdminRootComponent,
    CoreSelectors,
    NavMenuItem,
} from '@ralba/core';
import { UserTenanciesSelectors } from '@tpa/core';
@Component({
  selector: 'tpa-root',
  templateUrl: './layout.component.html',
  // pdf preview isssue ->
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutRootComponent extends AdminRootComponent {
  isCoreLoaded$ = this._store$.pipe(select(CoreSelectors.selectIsLoaded));
  tenancyName$ = this._store$.pipe(
    select(UserTenanciesSelectors.selectCurrentTenancyName)
  );

  constructor(
    private _store$: Store<never>,
    @Inject(APP_NAV_MENU) navMenu: NavMenuItem[],
    @Inject(APP_TITLE) public appTitle: string = 'Admin'
  ) {
    super(_store$, navMenu, appTitle);
  }
}
