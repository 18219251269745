import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { PushModule } from '@ngrx/component';
import { ClaimPipe, EducationLevelPipe, GenderPipe, HealthDisadvantagePipe, LoanPurposePipe, MaritalStatusPipe, MonthPipe, NullToDashPipe, ResidentureEvaluationPipe, ResidenturePipe, SalaryPipe, YesOrNoPipe } from '@tpa/shared';
import { SharedModule } from '@tpa/shared';
import { HandleBarsPreviewComponent, SanitizeHtmlPipe } from './handlebars-preview.component';
import { EmployeesFormPipesModule } from '@tpa/shared';





@NgModule({
  declarations: [HandleBarsPreviewComponent, SanitizeHtmlPipe],
  imports: [
    TranslocoModule,
    HttpClientModule,
    CommonModule,
    PushModule,
    EmployeesFormPipesModule,
    SharedModule
  ],
  exports: [HandleBarsPreviewComponent],
  providers: [
    MonthPipe,
    EducationLevelPipe,
    MaritalStatusPipe,
    SalaryPipe,
    YesOrNoPipe,
    GenderPipe,
    ResidenturePipe,
    HealthDisadvantagePipe,
    ClaimPipe,
    LoanPurposePipe,
    ResidentureEvaluationPipe,
    NullToDashPipe]
})
export class HandlebarsModule { }
