import { createAction, props } from '@ngrx/store';

const initCookie = createAction('[Cokie] init');
const detectCookie = createAction(
  '[Cokie] detect cookie',
  props<{ detected: boolean; enable: boolean }>()
);
const setCookie = createAction(
  '[Cokie] set cookie',
  props<{ enable: boolean }>()
);

export const CookieActions = {
  initCookie,
  detectCookie,
  setCookie,
};
