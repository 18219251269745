import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetModule, PushModule } from '@ngrx/component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ChangeLangaugeComponent } from './change-language.component';
import { SharedModule } from '@tpa/shared';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LetModule,
    PushModule,
    ReactiveFormsModule,
    ButtonModule,
    DropDownListModule,
    SharedModule,
    TranslocoModule,
  ],
  exports: [ChangeLangaugeComponent],
  declarations: [ChangeLangaugeComponent],
  providers: [],
})
export class ChangeLangaugeModule {}
