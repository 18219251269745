import { FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { Observable } from 'rxjs';
import { z } from 'zod';

// TPA origin
export const VariantaNull: EmpTabsFormModelV2 = {
  dodatecneAtributy: {},
  personalPart: {
    health: {},
    personalInfo: {},
    execution: {
      exekuce: null,
    },
  },
  taxDeclaration: {
    rezidentura: {},
    taxDeclaration: {
      monthDiscount: {},
      partner: {},
      children: {
        deti: [],
      },
    },
  },
};

// Multistep guide default
export const EMP_TABS_INIT_DATA: EmpTabsFormModelV2 = {
  pre: true,
  ...VariantaNull,
};

export function isObject(x: any) {
  return x != null && typeof x === 'object';
}

export interface IFormError {
  parentKey: string;
  control: string;
  message: Observable<string> | string | undefined;
  field: FormlyFieldConfig;
  index: number;
}

export function getFormValidationErrors(
  fields: FormlyFieldConfig[],
  config: FormlyConfig,
  parentKey: string = ''
): IFormError[] {
  const result: IFormError[] = [];

  fields.forEach((field) => {
    // Object.keys(form.controls).forEach((key) => {
    if (field.fieldGroup) {
      if (!field.props?.disabled && !field.hide) {
        result.push(
          ...getFormValidationErrors(
            field.fieldGroup,
            config,
            parentKey + '.' + field.key
          )
        );
      }
    }
    const error = errorMessage(field, config);
    if (error) {
      if (!field.props?.disabled && !field.hide) {
        result.push({
          parentKey: parentKey,
          control:
            (field.key || field.props?.label || field.props?.description) + '',
          field: field,
          message: error,
          index: 0,
        });
      }
    }
    // });
  });

  return result;
}

export function errorMessage(field: FormlyFieldConfig, config: FormlyConfig) {
  const fieldForm = field.formControl;
  if (fieldForm)
    for (const error in fieldForm.errors) {
      // eslint-disable-next-line no-prototype-builtins
      if (fieldForm.errors.hasOwnProperty(error)) {
        let message = config.getValidatorMessage(error);
        if (isObject(fieldForm.errors[error])) {
          if (fieldForm.errors[error].errorPath) {
            return undefined;
          }

          if (fieldForm.errors[error].message) {
            message = fieldForm.errors[error].message;
          }
        }
        if (field.validation?.messages?.[error]) {
          message = field.validation.messages[error];
        }

        if (field.validators?.[error]?.message) {
          message = field.validators[error].message;
        }

        if (field.asyncValidators?.[error]?.message) {
          message = field.asyncValidators[error].message;
        }

        if (typeof message === 'function') {
          return message(fieldForm.errors[error], field);
        }

        return message;
      }
    }

  return undefined;
}

// export interface EmpTabsFormModelV2 {
//   pre?: boolean;
//   personalPart?: {
//     personalInfo: {
//       jmeno?: string;
//       prijmeni?: string;
//       email?: string;
//       permanentniAdresa?: Adresa;
//       kontaktniAdresa?: Adresa;
//       prechodnaAdresa?: Adresa;
//       titulpred?: string;
//       titulza?: string;
//       rodnePrijmeni?: string;
//       rodneCislo?: string;
//       datumNarozeni?: string;
//       mistoNarozeni?: string;
//       rodinnyStav?: string;

//       vzdelani?: string;
//       pocetVychDeti?: number;
//       statniPrislusnost?: string;
//       druhaStatniPrislusnostCheck?: boolean;
//       druhaStatniPrislusnost?: any;
//       pohlavi?: string | null;
//     };

//     // allowContactAddress?: boolean | null;
//     // allowTemporaryAddress?: boolean | null;

//     permanentniAdresa?: Adresa | null;
//     kontaktniAdresa?: Adresa | null;
//     prechodnaAdresa?: Adresa | null;

//     allowContactAddress?: boolean;
//     allowTemporaryAddress?: boolean;

//     wage: {
//       salaryForm?: string | null;
//       cisloUctu?: number | null;
//       idUstavu?: string | null;
//       iban?: string | null;
//     };

//     health?: ZdravotniPojistovnaAStav | null;

//     idcard: {
//       typDokladu?: string | null;
//       vydalStat?: string | null;
//       doklad: Doklad | null;
//       cisloDokladu?: string | null;
//       datumExpirace?: string | null;
//     };

//     execution: {
//       exekuce: boolean;
//     };
//     dalsiPracovniPomer: {
//       dalsiPracovniPomer: boolean;
//       nazevDalsihoZamestnavatele: string;
//       sidloDalsihoZamestnavatele: string;
//     };
//   };
//   taxDeclaration?: {
//     rezidentura: {
//       rezidentura?: string | null;
//       rezidenturaVeState?: string | null;
//       rezidenturaText?: string | null;
//       rezidenturaVyhodnoceni?: string | null;
//       identifikace?: string | null;
//       zdrzovaniVCR?: boolean | null;
//       studiumVCR?: boolean | null;
//     };
//     taxDeclaration: {
//       monthDiscount: {
//         zakladniSlevaNaPoplatnika?: { value: boolean | null } | null;
//         rozsirenaSlevaNaInvalidituMesice?: { value: MonthFlags | null } | null;
//         zakladniSlevaNaInvalidituMesice?: { value: MonthFlags | null } | null;
//         slevaNaDrziteleZTPPMesice?: { value: MonthFlags | null } | null;
//         slevaNaStudentaMesice?: { value: MonthFlags | null } | null;
//       };
//       children: {
//         deti?: Dite[];
//         // ????? vubec nevim k cemu to je
//         // jineProhlaseni?: boolean;
//       };

//       partner: {
//         dalsiPoplatnik?: boolean | null;
//         jmenoPoplatnika?: string | null;
//         prijmeniPoplatnika?: string | null;
//         rodneCisloPoplatnika?: string | null;
//         trvalyPobytPoplatnika?: string | null;

//         uplatnujePoplatnik?: boolean | null;
//         zamestnaniPoplatnika?: boolean | null;

//         platceDanePoplatnika?: string | null;
//         adresaPlatceDanePoplatnika?: string | null;
//       };

//       soucasneNeuplatnuji?: boolean | null;

//       affidavit?: null | {
//         chciProhlaseni?: boolean | null;
//         prohlaseniKProhlaseni?: boolean | null;
//       };
//     };

//     datumNastupu?: string | null;
//   };
//   pravdivostUdaju?: boolean | null;
//   dodatecneAtributy?: any | null;
// }

// interface DodatecneAtributy {
//   nazev?: string;
//   value?: string;
//   type?: string;
//   placeholder?: string;
// }

export const zDodatecenAtributy = z.object({
  nazev: z.string().optional().nullable(),
  value: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  placeholder: z.string().optional().nullable(),
});

// interface Adresa {
//   ulice?: string;
//   popCislo?: string;
//   orCislo?: string;
//   mesto?: string;
//   psc?: string;
//   zeme?: string;
// }

export const zAdresa = z.object({
  ulice: z.string().nullable().optional(),
  popCislo: z.string().nullable().optional(),
  orCislo: z.string().nullable().optional(),
  mesto: z.string().nullable().optional(),
  psc: z.string().nullable().optional(),
  zeme: z.string().nullable().optional(),
});

// interface Duchody {
//   pobiramDuchod?: boolean;
//   predcasny?: boolean;
//   starobni?: boolean;
//   invalidni?: boolean;
//   jiny?: string;
//   jinyDuchod?: boolean;
// }

export const zDuchody = z.object({
  pobiramDuchod: z.boolean().optional().nullable(),
  predcasny: z.boolean().optional().nullable(),
  starobni: z.boolean().optional().nullable(),
  invalidni: z.boolean().optional().nullable(),
  jiny: z.string().optional().nullable(),
  jinyDuchod: z.boolean().optional().nullable(),
});

// interface ZdravotniPojistovnaAStav {
//   zdravPojistovna?: number;
//   jinaZdravPojistovna?: string;
//   cisloPojistence?: number;
//   zpsZtpp?: number;
//   duchody?: Duchody;
//   ztpp?: boolean;
//   a1?: boolean;
//   zdravotniZnevyhodneni?: string;
// }

export const zZdravotniPojistovnaAStav = z.object({
  zdravPojistovna: z.number().nullable().optional(),
  jinaZdravPojistovna: z.string().nullable().optional(),
  cisloPojistence: z.string().nullable().optional(),
  zpsZtpp: z.number().nullable().optional(),
  duchody: zDuchody.optional(),
  ztpp: z.boolean().nullable().optional(),
  a1: z.boolean().nullable().optional(),
  zdravotniZnevyhodneni: z.string().nullable().optional(),
});

// interface Doklad {
//   cisloPasu?: any;
//   expiracePasu?: Date;
//   identifikace?: number;
// }

export const zDoklad = z.object({
  cisloPasu: z.any().optional().nullable(),
  //expiracePasu: z.date().optional().nullable(),
  expiracePasu: z.string().or(z.date()).optional().nullable(),
  identifikace: z.number().optional().nullable(),
});

// interface Uplatneni {
//   narokVeVysi?: string;
//   ztpp?: boolean;
//   zletileDite?: boolean;
//   mesice?: MonthFlags;
// }

export const zUplatneni = z.object({
  narokVeVysi: z.string().optional().nullable(),
  ztpp: z.boolean().optional().nullable(),
  zletileDite: z.boolean().optional().nullable(),
  mesice: z.number().optional().nullable(),
});

// interface Dite {
//   jmeno?: string;
//   prijmeni?: string;
//   rodneCislo?: string;
//   uplatneni?: Uplatneni[];
// }

export const zDite = z.object({
  jmeno: z.string().optional().nullable(),
  prijmeni: z.string().optional().nullable(),
  rodneCislo: z.string().optional().nullable(),
  uplatneni: z.array(zUplatneni).optional().nullable(),
});

export const zEmpTabsFormModelV2 = z.object({
  pre: z.boolean().optional(),
  personalPart: z
    .object({
      personalInfo: z
        .object({
          jmeno: z.string().optional().nullable(),
          prijmeni: z.string().optional().nullable(),
          email: z.string().optional().nullable(),
          permanentniAdresa: zAdresa.nullable().optional().nullable(),
          kontaktniAdresa: zAdresa.nullable().optional().nullable(),
          prechodnaAdresa: zAdresa.nullable().optional().nullable(),
          titulpred: z.string().optional().nullable(),
          titulza: z.string().optional().nullable(),
          rodnePrijmeni: z.string().optional().nullable(),
          rodneCislo: z.string().optional().nullable(),
          datumNarozeni: z.string().optional().nullable(),
          mistoNarozeni: z.string().optional().nullable(),
          rodinnyStav: z.string().nullable().optional(),
          vzdelani: z.string().nullable().optional(),
          pocetVychDeti: z.number().optional().nullable(),
          statniPrislusnost: z.string().optional().nullable(),
          druhaStatniPrislusnostCheck: z.boolean().optional().nullable(),
          druhaStatniPrislusnost: z.any().optional().nullable(),
          pohlavi: z.string().nullable().optional(),
        })
        .optional()
        .nullable(),

      // allowContactAddress?: boolean | null;
      // allowTemporaryAddress?: boolean | null;
      permanentniAdresa: zAdresa.nullable().optional(),
      kontaktniAdresa: zAdresa.nullable().optional(),
      prechodnaAdresa: zAdresa.nullable().optional(),

      allowContactAddress: z.boolean().optional(),
      allowTemporaryAddress: z.boolean().optional(),

      wage: z
        .object({
          salaryForm: z.string().nullable().optional(),
          cisloUctu: z.string().nullable().optional(),
          idUstavu: z.string().nullable().optional(),
          iban: z.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      health: zZdravotniPojistovnaAStav.optional().nullable(),

      idcard: z
        .object({
          typDokladu: z.string().nullable().optional(),
          vydalStat: z.string().nullable().optional(),
          doklad: zDoklad.nullable().optional(),
          cisloDokladu: z.string().nullable().optional(),
          datumExpirace: z.string().or(z.date()).nullable().optional(),
        })
        .optional(),

      execution: z
        .object({
          exekuce: z.boolean().nullable().optional(),
        })
        .optional()
        .nullable(),
      dalsiPracovniPomer: z
        .object({
          dalsiPracovniPomer: z.boolean().nullable().optional(),
          nazevDalsihoZamestnavatele: z.string().nullable().optional(),
          sidloDalsihoZamestnavatele: z.string().nullable().optional(),
        })
        .nullable()
        .optional(),
    })
    .optional(),
  //taxDeclaration optional
  taxDeclaration: z
    .object({
      rezidentura: z
        .object({
          rezidentura: z.string().or(z.boolean()).nullable().optional(),
          rezidenturaVeState: z.string().nullable().optional(),
          rezidenturaText: z.string().nullable().optional(),
          rezidenturaVyhodnoceni: z.string().nullable().optional(),
          identifikace: z.string().nullable().optional(),
          zdrzovaniVCR: z.boolean().nullable().optional(),
          studiumVCR: z.boolean().nullable().optional(),
        })
        .nullable()
        .optional(),
      taxDeclaration: z
        .object({
          monthDiscount: z
            .object({
              //vsechno objekty optional
              zakladniSlevaNaPoplatnika: z
                .object({ value: z.boolean().nullable().optional() })
                .nullable()
                .optional(),
              rozsirenaSlevaNaInvalidituMesice: z
                .object({ value: z.number().nullable().optional() })
                .nullable()
                .optional(),
              zakladniSlevaNaInvalidituMesice: z
                .object({ value: z.number().nullable().optional() })
                .nullable()
                .optional(),
              slevaNaDrziteleZTPPMesice: z
                .object({ value: z.number().nullable().optional() })
                .nullable()
                .optional(),
              slevaNaStudentaMesice: z
                .object({ value: z.number().nullable().optional() })
                .nullable()
                .optional(),
            })
            .nullable()
            .optional(),
          children: z
            .object({
              deti: z.array(zDite).optional(),
              // ????? vubec nevim k cemu to je
              // jineProhlaseni?: boolean;
            })
            .nullable()
            .optional(),
          partner: z
            .object({
              dalsiPoplatnik: z.boolean().nullable().optional(),
              jmenoPoplatnika: z.string().nullable().optional(),
              prijmeniPoplatnika: z.string().nullable().optional(),
              rodneCisloPoplatnika: z.string().nullable().optional(),
              trvalyPobytPoplatnika: z.string().nullable().optional(),

              uplatnujePoplatnik: z.boolean().nullable().optional(),
              zamestnaniPoplatnika: z.boolean().nullable().optional(),

              platceDanePoplatnika: z.string().nullable().optional(),
              adresaPlatceDanePoplatnika: z.string().nullable().optional(),
            })
            .nullable()
            .optional(),
          soucasneNeuplatnuji: z.boolean().nullable().optional(),

          //affidavit optional a nullable
          affidavit: z
            .nullable(
              z
                .object({
                  chciProhlaseni: z.boolean().nullable().optional(),
                  prohlaseniKProhlaseni: z.boolean().nullable().optional(),
                })
                .optional()
                .nullable()
            )
            .optional(),
        })
        .nullable()
        .optional(),

      datumNastupu: z.string().nullable().optional(),
    })
    .nullable()
    .optional(),
  pravdivostUdaju: z.boolean().nullable().optional(),
  dodatecneAtributy: z.any().nullable().optional(),
});

// interface ServerHeoModel {
//   jmeno?: string;
//   prijmeni?: string;
//   email?: string;
//   permanentniAdresa?: Adresa;
//   kontaktniAdresa?: Adresa;
//   prechodnaAdresa?: Adresa;
//   titulpred?: string;
//   titulza?: string;
//   rodnePrijmeni?: string;
//   rodneCislo?: string;
//   datumNarozeni?: string;
//   mistoNarozeni?: string;
//   rodinnyStav?: string;

//   vzdelani?: string;
//   pocetVychDeti?: number;
//   statniPrislusnost?: string;
//   cisloUctu?: string;
//   idUstavu?: string;
//   iban?: string;
//   cisloDokladu?: string;
//   datumExpirace?: string;
//   chciProhlaseni?: boolean;
//   datumNastupu?: string;
// }

export const zServerHeoModel = z.object({
  jmeno: z.string().optional(),
  prijmeni: z.string().optional(),
  email: z.string().optional(),
  permanentniAdresa: zAdresa.nullable().optional(),
  kontaktniAdresa: zAdresa.nullable().optional(),
  prechodnaAdresa: zAdresa.nullable().optional(),
  titulpred: z.string().optional(),
  titulza: z.string().optional(),
  rodnePrijmeni: z.string().optional(),
  rodneCislo: z.string().optional(),
  datumNarozeni: z.string().optional(),
  mistoNarozeni: z.string().optional(),
  rodinnyStav: z.string().optional(),

  vzdelani: z.string().optional(),
  pocetVychDeti: z.number().optional(),
  statniPrislusnost: z.string().optional(),
  cisloUctu: z.string().optional(),
  idUstavu: z.string().optional(),
  iban: z.string().optional(),
  cisloDokladu: z.string().optional(),
  datumExpirace: z.string().or(z.date()).nullable().optional(),
  chciProhlaseni: z.boolean().optional(),
  datumNastupu: z.string().optional(),
  pohlavi: z.string().optional(),
});

// interface ServerAppModel {
//   druhaStatniPrislusnost?: any;
//   rezidentura?: string;
//   rezidenturaVeState?: string;
//   rezidenturaText?: string;
//   rezidenturaVyhodnoceni?: string;
//   zdravotniPojistovnaAStav?: ZdravotniPojistovnaAStav;
//   doklad?: Doklad;
//   dalsiPracovniPomer?: boolean | null;
//   exekuce?: boolean | null;
//   jineProhlaseni?: boolean;
//   deti?: Dite[];
//   druhaStatniPrislusnostCheck?: boolean;
//   pohlavi?: string;
//   zdrzovaniVCR?: boolean | null;
//   studiumVCR?: boolean | null;
//   salaryForm?: string;
//   prohlaseniKProhlaseni?: boolean;
//   nazevDalsihoZamestnavatele?: string | null;
//   sidloDalsihoZamestnavatele?: string | null;
//   dalsiPoplatnik?: boolean;

//   jmenoPoplatnika?: string;
//   prijmeniPoplatnika?: string;
//   rodneCisloPoplatnika?: string;
//   trvalyPobytPoplatnika?: string;
//   uplatnujePoplatnik?: boolean;
//   zamestnaniPoplatnika?: boolean;
//   platceDanePoplatnika?: string;
//   adresaPlatceDanePoplatnika?: string;
//   soucasneNeuplatnuji?: boolean;
//   pravdivostUdaju?: boolean;
//   typDokladu?: string;
//   vydalStat?: string;
//   identifikace?: string | null;
//   /// mesicni slevy
//   zakladniSlevaNaPoplatnika?: boolean;
//   rozsirenaSlevaNaInvalidituMesice?: MonthFlags;
//   zakladniSlevaNaInvalidituMesice?: MonthFlags;
//   slevaNaDrziteleZTPPMesice?: MonthFlags;
//   slevaNaStudentaMesice?: MonthFlags;
// }

export const zServerAppModel = z.object({
  druhaStatniPrislusnost: z.string().nullable().optional(),
  rezidentura: z.string().or(z.boolean()).nullable().optional(),
  rezidenturaVeState: z.string().nullable().optional(),
  rezidenturaText: z.string().nullable().optional(),
  rezidenturaVyhodnoceni: z.string().nullable().optional(),
  zdravotniPojistovnaAStav: zZdravotniPojistovnaAStav.nullable().optional(),
  doklad: zDoklad.nullable().optional(),
  dalsiPracovniPomer: z.boolean().nullable().optional(),
  exekuce: z.boolean().nullable().optional(),
  jineProhlaseni: z.boolean().nullable().optional(),
  deti: zDite.array().nullable().optional(),
  druhaStatniPrislusnostCheck: z.boolean().nullable().optional(),
  // pohlavi: z.string().nullable().optional(),
  zdrzovaniVCR: z.boolean().nullable().optional(),
  studiumVCR: z.boolean().nullable().optional(),
  salaryForm: z.string().nullable().optional(),
  prohlaseniKProhlaseni: z.boolean().nullable().optional(),
  nazevDalsihoZamestnavatele: z.string().nullable().optional(),
  sidloDalsihoZamestnavatele: z.string().nullable().optional(),
  dalsiPoplatnik: z.boolean().nullable().optional(),

  jmenoPoplatnika: z.string().nullable().optional(),
  prijmeniPoplatnika: z.string().nullable().optional(),
  rodneCisloPoplatnika: z.string().nullable().optional(),
  trvalyPobytPoplatnika: z.string().nullable().optional(),
  uplatnujePoplatnik: z.boolean().nullable().optional(),
  zamestnaniPoplatnika: z.boolean().nullable().optional(),
  platceDanePoplatnika: z.string().nullable().optional(),
  adresaPlatceDanePoplatnika: z.string().nullable().optional(),
  soucasneNeuplatnuji: z.boolean().nullable().optional(),
  pravdivostUdaju: z.boolean().nullable().optional(),
  typDokladu: z.string().nullable().optional(),
  vydalStat: z.string().nullable().optional(),
  identifikace: z.string().nullable().optional(),
  zakladniSlevaNaPoplatnika: z.boolean().nullable().optional(),
  rozsirenaSlevaNaInvalidituMesice: z.number().nullable().optional(),
  zakladniSlevaNaInvalidituMesice: z.number().nullable().optional(),
  slevaNaDrziteleZTPPMesice: z.number().nullable().optional(),
  slevaNaStudentaMesice: z.number().nullable().optional(),
});
export type EmpTabsFormModelV2 = z.infer<typeof zEmpTabsFormModelV2>;

export type DodatecenAtributy = z.infer<typeof zDodatecenAtributy>;
export type Adresa = z.infer<typeof zAdresa>;
export type Duchody = z.infer<typeof zDuchody>;
export type ZdravotniPojistovnaAStav = z.infer<
  typeof zZdravotniPojistovnaAStav
>;
export type Doklad = z.infer<typeof zDoklad>;
export type Uplatneni = z.infer<typeof zUplatneni>;
export type Dite = z.infer<typeof zDite>;
export type ServerHeoModel = z.infer<typeof zServerHeoModel>;
export type ServerAppModel = z.infer<typeof zServerAppModel>;

export const MY_TEST_CARD = {
  attachments: null,
  karta: {
    jmeno: 'a',
    prijmeni: 'a',
    email: 'ala',
    permanentniAdresa: {
      ulice: 'sssasdf',
      popCislo: '767',
      orCislo: '14',
      mesto: 'Praha 4',
      psc: '140 00',
      zeme: 'CZ',
    },
    kontaktniAdresa: {
      ulice: 'sssasdf',
      popCislo: '767',
      orCislo: '14',
      mesto: 'Praha 4',
      psc: '140 00',
      zeme: 'CZ',
    },
    prechodnaAdresa: {
      ulice: null,
      popCislo: null,
      orCislo: null,
      mesto: null,
      psc: null,
      zeme: null,
    },
    titulpred: 'MgA.',
    titulza: null,
    rodnePrijmeni: 's',
    rodneCislo: '745222/0215',
    datumNarozeni: '1974-02-22T00:00:00Z',
    mistoNarozeni: 'Praha',
    rodinnyStav: 'married',
    pohlavi: 'man',
    vzdelani: 'T',
    // pocetVychDeti: 0,
    statniPrislusnost: 'CZ',
    cisloUctu: '1110169143',
    idUstavu: '0800',
    iban: null,
    cisloDokladu: null,
    datumExpirace: null,
    chciProhlaseni: true,
    datumNastupu: null,
  },
  dodatecneAtributy: {},
  app: {
    rezidentura: 'true',
    rezidenturaVeState: 'CZ',
    rezidenturaText: null,
    rezidenturaVyhodnoceni: 'true',
    zdrzovaniVCR: true,
    studiumVCR: null,
    identifikace: null,
    doklad: null,
    typDokladu: null,
    vydalStat: null,
    dalsiPracovniPomer: null,
    exekuce: false,
    salaryForm: 'cash',
    pohlavi: 'man',
    prohlaseniKProhlaseni: true,
    soucasneNeuplatnuji: false,
    zdravotniPojistovnaAStav: {
      zdravPojistovna: 111,
      cisloPojistence: '9206260184',
      ztpp: false,
      zdravotniZnevyhodneni: 'no',
    },
    zakladniSlevaNaPoplatnika: true,
    rozsirenaSlevaNaInvalidituMesice: null,
    zakladniSlevaNaInvalidituMesice: 4095,
    slevaNaDrziteleZTPPMesice: null,
    slevaNaStudentaMesice: 4095,
  },
};
