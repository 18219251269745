import { ChangeDetectionStrategy, Component } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-month-sleydsfct',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <tpa-month-select-custom
      [formControl]="$any(formControl)"
      (onChange)="to.change && to.change(field, $event)"
      [allYear]="to.attributes?.allYear || ''"
    ></tpa-month-select-custom>
  `,
})
export class FormlyFieldMonthlySelect extends FieldType {
  defaultOptions = {
    props: {
      attributes: {
        allYear: translate('TPA.allMonths'),
      },
    },
  };
}
