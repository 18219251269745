import { Action, createReducer, on } from '@ngrx/store';
import { AsyncState } from '@ralba/shared';
import { UserTenancyDto } from '@tpa/api';
import { z } from 'zod';
import { GetUserTenanciesActions, UserTenancyActions } from '../actions';
import { ITenancyFeaturesAndPermissionns } from '../model';

export const impersonateZodType = z.array(
  z.object({
    tenantId: z.number(),
    userId: z.number(),
  })
);

export interface State
  extends AsyncState.IAsyncState,
    ITenancyFeaturesAndPermissionns {
  accounts: UserTenancyDto[];
  userId: number | null;
  features: {};
  allPermissions: {};
  grantedPermissions: {};
  hasEmployeeCard: null | number;
  hasSignedEmployeeCard: boolean | null;
}

const initialState: State = {
  ...AsyncState.initialState,
  accounts: [],
  hasEmployeeCard: null,
  userId: null,
  features: {},
  allPermissions: {},
  grantedPermissions: {},
  hasSignedEmployeeCard: null,
};

const UserTenanciesReducer = createReducer(
  initialState,
  on(GetUserTenanciesActions.request, (state) => ({
    ...state,
    ...AsyncState.loadingState,
  })),
  on(GetUserTenanciesActions.success, (state, { result }) => {
    // Overwrite for admin impersonate
    let accounts = result;

    const impersonate = impersonateZodType.safeParse(
      JSON.parse(localStorage.getItem('impersonate')) as any
    );
    if (impersonate.success) {
      accounts = result.map((x) => {
        const impersonateRecord = impersonate.data.find(
          (k) => k.tenantId == x.tenantId
        );
        if (impersonateRecord) {
          return {
            ...x,
            tenancyName: x.tenancyName + ' (Impersonate)',
            tenantId: impersonateRecord.tenantId,
            id: impersonateRecord.userId,
          };
        }
        return x;
      });
    }

    return {
      ...state,
      ...AsyncState.loadedState,
      accounts: accounts,
    };
  }),
  on(UserTenancyActions.setUserTenancyAccount, (state, { userId }) => ({
    ...state,
    ...AsyncState.loadingState,
    userId,
  })),
  on(UserTenancyActions.markEmployeeCardAsSigned, (state) => ({
    ...state,
    hasSignedEmployeeCard: true,
  })),
  on(
    UserTenancyActions.getUserTenancyFeatureAndPermissionsActions.request,
    (state) => ({
      ...state,
      ...AsyncState.loadingState,
      features: null,
      allPermissions: {},
      grantedPermissions: {},
      hasEmployeeCard: null,
    })
  ),
  on(
    UserTenancyActions.getUserTenancyFeatureAndPermissionsActions.success,
    (
      state,
      {
        result: {
          features,
          allPermissions,
          grantedPermissions,
          hasEmployeeCard,
          hasSignedEmployeeCard,
        },
      }
    ) => ({
      ...state,
      ...AsyncState.loadedState,
      features,
      allPermissions,
      grantedPermissions,
      hasEmployeeCard,
      hasSignedEmployeeCard,
    })
  )
);

export function reducer(state: State, action: Action) {
  return UserTenanciesReducer(state, action);
}

export const selecetAccounts = (state: State) => state.accounts;
export const selecetUserId = (state: State) => state.userId;
export const selecetFeatures = (state: State) => state.features;
export const selecetPermissions = (state: State) => ({
  allPermissions: state.allPermissions,
  grantedPermissions: state.grantedPermissions,
});

export const ownEmployeeCard = (state: State) => !!state.hasEmployeeCard;
export const employeeCardId = (state: State) => state.hasEmployeeCard;
export const signedEmployeeCard = (state: State) => state.hasSignedEmployeeCard;
