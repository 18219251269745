import { Component, Inject, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MenuItem } from '@ralba/shared';
import { filter, first, map, tap } from 'rxjs/operators';
import { AccountActions, NavMenuActions } from '../../actions';
import { Auth, NavMenuItem } from '../../models';
import { NavMenuSelectors, SessionSelectors } from '../../selectors';
import { APP_TITLE, PermissionChecker } from '../../services';

import { UntilDestroy } from '@ngneat/until-destroy';
import { APP_NAV_MENU } from '../../initializer';

@UntilDestroy()
@Component({
  selector: 'ral-admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.scss'],
})
export class AdminRootComponent implements OnInit {
  userName$ = this.store$.pipe(select(SessionSelectors.selectUserName));
  userActionsMenu: MenuItem[] = [
    {
      label: 'Profile',
      routerLink: ['/', 'system', 'profile'], //TOOD: URL injections
    },
    {
      label: 'SignOut',
      command: (e) => this.logout(),
    },
  ];

  menuItems$ = this.store$.pipe(select(NavMenuSelectors.selectFNavMenu));
  sideNavOpen$ = this.store$.pipe(select(NavMenuSelectors.selectSideNavOpen));

  menuItems: NavMenuItem[];

  constructor(
    public store$: Store<never>,
    @Inject(APP_NAV_MENU) navMenu: NavMenuItem[],
    @Inject(APP_TITLE) public appTitle: string = 'Admin'
  ) {
    this.store$.dispatch(
      NavMenuActions.registerMenuItems({
        menuItems: navMenu,
      })
    );

    // .isAuthenticated$.subscribe(e => console.log("ROOT CMP", e))
    // this.sessionService.getCurrentLoginInformationsAndStoreResult();
  }

  ngOnInit() {
    // this.httpClient.get(`${AbpConsts.remoteServiceBaseUrl}/AntiForgery/SetCookie`)
    //   .subscribe(response => { })
    this.registerMenuItems();
    // this.oidcSecurityService.isAuthenticated$
    //   .pipe(
    //     distinctUntilChanged(),
    //     tap((x) => console.log(x)),
    //     tap(() =>
    //       this.sessionService.getCurrentLoginInformationsAndStoreResult()
    //     )
    //   )
    //   .subscribe();
  }

  logout() {
    this.store$.dispatch(AccountActions.logOut());
  }

  reload() {
    window.location.reload();
  }
  clearStorage() {
    localStorage.clear();
  }

  // logOut() {
  //   this.store$.dispatch(AccountActions.logOut());
  // }

  private registerMenuItems() {
    this.menuItems$
      .pipe(
        filter(({ IsLoaded }) => IsLoaded),
        first(),
        map(({ navMenuItems, permissions }) => ({
          navMenuItems: JSON.parse(
            JSON.stringify(navMenuItems)
          ) as NavMenuItem[],
          permissions,
        })),
        map(({ navMenuItems, permissions }) =>
          this.filterMenuItems(navMenuItems, permissions)
        ),

        tap((navMenuItems) => {
          this.menuItems = navMenuItems;
        })
      )
      .subscribe();
  }

  private filterMenuItems(items: NavMenuItem[], permissions: Auth) {
    return items.map((item) => {
      item.visible = true;
      if (item.items && item.items.length > 0) {
        item.items = this.filterMenuItems(item.items, permissions);
      }
      if (item.authorization) {
        if (
          !PermissionChecker.isGranted(
            item.authorization.permission,
            permissions.allPermissions,
            permissions.grantedPermissions,
            item.authorization.requireAll
          )
        ) {
          item.visible = false;
        }
      }

      if (!item.items) {
      } else {
        if (item.items.filter((f) => f.visible).length === 0) {
          item.visible = false;
        }
      }

      return item;
    });
  }

  toggleSideNav() {
    this.store$.dispatch(NavMenuActions.toggleSideNav());
  }
}
