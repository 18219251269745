import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-number-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ejs-numerictextbox
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [class.ng-dirty]="showError"
      (onChange)="to.change && to.change(field, $event)"
      [placeholder]="to.placeholder"
      [min]="to.attributes?.['min']"
      [max]="to.attributes?.['max']"
      [format]="to.attributes?.['format']"
      [showSpinButton]="false"
      [step]="0"
    ></ejs-numerictextbox>
  `,
})
export class FormlyNumberFieldInput extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        min: null,
        max: null,
        format: 'n',
      },
    },
  };
}
