import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { OGMeta } from './meta';
import { OgMetaTagsActions } from './meta.actions';

export const FEATURE_KEY = 'OG Meta';

const initialState: OGMeta = {
  title: null,
  type: null,
  url: null,
};

const r = createReducer(
  initialState,
  on(OgMetaTagsActions.setMetaTags, (state, { tags }) => ({
    ...state,
    ...tags,
  }))
);

export function reducer(state: OGMeta, actiion: Action) {
  return r(state, actiion);
}

export const selectOGMeta = createFeatureSelector<OGMeta>(FEATURE_KEY);
