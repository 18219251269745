import { SideMenuComponent } from './side-menu/side-menu.component';
import { TableWrapperComponent } from './table-wrapper/table-wrapper.component';
import { FormWrapperComponent } from './form-wrapper/form-wrapper.component';
import { SeparatorComponent } from './separator/separator.component';
import { ValueIconComponent } from './value-icon/value-icon.component';
import { AdminTopBarComponent } from './top-menu/top-menu.component';
import { SideLogoHeaderComponent } from './side-logo-header/side-logo-header.component';
import { CardComponent } from './card/card.component';
import { TitleComponent } from './title/title.component';
import { EmptyComponent } from './empty-component/empty.component';
import { SideMenuItemComponent } from './side-menu-item/side-menu-item.component';
import { RightStickyPartComponent } from './right-sticky-part/right-sticky-part.component';
import { LoadingComponent } from './loading/loading.component';

export const RAL_UI_COMPONENTS = [
  SideMenuComponent,
  TableWrapperComponent,
  SeparatorComponent,
  ValueIconComponent,
  FormWrapperComponent,
  AdminTopBarComponent,
  SideLogoHeaderComponent,
  CardComponent,
  TitleComponent,
  EmptyComponent,
  SideMenuItemComponent,
  RightStickyPartComponent,
  LoadingComponent,
];

export {
  SideMenuComponent,
  TableWrapperComponent,
  SeparatorComponent,
  ValueIconComponent,
  FormWrapperComponent,
  AdminTopBarComponent,
  SideLogoHeaderComponent,
  CardComponent,
  TitleComponent,
  EmptyComponent,
  RightStickyPartComponent,
  SideMenuItemComponent,
  LoadingComponent,
};
