import { HttpClient } from '@angular/common/http';
import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { HandleBarsPreviewComponentStore } from './handlebars-preview.store';


import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {
  TPA_FEATURES
} from '@tpa/core';

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform {


  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }

}


@Component({
  selector: 'tpa-handlebars-preview',
  templateUrl: 'handlebars-preview.component.html',

  providers: [HandleBarsPreviewComponentStore],
})
export class HandleBarsPreviewComponent {
  FEATURES = TPA_FEATURES;

  @Input() printButtonKey = 'TPA.Print';

  @Input() set data(value: unknown) {
    if (value && value) {
      // const url = `${AbpConsts.remoteServiceBaseUrl}/api/services/tpa/UserTenancy/GetTenancies`;
      // let userId;
      // let tenantId;
      // this.http.get(url, { responseType: 'json' }).subscribe(
      //   (response) => console.log(response));
      this.handleBarsPreviewComponentStore.updateData(value);
    }
  }
  @Input() set url(value: string) {
    if (value && value)
      this.handleBarsPreviewComponentStore.updateByDownloadFromUrl(value);
  }
  @Input() set forYear(value: number) {
    if (value && value)
      this.handleBarsPreviewComponentStore.updateForYear(value);
  }
  @Input() set template(value: string) {
    if (value && value)
      this.handleBarsPreviewComponentStore.updateTemplate(value);
  }
  @Input() set signatureMetadata(value: unknown) {
    if (value && value)
      this.handleBarsPreviewComponentStore.updateSignatureMetadata(value);
  }

  constructor(
    public handleBarsPreviewComponentStore: HandleBarsPreviewComponentStore,
    public http: HttpClient
  ) { }

  resizeIframe(obj: HTMLIFrameElement) {
    // console.log('resizeIframe', obj.contentWindow);
    if (obj && obj.contentWindow)
      obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
  }


}
