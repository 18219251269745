import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  AttachmentDto,
  GetArtifactAttachments,
  TenancyRequestDto,
  UploadArtifactAttachment,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageArtifactAttachmentHttpApiService {
  constructor(private rest: RestService) {}

  public get(params: TenancyRequestDto<GetArtifactAttachments>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/artifact-attachment/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<AttachmentDto[]>>(request);
  }

  public upload(body: TenancyRequestDto<UploadArtifactAttachment>) {
    const request: Rest.Request<TenancyRequestDto<UploadArtifactAttachment>> = {
      method: 'POST',
      url: '/api/manage/artifact-attachment/upload',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<UploadArtifactAttachment>,
      Rest.AbpResponse<null>
    >(request);
  }
}
