import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { flattenObject, sortObjectProp, unFlattenObject } from '@tpa/shared';

@Directive()
export class BaseForm<T extends Record<string, unknown>> implements OnInit {
  _editMode = true;
  form = new FormGroup({});
  model: T | undefined; // = JSON.parse(JSON.stringify(MULTIPLE_ACTION_INIT_DATA));
  options: FormlyFormOptions = {
    formState: {
      //providerSide: true,
      editMode: this._editMode,
    },
  };

  fields: FormlyFieldConfig[] = [];

  debounce = true;

  @ViewChild('formElement') formElement: ElementRef | undefined;

  // Laoding and button textation
  @Input() hideSubmitButton = false;
  @Input() submitText = 'submit';
  @Input() submitIcon = 'none';
  @Input() _loading = false;
  @Input() loadingText = 'processing';
  @Input() loadingIcon = 'fa-spinner fa-pulse';
  currentSubmitText = 'submit';
  currentSubmitIcon = '';
  @Input() set loading(value: boolean | undefined) {
    if (this._loading === undefined) return;
    this._loading = value as boolean;
    this.currentSubmitText = value ? this.loadingText : this.submitText;
    this.currentSubmitIcon = value ? this.loadingIcon : this.submitIcon;
  }

  @Input() set value(inputValue: T | undefined) {
    if (this._loading === undefined) return;
    // same value not patch
    // console.log(value);
    const value = JSON.stringify(
      sortObjectProp(flattenObject(inputValue as T))
    );
    const model = JSON.stringify(
      sortObjectProp(flattenObject(this.model || {}))
    );
    if (value === model) return;
    // if null
    if (inputValue != null) {
      // console.log(value, JSON.stringify(value) === JSON.st=ringify(this.model));
      // console.log('stting value to form', value);
      this.model = unFlattenObject<T>(JSON.parse(value));
    }
    if (inputValue == null) {
      console.error('Dont push null to formlyform empty object is better {}');
      this.model = {} as T;
    }

    this.afterNewValueInput(this.model as T);
    // reset model
    // breaks formAraaayChanges
    // this.options.resetModel && this.options.resetModel();
  }

  @Input() set editMode(value: boolean) {
    this._editMode = value;
    this.options.formState = {
      ...this.options.formState,
      editMode: this._editMode,
    };
  }

  @Output() formSubmit = new EventEmitter<T>();
  @Output() validationFailed = new EventEmitter<never>();
  @Output() onInit = new EventEmitter<never>();

  constructor(model: T | null | undefined = null) {
    if (model) {
      this.model = JSON.parse(JSON.stringify(model));
    }

    // console.log(model);
  }

  ngOnInit() {
    this.onInit.emit();
  }

  onFormSubmit(model: T | undefined) {
    // console.log('submiting');
    // submit if valid and not in loading state

    if (model && this.form.valid && !this.loading) {
      this.submit(model);
    } else if (!this.loading) {
      console.log(this.loading, this.model, this.form.valid, this.form.errors);
      this.validationFailed.emit();
    }
  }

  /**
   * This function is for modifying the model after the value has been set.
   * @param model
   */
  protected afterNewValueInput(model: T) {
    //Not implemented
  }

  /**
   * This function is for modifying the model after validation before emiting
   * @param model
   */
  protected submit(model: T) {
    this.formSubmit.emit(JSON.parse(JSON.stringify(model)) as T);
  }

  /**
   * This function calls onSubmit on the form if the id #formElement
   * is added to <form>. This will also trigger validation and display
   * error messages. Very useful for linking a form together...
   */
  public callSubmit() {
    if (this.formElement && this.debounce) {
      this.debounce = false;
      setTimeout(() => (this.debounce = true), 200);
      this.formElement?.nativeElement.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
    }
  }
}
