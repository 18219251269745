import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-color',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <input
      ejs-colorpicker
      type="color"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [class.ng-dirty]="showError"
      (onChange)="to.change && to.change(field, $event)"
    />
  `,
})
export class ColorField extends FieldType {}
