<div>
  <ral-side-menu>
    <ral-side-menu-item *ngIf="loaded$ | ngrxPush">
      <ral-side-menu>
        <!-- *ngIf="accountsNotSingle$ | async" -->
        <ng-container>
          <ral-side-menu-item [icon]="'fa-th'" [label]="'TPA.MyCompanies' | transloco | firstToUpperCase"
            [routerLink]="['/', 'tenancy', 'dashboard']">
          </ral-side-menu-item>
        </ng-container>
        <ng-template [permissions]="['Pages.Administration.Roles']" permissionsFor="HOST" tpaCheck>
          <ral-side-menu-item [icon]="'fa-user-shield'" [label]="'TPA.Roles' | transloco | firstToUpperCase"
            [routerLink]="['/', 'role']">
          </ral-side-menu-item>
        </ng-template>
        <ng-template [permissions]="['Pages.Administration.Roles']" permissionsFor="HOST" tpaCheck>
          <ral-side-menu-item [icon]="'fa-users'" [label]="'TPA.Users' | transloco | firstToUpperCase"
            [routerLink]="['/', 'user']">
          </ral-side-menu-item>
        </ng-template>
        <ng-template [permissions]="['TPA.Company']" permissionsFor="HOST" tpaCheck>
          <ral-side-menu-item [icon]="'fa-building'" [label]="'TPA.Companies' | transloco | firstToUpperCase"
            [routerLink]="['/', 'company']">
          </ral-side-menu-item>
        </ng-template>
        <!-- tpaCheck [permissions]="['TPA.Announcements']" permissionsFor="HOST" -->
        <ng-template [permissions]="['TPA.Announcements']" permissionsFor="HOST" tpaCheck>
          <ral-side-menu-item [icon]="'fa-comment-alt-lines'"
            [label]="'TPA.Announcements' | transloco | firstToUpperCase" [routerLink]="['/', 'announcements']">
          </ral-side-menu-item>
        </ng-template>
        <ng-template [permissions]="['TPA.ETL']" permissionsFor="HOST" tpaCheck>
          <ral-side-menu-item [icon]="'fa-cog'" [label]="'TPA.ETL' | transloco | firstToUpperCase"
            [routerLink]="['/', 'etl']">
          </ral-side-menu-item>
        </ng-template>
        <ng-template [permissions]="['Pages.Administration.Host.Settings']" permissionsFor="HOST" tpaCheck>
          <ral-side-menu-item [icon]="'fa-cog'" [label]="'TPA.Configuration:Nav:Label' | transloco | firstToUpperCase"
            [routerLink]="['/','configuration']">
          </ral-side-menu-item>
        </ng-template>
      </ral-side-menu>
    </ral-side-menu-item>
  </ral-side-menu>

  <ng-container *ngIf="isLoaded$ | ngrxPush">
    <ral-side-menu *ngrxLet="identifer$ as identifer">
      <ral-side-menu-item>
        <ral-side-menu>
          <ng-template side="TENANT" tpaCheck>
            <ral-side-menu-item [label]="'TPA.Overview' | transloco | firstToUpperCase"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'dashboard']" icon="fa-tachometer-alt-fast">
            </ral-side-menu-item>
          </ng-template>
          <ng-template [feature]="'TPA.Payrolls'" [permissions]="['TPA.Payrolls.GetOwnPayrolls']" side="TENANT"
            tpaCheck>
            <ral-side-menu-item [label]="'TPA.Payrolls' | transloco | firstToUpperCase"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'payrolls']" icon="fa-scroll">
            </ral-side-menu-item>
          </ng-template>

          <ng-template [feature]="'TPA.Documents'" [permissions]="['TPA.MyDocuments']" side="TENANT" tpaCheck>
            <ral-side-menu-item [label]="'TPA.MyDocuments' | transloco | firstToUpperCase"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'documents']" icon="fa-file-alt">
            </ral-side-menu-item>
          </ng-template>

          <ng-template *ngIf="hasEmployeeCard$ | async" tpaCheck side="TENANT" [feature]="FEATURES.PersonalCardUI">
            <ral-side-menu-item [label]="'TPA.MyEmployeeCard' | transloco " icon="fa-id-card-alt"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'my-card', 'personal-data']" icon="fa-id-card-alt">
            </ral-side-menu-item>
          </ng-template>
          <ng-template *ngIf="hasEmployeeCard$ | async" [feature]="FEATURES.EmployeeTaxDeclaration" side="TENANT"
            tpaCheck>
            <ral-side-menu-item [label]="'TPA.MyTaxDeclaration' | transloco " icon="fa-id-card-alt"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'my-card', 'tax-declaration']" icon="fa-id-card-alt">
            </ral-side-menu-item>
          </ng-template>

          <ng-template *ngIf="hasEmployeeCard$ | async" [feature]="'TPA.EmployeeTaxAnnualSettlement'" side="TENANT"
            tpaCheck>
            <ral-side-menu-item
              [label]="'TPA.My{0}' | transloco:{'0': 'TPA.RZD' | transloco | lowercase } | firstToUpperCase"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'my-tax-recap']" icon="fa-gavel">
            </ral-side-menu-item>
          </ng-template>
          <!-- ['TPA.EmployeeTaxAnnualSettlement','TPA.EmployeeCard','TPA.GeneralArtefact' ]-->
          <ng-template [permissions]="['TPA.MyArtifact']" side="TENANT" tpaCheck>
            <ral-side-menu-item [label]="'TPA.MyArtifacts'| transloco | firstToUpperCase"
              [routerLink]="['/', 'tenancy', identifer.tenantId, 'artifacts']" icon="fa-cabinet-filing">
            </ral-side-menu-item>
          </ng-template>
        </ral-side-menu>
      </ral-side-menu-item>
      <ng-template side="TENANT" tpaCheck>
        <ral-side-menu-item [label]="'TPA.Settings' | transloco | firstToUpperCase"
          [routerLink]="['/', 'tenancy', identifer.tenantId, 'user-setting']" icon="fa-cog">
        </ral-side-menu-item>
      </ng-template>
    </ral-side-menu>

    <ng-template
      [permissions]="['Pages.Administration.Roles', 'TPA.PayrollRecap.Detail','TPA.GeneralArtifact','TPA.EmployeeCards','TPA.Rzd','TPA.User','Pages.Administration.Roles']"
      side="TENANT" tpaCheck>
      <ral-side-menu *ngrxLet="identifer$ as identifer">
        <ral-side-menu-item>
          <ral-side-menu>
            <ng-template [permissions]="compound_permissions.CAN_VIEW_TASKS" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.Tasks'| transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'tasks']" icon="fa-ballot-check">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [feature]="'TPA.PayrollRecap'" [permissions]="['TPA.PayrollRecap.Detail']" side="TENANT"
              tpaCheck>
              <ral-side-menu-item [label]="'TPA.PayrollRecaps' | transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'payrollrecap']" icon="fa-tasks">
              </ral-side-menu-item>
            </ng-template>
            <ng-template [feature]="'TPA.GeneralArtefact'" [permissions]="['TPA.GeneralArtifact']" side="TENANT"
              tpaCheck>
              <ral-side-menu-item [label]="'TPA.GeneralArtifacts'| transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'general-artifacts']" icon="fa-file-certificate">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [feature]="'TPA.Documents'" [permissions]="['TPA.Documents']" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.Documents' | transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'all-documents']" icon="fa-file-alt">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [feature]="'TPA.EmployeeCard'" [permissions]="['TPA.EmployeeCards']" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.EmployeeCards' | transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'employee-cards']" icon="fa-id-card">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [permissions]="['TPA.Rzd']" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.RZD' | transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'rzd-management']" icon="fa-user-shield">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [feature]="'TPA.Payrolls'" [permissions]="['TPA.EmployeePayrolls']" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.EmployeePayrolls' | transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'employee-payroll']" icon="fa-scroll">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [permissions]="['TPA.User']" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.Users'| transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'user']" icon="fa-users">
              </ral-side-menu-item>
            </ng-template>

            <ng-template [permissions]="['Pages.Administration.Roles']" side="TENANT" tpaCheck>
              <ral-side-menu-item [label]="'TPA.Roles' | transloco | firstToUpperCase"
                [routerLink]="['/', 'tenancy', identifer.tenantId, 'role']" icon="fa-user-shield">
              </ral-side-menu-item>
            </ng-template>
            <ng-template [permissions]="['TPA.Announcements']" side="TENANT" tpaCheck>
              <ral-side-menu-item [icon]="'fa-comment-alt-lines'"
                [label]="'TPA.Announcements' | transloco | firstToUpperCase"
                [routerLink]="['/','tenancy', identifer.tenantId, 'announcements']">
              </ral-side-menu-item>
            </ng-template>
            <ng-template tpaCheck [permissions]="[ 'TPA.User']" side="TENANT">
              <ral-side-menu-item [icon]="'fa-user-group'" [label]="'TPA.Departments' | transloco | firstToUpperCase"
                [routerLink]="['/','tenancy', identifer.tenantId, 'departments']">
              </ral-side-menu-item>
            </ng-template>

          </ral-side-menu>
        </ral-side-menu-item>
        <ng-template tpaCheck side="TENANT">
          <ral-side-menu-item [label]="'TPA.Settings' | transloco | firstToUpperCase" icon="fa-cog"
            [routerLink]="['/', 'tenancy', identifer.tenantId, 'user-setting']">
          </ral-side-menu-item>
        </ng-template>
      </ral-side-menu>
    </ng-template>
  </ng-container>
</div>
<div class="version" *ngIf="version$ | async as version">
  <div class="flex gap-6 items-center">
    <i class="fa-box fa-fw fal"></i>
    <span>
      {{ version}}
    </span>
  </div>
</div>