import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class AuthorizeHttpInterceptor implements HttpInterceptor {
  constructor(public oidcSecurityService: OidcSecurityService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthorizationHeaders(req));
  }

  protected addAuthorizationHeaders(req: HttpRequest<any>): HttpRequest<any> {
    let authorizationHeaders = req.headers
      ? req.headers.getAll('Authorization')
      : null;
    if (!authorizationHeaders) {
      authorizationHeaders = [];
    }

    if (
      !this.itemExists(
        authorizationHeaders,
        (item: string) => item.indexOf('Bearer ') === 0
      )
    ) {
      const token = this.oidcSecurityService.getToken(); //this.oAuth.getAccessToken();
      if (req.headers && token) {
        //   headers = headers.set('Authorization', 'Bearer ' + token);
        return req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
      }
    }

    return req;
  }

  private itemExists<T>(items: T[], predicate: (item: T) => boolean): boolean {
    for (let i = 0; i < items.length; i++) {
      if (predicate(items[i])) {
        return true;
      }
    }

    return false;
  }
}
