import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-date-time-picker',
  template: `
    <!-- {{ moment(formControl.value).format() }}<br />
    {{ formControl.value | date }}<br />
    {{ formControl.value | date: 'long' }} -->

    <ejs-datetimepicker [formControl]="$any(formControl)" [format]="to['format']">
    </ejs-datetimepicker>

    <!-- [min]="to.min"
    [max]="to.max" -->

    <!-- [locale]="to.attributes.locale" -->
    <!-- [placeholder]="placeholder" -->
  `,
  styleUrls: ['./date-picker.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldDateTimePicker extends FieldType implements OnInit {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        min: '',
        max: '',
        format: null, //'dd. MM. yyyy'
        //locale: this.locale,
      },
    },
  };
  //@Inject(LOCALE_ID) private locale

  constructor() {
    super();
  }

  ngOnInit() {
    if (
      this.formControl.value === null ||
      this.formControl.value === undefined
    ) {
      this.formControl.patchValue('');
    }
  }
}
