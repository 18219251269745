import { FormlyFieldConfig } from '@ngx-formly/core';

export const sharedMonthDiscount = (): FormlyFieldConfig => ({
  type: 'new-month-select',
  key: 'value',
  props: {
    checkboxLabel: 'TPA.ForMonths',
    translate: true,
    yesText: 'TPA.Yes',
    noText: 'TPA.No',
  },
});
