export interface IFormState<T> {
  model: T;
}
export interface IFormOptionsState<T> {
  options: T;
}

// export const initialState: IAsyncState = { loading: false, loaded: false };
// export const loadingState: IAsyncState = { loaded: false, loading: true };
// export const loadedState: IAsyncState = { loaded: true, loading: false };

export const selectModel = <T>(state: IFormState<T>) => state.model;
export const selectOptions = <T>(state: IFormOptionsState<T>) => state.options;
