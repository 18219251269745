import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AdminContainerBase } from '@ralba/shared';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';

import { Manage2faStore,FormDeactivateType,FormActivateType } from './manage-user-2fa.state';


@Component({
  selector: 'tpa-manage-user-2fa',
  templateUrl: 'manage-user-2fa.component.html',
  providers: [Manage2faStore],
  styleUrls: ['./manage-user-2fa.component.scss'],
})
export class ManageUser2FaComponent
  extends AdminContainerBase
  implements OnInit
{
  //
  // Modal
  //

  showModalForActivate: boolean;
  showModalForDisable: boolean;

  @ViewChild(TabComponent, { static: false })
  tab: TabComponent;

  form = new UntypedFormGroup({});
  model:FormActivateType = {};

  options: FormlyFormOptions = {
    formState: {
      selectedTab: 0,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      key: 'phoneNumber',
      type: 'input',
      className: 'p-col-12',
      props: {
        label: 'TPA.Phone',
        translate: true,
        required: true,
      },
      validators: {
        validation: [
          {
            name: 'validatePhoneNumber',
          },
        ],
      },
      hideExpression: (
        model: any,
        formState: any,
        field: FormlyFieldConfig
      ) => {
        return formState.selectedTab != 1;
      },
      defaultValue: '',
    },
    {
      key: 'twoFactorVerificationCode',
      type: 'input',
      className: 'p-col-12',
      props: {
        required: true,
        label: 'TPA.2FACode',
        translate: true,
        minLength: 6,
        maxLength: 6,
      },
      hideExpression: (
        model: any,
        formState: any,
        field: FormlyFieldConfig
      ) => {
        return formState.selectedTab != 2;
      },
      defaultValue: '',
    },
  ];

  formDeactivate = new UntypedFormGroup({});
  modelDeactivate: FormDeactivateType = {};

  optionsDeactivate: FormlyFormOptions = {
    formState: {},
  };

  fieldsDeactivate: FormlyFieldConfig[] = [
    {
      key: 'provider',
      type: 'input',
      className: 'p-col-12',
      props: {
        required: true,
      },
      hideExpression: true,
      defaultValue: 'GoogleAuthenticator',
    },
    {
      key: 'twoFactorVerificationCode',
      type: 'input',
      className: 'p-col-12',
      props: {
        required: true,
        label: 'TPA.2FACode',
        translate: true,
        minLength: 6,
        maxLength: 6,
      },
      defaultValue: '',
    },
  ];

  constructor(public readonly state: Manage2faStore) {
    super(state.store$);
  }

  ngOnInit() {
    this.state.getCurrentUserProfileEditDto({});
  }

  setFormState() {
    this.options.formState = {
      ...this.options.formState,
      selectedTab: this.tab?.selectedItem,
    };
  }

  enableGoogleAuthenticator() {
    this.state.requestForGoogleAuthenticatorKey({});
  }

  disableGoogleAuthenticator() {
    this.state.openDeactivedModal();
  }

  tryToActivateClose() {
    this.state.closeActivateModal();
  }

  tryToDactivateClose() {
    this.state.closeDeactivedModal();
  }

  next(model) {
    if (this.tab.selectedItem == 0) {
      this.tab.select(this.tab.selectedItem + 1);
    } else if (this.tab.selectedItem == 1) {
      this.sendSmsCode(model);
    } else if (this.tab.selectedItem == 2) {
      this.confirm2FA(model);
    }
    this.setFormState();
  }

  back() {
    if (this.tab.selectedItem > 0) {
      this.tab.select(this.tab.selectedItem - 1);
    } else {
      this.tryToActivateClose();
    }
    this.setFormState();
  }

  confirm2FA(model: FormDeactivateType) {
    this.state.confirmGoogleAuthenticatorKey(model);
  }

  sendSmsCode(model:FormActivateType) {
    if (this.form.valid && model.phoneNumber) {
      this.state.sendSmsCode({
        phoneNumber: model.phoneNumber + "" 
      });
      this.tab.select(this.tab.selectedItem + 1);
    }
  }


  deactivate2FA(model: FormDeactivateType) {
    if (this.formDeactivate.valid) {
      this.state.disableGoogleAuthenticator(model);
      this.optionsDeactivate.resetModel();
    }
  }

  setProvider(provider: string) {
    if (provider == 'Phone') {
      this.state.sendSmsCode({ phoneNumber: null });
    }

    this.fieldsDeactivate
      .find((p) => p.key == 'provider')
      ?.formControl.patchValue(provider);
  }
}
