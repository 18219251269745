import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule, PushModule } from '@ngrx/component';
import { FormlyModule } from '@ngx-formly/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { SharedModule } from '@tpa/shared';
import { TpaUiModule } from '@tpa/ui';
import { ChangeEmailNotificationsSettingsComponent } from './change-email-notification-settings.component';

@NgModule({
  imports: [
    CommonModule,
    TpaUiModule,
    TranslocoModule,
    LetModule,
    PushModule,
    ReactiveFormsModule,
    FormlyModule,
    ButtonModule,
    SharedModule,
  ],
  declarations: [ChangeEmailNotificationsSettingsComponent],
  exports: [ChangeEmailNotificationsSettingsComponent],
  providers: [],
})
export class ChangeEmailNotificationsSettingsModule {}
