/* eslint-disable @typescript-eslint/no-explicit-any */
export const flattenObject = (
  obj: Record<string, unknown>,
  prefix = ''
): Record<string, unknown> =>
  !obj
    ? {}
    : Object.keys(obj).reduce((acc: Record<string, unknown>, k) => {
        const pre = prefix.length ? `${prefix}.` : '';
        if (
          typeof obj[k] === 'object' &&
          obj[k] !== null &&
          Object.keys(obj[k] as Record<string, unknown>).length > 0
        )
          Object.assign(
            acc,
            flattenObject(obj[k] as Record<string, unknown>, pre + k)
          );
        else acc[pre + k] = obj[k];
        return acc;
      }, {});

export const unFlattenObject = <T>(obj: { [key: string]: never }) =>
  Object.keys(obj).reduce((res, k) => {
    k.split('.').reduce(
      (acc: any, e, i, keys) =>
        acc[e] ||
        (acc[e] = isNaN(Number(keys[i + 1]))
          ? keys.length - 1 === i
            ? obj[k]
            : {}
          : []),
      res
    );
    return res as T;
  }, {} as T);

export function sortObjectProp<T extends Record<string, unknown>>(obj: T) {
  const sort = (source: Record<string, unknown>) =>
    Object.keys(source)
      .sort()
      .reduce((obj: any, key) => {
        obj[key] = source[key];
        return obj;
      }, {});

  return sort(flattenObject(obj));
}
