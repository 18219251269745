import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { RalbaUiModule } from '@ralba/ui';
import { COMPONENTS } from './components';
import { EFFECTS } from './effects';
import {
  AuthorizeHttpInterceptor,
  LocalizationHttpInterceptor,
} from './httpInterceptors';
import { rootReducers } from './reducers';

import { RouterModule } from '@angular/router';

import { GUARD_PROVIDERS } from './guards';
import { PortalCoreModuleConfig } from './models';
import {
  APP_TITLE,
  AuthService,
  IS_PRODUCTION,
  SERVICE_PROVIDERS,
} from './services';

import { AppConfig } from '@ralba/shared';
import { ToastModule } from './features';

import { HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import {
  AUTH_COMPONETNS,
  AuthCallbackComponent,
  UnauthorizedComponent,
} from './containers';

import { TranslocoRootModule } from './translate.module';

export function configureAuth(oidcConfigService: OidcConfigService) {
  return () => {
    oidcConfigService.withConfig(AppConfig.config.authConfig);
  };
}

export const Auth_Provider = {
  provide: APP_INITIALIZER,
  useFactory: configureAuth,
  deps: [OidcConfigService],
  multi: true,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'auth',
        children: [
          { path: 'unauthorized', component: UnauthorizedComponent },
          { path: 'callback', component: AuthCallbackComponent },
        ],
      },
    ]),
    RalbaUiModule,
    AuthModule.forRoot(),
    StoreModule.forRoot(rootReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot(EFFECTS),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    TranslocoRootModule,
    HttpClientXsrfModule,
    ToastModule,
  ],
  declarations: [...COMPONENTS, ...AUTH_COMPONETNS],
  exports: [...COMPONENTS],
  providers: [
    AuthorizeHttpInterceptor,
    ...SERVICE_PROVIDERS,
    ...GUARD_PROVIDERS,
  ],
})
export class AdminCoreModule {
  static forRoot(
    config: PortalCoreModuleConfig = {
      appTitle: null,
      navMenuItems: null,
      isProd: false,
    }
  ): ModuleWithProviders<AdminCoreModule> {
    return {
      ngModule: AdminCoreModule,
      providers: [
        AuthService,
        Auth_Provider,
        {
          provide: IS_PRODUCTION,
          useValue: config.isProd,
        },
        {
          provide: APP_TITLE,
          useValue: config.appTitle,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LocalizationHttpInterceptor,
          multi: true,
        },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: adminInitializerFactory,
        //   deps: [NAV_MENU_ITMES_TOKEN, SessionService, Store],
        //   multi: true
        // }
      ],
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: AdminCoreModule) {
    if (parentModule) {
      throw new Error(
        'AdminCoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
