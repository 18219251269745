<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div
      *ngIf="
        model?.zdrzovaniVCR === undefined || model.zdrzovaniVCR === null;
        else notEmpty
      "
    >
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p class="description">
        {{ 'TPA.ResidentureQ1' | transloco }}
      </p>
      <p>
        <mark>
          {{ model?.zdrzovaniVCR | yesOrNo | nullToDash }}
        </mark>
      </p>
      <div class="space-12"></div>

      <div *ngIf="model?.studiumVCR">
        <p class="description">
          {{ 'TPA.ResidentureQ2' | transloco }}
        </p>
        <p>
          <mark>
            {{ model?.studiumVCR | yesOrNo | nullToDash }}
          </mark>
        </p>
      </div>
      <div class="space-12"></div>
      <p>
        {{ 'TPA.Evaluation' | transloco }}:
        {{ model?.rezidenturaVyhodnoceni | residenture }}
      </p>
      <p>
        {{ 'TPA.Inputed' | transloco }}: {{ model?.rezidentura | residenture }}
      </p>
      <p *ngIf="model?.rezidentura !== 'true'">
        {{ 'TPA.ResidentureCountry' | transloco }}:
        <span *ngIf="model?.rezidenturaVeState">
          {{ 'TPA.' + model?.rezidenturaVeState | transloco }}
          <ng-container *ngIf="model?.identifikace">
            ({{ model?.identifikace }})
          </ng-container>
        </span>
      </p>
    </ng-template>
  </div>
</ng-template>
