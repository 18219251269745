import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild, Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { SessionService } from '@ralba/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  exhaustMap, map, tap, timeoutWith
} from 'rxjs/operators';

// , CanActivateChild, CanLoad
@Injectable()
export class AuthenticationGurad implements CanActivate, CanActivateChild {
  private isAuthenticated: boolean;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private sessionService: SessionService,
    private router: Router
  ) {
    // this.authService.isAuthenticated$.subscribe(
    //   (i) => (this.isAuthenticated = i)
    // );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.oidcSecurityService.checkAuthIncludingServer().pipe(
      timeoutWith(
        7000,
        of(false).pipe(tap(() => console.log('Timeout for check auth')))
      ),
      exhaustMap((isAuthorized: boolean) => {
        if (!isAuthorized) {
          sessionStorage.setItem('redirect_uri', state.url);
          this.router.navigate(['/', 'auth', 'unauthorized']);
          return of(false);
        }

        return this.sessionService
          .getCurrentLoginInformationsAndStoreResult()
          .pipe(map(() => true));
      })
    );
    // return this.oidcSecurityService.isAuthenticated$
    //   .pipe(
    //     exhaustMap((isAuthorized: boolean) => {
    //       if (!isAuthorized) {
    //         sessionStorage.setItem('redirect_uri', state.url);
    //         this.router.navigate(['/', 'auth', 'unauthorized']);
    //         return of(false);
    //       }

    //       return this.sessionService.getCurrentLoginInformationsAndStoreResult()
    //         .pipe(
    //           map(() => true)
    //         )
    //     })
    //   )

    // return this.oidcSecurityService.checkAuthIncludingServer().pipe(
    //   tap((x) => {
    //     if (!x) {
    //       this.oidcSecurityService.authorize();
    //     }
    //   })
    // );
    // return this.authService.isDoneLoading$.pipe(
    //   filter((isDone) => isDone),
    //   tap((_) => this.isAuthenticated || this.authService.login(state.url)),
    //   map((_) => this.isAuthenticated)
    // );
  }
}
