import { Component, Input } from '@angular/core';
import { AnimationModel } from '@syncfusion/ej2-angular-progressbar';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'tpa-loading',
  templateUrl: 'loading.component.html',
})
export class LoadingComponent {
  @Input() loaded?: null | boolean;
  id: string;

  public animation: AnimationModel = { enable: true, duration: 2000, delay: 0 };

  constructor() {
    this.id = uuidv4();
  }
}
