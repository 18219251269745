import { SessionService } from './session.service';
import { PermissionChecker } from './permission-checker.service';
import { AuthService } from './auth.service';
import { BackNavigationService } from './navigation-histroy.service';
import { UrlCryptoService } from './url-crypto.service';
import { InjectionToken, Provider } from '@angular/core';

const APP_TITLE = new InjectionToken<string>('AppTitle');
const IS_PRODUCTION = new InjectionToken<string>('IS_PRODUCTION');

export const SERVICE_PROVIDERS: Provider[] = [
  SessionService,
  PermissionChecker,
  BackNavigationService,
  UrlCryptoService,
];

export {
  SessionService,
  PermissionChecker,
  AuthService,
  BackNavigationService,
  UrlCryptoService,
  APP_TITLE,
  IS_PRODUCTION,
};
