import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { countryOptions } from '@tpa/features/emp-data';
import { isValid, parseISO } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { RodneCislo, specialCharsParser } from './utils';

export const convertPersonalIdentificationNumberDateWithoutSlash = (
  input: string
): Date => {
  const value = input + '';

  if (value == null || !value.match(/(\d{6}\d{3,4})/)) {
    throw new Error('invalidate');
  }

  const withoutSlash = value; // .replace('/', '');

  const syear = withoutSlash.slice(0, 2);
  const smonth = withoutSlash.slice(2, 4);
  const sday = withoutSlash.slice(4, 6);
  const sext = withoutSlash.slice(6, 9);
  const compose = `${syear}${smonth}${sday}${sext}`;
  let year = parseInt(withoutSlash.slice(0, 2));
  let month = parseInt(withoutSlash.slice(2, 4));
  //const day = parseInt(withoutSlash.slice(4, 6));
  //const ext = parseInt(withoutSlash.slice(6, 9));
  const c = withoutSlash.length == 10 ? parseInt(withoutSlash.slice(9, 10)) : 0;
  // if (withoutSlash.length == 9) {
  //   year += year < 54 ? 1900 : 1800;
  // }
  if (withoutSlash.length == 9) {
    if (year < 54) {
      year += 1900;
    } else {
      throw new Error('1900');
    }
  } else {
    let mod = parseInt(compose) % 11;
    if (mod == 10) mod = 0;
    if (mod != c) {
      throw new Error('invalidate');
    }
    year += year < 54 ? 2000 : 1900;
  }

  if (month > 70 && year > 2003) {
    month -= 70;
  } else if (month > 50) {
    month -= 50;
  } else if (month > 20 && year > 2003) {
    month -= 20;
  }

  const date = parseISO(`${year}-${minTwoChar(month)}-${sday}`);

  if (!isValid(date)) {
    throw new Error('invalidate');
  }

  return date;
};

// helper for personal identification number
function minTwoChar(value: number) {
  const val = value.toString();
  if (val.length == 1) {
    return '0' + val;
  }
  return val;
}

export const sharedPersonalInformation = (): FormlyFieldConfig[] => [
  {
    key: 'jmeno',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      translate: true,
      label: 'TPA.FirstName',
      required: true,
    },
  },
  {
    key: 'prijmeni',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      translate: true,
      label: 'TPA.Surname',
      required: true,
    },
  },
  {
    key: 'titulpred',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      translate: true,

      label: 'TPA.TitleBeforeName',
    },
  },
  {
    key: 'titulza',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      translate: true,

      label: 'TPA.TitleAfterName',
    },
  },
  {
    key: 'rodnePrijmeni',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      translate: true,
      label: 'TPA.MaidenName',
      required: true,
    },
  },
  {
    key: 'pohlavi',
    type: 'select',
    className: 'p-col-12 p-md-6',
    props: {
      translate: true,
      translateOptions: true,
      placeholder: 'TPA.SelectPlaceholder',
      label: 'TPA.Gender',
      required: true,

      options: [
        { label: 'TPA.Man', value: 'man' },
        { label: 'TPA.Woman', value: 'woman' },
        { label: 'TPA.Undefined', value: 'undefined' },
      ],
    },
    expressionProperties: {
      hide: (a, formState) => {
        return !formState.full || false;
      },
    },
  },
  {
    key: 'statniPrislusnost',
    type: 'select',
    className: 'p-col-12 p-md-6',
    props: {
      placeholder: 'TPA.SelectPlaceholder',
      label: 'TPA.Nationality',
      required: true,
      translate: true,
      options: countryOptions(),
    },
  },
  {
    className: 'p-col-12',
    fieldGroup: [
      {
        key: 'druhaStatniPrislusnostCheck',
        type: 'checkbox',
        className: 'p-col-12',

        props: {
          checkboxLabel: 'TPA.HasSecondNationality',
          translate: true,
        },
      },
      {
        key: 'druhaStatniPrislusnost',
        type: 'select',
        className: 'p-col-12 p-md-6',
        props: {
          placeholder: 'TPA.SelectPlaceholder',
          label: 'TPA.SecondNationality',
          translate: true,
          translateOptions: true,
          options: countryOptions(),
        },
        hideExpression: '(model?.druhaStatniPrislusnostCheck != true)',
      },
    ],
  },
  {
    ...RodneCislo(translate('TPA.BirthNumber')),
    key: 'rodneCislo',
    className: 'p-col-12 p-md-6',
    props: {
      ...RodneCislo(translate('TPA.BirthNumber')).props,
      change: (field?: FormlyFieldConfig) => {
        if (field?.formControl?.valid && field?.formControl?.value) {
          const d = formatISO(
            convertPersonalIdentificationNumberDateWithoutSlash(
              field.formControl.value.replace('/', '')
            )
          );

          const datumNarozeni = field?.parent?.fieldGroup?.find(
            (p) => p.key == 'datumNarozeni'
          );
          if (datumNarozeni) datumNarozeni.formControl?.setValue(d);
        }
      },
    },

    hideExpression: (m, formState, field) => {
      return (
        (field || false) &&
        !(
          field.model?.statniPrislusnost == 'CZ' ||
          field.model?.druhaStatniPrislusnost == 'CZ'
        )
      );
    },
    expressionProperties: {
      'props.required': (m, formState, field) => {
        return field?.model?.statniPrislusnost == 'CZ';
      },
    },
  },
  {
    key: 'datumNarozeni',
    type: 'date-picker',
    className: 'p-col-12 p-md-6',
    // defaultValue: new Date(),
    props: {
      translate: true,
      label: 'TPA.DateOfBirth',
      required: true,
      attributes: {
        format: 'dd. MM. yyyy',
      },
    },
  },

  {
    key: 'mistoNarozeni',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      translate: true,
      label: 'TPA.PlaceOfBirth',
      required: true,
    },
  },

  {
    key: 'rodinnyStav',
    type: 'select',
    className: 'p-col-12 p-md-6',
    props: {
      translate: true,
      translateOptions: true,

      placeholder: 'TPA.SelectPlaceholder',
      label: 'TPA.MaritalStatus',
      required: true,
      options: [
        { label: 'TPA.Single', value: 'single' },
        { label: 'TPA.Married', value: 'married' },
        { label: 'TPA.Divorced', value: 'divorced' },
        { label: 'TPA.Widowed', value: 'widowed' },
        { label: 'TPA.Fiancee', value: 'fiancee' },
        { label: 'TPA.Registered', value: 'registered' },
        { label: 'TPA.Unidentified', value: 'unidentified' },
      ],
    },
    expressionProperties: {
      hide: (a, formState) => {
        return !formState.full || false;
      },
    },
  },
  {
    key: 'vzdelani',
    type: 'select',

    className: 'p-col-12 p-md-6',
    props: {
      translate: true,
      translateOptions: true,

      placeholder: 'TPA.SelectPlaceholder',
      label: 'TPA.EducationLevel',
      required: true,
      options: [
        { label: 'TPA.EduA', value: 'A' },
        { label: 'TPA.EduB', value: 'B' },
        { label: 'TPA.EduC', value: 'C' },
        { label: 'TPA.EduD', value: 'D' },
        { label: 'TPA.EduE', value: 'E' },
        { label: 'TPA.EduH', value: 'H' },
        { label: 'TPA.EduJ', value: 'J' },
        { label: 'TPA.EduK', value: 'K' },
        { label: 'TPA.EduL', value: 'L' },
        { label: 'TPA.EduM', value: 'M' },
        { label: 'TPA.EduN', value: 'N' },
        { label: 'TPA.EduR', value: 'R' },
        { label: 'TPA.EduT', value: 'T' },
        { label: 'TPA.EduV', value: 'V' },
      ],
    },
    expressionProperties: {
      hide: (a, formState) => {
        return !formState.full || false;
      },
    },
  },
];

export const sharedCountOfEducableChildren = (): FormlyFieldConfig => ({
  key: 'pocetVychDeti',
  type: 'number',
  hide: !true,
  className: 'p-col-12 p-md-6',
  props: {
    translate: true,
    label: 'TPA.NumberOfChildren',
    description: 'TPA.NumberOfChildrenDesc',
    min: 0,
  },
  defaultValue: 0,
  expressionProperties: {
    'props.required': 'formState.isVychDetiRequired()',
    hide: (a, formState) => {
      return !formState.full || false;
    },
  },
});
