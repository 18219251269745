import { AfterContentInit, Component, ViewChild } from '@angular/core';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { tap } from 'rxjs/operators';

import { Actions, ofType } from '@ngrx/effects';
import { ToastActions } from './toast.actions';

@Component({
  selector: 'ral-toast',
  template: ` <ejs-toast [position]="position"> </ejs-toast> `,
})
export class RalToastComponent implements AfterContentInit {
  // interval: any;
  @ViewChild(ToastComponent, { static: true }) element: ToastComponent;
  public position = { X: 'Right', Y: 'Bottom' };

  constructor(private actions$: Actions) { }

  ngAfterContentInit(): void {
    this.actions$
      .pipe(
        ofType(ToastActions.showToastMessage),
        tap(({ toast }) => {
          this.element.show({
            title: toast.title ?? "",
            content: toast.content ?? "",
            cssClass: toast.cssClass ?? ""
          });
        })
      )
      .subscribe();
  }
}
