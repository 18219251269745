import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskListDto } from '@tpa/api';
import * as moment from 'moment';

interface Task {
  id: number
  date?: number | Date | moment.Moment;
  text?: string;
}

@Component({
  selector: 'tpa-task',
  templateUrl: 'task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskComponent implements OnInit {
  @Input() task: TaskListDto;

  @Output() completeClick: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  completBtnclick(id: number) {
    this.completeClick.emit(id);
  }

}
