import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import { AnnouncementDto, TenancyRequestDto } from '../dto';

@Injectable({ providedIn: 'root' })
export class AnnouncementHttpApiService {
  constructor(private rest: RestService) {}
  public getAnnouncements(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/announcement/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<AnnouncementDto[]>>(
      request
    );
  }
}
