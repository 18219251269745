import { createSelector } from '@ngrx/store';
import { Auth } from '../models';
import { PermissionChecker } from '../services';
import { CoreSelectors } from './core.selectors';

const selectIsGratned = (
  permissions: string[],
  requreireAll: boolean = false
) =>
  createSelector(CoreSelectors.selectAuth, (state: Auth) =>
    PermissionChecker.isGranted(
      permissions,
      state.allPermissions,
      state.grantedPermissions,
      requreireAll
    )
  );

export const PermissionSelectors = {
  selectIsGratned,
};
