import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Rest, RestService } from '@ralba/shared';
import { from } from 'rxjs';
import { SessionActions } from '../actions';
import { CurrentLoginInformations, GetAll } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  SERVICE_URL = '/api/session/get-current-login-informations';
  constructor(private rest: RestService, private store$: Store<never>) {}

  getCurrentLoginInformationsAndStoreResult() {
    return from(
      this.getCurrentLoginInformations()
        .toPromise()
        .then((response) =>
          this.store$.dispatch(
            SessionActions.currentLoginInformationsLoaded(response.result)
          )
        )
    );
  }

  getAll() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `/AbpUserConfiguration/GetAll`,
    };
    return this.rest.request<null, Rest.AbpResponse<GetAll>>(request);
  }

  getCurrentLoginInformations() {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `/api/session/get-current-login-informations`,
    };

    return this.rest.request<null, Rest.AbpResponse<CurrentLoginInformations>>(
      request
    );
  }
}
