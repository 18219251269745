<ng-container *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card">
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
  <div
    *ngIf="to.attributes?.discountDescription"
    class="p-col-12"
    style="padding: 8px"
  >
    <p class="description">
      {{ (to.attributes.discountDescription + "") | transloco }}
    </p>
  </div>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <!-- {{ model.value }} -->
    <div [ngSwitch]="model?.value">
      <p *ngSwitchCase="MonthFlags.None">{{ 'TPA.NotApplied' | transloco }}</p>
      <p *ngSwitchCase="null">{{ 'TPA.NotApplied' | transloco }}</p>
      <p *ngSwitchCase="4095">{{ 'TPA.ApplyingAllYearRound' | transloco }}</p>
      <p *ngSwitchDefault>
        {{ 'TPA.ApplicationForMonths' | transloco }}:
        {{ model?.value | monthPipe }}
      </p>
    </div>
  </div>
</ng-template>
<!-- <pre>
    {{ this.model | json }}
  </pre
> -->
