import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'loanPurpose',
})
export class LoanPurposePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText;
    switch (value) {
      case 'vystavba': {
        returnedText = translate('TPA.LoanInterestsPurpose1');
        break;
      }
      case 'pozemekVystavba': {
        returnedText = translate('TPA.LoanInterestsPurpose2');
        break;
      }
      case 'koupe': {
        returnedText = translate('TPA.LoanInterestsPurpose3');
        break;
      }
      case 'rekonstrukce': {
        returnedText = translate('TPA.LoanInterestsPurpose4');
        break;
      }
      case 'splaceni': {
        returnedText = translate('TPA.LoanInterestsPurpose5');
        break;
      }
      case 'vyporadani': {
        returnedText = translate('TPA.LoanInterestsPurpose6');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
