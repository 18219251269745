import { InjectionToken } from '@angular/core';
import { NavMenuItem } from '../models';

export const APP_CONFIG_PATH = new InjectionToken<string>('AppConfigUrl');
export const APP_NAV_MENU = new InjectionToken<NavMenuItem[]>('APP_NAV_MENU');

// export function appInitializer(
//   sessionService: SessionService,
//   store$: Store<never>,
//   translate: TranslocoService
// ) {
//   return () =>
//     sessionService
//       .getAll()
//       .pipe(
//         tap(({ result }) => {
//           const {
//             localization: { languages, currentLanguage },
//           } = result;
//           translate.setAvailableLangs(languages.map((x) => x.name));
//           translate.setDefaultLang(currentLanguage.name);
//           store$.dispatch(SessionActions.getAllLoadded(result));
//         }),
//         catchError((error) => {
//           console.error(error)
//           window.location.pathname = '500.html';
//           return of(null);
//         })
//       )
//       .toPromise();
// }

// export const APP_INITIALIZER_PROVIDER = {
//   provide: APP_INITIALIZER,
//   useFactory: appInitializer,
//   deps: [SessionService, Store, TranslocoService],
//   multi: true,
// };
