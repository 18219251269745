import { Action, createReducer, on } from '@ngrx/store';
import { NavMenuActions } from '../actions';
import { NavMenuItem } from '../models';

export interface NavMenuState {
  menu: NavMenuItem[];
  sidenavOpen: boolean;
}

const iniitialSate: NavMenuState = {
  menu: [],
  sidenavOpen: false,
};

const reducer = createReducer(
  iniitialSate,
  on(NavMenuActions.registerMenuItems, (state, { menuItems }) => ({
    ...state,
    menu: menuItems,
  })),
  on(NavMenuActions.openSideNav, (state) => ({
    ...state,
    sidenavOpen: true,
  })),
  on(NavMenuActions.closeSideNav, (state) => ({
    ...state,
    sidenavOpen: false,
  }))
);

export function navMenuReducer(state: NavMenuState, action: Action) {
  return reducer(state, action);
}
export const selectNavMenu = (state: NavMenuState) => state.menu;
export const selectSideNavOpen = (state: NavMenuState) => state.sidenavOpen;
