import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'tpa-changes-summary',
  templateUrl: 'changes-summary.component.html',
  styles: [
    `
      :host {
        width: 100%;
      }
      ::ng-deep .changes-box .e-card {
        background: #e9e9e9 !important;
        border: none;
      }
    `,
  ],
})
export class ChangesSummaryComponent extends FieldType {
  defaultOptions = {
    props: {},
  };
}
