export * from './dtos-proxy';
import { EntityDto } from '@ralba/shared';
import { PayrollRecapStateEnum, UserNotificationState } from './dtos-proxy';

// ----------------------
export interface TenancyRequestDto<TBody = any> {
  userId?: number;
  tenantId?: number;
  body?: TBody;
}

export interface PagedInputDto {
  maxResultCount: number;
  skipCount?: number;
}

export interface PagedAndSortedInputDto extends PagedInputDto {
  sorting?: string
}
// ----------------------

export interface GetMessages extends EntityDto {
  skip: number;
  take: number;
  artifactType: string;
}

export interface GetArtifactAttachments extends EntityDto {
  artifactType: string;
}

export interface GetDemandsInputDto extends PagedInputDto {
  state?: PayrollRecapStateEnum
}

export interface GetDemandsInputDto extends PagedInputDto {
  state?: PayrollRecapStateEnum
}

export interface GetRolesInput {
  permissions?: string[]
}

export interface TPAGetUsersInput extends PagedAndSortedInputDto {
  filter: string;
}

export interface GetCompaniesInput extends PagedAndSortedInputDto {
  filter: string;
}

export interface GetUserNotificationsInput extends PagedInputDto {
  state?: UserNotificationState
  startDate?: Date,
  endDate?: Date
}

export enum JournalResult {
  New = 0,
  Ok = 200,
  Warning = 300,
  UndefinedOperation = 404,
  Error = 500,
}

export enum PayrollRecapState {
  New = 0,
  Approved = 1,
  Invalidated = 2,
  Denied = 3,
}

export interface ArtifactDto {
  id: number,
  artifactType: string
}
export interface GetRzdInput extends EntityDto {
  versionId?: number | null,
}