import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'ral-formly-multiple-check-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host { display: block; width: 100%; }'],
  template: `
    <div class="p-grid">
      <div
        *ngFor="
          let option of to.options | formlySelectOptions: field | async;
          let i = index
        "
        class="p-col-{{ to.attributes.grid }}"
      >
        <ejs-checkbox
          [value]="option.value"
          [label]="option.label"
          [formlyAttributes]="field"
          [formControl]="$any(formControl)"
          (onChange)="to.change && to.change(field, $event)"
        ></ejs-checkbox>
      </div>
    </div>
  `,
})
export class FormlyMultipleCheckFieldInput extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        grid: 12,
      },
    },
  };

  // onChange(value: any, checked: boolean) {
  //   this.formControl.markAsTouched();
  // }
}
