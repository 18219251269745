import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  TenancyRequestDto,
  UserPermissionsAndFeaturesDto,
  UserTenancyDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class UserTenancyHttpApiService {
  constructor(private rest: RestService) {}

  public getTenancies(params?: {}) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user-tenancy/get/tenancies',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<UserTenancyDto[]>>(request);
  }

  public getTenancyPermissionsAndFeatures(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user-tenancy/get/tenant-permissions-and-feature',
      params,
    };
    return this.rest.request<
      null,
      Rest.AbpResponse<UserPermissionsAndFeaturesDto>
    >(request);
  }
}
