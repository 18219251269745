<ng-container *ngIf="to['open']; else card">
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <!-- <h2 class="h1 mb-4">
      {{ 'TPA.EmployeeCardTitle' | transloco }}

    </h2> -->
    <p>Rozbalte pro zobrazení kompletních dat</p>
  </div>
</ng-template>
