import { translate } from '@ngneat/transloco';


export const countryOptions = () => {
  return [
    { label: translate('TPA.CZ'), value: 'CZ' },
    { label: translate('TPA.AF'), value: 'AF' },
    { label: translate('TPA.AL'), value: 'AL' },
    { label: translate('TPA.DZ'), value: 'DZ' },
    { label: translate('TPA.AS'), value: 'AS' },
    { label: translate('TPA.VI'), value: 'VI' },
    { label: translate('TPA.AD'), value: 'AD' },
    { label: translate('TPA.AO'), value: 'AO' },
    { label: translate('TPA.AI'), value: 'AI' },
    { label: translate('TPA.AQ'), value: 'AQ' },
    { label: translate('TPA.AG'), value: 'AG' },
    { label: translate('TPA.AR'), value: 'AR' },
    { label: translate('TPA.AM'), value: 'AM' },
    { label: translate('TPA.AW'), value: 'AW' },
    { label: translate('TPA.AU'), value: 'AU' },
    { label: translate('TPA.AZ'), value: 'AZ' },
    { label: translate('TPA.BS'), value: 'BS' },
    { label: translate('TPA.BH'), value: 'BH' },
    { label: translate('TPA.BD'), value: 'BD' },
    { label: translate('TPA.BB'), value: 'BB' },
    { label: translate('TPA.BE'), value: 'BE' },
    { label: translate('TPA.BZ'), value: 'BZ' },
    { label: translate('TPA.BY'), value: 'BY' },
    { label: translate('TPA.BJ'), value: 'BJ' },
    { label: translate('TPA.BM'), value: 'BM' },
    { label: translate('TPA.BT'), value: 'BT' },
    { label: translate('TPA.BO'), value: 'BO' },
    { label: translate('TPA.BA'), value: 'BA' },
    { label: translate('TPA.BW'), value: 'BW' },
    { label: translate('TPA.BV'), value: 'BV' },
    { label: translate('TPA.BR'), value: 'BR' },
    { label: translate('TPA.IO'), value: 'IO' },
    { label: translate('TPA.VG'), value: 'VG' },
    { label: translate('TPA.BN'), value: 'BN' },
    { label: translate('TPA.BG'), value: 'BG' },
    { label: translate('TPA.BF'), value: 'BF' },
    { label: translate('TPA.BI'), value: 'BI' },
    { label: translate('TPA.XC'), value: 'XC' },
    { label: translate('TPA.CK'), value: 'CK' },
    { label: translate('TPA.CW'), value: 'CW' },
    { label: translate('TPA.TD'), value: 'TD' },
    { label: translate('TPA.ME'), value: 'ME' },
    { label: translate('TPA.CN'), value: 'CN' },
    { label: translate('TPA.DK'), value: 'DK' },
    { label: translate('TPA.DM'), value: 'DM' },
    { label: translate('TPA.DO'), value: 'DO' },
    { label: translate('TPA.DJ'), value: 'DJ' },
    { label: translate('TPA.EG'), value: 'EG' },
    { label: translate('TPA.EC'), value: 'EC' },
    { label: translate('TPA.ER'), value: 'ER' },
    { label: translate('TPA.EE'), value: 'EE' },
    { label: translate('TPA.ET'), value: 'ET' },
    { label: translate('TPA.EU'), value: 'EU' },
    { label: translate('TPA.FO'), value: 'FO' },
    { label: translate('TPA.FK'), value: 'FK' },
    { label: translate('TPA.FJ'), value: 'FJ' },
    { label: translate('TPA.PH'), value: 'PH' },
    { label: translate('TPA.FI'), value: 'FI' },
    { label: translate('TPA.FR'), value: 'FR' },
    { label: translate('TPA.TF'), value: 'TF' },
    { label: translate('TPA.PF'), value: 'PF' },
    { label: translate('TPA.GA'), value: 'GA' },
    { label: translate('TPA.GM'), value: 'GM' },
    { label: translate('TPA.GH'), value: 'GH' },
    { label: translate('TPA.GI'), value: 'GI' },
    { label: translate('TPA.GD'), value: 'GD' },
    { label: translate('TPA.GL'), value: 'GL' },
    { label: translate('TPA.GE'), value: 'GE' },
    { label: translate('TPA.QP'), value: 'QP' },
    { label: translate('TPA.GU'), value: 'GU' },
    { label: translate('TPA.GT'), value: 'GT' },
    { label: translate('TPA.GN'), value: 'GN' },
    { label: translate('TPA.GW'), value: 'GW' },
    { label: translate('TPA.GY'), value: 'GY' },
    { label: translate('TPA.HT'), value: 'HT' },
    { label: translate('TPA.HM'), value: 'HM' },
    { label: translate('TPA.HN'), value: 'HN' },
    { label: translate('TPA.HK'), value: 'HK' },
    { label: translate('TPA.CL'), value: 'CL' },
    { label: translate('TPA.HR'), value: 'HR' },
    { label: translate('TPA.IN'), value: 'IN' },
    { label: translate('TPA.ID'), value: 'ID' },
    { label: translate('TPA.IQ'), value: 'IQ' },
    { label: translate('TPA.IR'), value: 'IR' },
    { label: translate('TPA.IE'), value: 'IE' },
    { label: translate('TPA.IS'), value: 'IS' },
    { label: translate('TPA.IT'), value: 'IT' },
    { label: translate('TPA.IL'), value: 'IL' },
    { label: translate('TPA.JM'), value: 'JM' },
    { label: translate('TPA.JP'), value: 'JP' },
    { label: translate('TPA.YE'), value: 'YE' },
    { label: translate('TPA.ZA'), value: 'ZA' },
    { label: translate('TPA.GS'), value: 'GS' },
    { label: translate('TPA.SS'), value: 'SS' },
    { label: translate('TPA.JO'), value: 'JO' },
    { label: translate('TPA.KY'), value: 'KY' },
    { label: translate('TPA.KH'), value: 'KH' },
    { label: translate('TPA.CM'), value: 'CM' },
    { label: translate('TPA.CA'), value: 'CA' },
    { label: translate('TPA.CV'), value: 'CV' },
    { label: translate('TPA.QA'), value: 'QA' },
    { label: translate('TPA.KZ'), value: 'KZ' },
    { label: translate('TPA.KE'), value: 'KE' },
    { label: translate('TPA.KI'), value: 'KI' },
    { label: translate('TPA.CC'), value: 'CC' },
    { label: translate('TPA.CO'), value: 'CO' },
    { label: translate('TPA.KM'), value: 'KM' },
    { label: translate('TPA.CG'), value: 'CG' },
    { label: translate('TPA.CD'), value: 'CD' },
    { label: translate('TPA.KR'), value: 'KR' },
    { label: translate('TPA.KP'), value: 'KP' },
    { label: translate('TPA.XK'), value: 'XK' },
    { label: translate('TPA.CR'), value: 'CR' },
    { label: translate('TPA.CU'), value: 'CU' },
    { label: translate('TPA.KW'), value: 'KW' },
    { label: translate('TPA.CY'), value: 'CY' },
    { label: translate('TPA.KG'), value: 'KG' },
    { label: translate('TPA.LA'), value: 'LA' },
    { label: translate('TPA.LS'), value: 'LS' },
    { label: translate('TPA.LB'), value: 'LB' },
    { label: translate('TPA.LR'), value: 'LR' },
    { label: translate('TPA.LY'), value: 'LY' },
    { label: translate('TPA.LI'), value: 'LI' },
    { label: translate('TPA.LT'), value: 'LT' },
    { label: translate('TPA.LV'), value: 'LV' },
    { label: translate('TPA.LU'), value: 'LU' },
    { label: translate('TPA.MO'), value: 'MO' },
    { label: translate('TPA.MG'), value: 'MG' },
    { label: translate('TPA.HU'), value: 'HU' },
    { label: translate('TPA.MK'), value: 'MK' },
    { label: translate('TPA.MY'), value: 'MY' },
    { label: translate('TPA.MW'), value: 'MW' },
    { label: translate('TPA.MV'), value: 'MV' },
    { label: translate('TPA.ML'), value: 'ML' },
    { label: translate('TPA.MT'), value: 'MT' },
    { label: translate('TPA.MA'), value: 'MA' },
    { label: translate('TPA.MH'), value: 'MH' },
    { label: translate('TPA.MQ'), value: 'MQ' },
    { label: translate('TPA.MU'), value: 'MU' },
    { label: translate('TPA.MR'), value: 'MR' },
    { label: translate('TPA.YT'), value: 'YT' },
    { label: translate('TPA.XL'), value: 'XL' },
    { label: translate('TPA.UM'), value: 'UM' },
    { label: translate('TPA.MX'), value: 'MX' },
    { label: translate('TPA.FM'), value: 'FM' },
    { label: translate('TPA.MD'), value: 'MD' },
    { label: translate('TPA.MC'), value: 'MC' },
    { label: translate('TPA.MN'), value: 'MN' },
    { label: translate('TPA.MS'), value: 'MS' },
    { label: translate('TPA.MZ'), value: 'MZ' },
    { label: translate('TPA.MM'), value: 'MM' },
    { label: translate('TPA.NA'), value: 'NA' },
    { label: translate('TPA.NR'), value: 'NR' },
    { label: translate('TPA.DE'), value: 'DE' },
    { label: translate('TPA.NP'), value: 'NP' },
    { label: translate('TPA.QU'), value: 'QU' },
    { label: translate('TPA.UN'), value: 'UN' },
    { label: translate('TPA.NE'), value: 'NE' },
    { label: translate('TPA.NG'), value: 'NG' },
    { label: translate('TPA.NI'), value: 'NI' },
    { label: translate('TPA.NU'), value: 'NU' },
    { label: translate('TPA.AN'), value: 'AN' },
    { label: translate('TPA.NL'), value: 'NL' },
    { label: translate('TPA.NF'), value: 'NF' },
    { label: translate('TPA.NO'), value: 'NO' },
    { label: translate('TPA.NC'), value: 'NC' },
    { label: translate('TPA.NZ'), value: 'NZ' },
    { label: translate('TPA.PS'), value: 'PS' },
    { label: translate('TPA.OM'), value: 'OM' },
    { label: translate('TPA.PK'), value: 'PK' },
    { label: translate('TPA.PW'), value: 'PW' },
    { label: translate('TPA.PA'), value: 'PA' },
    { label: translate('TPA.PG'), value: 'PG' },
    { label: translate('TPA.PY'), value: 'PY' },
    { label: translate('TPA.PE'), value: 'PE' },
    { label: translate('TPA.PN'), value: 'PN' },
    { label: translate('TPA.CI'), value: 'CI' },
    { label: translate('TPA.PL'), value: 'PL' },
    { label: translate('TPA.PR'), value: 'PR' },
    { label: translate('TPA.PT'), value: 'PT' },
    { label: translate('TPA.AT'), value: 'AT' },
    { label: translate('TPA.RE'), value: 'RE' },
    { label: translate('TPA.GQ'), value: 'GQ' },
    { label: translate('TPA.RO'), value: 'RO' },
    { label: translate('TPA.RU'), value: 'RU' },
    { label: translate('TPA.RW'), value: 'RW' },
    { label: translate('TPA.EL'), value: 'EL' },
    { label: translate('TPA.GR'), value: 'GR' },
    { label: translate('TPA.PM'), value: 'PM' },
    { label: translate('TPA.SV'), value: 'SV' },
    { label: translate('TPA.WS'), value: 'WS' },
    { label: translate('TPA.SM'), value: 'SM' },
    { label: translate('TPA.SA'), value: 'SA' },
    { label: translate('TPA.SN'), value: 'SN' },
    { label: translate('TPA.MP'), value: 'MP' },
    { label: translate('TPA.SC'), value: 'SC' },
    { label: translate('TPA.SL'), value: 'SL' },
    { label: translate('TPA.SG'), value: 'SG' },
    { label: translate('TPA.SK'), value: 'SK' },
    { label: translate('TPA.SI'), value: 'SI' },
    { label: translate('TPA.SO'), value: 'SO' },
    { label: translate('TPA.AE'), value: 'AE' },
    { label: translate('TPA.GB'), value: 'GB' },
    { label: translate('TPA.US'), value: 'US' },
    { label: translate('TPA.XS'), value: 'XS' },
    { label: translate('TPA.RS'), value: 'RS' },
    { label: translate('TPA.LK'), value: 'LK' },
    { label: translate('TPA.CF'), value: 'CF' },
    { label: translate('TPA.SD'), value: 'SD' },
    { label: translate('TPA.SR'), value: 'SR' },
    { label: translate('TPA.SH'), value: 'SH' },
    { label: translate('TPA.LC'), value: 'LC' },
    { label: translate('TPA.BL'), value: 'BL' },
    { label: translate('TPA.KN'), value: 'KN' },
    { label: translate('TPA.SX'), value: 'SX' },
    { label: translate('TPA.VA'), value: 'VA' },
    { label: translate('TPA.ST'), value: 'ST' },
    { label: translate('TPA.VC'), value: 'VC' },
    { label: translate('TPA.SZ'), value: 'SZ' },
    { label: translate('TPA.SY'), value: 'SY' },
    { label: translate('TPA.SB'), value: 'SB' },
    { label: translate('TPA.ES'), value: 'ES' },
    { label: translate('TPA.SE'), value: 'SE' },
    { label: translate('TPA.CH'), value: 'CH' },
    { label: translate('TPA.TJ'), value: 'TJ' },
    { label: translate('TPA.TZ'), value: 'TZ' },
    { label: translate('TPA.TH'), value: 'TH' },
    { label: translate('TPA.TW'), value: 'TW' },
    { label: translate('TPA.TG'), value: 'TG' },
    { label: translate('TPA.TK'), value: 'TK' },
    { label: translate('TPA.TO'), value: 'TO' },
    { label: translate('TPA.TT'), value: 'TT' },
    { label: translate('TPA.TN'), value: 'TN' },
    { label: translate('TPA.TR'), value: 'TR' },
    { label: translate('TPA.TM'), value: 'TM' },
    { label: translate('TPA.TC'), value: 'TC' },
    { label: translate('TPA.TV'), value: 'TV' },
    { label: translate('TPA.UG'), value: 'UG' },
    { label: translate('TPA.UA'), value: 'UA' },
    { label: translate('TPA.UY'), value: 'UY' },
    { label: translate('TPA.UZ'), value: 'UZ' },
    { label: translate('TPA.CX'), value: 'CX' },
    { label: translate('TPA.VU'), value: 'VU' },
    { label: translate('TPA.VE'), value: 'VE' },
    { label: translate('TPA.VN'), value: 'VN' },
    { label: translate('TPA.TL'), value: 'TL' },
    { label: translate('TPA.WF'), value: 'WF' },
    { label: translate('TPA.ZM'), value: 'ZM' },
    { label: translate('TPA.QV'), value: 'QV' },
    { label: translate('TPA.ZW'), value: 'ZW' },
  ];
};