import { Component, ViewChild } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { FieldArrayType } from '@ngx-formly/core';
import { AbpConsts } from '@ralba/shared';
import {
  ActionCompleteEventArgs,
  UploaderComponent,
  UploadingEventArgs
} from '@syncfusion/ej2-angular-inputs';
import { L10n } from '@syncfusion/ej2-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'tpa-fileupload',
  template: `
    <ng-container *ngIf="to.allowMultiple || (!to.allowMultiple && formControl.value.length === 0)">
      <ejs-uploader
        (success)="onUploadSuccess($event)"
        (selected)="selected($event)"
        (uploading)="uploading($event)"
        (actionComplete)="actionComplete($event)"
        [asyncSettings]="path"
        [autoUpload]="true"
        [multiple]="to.allowMultiple"
        locale="cs-Cz"
      ></ejs-uploader>
    </ng-container>    

    <br />
    <div class="p-grid" *ngFor="let file of field.model; let i = index">
      <div class="p-col">{{ file.fileName }}</div>
      <div class="p-col-fixed">
        <button (click)="remove(i)" class="e-btn e-lib e-danger e-small">
          <i class="fal fa-trash-alt"></i>
          <span>{{'TPA.Delete' | transloco | firstToUpperCase }}</span>
        </button>
      </div>
    </div>
  `,
  styles: [
    '::ng-deep .e-file-select-wrap{padding: 8px !important;}::ng-deep .u-upload{border-radius: 5px !important}',
  ],
})
export class FileuploadFieldType extends FieldArrayType {
  @ViewChild(UploaderComponent) uploader: UploaderComponent;

  /**
   *
   */
  constructor(public oidcSecurityService: OidcSecurityService) {
    super();
  }

  defaultOptions = {
    props: {
      options: [],
      allowMultiple: true
    },
  };

  public path = {
    saveUrl: `${AbpConsts.remoteServiceBaseUrl}/TpaFiles/Upload`,
    // removeUrl: 'http://localhost:5000/TpaFiles/Remove'
  };

  public onUploadSuccess(args: any): void {
    // const x = new Uploader();
    const response = ((args.e as ProgressEvent).currentTarget as XMLHttpRequest)
      .response;
    const { result } = JSON.parse(response);

    this.add(null, result[0], { markAsDirty: true });
    this.formControl.setErrors({ uploading: true });
  }

  uploading(args: UploadingEventArgs) {
    this.formControl.setErrors({ uploading: true });
    args.currentRequest.setRequestHeader(
      'Authorization',
      `Bearer ${this.oidcSecurityService.getToken()}`
    );
  }

  actionComplete(args: ActionCompleteEventArgs) {

    this.formControl.setErrors(null);
    this.uploader.remove(args.fileData);
  }

  ngOnInit() {
    // TODO: Jakub s Barnym je potřeba zjistit jak spravne pořešit
    L10n.load({
      'cs-Cz': {
        uploader: {
          // invalidMinFileSize:
          //   "La taille du fichier est trop petite! S'il vous plaît télécharger des fichiers avec une taille minimale de 10 Ko",
          // invalidMaxFileSize: 'La taille du fichier dépasse 28 Mo',
          // invalidFileType: "Le type de fichier n'est pas autorisé",
          Browse: translate('TPA.BrowseFiles'),
          // Clear: 'Clair',
          // Upload: 'Télécharger',
          // dropFilesHint: 'ou Déposer des fichiers ici',
          // uploadFailedMessage: "Impossible d'importer le fichier",
          // uploadSuccessMessage: 'Fichier téléchargé avec succès',
          // removedSuccessMessage: 'Fichier supprimé avec succès',
          // removedFailedMessage: "Le fichier n'a pas pu être supprimé",
          // inProgress: 'Téléchargement',
          // readyToUploadMessage: 'Prêt à télécharger',
          // remove: 'Retirer',
          // cancel: 'Annuler',
          // delete: 'Supprimer le fichier',
        },
      },
    });
  }



  guid() {
    return uuidv4();
  }

  formatNameWithUUID(uuid: string, fileName: string) {
    return `${uuid}_${fileName}`;
  }

  removeUUID(fileName: string) {
    return fileName.slice(fileName.indexOf('_'), fileName.length - 1);
  }
}
