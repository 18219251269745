import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AnimationModel } from '@syncfusion/ej2-angular-progressbar';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'ral-loading',
  templateUrl: 'loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnInit {
  @Input() loaded: boolean;
  id: string;

  public animation: AnimationModel = { enable: true, duration: 2000, delay: 0 };

  constructor() {
    this.id = uuidv4();
  }

  ngOnInit() {}
}
