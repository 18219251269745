import { Injectable } from '@angular/core';
import { translate, TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { CompanyAddressDto, EmployeeCardMetadataDto } from '@tpa/api';
import { EducationLevelPipe, GenderPipe, HealthDisadvantagePipe, MaritalStatusPipe, MonthPipe, SalaryPipe, YesOrNoPipe } from '@tpa/shared';
import { taxDeclarationV2 } from '@tpa/shared';
import Handlebars from 'handlebars/dist/handlebars.js';
import moment from 'moment';

interface Address {
  mesto: string;
  zeme: string;
  ulice: string;
  popCislo: string;
  orCislo: string;
  psc: string;
}

@Injectable({ providedIn: 'root' })
export class HandleBarsService {
  hbs = Handlebars;
  locale: string | null = null;


  public salaryPipe: SalaryPipe = new SalaryPipe();
  public yesornoPipe: YesOrNoPipe = new YesOrNoPipe();
  public healthPipe: HealthDisadvantagePipe = new HealthDisadvantagePipe();
  public monthPipe: MonthPipe = new MonthPipe();
  public genderPipe: GenderPipe = new GenderPipe();
  public maritalStatusPipe: MaritalStatusPipe = new MaritalStatusPipe();
  public educationPipe: EducationLevelPipe = new EducationLevelPipe();


  convert(template: string, data: unknown) {
    return this.hbs.compile(template)(data);
  }

  constructor(

    public translocoLocaleService: TranslocoLocaleService,
    public translocoService: TranslocoService) {

    this.hbs.registerHelper('notnull', (val: string) =>
      val == '' ? '' : 'X');
    this.hbs.registerHelper('address', (val: Address) => {
      return this.convertAddressToString(val);
    });
    this.hbs.registerHelper('date', (val: string) => this.convertDate(val));
    this.hbs.registerHelper('taxDeclarationYear', (val: string) => this.taxDeclarationYear(val));
    this.hbs.registerHelper('boolconvert', (val: boolean) => this.boolConvert(val));
    this.hbs.registerHelper('boolconvertx', (val: boolean) => this.boolConvertX(val));
    this.hbs.registerHelper('applychildren', (val: string) =>
      this.applyChildren(val));
    this.hbs.registerHelper('monthConvert', (val: number) =>
      this.monthConvert(val));
    this.hbs.registerHelper('gender', (val: string) =>
      this.genderConvert(val));
    this.hbs.registerHelper('marital', (val: string) =>
      this.maritalConvert(val));
    this.hbs.registerHelper('education', (val: string) =>
      this.educationConvert(val));
    this.hbs.registerHelper('country', (val: string) =>
      this.countryConvert(val));
    this.hbs.registerHelper('transloco', (val: string) =>
      this.translocoConvert(val));
    this.hbs.registerHelper('salary', (val: string) =>
      this.salaryConvert(val));
    this.hbs.registerHelper('health', (val: string) =>
      this.healthConvert(val));
    this.hbs.registerHelper('addressesEqual', (val1: Address, val2: Address) =>
      this.addressesEqual(val1, val2));
    this.hbs.registerHelper('salaryFormCash', (val: string) =>
      this.salaryFormCashConvert(val));
    this.hbs.registerHelper('yesorno', (val: string) =>
      this.yesornoConvert(val));
    this.hbs.registerHelper('stringToBool', (val: string) =>
      this.stringToBool(val));
    this.hbs.registerHelper('taxPayerAddress', (val: CompanyAddressDto) =>
      this.taxPayerAddress(val));
    this.hbs.registerHelper('taxPayerNameWithAddress', (val: EmployeeCardMetadataDto) =>
      this.taxPayerNameWithAddress(val));
    this.hbs.registerHelper('shouldShowStudentDiscount', (val: number | string | null) =>
      this.shouldShowStudentDiscount(val));
  }


  boolConvert(val: boolean) {
    return val == true ? "ANO" : "NE";
  }
  boolConvertX(val: boolean) {
    return val == true ? 'X' : '';
  }

  convertAddressToString(value: Address) {
    if (!value) return '';
    if (!value.ulice && !value.mesto) return '';



    let orCislo;
    if (value.orCislo) orCislo = `/${value.orCislo}`; else orCislo = '';

    if (
      `${value.ulice}${value.popCislo}${orCislo}${value.mesto}${value.psc}${value.mesto}`
        .length == 0
    )
      return '';



    return [
      `${value.ulice} ${value.popCislo}${orCislo}`,
      value.mesto,
      value.psc,
      translate("TPA." + value.zeme)
    ].join(', ');
  }

  convertDate(value: string) {
    if (!value) return '';
    return this.translocoLocaleService.localizeDate(
      new Date(value),
      this.locale || this.translocoLocaleService.getLocale()
    );
  }

  taxDeclarationYear(value: string) {
    if (!value) return '';
    return new Date(value).getFullYear();

    // Podpisova cast - v normalnim roce to má byt od záři 2022
    // u RZD dodatecne od ???
    // if (!value) return '';
    // return this.translocoLocaleService.localizeDate(
    // new Date(value),
    // this.locale || this.translocoLocaleService.getLocale()
    // );
  }

  applyChildren(value: string) {
    switch (value) {
      case "firstchild":
        return "1.";
      case "secondchild":
        return "2.";
      case "thirdandmorechild":
        return "3.";
      case "notapply":
        return "N";
    }
  }

  monthConvert(value: number) {
    return this.monthPipe.transform(value);
  }

  genderConvert(value: string) {
    return this.genderPipe.transform(value);
  }

  maritalConvert(value: string) {
    return this.maritalStatusPipe.transform(value);
  }

  educationConvert(value: string) {
    return this.educationPipe.transform(value);
  }

  countryConvert(value: string) {
    return this.translocoService.translate("TPA." + value);
  }

  translocoConvert(value: string) {
    return this.translocoService.translate(value);
  }

  salaryConvert(value: string) {
    return this.salaryPipe.transform(value);
  }

  addressesEqual(a1: Address, a2: Address) {
    if (a1.ulice == a2.ulice &&
      a1.mesto == a2.mesto &&
      a1.orCislo == a2.orCislo &&
      a1.popCislo == a2.popCislo &&
      a1.psc == a2.psc &&
      a1.zeme == a2.zeme) {
      return true
    }
    return false
  }

  salaryFormCashConvert(value: string) {
    return value == "cash";
  }

  healthConvert(value: string) {
    return this.healthPipe.transform(value);
  }

  yesornoConvert(value: string) {
    return this.yesornoPipe.transform(value);
  }

  stringToBool(value: string) {
    if (value == "true") {
      return true;
    } else {
      return false;
    }
  }

  // Roháčova 1203/69, Praha 3 130 00, Česká republika
  taxPayerAddress(companyAddress: CompanyAddressDto) {
    const parts: string[] = [];
    if (companyAddress.street && companyAddress.descriptiveNumber) {
      parts.push(`${companyAddress.street} ${companyAddress.descriptiveNumber}`)
    }

    if (companyAddress.orientationNumber)
      parts[0] = `${parts[0]}/${companyAddress.orientationNumber}`;

    if (companyAddress.placeOfResidence || companyAddress.postalCode) {
      const parr2 = [];
      if (companyAddress.placeOfResidence) {
        parr2.push(companyAddress.placeOfResidence)
      }
      if (companyAddress.postalCode) {
        parr2.push(companyAddress.postalCode)
      }

      if (parr2.length > 0)
        parts.push(parr2.join(" "))
    }
    if (companyAddress.country) {
      parts.push(companyAddress.country)
    }

    return parts.join(", ");
  }

  // Ralba s.r.o., Roháčova 1203/69, Praha 3 130 00, Česká republika
  taxPayerNameWithAddress(metadata: EmployeeCardMetadataDto) {
    const parts = []
    if (metadata.tenantName) {
      parts.push(metadata.tenantName)
    }
    if (metadata?.companyAddress && this.taxPayerAddress(metadata?.companyAddress)) {
      parts.push(this.taxPayerAddress(metadata.companyAddress))
    }
    return parts.join(", ")
  }

  shouldShowStudentDiscount(value: number | null | string) {
    if (typeof value === 'number') {
      //2024 should be the edge
      return taxDeclarationV2.year() > value;
    }
    if (typeof value === 'string') {
      return taxDeclarationV2.isAfter(moment(value));
    }
    return false;
  }
}
