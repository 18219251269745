import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'ral-admin-top-bar',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminTopBarComponent implements OnInit {
  @Input() displayName: string | undefined;
  @Input() title: string | undefined;
  @Input() accountImage: string | undefined = null;
  @Input() profileUrl: string[] | undefined = ['/', 'system', 'profile'];
  @Output() logout: EventEmitter<void> = new EventEmitter();
  @Output() toggle: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
