import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';

@Component({
  selector: 'formly-field-select-with-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ejs-dropdownlist
      [class.ng-dirty]="showError"
      [placeholder]="to.placeholder"
      [formControl]="$any(formControl)"
      [fields]="fields"
      [dataSource]="to.options | formlySelectOptions: field | async"
      (change)="to.change && to.change(field, $event)"
      [showClearButton]="true"
      [allowFiltering]="to.allowFiltering || false"
      (filtering)="onFiltering($event)"
    ></ejs-dropdownlist>
  `,
})
export class SelectWithFilterFormlyField extends FieldType {
  public fields = { text: 'label', value: 'value' };

  @ViewChild(DropDownListComponent) cmp: DropDownListComponent;

  defaultOptions = {
    props: {
      options: [],
      allowFiltering: false,
      onFilter: (e: FilteringEventArgs, dataSource: any[]) => {
        let query = new Query();
        //frame the query based on search string with filter type.
        query =
          e.text != '' ? query.where('label', 'contains', e.text, true) : query;
        //pass the filter data source, filter query to updateData method.
        e.updateData(dataSource, query);
      },
    },
  };

  public onFiltering(e: FilteringEventArgs) {
    this.to.onFilter(e, this.cmp.dataSource);
  }
}
