import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule, PushModule } from '@ngrx/component';
import { TpaUiModule } from '@tpa/ui';
import { TenancyCardComponent } from './tenancy-smard-card.component';
@NgModule({
  imports: [CommonModule, LetModule, PushModule, TpaUiModule, TranslocoModule],
  declarations: [TenancyCardComponent],
  exports: [TenancyCardComponent],
  providers: [],
})
export class TenancySmartCardModule {}
