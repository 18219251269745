import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CoreSelectors } from '@ralba/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';



@Injectable()
export class InitGuard implements CanActivate, CanActivateChild {
  constructor(private store$: Store, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const feature = route.data.feature;
    return this.store$.pipe(
      select(CoreSelectors.selectIsLoaded),
      filter((isloaded) => isloaded),
      map((isloaded) => true));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}