import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { RalbaUiModule } from '@ralba/ui';
import { HasPermissionDirective } from './directives/permission.directive';

import { Pipe, PipeTransform } from '@angular/core';
import { TextUtis } from './utils';
@Pipe({
  name: 'firstToUpperCase'
})
export class FirstUpperPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (typeof value !== 'string') return ''
    return TextUtis.firstToUpperCase(value);
  }
}


@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [HasPermissionDirective, FirstUpperPipe],
  exports: [HasPermissionDirective, FirstUpperPipe],
})
export class SharedModule { }
