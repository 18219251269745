import { Component, HostBinding } from '@angular/core';
import {
  FieldType,
  FormlyExtension,
  FormlyFieldConfig
} from '@ngx-formly/core';
import { employeeFormPersonInfo } from '../../form-parts-definition';

@Component({
  selector: 'tpa-summary-personal',
  templateUrl: './summary-personal.component.html',
})
export class SummaryPersonalComponent
  extends FieldType
  implements FormlyExtension
{
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: false,
    },
  };

  @HostBinding('class.p-grid') c = true;
  @HostBinding('class.tpa-wrapper__control') a = true;

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = [
      {
        props: {
          //label: 'User info',
        },
        validators: {
          validation: [
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'rezidentura' },
            },
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'zakladniSlevaNaInvalidituMesice' },
            },
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'rozsirenaSlevaNaInvalidituMesice' },
            },
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'mamdeti' },
            },
          ],
        },
        fieldGroup: [
          ...employeeFormPersonInfo(true, true),
          //this._additionalData,
        ],
      },
    ];
  }
}
