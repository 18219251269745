import {Injectable} from '@angular/core';
import {translate} from '@ngneat/transloco';
import {ComponentStore} from '@ngrx/component-store';
import {Store} from '@ngrx/store';
import {AccountActions, ToastActions} from '@ralba/core';
import {AsyncState} from '@ralba/shared';
import {ProfileHttpApiService, TpaCurrentUserProfileEditDto, TpaUpdateGoogleAuthenticatorKeyOutput,} from '@tpa/api';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {Options} from "ngx-qrcode-styling";

export interface Manage2faState extends AsyncState.IAsyncState {
  profile: TpaCurrentUserProfileEditDto & {
    isGoogleAuthenticatorConfiremed: boolean
  };

  openActivateModal: boolean;
  openDeactivedModal: boolean;
}

export interface FormDeactivateType {
  provider?: string;
  twoFactorVerificationCode?: string;
}

export interface FormActivateType {
  phoneNumber?: string;
  twoFactorVerificationCode?: string;
}


@Injectable()
export class Manage2faStore extends ComponentStore<Manage2faState> {
  readonly isLoaded$ = this.select(({ loaded }) => loaded);
  readonly openActivateModal$ = this.select(
    ({ openActivateModal }) => openActivateModal
  );
  readonly openDeactivedModal$ = this.select(
    ({ openDeactivedModal }) => openDeactivedModal
  );
  readonly profile$ = this.select(
    ({ profile }) => profile
  );

  public readonly qrCode$ = this.select<Options>(({ profile}) => ({
    width: 200,
    height: 200,
    data: profile.qrCodeSetupImageUrl
  }));

  readonly updateProfile = this.updater(
    (state, input: { profile: TpaCurrentUserProfileEditDto }) => ({
      ...state,
      profile: {
        ...input.profile,
        isGoogleAuthenticatorConfiremed:
          input.profile.isGoogleAuthenticatorEnabled,
      },
      ...AsyncState.loadedState,
    })
  );
  readonly updateGoogleAuthenticator = this.updater(
    (state, input: { profile: TpaUpdateGoogleAuthenticatorKeyOutput }) => ({
      ...state,
      profile: {
        ...state.profile,
        ...input.profile,
        isGoogleAuthenticatorEnabled: true,
        isGoogleAuthenticatorConfiremed: false,
      },
      openActivateModal: true,
      ...AsyncState.loadedState,
    })
  );
  readonly confirmGoogleAuthenticator = this.updater((state) => ({
    ...state,
    profile: {
      ...state.profile,
      isGoogleAuthenticatorEnabled: false,
      isGoogleAuthenticatorConfiremed: true,
    },
    openActivateModal: false,
    ...AsyncState.loadedState,
  }));
  readonly openActivateModal = this.updater((state) => ({
    ...state,
    openActivateModal: true,
  }));

  // readonly beforeRequest = this.updater((state) => ({
  //   ...state,
  //   //...AsyncState.initialState,
  // }));
  readonly closeActivateModal = this.updater((state) => ({
    ...state,
    openActivateModal: false,
  }));
  readonly openDeactivedModal = this.updater((state) => ({
    ...state,
    openDeactivedModal: true,
  }));
  readonly closeDeactivedModal = this.updater((state) => ({
    ...state,
    openDeactivedModal: false,
  }));
  readonly getCurrentUserProfileEditDto = this.effect(
    (input: Observable<{}>) => {
      return input.pipe(
        // tap((model) => {
        //   this.beforeRequest();
        // }),
        exhaustMap((model) =>
          this.profileService.getCurrentUserProfile({}).pipe(
            map(({ result }) => [this.updateProfile({ profile: result })]),
            catchError(({ error }) => {
              this.store$.dispatch(
                ToastActions.showToastApiError({
                  ...error?.error,
                  details: translate('TPA.UnableToGetSettings2FA'),
                })
              ); // Nepodařilo se zjistit nastavení dvoufázového ověření
              return of(null);
            })
          )
        )
      );
    }
  );
  readonly requestForGoogleAuthenticatorKey = this.effect(
    (input: Observable<{}>) => {
      return input.pipe(
        // tap((model) => {
        //   this.beforeRequest();
        // }),
        exhaustMap((model) =>
          this.profileService.updateGoogleAuthenticatorKey().pipe(
            map(({ result }) => [
              this.updateGoogleAuthenticator({ profile: result }),
            ]),
            catchError(({ error }) => {
              this.store$.dispatch(
                ToastActions.showToastApiError({
                  ...error?.error,
                  details: translate('TPA.UnableToGetCodeByApplication'),
                })
              ); // Nepodařilo se získat kód pro ověření pomocí autentikační aplikace
              return of(null);
            })
          )
        )
      );
    }
  );
  readonly sendSmsCode = this.effect(
    (input: Observable<{ phoneNumber: string }>) => {
      /// input ma {code:string}
      return input.pipe(
        exhaustMap(({ phoneNumber }) =>
          this.profileService.sendVerificationSmsCode({ phoneNumber }).pipe(
            tap(() =>
              this.store$.dispatch(
                ToastActions.showToastInfo(
                  translate('TPA.SmsSentTitle'),
                  translate('TPA.SmsSentParagraph')
                )
              )
            ),
            catchError(({ error }) => {
              this.store$.dispatch(
                ToastActions.showToastApiError({
                  ...error?.error,
                  details: translate('TPA.UnableToSendSMSCode'),
                })
              ); //  Nepodařilo se odeslat SMS kód na Vaše telefonní číslo
              return of(null);
            })
          )
        )
      );
    }
  );
  readonly confirmGoogleAuthenticatorKey = this.effect((input: Observable<FormActivateType>) => {
    /// input ma {code:string}
    return input.pipe(
      exhaustMap((input) =>
        this.profileService.enableTwoFactorAuth({
          phoneNumber: input.phoneNumber,
          twoFactorVerificationCode: input.twoFactorVerificationCode
        }).pipe(
          map(({ result }) => this.confirmGoogleAuthenticator()),
          tap((p) => this.store$.dispatch(AccountActions.logOut())),
          catchError(({ error }) => {
            this.store$.dispatch(
              ToastActions.showToastApiError({
                ...error?.error,
                details: translate('TPA.UnableToFinish2FASettings'),
              })
            ); // Nepodařilo se dokončit nastavení dvoufázového ověření
            return of(null);
          })
        )
      )
    );
  }
  );
  readonly disableGoogleAuthenticator = this.effect((input: Observable<FormDeactivateType>) => {
    /// input ma {code:string}
    return input.pipe(
      exhaustMap((input) =>
        this.profileService.disableTwoFactorAuth(input).pipe(
          map(({ result }) =>
            this.setState((state) => ({
              ...state,
              profile: {
                ...state.profile,
                isGoogleAuthenticatorEnabled: false,
                isGoogleAuthenticatorConfiremed: false,
              },
              openDeactivedModal: false,
            }))
          ),
          catchError(({ error }) => {
            this.store$.dispatch(
              ToastActions.showToastApiError({
                ...error?.error,
                details: translate('TPA.UnableToDeactivate2FA'),
              })
            ); // Nepodařilo se deaktivovat dvoufázové ověření
            return of(null);
          })
        )
      )
    );
  }
  );

  constructor(
    public store$: Store<never>,
    private profileService: ProfileHttpApiService
  ) {
    super({
      profile: {
        isGoogleAuthenticatorEnabled: false,
        manualEntryKey: '',
        phoneNumber: '',
        qrCodeSetupImageUrl: '',
        isGoogleAuthenticatorConfiremed: false,
      },
      openActivateModal: false,
      openDeactivedModal: false,
      ...AsyncState.initialState,
    });
  }
}
