// export * from './abp.consts';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import moment from 'moment';

export interface IAppConfig {
  [key: string]: any;
  remoteServiceBaseUrl: string;
  appBaseUrl: string;
  authConfig: OpenIdConfiguration;
}

export class AppConfig {
  static config: IAppConfig = {
    authConfig: {},
    remoteServiceBaseUrl: '/api',
    appBaseUrl: '',
  };

  static get<T, K = IAppConfig & T>(): K {
    return <K>(<unknown>AppConfig.config);
  }
}


export const taxDeclarationV2 = moment("2024-01-01");
