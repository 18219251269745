import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'yesOrNo',
})
export class YesOrNoPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnText;
    switch (value) {
      case 'yes': {
        returnText = translate('TPA.Yes');
        break;
      }
      case 'no': {
        returnText = translate('TPA.No');
        break;
      }
      case true: {
        returnText = translate('TPA.Yes');
        break;
      }
      case false: {
        returnText = translate('TPA.No');
        break;
      }
      case 'true': {
        returnText = translate('TPA.Yes');
        break;
      }
      case 'false': {
        returnText = translate('TPA.No');
        break;
      }
      default: {
        returnText = value;
        break;
      }
    }
    return returnText;
  }
}
