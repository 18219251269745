import { isNotNullOrUndefined } from '@tpa/core';
import moment from 'moment';
import {
  Duchody,
  EmpTabsFormModelV2,
  MY_TEST_CARD,
  ServerAppModel,
  ServerHeoModel,
  VariantaNull,
  ZdravotniPojistovnaAStav,
} from './employee.types';

/**
 * from  FE to server
 */
export const mapToServerHeoModel = (
  data: EmpTabsFormModelV2
): ServerHeoModel => {
  let datumNarozeni = data.personalPart?.personalInfo?.datumNarozeni;
  if (datumNarozeni) datumNarozeni = moment(datumNarozeni).format('yyyy-MM-DD');

  let datumExpirace = data.personalPart?.idcard?.datumExpirace;
  if (datumExpirace) datumExpirace = moment(datumExpirace).format('yyyy-MM-DD');

  let datumNastupu = data.taxDeclaration?.datumNastupu;
  if (datumNastupu) datumNastupu = moment(datumNastupu).format('yyyy-MM-DD');

  return JSON.parse(
    JSON.stringify({
      jmeno: data.personalPart?.personalInfo?.jmeno,
      prijmeni: data.personalPart?.personalInfo?.prijmeni,
      email: data.personalPart?.personalInfo?.email,
      permanentniAdresa: data.personalPart?.permanentniAdresa || {},
      kontaktniAdresa: data.personalPart?.kontaktniAdresa || {},
      prechodnaAdresa: data.personalPart?.prechodnaAdresa || {},
      titulpred: data.personalPart?.personalInfo?.titulpred,
      titulza: data.personalPart?.personalInfo?.titulza,
      rodnePrijmeni: data.personalPart?.personalInfo?.rodnePrijmeni,
      rodneCislo: data.personalPart?.personalInfo?.rodneCislo,
      datumNarozeni: datumNarozeni,
      mistoNarozeni: data.personalPart?.personalInfo?.mistoNarozeni,
      rodinnyStav: data.personalPart?.personalInfo?.rodinnyStav,
      pohlavi: data.personalPart?.personalInfo?.pohlavi,
      vzdelani: data.personalPart?.personalInfo?.vzdelani,
      pocetVychDeti: data.personalPart?.personalInfo?.pocetVychDeti,
      statniPrislusnost: data.personalPart?.personalInfo?.statniPrislusnost,
      cisloUctu: data.personalPart?.wage?.cisloUctu,
      idUstavu: data.personalPart?.wage?.idUstavu,
      iban: data.personalPart?.wage?.iban,
      cisloDokladu: data.personalPart?.idcard?.cisloDokladu,
      datumExpirace: datumExpirace,
      chciProhlaseni:
        data.taxDeclaration?.taxDeclaration?.affidavit?.chciProhlaseni,
      datumNastupu: datumNastupu,
    })
  );
};

/**
 * from  FE to server
 */
export const mapToServerAppModel = (
  data: EmpTabsFormModelV2
): ServerAppModel => {
  try {
    let obj: ServerAppModel = {
      rezidentura:
        data.taxDeclaration?.rezidentura?.rezidentura?.toString() || undefined,
      rezidenturaVeState:
        (data.taxDeclaration?.rezidentura?.rezidentura?.toString() !== 'true' &&
          data.taxDeclaration?.rezidentura?.rezidenturaVeState) ||
        undefined,
      rezidenturaText:
        data.taxDeclaration?.rezidentura?.rezidenturaText || undefined,
      rezidenturaVyhodnoceni:
        data.taxDeclaration?.rezidentura?.rezidenturaVyhodnoceni || undefined,

      zdrzovaniVCR: data.taxDeclaration?.rezidentura?.zdrzovaniVCR,
      studiumVCR: data.taxDeclaration?.rezidentura?.studiumVCR,
      identifikace:
        (data.taxDeclaration?.rezidentura?.rezidentura?.toString() !== 'true' &&
          data.taxDeclaration?.rezidentura?.identifikace) ||
        undefined,

      doklad: data.personalPart?.idcard?.doklad || undefined,
      typDokladu: data.personalPart?.idcard?.typDokladu || undefined,
      vydalStat: data.personalPart?.idcard?.vydalStat || undefined,

      dalsiPracovniPomer:
        data.personalPart?.dalsiPracovniPomer?.dalsiPracovniPomer,
      nazevDalsihoZamestnavatele:
        data.personalPart?.dalsiPracovniPomer?.nazevDalsihoZamestnavatele,
      sidloDalsihoZamestnavatele:
        data.personalPart?.dalsiPracovniPomer?.sidloDalsihoZamestnavatele,

      exekuce: data.personalPart?.execution?.exekuce,
      // TODO proverit
      // jineProhlaseni: data.jineProhlaseni,

      salaryForm: data.personalPart?.wage?.salaryForm || undefined,

      // Schvaleno v notion, nyni posilat jen v karta casti
      // pohlavi: data.personalPart?.personalInfo?.pohlavi || undefined,
    };
    if (data.taxDeclaration?.taxDeclaration?.affidavit) {
      obj = {
        ...obj,
        prohlaseniKProhlaseni: data.taxDeclaration.taxDeclaration.affidavit
          ?.prohlaseniKProhlaseni
          ? data.taxDeclaration.taxDeclaration.affidavit.prohlaseniKProhlaseni
          : data.taxDeclaration.taxDeclaration.affidavit.chciProhlaseni ||
            false,
        soucasneNeuplatnuji: data.taxDeclaration.taxDeclaration
          .soucasneNeuplatnuji
          ? true
          : false,
      };
    }

    if (
      data.personalPart?.personalInfo?.druhaStatniPrislusnostCheck &&
      data.personalPart?.personalInfo?.druhaStatniPrislusnostCheck
    ) {
      obj = {
        ...obj,
        druhaStatniPrislusnost:
          data.personalPart?.personalInfo?.druhaStatniPrislusnost,
      };
    }

    if (data.personalPart?.health) {
      let zdravotniPojistovnaAStav: ZdravotniPojistovnaAStav = {
        zdravPojistovna: data.personalPart?.health?.zdravPojistovna,
        // TODO: remove if not zdravPojistovna "jina"
        jinaZdravPojistovna: data.personalPart?.health?.jinaZdravPojistovna,
        cisloPojistence: data.personalPart?.health?.cisloPojistence,
        zpsZtpp: data.personalPart?.health?.zpsZtpp,
        ztpp: data.personalPart?.health?.ztpp,
        a1: data.personalPart?.health?.a1,
        zdravotniZnevyhodneni: data.personalPart?.health?.zdravotniZnevyhodneni,
      };

      if (
        isNotNullOrUndefined(data.personalPart?.health?.duchody?.pobiramDuchod)
      ) {
        const duchody = data.personalPart.health.duchody;
        let duchodyResult: Duchody = {
          predcasny: false,
          starobni: false,
          invalidni: false,
          jiny: undefined,
        };
        if (data.personalPart.health.duchody.pobiramDuchod) {
          duchodyResult = {
            predcasny: duchody.predcasny,
            starobni: duchody.starobni,
            invalidni: duchody.invalidni,
            jiny: duchody.jinyDuchod ? duchody.jiny : undefined,
          };
        }
        zdravotniPojistovnaAStav = {
          ...zdravotniPojistovnaAStav,
          duchody: duchodyResult,
        };
      }

      obj = {
        ...obj,
        zdravotniPojistovnaAStav: zdravotniPojistovnaAStav,
      };
    }

    if (data.taxDeclaration?.taxDeclaration?.affidavit?.chciProhlaseni) {
      //Mesicni slevy
      const monthDiscount = data.taxDeclaration.taxDeclaration.monthDiscount;
      obj = {
        ...obj,
        zakladniSlevaNaPoplatnika:
          monthDiscount?.zakladniSlevaNaPoplatnika?.value,
        rozsirenaSlevaNaInvalidituMesice:
          monthDiscount?.rozsirenaSlevaNaInvalidituMesice?.value || 0,
        zakladniSlevaNaInvalidituMesice:
          monthDiscount?.zakladniSlevaNaInvalidituMesice?.value || 0,
        slevaNaDrziteleZTPPMesice:
          monthDiscount?.slevaNaDrziteleZTPPMesice?.value || 0,
        slevaNaStudentaMesice: monthDiscount?.slevaNaStudentaMesice?.value || 0,
      };

      const deti = data.taxDeclaration.taxDeclaration.children?.deti;
      const dalsiPoplatnik =
        data.taxDeclaration?.taxDeclaration?.partner?.dalsiPoplatnik;
      if (deti && deti.length > 0) {
        obj = {
          ...obj,
          deti: deti,
          dalsiPoplatnik: dalsiPoplatnik == null ? undefined : dalsiPoplatnik,
        };
        const partner = data.taxDeclaration?.taxDeclaration?.partner;

        if (partner && partner.dalsiPoplatnik == true) {
          obj = {
            ...obj,
            jmenoPoplatnika: partner.jmenoPoplatnika || undefined,
            prijmeniPoplatnika: partner.prijmeniPoplatnika || undefined,
            rodneCisloPoplatnika: partner.rodneCisloPoplatnika || undefined,
            trvalyPobytPoplatnika: partner.trvalyPobytPoplatnika || undefined,
            uplatnujePoplatnik:
              partner.uplatnujePoplatnik === null
                ? undefined
                : partner.uplatnujePoplatnik,
            zamestnaniPoplatnika:
              partner.zamestnaniPoplatnika === null
                ? undefined
                : partner.zamestnaniPoplatnika,
            platceDanePoplatnika: partner.platceDanePoplatnika || undefined,
            adresaPlatceDanePoplatnika:
              partner.adresaPlatceDanePoplatnika || undefined,
          };
        }
      }
    }
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error(e);
  }
  return null;
};
/**
 * Server to FE
 */
export const mapToApp = (
  karta?: ServerHeoModel | undefined,
  app?: ServerAppModel | undefined,
  dodatecneAtributy?: any,
  resetCausedByNewYear?: boolean
): EmpTabsFormModelV2 => {
  try {
    const helios = karta ? karta : mapToServerHeoModel(VariantaNull);
    const portal = app ? app : mapToServerAppModel(VariantaNull);

    // check if rezidentura null, clear all data about rezidenci
    let rezidentura: string | boolean = (portal.rezidentura as
      | string
      | boolean
      | undefined)
      ? (portal.rezidentura as string | boolean)
      : false;
    //console.log(typeof rezidentura,typeof rezidentura === 'boolean')
    if (typeof rezidentura === 'boolean' && rezidentura == true) {
      rezidentura = 'true';
    }
    if (typeof rezidentura === 'boolean' && rezidentura == false) {
      rezidentura = 'false';
    }

    let rezidenturaData = {
      rezidentura: portal.rezidentura || null,
      rezidenturaVeState:
        (portal.rezidentura !== 'true' && portal.rezidenturaVeState) || null,
      rezidenturaText: portal.rezidenturaText || null,
      rezidenturaVyhodnoceni: portal.rezidenturaVyhodnoceni || null,
      identifikace: portal.identifikace || null,
      zdrzovaniVCR:
        portal.zdrzovaniVCR !== undefined ? portal.zdrzovaniVCR : null,
      studiumVCR: portal.studiumVCR !== undefined ? portal.studiumVCR : null,
    };
    if (
      rezidentura == null ||
      rezidentura == undefined ||
      portal.zdrzovaniVCR == null ||
      portal.zdrzovaniVCR == undefined
    ) {
      rezidenturaData = {
        rezidentura: null,
        rezidenturaVeState: null,
        rezidenturaText: null,
        rezidenturaVyhodnoceni: null,
        identifikace: portal.identifikace || null,
        zdrzovaniVCR: null,
        studiumVCR: null,
      };
    }

    // debugger;

    // const a = !isNotNullOrUndefined(portal.zakladniSlevaNaInvalidituMesice);
    // const b = isNotNullOrUndefined(portal.zakladniSlevaNaInvalidituMesice);
    // const c = a ? 'y' : 'n';
    // const d = b ? 'y' : 'n';

    // const zakladniSlevaNaInvalidituMesice = {
    //   value: !isNotNullOrUndefined(portal.zakladniSlevaNaInvalidituMesice)
    //     ? portal.zakladniSlevaNaInvalidituMesice
    //     : null,
    // };

    // console.log('original', helios.datumExpirace);
    // console.log('moment', moment(helios.datumExpirace, 'yyyy-MM-DD').toDate());

    const datumNarozeni = helios.datumNarozeni
      ? moment(helios.datumNarozeni, 'yyyy-MM-DD').toDate().toISOString()
      : undefined;
    const datumExpirace = helios.datumExpirace
      ? moment(helios.datumExpirace, 'yyyy-MM-DD').toDate().toISOString()
      : undefined;
    const datumNastupu = helios.datumNastupu
      ? moment(helios.datumNastupu, 'yyyy-MM-DD').toDate().toISOString()
      : undefined;

    const card: EmpTabsFormModelV2 = {
      personalPart: {
        personalInfo: {
          jmeno: helios.jmeno || undefined,
          prijmeni: helios.prijmeni || undefined,
          email: helios.email || undefined,

          titulpred: helios.titulpred || undefined,
          titulza: helios.titulza || undefined,
          rodnePrijmeni: helios.rodnePrijmeni || undefined,
          rodneCislo: helios.rodneCislo || undefined,
          datumNarozeni: datumNarozeni || undefined,
          mistoNarozeni: helios.mistoNarozeni || undefined,
          rodinnyStav: helios.rodinnyStav || undefined,

          vzdelani: helios.vzdelani || undefined,
          pocetVychDeti: helios.pocetVychDeti,
          statniPrislusnost: helios.statniPrislusnost || undefined,
          druhaStatniPrislusnost: portal.druhaStatniPrislusnost || null,
          druhaStatniPrislusnostCheck: portal.druhaStatniPrislusnost
            ? true
            : false,

          pohlavi: helios.pohlavi || null,
        },

        // allowContactAddress?: ,
        // allowTemporaryAddress?: ,

        permanentniAdresa: helios.permanentniAdresa || undefined,
        kontaktniAdresa: helios.kontaktniAdresa || undefined,
        prechodnaAdresa: helios.prechodnaAdresa || undefined,

        wage: {
          salaryForm: portal.salaryForm || null,
          cisloUctu: helios.cisloUctu || null,
          idUstavu: helios.idUstavu || null,
          iban: helios.iban || null,
        },
        health: {
          ...duchodyJineEvaluation(portal.zdravotniPojistovnaAStav),
        },
        idcard: {
          typDokladu: portal.typDokladu || null,
          vydalStat: portal.vydalStat || null,
          doklad: portal.doklad || null,
          cisloDokladu: helios.cisloDokladu || null,
          datumExpirace: datumExpirace || null,
        },
        execution: {
          exekuce: portal.exekuce || false,
        },
        dalsiPracovniPomer: {
          dalsiPracovniPomer: portal?.dalsiPracovniPomer || false,
          nazevDalsihoZamestnavatele: portal.nazevDalsihoZamestnavatele || '',
          sidloDalsihoZamestnavatele: portal.sidloDalsihoZamestnavatele || '',
        },
      },
      taxDeclaration: {
        rezidentura: {
          ...rezidenturaData,
          // rezidentura: portal.rezidentura || null,
          // rezidenturaVeState: portal.rezidenturaVeState || null,
          // rezidenturaText: portal.rezidenturaText || null,
          // rezidenturaVyhodnoceni: portal.rezidenturaVyhodnoceni || null,
          // identifikace: portal.identifikace || null,
          // zdrzovaniVCR:
          //   portal.zdrzovaniVCR !== undefined ? portal.zdrzovaniVCR : null,
          // studiumVCR: portal.studiumVCR !== undefined ? portal.studiumVCR : null,
        },
        taxDeclaration: {
          monthDiscount: {
            zakladniSlevaNaPoplatnika: {
              value: isNotNullOrUndefined(portal.zakladniSlevaNaPoplatnika)
                ? portal.zakladniSlevaNaPoplatnika
                : null,
            },
            rozsirenaSlevaNaInvalidituMesice: {
              value: isNotNullOrUndefined(
                portal.rozsirenaSlevaNaInvalidituMesice
              )
                ? portal.rozsirenaSlevaNaInvalidituMesice
                : null,
            },
            zakladniSlevaNaInvalidituMesice: {
              value: isNotNullOrUndefined(
                portal.zakladniSlevaNaInvalidituMesice
              )
                ? portal.zakladniSlevaNaInvalidituMesice
                : null,
            },
            slevaNaDrziteleZTPPMesice: {
              value: isNotNullOrUndefined(portal.slevaNaDrziteleZTPPMesice)
                ? portal.slevaNaDrziteleZTPPMesice
                : null,
            },
            slevaNaStudentaMesice: {
              value: isNotNullOrUndefined(portal.slevaNaStudentaMesice)
                ? portal.slevaNaStudentaMesice
                : null,
            },
          },
          children: {
            // TODO proverit
            // jineProhlaseni: portal.jineProhlaseni || null,
            deti: portal.deti || [],
          },

          affidavit: {
            prohlaseniKProhlaseni:
              portal.prohlaseniKProhlaseni != undefined
                ? portal.prohlaseniKProhlaseni
                : null,
            chciProhlaseni: helios.chciProhlaseni || false,
          },

          partner: {
            dalsiPoplatnik: portal.dalsiPoplatnik,

            jmenoPoplatnika: portal.jmenoPoplatnika || null,
            prijmeniPoplatnika: portal.prijmeniPoplatnika || null,
            rodneCisloPoplatnika: portal.rodneCisloPoplatnika || null,
            trvalyPobytPoplatnika: portal.trvalyPobytPoplatnika || null,
            uplatnujePoplatnik: portal.uplatnujePoplatnik,
            zamestnaniPoplatnika: portal.zamestnaniPoplatnika,
            platceDanePoplatnika: portal.platceDanePoplatnika || null,
            adresaPlatceDanePoplatnika:
              portal.adresaPlatceDanePoplatnika || null,
          },

          soucasneNeuplatnuji: portal.soucasneNeuplatnuji,
        },

        datumNastupu: datumNastupu || null,
      },
      pravdivostUdaju: false,
      dodatecneAtributy: {},
    };
    /// RESETOVANI pro novy rok
    if (resetCausedByNewYear) {
      if (card.taxDeclaration) {
        card.taxDeclaration.taxDeclaration.affidavit = {
          chciProhlaseni: null,
          prohlaseniKProhlaseni: null,
        };
        card.taxDeclaration.taxDeclaration.monthDiscount = {
          zakladniSlevaNaPoplatnika: {
            value: null,
          },
          rozsirenaSlevaNaInvalidituMesice: {
            value: null,
          },
          zakladniSlevaNaInvalidituMesice: {
            value: null,
          },
          slevaNaDrziteleZTPPMesice: {
            value: null,
          },
          slevaNaStudentaMesice: {
            value: null,
          },
        };
        if (card.taxDeclaration.taxDeclaration.children?.deti) {
          card.taxDeclaration.taxDeclaration.children.deti =
            card.taxDeclaration.taxDeclaration.children.deti.map((p) => ({
              ...p,
              uplatneni: [],
            }));
          if (card.taxDeclaration.taxDeclaration.partner) {
            card.taxDeclaration.taxDeclaration.partner.dalsiPoplatnik = null;
            card.taxDeclaration.taxDeclaration.partner.uplatnujePoplatnik =
              null;
            card.taxDeclaration.taxDeclaration.partner.zamestnaniPoplatnika =
              null;
          }
        }
      }
      // card.taxDeclaration.taxDeclaration.soucasneNeuplatnuji = null
    }

    return JSON.parse(JSON.stringify(card));
  } catch (e) {
    console.error(e);
  }
};

export const duchodyJineEvaluation = (
  a: ZdravotniPojistovnaAStav | undefined | null
): ZdravotniPojistovnaAStav => {
  if (a && a.duchody) {
    const jinyDuchod = !!a.duchody.jiny;

    if (
      a.duchody.predcasny ||
      a.duchody.invalidni ||
      a.duchody.starobni ||
      jinyDuchod
    ) {
      return {
        ...a,
        duchody: {
          ...a.duchody,
          pobiramDuchod: true,
          jinyDuchod: jinyDuchod,
        },
      };
    } else if (
      isNotNullOrUndefined(a.duchody.predcasny) ||
      isNotNullOrUndefined(a.duchody.invalidni) ||
      isNotNullOrUndefined(a.duchody.starobni)
    ) {
      return {
        ...a,
        duchody: {
          pobiramDuchod: false,
          ...a.duchody,
        },
      };
    }
  }
  return (
    a || {
      duchody: {
        pobiramDuchod: null,
      },
    }
  );
};

export const VALID_FORM_INIT_DATA: EmpTabsFormModelV2 = {
  pre: true,
  ...mapToApp(
    JSON.parse(JSON.stringify(MY_TEST_CARD.karta)) as ServerHeoModel,
    JSON.parse(JSON.stringify(MY_TEST_CARD.app)) as ServerAppModel,
    MY_TEST_CARD.dodatecneAtributy
  ),
  // personalPart: {
  //   personalInfo: {
  //     jmeno: 'Michal',
  //     datumNarozeni: '1970-12-31T23:00:00Z',
  //   },
  //   wage: {},
  //   idcard: {
  //     doklad: {},
  //   },
  //   execution: { exekuce: false },
  //   dalsiPracovniPomer: {
  //     dalsiPracovniPomer: false,
  //     nazevDalsihoZamestnavatele: '',
  //     sidloDalsihoZamestnavatele: '',
  //   },
  // },
};
