import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AsyncState } from '@ralba/shared';
import { session } from '../reducers';

const sessionFeatureSelect =
  createFeatureSelector<session.SessionState>('session');

const selectUserName = createSelector(
  sessionFeatureSelect,
  session.selectUserName
);

const selectFullUserName = createSelector(
  sessionFeatureSelect,
  session.selcetFullUserName
);

const selectIsLoaded = createSelector(
  sessionFeatureSelect,
  AsyncState.selectIsLoaded
);

export const SessionSelectors = {
  selectIsLoaded,
  selectUserName,
  selectFullUserName,
};
