<header>
  <div class="p-grid p-grid--with-bottom p-justify-between p-align-center">
    <div class="p-col-fixed" *ngIf="heading">
      <h2 class="h1 h1--title">{{ heading }}</h2>
    </div>
    <div class="p-col-fixed">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="p-col-fixed">
      <ng-content></ng-content>
    </div>
  </div>
</header>
