<div class="side-bar-logo">
  <div class="p-grid p-grid--with-bottom p-align-center">
    <div class="p-col-fixed">
      <button class="e-btn e-lib close" (click)="toggle.emit()">
        <i class="fal fa-times"></i>
      </button>
      <img [src]="logo" [alt]="title" (click)="toggle.emit()" />
    </div>
    <div class="p-col side-bar-logo__text">
      <span class="app-title">
        {{ title }}
      </span>
      <span>&nbsp;</span>
    </div>
  </div>
</div>
