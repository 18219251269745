<!-- value: {{ value }}
<br />
binary: {{ toBinary(value)}}
<br /> -->

<div class="month-wrapper">
  <span *ngFor="let opt of options">
    <ejs-checkbox
      [label]="opt.text"
      cssClass="e-small e-primary"
      [checked]="hasFlag(value, opt.value)"
      [disabled]="hasFlag(disabledMask, opt.value)"
      (change)="flagChange(opt.value)"
    ></ejs-checkbox>
    &nbsp;
  </span>
  &nbsp; &nbsp; &nbsp;

  <span *ngIf="!disabledMask">
    <ejs-checkbox
      [label]="allYear"
      cssClass="e-small e-primary"
      [checked]="value === flags.All"
      (change)="toogleAll()"
    >
    </ejs-checkbox>
  </span>
</div>
