import { translate } from '@ngneat/transloco';
import { createAction, props } from '@ngrx/store';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';

const toastActionsIdentifier = 'show toast message';

function createToastObj(
  title: string,
  content: string,
  cssClass: string
): { toast: ToastModel } {
  return {
    toast: {
      title,
      content,
      cssClass,
    },
  };
}

const showToastMessage = createAction(
  toastActionsIdentifier,
  props<{ toast: ToastModel }>()
);

const showToastSuccess = createAction(
  toastActionsIdentifier,
  // props<{ title: string; content: string }>(),
  (title = 'Success', content = '') =>
    createToastObj(title, content, 'e-toast-success')
);

const showToastWarning = createAction(
  toastActionsIdentifier,
  (title = 'Warning', content = '') =>
    createToastObj(title, content, 'e-toast-warning')
);

const showToastInfo = createAction(
  toastActionsIdentifier,
  (title = 'Info', content = '') =>
    createToastObj(title, content, 'e-toast-info')
);

/**
 * Returns the sum of a and b
 * @param {string} title = 'Saved'
 * @param {string} content = 'All data were saved'
 * @returns {string}
 */
const showToastSaved = createAction(
  toastActionsIdentifier,
  (title = translate('TPA.CommonSucessTitle'), content = translate('TPA.CommonSuccessfulMessage')) =>
    createToastObj(title, content, 'e-toast-success')
);

/**
 * For FE errors
 * @param {string} title = 'Error'
 * @param {string} content = details null
 * @returns {string}
 */
const showToastError = createAction(
  toastActionsIdentifier,
  (title = 'Error', content: string) =>
    createToastObj(title, content, 'e-toast-danger')
);

/**
 * For errors returned by server
 * @param serverErrObj UserFriendlyException?
 */
const showToastApiError = createAction(
  toastActionsIdentifier,
  (serverErrObj: { code: string; details: string; message: string }) =>
    createToastObj(
      serverErrObj && serverErrObj.details
        ? serverErrObj.message
        : 'Server returns error',
      serverErrObj && serverErrObj.details
        ? serverErrObj.details
        : serverErrObj && serverErrObj.message,
      'e-toast-danger'
    )
);

export const ToastActions = {
  showToastMessage,
  showToastSuccess,
  showToastWarning,
  showToastInfo,
  showToastError,
  showToastSaved,
  showToastApiError,
};
