import { createAction, props } from '@ngrx/store';
import { TenancyRequestDto, UserTenancyDto } from '@tpa/api';
import { ITenancyFeaturesAndPermissionns } from '../model';
import { httpActionsFactory } from './temp.utils';


export const GetUserTenanciesActions = httpActionsFactory<{}, UserTenancyDto[]>('get user tenancies')

const getUserTenancyFeatureAndPermissionsActions = httpActionsFactory<TenancyRequestDto, ITenancyFeaturesAndPermissionns>('GetUserTenancyFeatureAndPermissionsActions')

const checkTenancyAccount = createAction("checkTenenancyAccount");
const checkTenancyAccountOK = createAction("checkTenenancyAccountOK");

const determineUserTenancyAccount = createAction("determine user tenancy account");
const setUserTenancyAccount = createAction("set user tenancy account", props<{ userId?: number }>());

const redirectToAccountSelect = createAction("redirect to account select");
const noAccountsFound = createAction("no accounts found");


const markEmployeeCardAsSigned = createAction("mark emplyoee card as signed");


export const UserTenancyActions = {
  determineUserTenancyAccount,
  setUserTenancyAccount,
  redirectToAccountSelect,
  noAccountsFound,
  checkTenancyAccount,
  checkTenancyAccountOK,
  getUserTenancyFeatureAndPermissionsActions,
  markEmployeeCardAsSigned
}