import { ɵɵdirectiveInject, NgZone } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export function outsideZone<T>() {
  return function (source: Observable<T>) {
    return new Observable((observer) => {
      let sub: Subscription;
      ɵɵdirectiveInject(NgZone).runOutsideAngular(() => {
        sub = source.subscribe(observer);
      });

      return sub;
    });
  };
}
