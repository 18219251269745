import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TargetBlankService {
  constructor(private router: Router) {}

  targetBlank(path: (string | number)[], ar: NavigationExtras = {}) {
    const url = this.router.serializeUrl(this.router.createUrlTree(path, ar));

    window.open(location.origin + url, '_blank');
  }
}
