import { createAction, props } from '@ngrx/store';

/// Action triged after loaded app in browser
/// usful for server side rendering
const appLoadedInBrowser = createAction('[Core] App Loaded In Browser');

const hoverState = createAction(
  '[Core] set hover state',
  props<{ hover: boolean }>()
);

const dimensions = createAction(
  '[Core] set window dimensions',
  props<{ width: number; height: number }>()
);

export const CoreActions = {
  appLoadedInBrowser,
  hoverState,
  dimensions,
};
