import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EFFECTS } from './effects';
import { GUARDS } from './guards';
import { USER_TENANCIES_INITIALIZER_PROVIDER } from './initializers';
import { FEATURE_KEY, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,

    RouterModule,
    StoreModule.forFeature(FEATURE_KEY, reducers),
    EffectsModule.forFeature(EFFECTS),
  ],
  declarations: [],
  exports: [],
  providers: [...GUARDS],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'TPACoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [USER_TENANCIES_INITIALIZER_PROVIDER],
    };
  }
}
