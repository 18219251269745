<ral-card (click)="to['open'] && open(to['open'])">
  <div
    class="e-card-header"
    style="width: 100%"
    *ngIf="to.label || to.description"
  >
    <div
      *ngIf="to.label && to['hideLabel'] !== true"
      class="ui-widget label-wrapper"
    >
      <div class="p-grid p-nogutter p-justify-between">
        <div class="p-col-fixed" (click)="toggle()">
          {{ to.label }}
          <span *ngIf="to.required && to['hideRequiredMarker'] !== true"
            >*</span
          >
        </div>
        <div
          class="p-col-fixed"
          *ngIf="!formState['readonly'] && !to['readonly']"
          style="margin-top: -6px; margin-right: -6px"
        >
          <div class="p4-grid p4-grid--with-bottom">
            <div class="p-col-fixed" *ngIf="to['actionText']">
              <button
                type="button"
                class="e-btn e-lib e-small e-primary"
                (click)="action()"
              >
                <span>
                  {{ to['actionText'] }}
                </span>
              </button>
            </div>
            <div class="p-col-fixed">
              <button
                type="button"
                class="e-btn e-lib e-small e-primary e-decent"
                (click)="toggle()"
              >
                <i *ngIf="!to['open']" class="fal fa-fw fa-edit"></i>
                <i *ngIf="to['open']" class="fal fa-fw fa-compress-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <p *ngIf="to.description" class="description">{{ to.description }}</p>
    </div>
  </div>
  <div class="e-card-content">
    <ng-container #fieldComponent></ng-container>

    <p
      *ngIf="
        !to['open'] &&
        !field.formControl?.valid &&
        !form.get(this.to['part'])?.valid
      "
      class="errorWrapperMessage"
      style="cursor: pointer"
      (click)="toggle()"
    >
      {{ 'TPA.SectionContainsErrors' | transloco }}
    </p>
  </div>
</ral-card>
