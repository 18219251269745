import { Component, Optional } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldWrapper } from '@ngx-formly/core';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { OpenEverythingService } from '../../open-everything.service';

@UntilDestroy()
@Component({
  selector: 'tpa-employee-card-wrapper',
  templateUrl: './wrapper.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .label-wrapper {
        width: 100%;
      }
      ::ng-deep .description {
        font-weight: 400;
        color: #8181a5 !important;
        color: var(--primary-gray, #8181a5) !important;
        min-width: 100%;
      }
    `,
  ],
})
export class EmployeeCardWrapperComponent extends FieldWrapper {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      groupValidation: true,
      readonly: false,
    },
  };

  constructor(@Optional() private  openEverythingService: OpenEverythingService) {
    super();
  }

  ngOnInit() {
    if (this.formControl) {
      if (!this.formControl.valid) {
        setTimeout(() => {
          this.open(false);
        }, 200);
      }

      if(this.openEverythingService){
        this.openEverythingService.openEverything$.pipe(
            untilDestroyed(this),
          ).subscribe(() => {
            if (this.formState['readonly'] === false && !this.formControl.valid)
              this.to['open'] =true
          })
        }

      this.formControl?.valueChanges
        .pipe(
          untilDestroyed(this),
          map(() => this.formControl.valid),
          distinctUntilChanged(),
          filter((p) => p === false),
        )
        .subscribe(() => this.open(false));
    }
  }

  toggle() {
    if (this.formState['readonly'] === false)
      this.to['open'] = !this.to['open'];
  }

  open($event: boolean | undefined) {

    if (
      !$event &&
      this.formState['readonly'] === false &&
      this.to['readonly'] === false
    ) {
      this.to['open'] = true;
    }
  }
  action() {
    if (this.to['actionPathKey']) {
      const value = JSON.parse(
        JSON.stringify(
          this.field?.parent?.fieldGroup?.find(
            (p) => p.key == this.to['actionPathKey']
          )?.model
        )
      );

      this.formControl.patchValue(value);
    }
  }
}
