import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TenancyFeatureChecker {
  constructor(private store$: Store<never>) {}

  private static checkIfFeatureIsDefined(
    featureName: string,
    features: { [key: string]: string }
  ) {
    const included = features[featureName];
    if (included) {
      return true;
    } else {
      // console.warn(`Feature "${featureName}" is note defined`);
    }
  }

  // static isEnabled(
  //   featureName: string,
  //   features: { [key: string]: string },
  // );

  static isEnabled(featureName: string, features: { [key: string]: string }) {
    if (TenancyFeatureChecker.checkIfFeatureIsDefined(featureName, features)) {
      return (
        features[featureName] === 'true' ||
        features[featureName] === 'True' ||
        features[featureName] === 'TRUE'
      );
    } else {
      return false;
    }
  }
}
