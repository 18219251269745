import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { select, Store } from '@ngrx/store';
import { ToastActions } from '@ralba/core';
import { UserConfigurationHttpApiService } from '@tpa/api';
import { UserTenanciesSelectors } from '@tpa/core';
import { EmpTabsFormModelV2 } from '@tpa/shared';
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

type EmployeeCardLog = {
  card: EmpTabsFormModelV2;
  timeStamp: string;
};
interface UserConfig {
  favoriteCompanies: number[];
  employeeCards: Record<number, EmployeeCardLog>;
}

export interface UserSettingsState {
  loading: boolean;
  config: UserConfig;
  filter: string | null;
}

@Injectable({ providedIn: 'root' })
export class UserSettingsStore extends ComponentStore<UserSettingsState> {
  readonly loading$ = this.select((state) => state.loading);

  //readonly cardLotusNotes$ = this.select((state) => state.cardLotusNotes);

  // readonly id$ = this.select((state) => state.document?.id);
  readonly config$ = this.select((state) => state.config);
  readonly filter$ = this.select((state) => state.filter);
  readonly savedEmployeeCardWithTimeStamp$ = combineLatest([
    this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer)),
    this.select((state) => state.config.employeeCards),
  ]).pipe(
    map(([userId, employeeCards]) =>
      userId && !!userId.tenantId
        ? employeeCards[userId.tenantId] || null
        : null
    )
  );
  readonly savedEmployeeCard$ = this.savedEmployeeCardWithTimeStamp$.pipe(
    map((employeeCard) => employeeCard?.card || null)
  );
  companies$ = combineLatest([
    this.store$.pipe(select(UserTenanciesSelectors.selectSortedAccounts)),
    this.filter$,
  ]).pipe(
    map(([companies, query]) =>
      query && query != ''
        ? (companies || []).filter((l) =>
            l.tenancyName.toLowerCase().includes(query.toLowerCase())
          ) || []
        : companies || []
    )
  );
  notFavouriteCompanies$ = combineLatest([this.config$, this.companies$]).pipe(
    map(
      ([favourite, companies]) =>
        (companies || []).filter(
          (p) => !favourite.favoriteCompanies.includes(p.tenantId)
        ) || []
    )
  );
  favouriteCompanies$ = combineLatest([this.config$, this.companies$]).pipe(
    map(
      ([favourite, companies]) =>
        (companies || []).filter((p) =>
          favourite.favoriteCompanies.includes(p.tenantId)
        ) || []
    )
  );
  readonly validationError = this.effect((input: Observable<never>) => {
    return input.pipe(tap(() => console.log('validationError')));
  });
  private readonly _requesting = this.updater((state) => ({
    ...state,
    loading: true,
  }));

  private readonly _requestFinished = this.updater((state) => ({
    ...state,
    loading: false,
  }));
  readonly saveChanges = this.effect((input: Observable<never>) => {
    return input.pipe(
      tap(() => this._requesting()),
      withLatestFrom(this.config$),
      switchMap(([, config]) =>
        this.userSettingsService
          .updateUserPreferences({
            data: JSON.stringify(config),
          })
          .pipe(
            map(({ result }) => {
              // if (result) this._updateDocument({ data: result });
              return true;
            }),
            catchError(({ error }) => {
              this.store$.dispatch(ToastActions.showToastApiError(error.error));
              return of(null);
            })
          )
      ),
      tap(() => this._requestFinished())
    );
  });
  private readonly _updateConfig = this.updater(
    (state, input: { data: UserConfig }) => ({
      ...state,
      config: input.data,
    })
  );
  readonly fetchData = this.effect((input: Observable<never>) => {
    return input.pipe(
      tap(() => this._requesting()),
      withLatestFrom(this.config$),
      switchMap(([, config]) =>
        this.userSettingsService.getUserPreferences().pipe(
          map(({ result, error }) => {
            if (result)
              this._updateConfig({
                data: { ...config, ...JSON.parse(result.data) },
              });
          }),
          catchError(({ error }) => {
            this.store$.dispatch(ToastActions.showToastApiError(error.error));
            return of(null);
          })
        )
      ),
      tap(() => this._requestFinished())
    );
  });
  readonly toggleFavorite = this.effect((input: Observable<number>) => {
    return input.pipe(
      withLatestFrom(this.config$),
      tap(([p, config]) => {
        const co = JSON.parse(JSON.stringify(config)) as UserConfig;

        const index = co.favoriteCompanies.indexOf(p);
        if (index > -1) {
          co.favoriteCompanies.splice(index, 1);
        } else {
          co.favoriteCompanies.push(p);
        }

        this._updateConfig({ data: co });
        this.saveChanges();
      })
    );
  });
  readonly saveEmployeeWIPCard = this.effect(
    (input: Observable<EmpTabsFormModelV2>) => {
      return input.pipe(
        withLatestFrom(
          this.config$,
          this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer))
        ),
        tap(([card, config, identifer]) => {
          const co = JSON.parse(JSON.stringify(config)) as UserConfig;
          const employeeLog: EmployeeCardLog = {
            card,
            timeStamp: new Date().toISOString(),
          };
          co.employeeCards[identifer.tenantId] = employeeLog;

          this._updateConfig({ data: co });
          this.saveChanges();
        })
      );
    }
  );
  readonly clearEmployeeWIPCard = this.effect((input: Observable<never>) => {
    return input.pipe(
      withLatestFrom(
        this.config$,
        this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer))
      ),
      tap(([, config, identifer]) => {
        const co = JSON.parse(JSON.stringify(config)) as UserConfig;
        co.employeeCards[identifer.tenantId] = undefined;
        this._updateConfig({ data: co });
        this.saveChanges();
      })
    );
  });
  private readonly _filterQuery = this.updater(
    (state, input: { filter: string }) => ({
      ...state,
      filter: input.filter,
    })
  );
  readonly filterQuery = this.effect((input: Observable<string>) => {
    return input.pipe(tap((p) => this._filterQuery({ filter: p as string })));
  });

  constructor(
    public store$: Store<never>,
    private userSettingsService: UserConfigurationHttpApiService
  ) {
    super({
      loading: false,
      config: {
        favoriteCompanies: [],
        employeeCards: {},
      },
      filter: null,
    });
    this.fetchData();
  }
}
