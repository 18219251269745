import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlCryptoService {
  PASSWROD = 'RALBA';
  constructor() {}
  encrypt(obj: any): string {
    const serializedObject = JSON.stringify(obj);
    const crypto = JSON.stringify(obj);

    if (crypto.length >= 1800) {
      console.warn('moc dlouhé URL');
    }
    return crypto;
  }

  decrypt(obj: string) {
    return JSON.parse(obj);
  }
}
