import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetUserTenanciesActions } from '../actions';

export function userTenanciesInitializer(store$: Store<never>) {
  return () => store$.dispatch(GetUserTenanciesActions.request({}));
}

export const USER_TENANCIES_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: userTenanciesInitializer,
  deps: [Store],
  multi: true,
};
