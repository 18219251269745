import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { FormlyModule } from '@ngx-formly/core';
import { RalbaUiModule } from '@ralba/ui';
import { SharedModule } from '@tpa/shared';
import { TpaUiModule } from '@tpa/ui';
import { COMPONENTS } from './components';
import { EmployeesFormPipesModule } from '@tpa/shared';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TpaUiModule,
    RalbaUiModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormlyModule,
    RalbaUiModule,
    EmployeesFormPipesModule
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [],
})
export class SharedEmpDataModule { }
