import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getApplicationConfig } from '@ralba/shared';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry.init({
//   dsn: 'https://317d0304c3d642ee8f5b96a0642b14c3@o322254.ingest.sentry.io/6100183',
//   integrations: [
//     new BrowserTracing({
//       tracePropagationTargets: [
//         'localhost',
//         'https://hr-portal.its-tpa.cz',
//         'https://demo.hr-portal.cz',
//       ],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   release: packageJson.version,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

getApplicationConfig(`assets/${environment.appConfig}`, () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((module) => {
      if (!environment.production) {
        const applicationRef = module.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);
      }
    })
    .catch((err) => console.error(err));
});
