<ng-container *ngIf="!shouldHideUpload">
  <ejs-uploader
    (actionComplete)="actionComplete($event)"
    (chunkSuccess)="onUploadSuccess($event)"
    (success)="onUploadSuccess($event)"
    (uploading)="uploading($event)"
    [asyncSettings]="path"
    [autoUpload]="true"
    [cssClass]="'tpa-uploader'"
    locale="cs-Cz"
  ></ejs-uploader>
</ng-container>
