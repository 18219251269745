import { Component } from '@angular/core';
import {
    FieldType,
    FormlyExtension,
    FormlyFieldConfig
} from '@ngx-formly/core';
import { employeeFormTaxDeclaration } from '../../form-parts-definition';

@Component({
  selector: 'tpa-summary-tax-declaration',
  templateUrl: './summary-tax-declaration.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tpa-wrapper__control' },
})
export class SummaryTaxDeclarationComponent
  extends FieldType
  implements FormlyExtension
{
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: false,
    },
  };

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = [
      // {
      //   type: 'personal-info',
      //   key: 'personalInfo',
      //   className: 'p-col-12',
      //   props: {
      //     translate: true,
      //     label: 'TPA.BasicInfo',
      //   },
      // },
      {
        props: {
          //label: 'User info',
        },
        validators: {
          validation: [
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'rezidentura' },
            },
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'zakladniSlevaNaInvalidituMesice' },
            },
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'rozsirenaSlevaNaInvalidituMesice' },
            },
            {
              name: 'validateDiscountsForNotRezidenci',
              options: { errorPath: 'mamdeti' },
            },
          ],
        },
        fieldGroup: [
          ...employeeFormTaxDeclaration(true),
          //this._additionalData,
        ],
      },
    ];
  }
}
