import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'healthDisadvantage',
})
export class HealthDisadvantagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText;
    switch (value) {
      case 'no': {
        returnedText = translate('TPA.No');
        break;
      }
      case 'ozz': {
        returnedText = translate('TPA.Ozz');
        break;
      }
      case 'firstorseconddegreeinvalidity': {
        returnedText = translate('TPA.InvalidityOneTwo');
        break;
      }
      case 'thirddegreeinvalidity': {
        returnedText = translate('TPA.InvalidityThree');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
