import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  AssignUserToRolesInput,
  CreateEmployeeUserInputDto,
  ManageTwoFactorAuthDto,
  ManageUserPasswrodInputDto,
  TenancyRequestDto,
  TenancyRequestDtoOfEntityDtoOfInt64,
  UserAccountDto,
  UserActiveDto,
  UserCompaniesListDto,
  UserRoleDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class UserHttpApiService {
  constructor(private rest: RestService) {}

  public getAccounts(params: TenancyRequestDto<EntityDto<number>>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user/get/accounts',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<UserAccountDto>>(request);
  }

  public getCompanyAccounts(params: EntityDto<number>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user/get/company-accounts',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<UserCompaniesListDto[]>>(
      request
    );
  }

  public resendActivationEmail(body: TenancyRequestDto<EntityDto<number>>) {
    const request: Rest.Request<TenancyRequestDtoOfEntityDtoOfInt64> = {
      method: 'POST',
      url: '/api/user/resend-activation-email',
      body
    }
    return this.rest.request<TenancyRequestDtoOfEntityDtoOfInt64, void>(request)
  }

  public updatePassword(body: ManageUserPasswrodInputDto) {
    const request: Rest.Request<ManageUserPasswrodInputDto> = {
      method: 'PUT',
      url: '/api/user/update/password',
      body,
    };
    return this.rest.request<
      ManageUserPasswrodInputDto,
      Rest.AbpResponse<null>
    >(request);
  }

  public getRoles(params: TenancyRequestDto<EntityDto<number>>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user/get/roles',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<UserRoleDto[]>>(request);
  }

  public updateRoles(body: TenancyRequestDto<AssignUserToRolesInput>) {
    const request: Rest.Request<TenancyRequestDto<AssignUserToRolesInput>> = {
      method: 'PUT',
      url: '/api/user/update/roles',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<AssignUserToRolesInput>,
      Rest.AbpResponse<null>
    >(request);
  }

  public getTwoFactorAuthAuthentication(
    params: TenancyRequestDto<EntityDto<number>>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user/get/two-factor-authentication',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<ManageTwoFactorAuthDto>>(
      request
    );
  }

  public updateTwoFactorAuthentication(
    body: TenancyRequestDto<ManageTwoFactorAuthDto>
  ) {
    const request: Rest.Request<TenancyRequestDto<ManageTwoFactorAuthDto>> = {
      method: 'PUT',
      url: '/api/user/update/two-factor-authentication',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<ManageTwoFactorAuthDto>,
      Rest.AbpResponse<null>
    >(request);
  }

  public getActiveStatus(params: TenancyRequestDto<EntityDto<number>>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/user/get/active-status',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<UserActiveDto>>(request);
  }

  public updateActiveStatus(body: TenancyRequestDto<UserActiveDto>) {
    const request: Rest.Request<TenancyRequestDto<UserActiveDto>> = {
      method: 'PUT',
      url: '/api/user/update/active-status',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<UserActiveDto>,
      Rest.AbpResponse<UserActiveDto>
    >(request);
  }

  public createEmployee(body: TenancyRequestDto<CreateEmployeeUserInputDto>) {
    const request: Rest.Request<TenancyRequestDto<CreateEmployeeUserInputDto>> =
      {
        method: 'POST',
        url: '/api/user/create/employee',
        body,
      };
    return this.rest.request<
      TenancyRequestDto<CreateEmployeeUserInputDto>,
      Rest.AbpResponse<number>
    >(request);
  }
}
