


import { Injectable, Provider } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ToastActions } from '@ralba/core';
import { DocumentDto, EmployeeDocumentHttpApiService, ManageDocumentHttpApiService } from '@tpa/api';
import { DocumentsActions, UserTenanciesSelectors, isNotNullOrUndefined } from '@tpa/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';


export type DocumentWithTenant = DocumentDto & { tenant: number };
export interface DocumentsTipsListState {
  data: DocumentWithTenant[];
  tagId: string | undefined;
  userId: number | null;
  validFrom: string | null;
}
type GetDocumentTypeQuery = {
  tagId: string | undefined;
  userId: number;
  validFrom: string | undefined | null
}


export abstract class DocumentTipsListService {
  public abstract getDocuments(args: GetDocumentTypeQuery): Observable<DocumentWithTenant[]>;
}

export function provideEmployeeTipsDocumentListService(

): Provider[] {
  return [
    { provide: DocumentTipsListService, useClass: EmployeeTipsListService },
  ];
}

export function provideManageTipsDocumentListService(): Provider[] {
  return [
    { provide: DocumentTipsListService, useClass: ManageTipsListService },
  ];
}



@Injectable()
export class EmployeeTipsListService extends DocumentTipsListService {
  constructor(
    private readonly store$: Store<never>,
    private service: EmployeeDocumentHttpApiService,
  ) {
    super();
  }

  getDocuments(args: GetDocumentTypeQuery) {
    return of(args).pipe(
      withLatestFrom(
        this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer), filter((p): p is {
          tenantId: number,
          userId: number,
        } => isNotNullOrUndefined(p.tenantId) && isNotNullOrUndefined(p.userId)))
      ),
      exhaustMap(([{ tagId, userId, validFrom }, identifier]) =>
        this.service
          .getDocuments({
            ...identifier,
            body: {
              tagId: tagId == 'all' ? undefined : tagId,
              ownerId: userId,
              IsDeleted: false,
              // validTo: moment().endOf('year').toISOString(),
              validFrom: validFrom ? validFrom : undefined,
              // validTo: moment().format(),
              // validTo: new Date().toISOString()
            },
          })
          .pipe(
            map(({ result }) =>
              result.map((p) => ({ ...p, tenant: identifier.tenantId }))
            ),
          )
      )

    )
  }
}


@Injectable()
export class ManageTipsListService extends DocumentTipsListService {
  constructor(
    private readonly store$: Store<never>,
    private service: ManageDocumentHttpApiService,
  ) {
    super();
  }

  getDocuments(args: GetDocumentTypeQuery) {
    return of(args).pipe(
      withLatestFrom(
        this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer), filter((p): p is {
          tenantId: number,
          userId: number,
        } => isNotNullOrUndefined(p.tenantId) && isNotNullOrUndefined(p.userId)))
      ),
      exhaustMap(([{ tagId, userId, validFrom }, identifier]) =>
        this.service
          .getDocuments({
            ...identifier,
            body: {
              tagId: tagId == 'all' ? undefined : tagId,
              ownerId: userId,
              IsDeleted: false,
              // validTo: moment().endOf('year').toISOString(),
              validFrom: validFrom ? validFrom : undefined,
              // validTo: moment().format(),
              // validTo: new Date().toISOString()
            },
          })
          .pipe(
            map(({ result }) =>
              result.map((p) => ({ ...p, tenant: identifier.tenantId }))
            ),
          )
      )

    )
  }
}





@Injectable()
export class DocumentsTipsListStore extends ComponentStore<DocumentsTipsListState> {
  data$ = this.select((s) => s.data);
  userId$ = this.select((s) => s.userId);
  tagId$ = this.select((s) => s.tagId);
  validFrom$ = this.select((s) => s.validFrom);
  tenantId$ = this.store$.pipe(select(UserTenanciesSelectors.selectTenantId));

  private _updateData = this.updater(
    (
      state,
      input: {
        data: DocumentWithTenant[];
      }
    ) => ({
      ...state,
      data: input.data,
    })
  );

  private _updateUserId = this.updater((state, input: { userId: number }) => ({
    ...state,
    userId: input.userId,
  }));

  private _updateTagId = this.updater((state, input: { tagId: string }) => ({
    ...state,
    tagId: input.tagId,
  }));

  private _updateValidFrom = this.updater(
    (state, input: { validFrom: string }) => ({
      ...state,
      validFrom: input.validFrom,
    })
  );

  constructor(
    public store$: Store<any>,
    protected readonly documentService: DocumentTipsListService,
    public actions$: Actions
  ) {
    super({
      data: [],
      userId: null,
      tagId: undefined,
      validFrom: null,
    });
  }

  readonly updateValidFrom = this.effect(
    (input: Observable<{ validFrom: string }>) => {
      return input.pipe(
        map((p) => this._updateValidFrom({ validFrom: p.validFrom })),
        tap(() => this.fetchData())
      );
    }
  );
  readonly updateTagId = this.effect((input: Observable<{ tagId: string }>) => {
    return input.pipe(
      map((p) => this._updateTagId({ tagId: p.tagId })),
      tap(() => this.fetchData())
    );
  });

  readonly updateUserId = this.effect(
    (
      input: Observable<{
        userId: number;
      }>
    ) => {
      return input.pipe(
        map((p) => this._updateUserId({ userId: p.userId })),
        tap(() => this.fetchData())
      );
    }
  );

  readonly fetchData = this.effect((input: Observable<never>) => {
    return input.pipe(
      withLatestFrom(
        this.userId$.pipe(filter((p): p is number => isNotNullOrUndefined(p))),
        this.tagId$.pipe(filter((p): p is string => isNotNullOrUndefined(p) )),
        this.validFrom$,
      ),
      exhaustMap(([, userId, tagId, validFrom]) =>
        this.documentService
          .getDocuments(
            {
              tagId,
              userId,
              validFrom
            })
          .pipe(
            map((result) => {
              if (result)
                this._updateData({
                  data: result
                });
            }),
            catchError(({ error }) => {
              this.store$.dispatch(ToastActions.showToastApiError(error.error));
              return of(null);
            })
          )
      )
    );
  });

  readonly updateList = this.effect(() => {
    return this.actions$.pipe(
      ofType(DocumentsActions.UpdateListOrGrid),
      tap(() => this.fetchData())
    );
  });

  // readonly onNavigation = this.effect(() => {
  //   return this.actions$.pipe(
  //     ofType(DocumentsActions.UpdateListOrGrid),
  //     tap(() => this._updateOpen({ value: false }))
  //   );
  // });
}
