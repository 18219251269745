import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AdminComponentBase } from '@ralba/shared';

@Component({
  selector: 'tpa-tenancy-card',
  templateUrl: 'tenancy-card.component.html',
  styleUrls: ['./tenancy-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenancyCardComponent extends AdminComponentBase  {
  _avatar: string;
  _tenancyName: string;
  _numberOfTask: number;

  @Input() set tenancyName(value: string) {
    this._tenancyName = value;
    this._avatar = value.slice(0, 2);
  }
  @Input() link: string[] = [];

  @Input() tenancyId: number | null = null;
  @Input() img: string = null;
  @Input() isFavourite = false;
  @Input() notifications: number = Math.round(Math.random() * 5000);
  @Input() tasks: number = Math.round(Math.random() * 50);

  @Output() toggleFavorite = new EventEmitter<number>();
  // TODO: task
  // TODO: opened artefacts

  constructor() {
    super();
  }


}
