import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  AnnouncementDto,
  SendAnnouncementDto,
  TenancyRequestDto,
  TenancyRequestDtoOfAnnouncementDto,
  TenancyRequestDtoOfSendAnnouncementDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageAnnouncementHttpApiService {
  constructor(private rest: RestService) {}

  public getAnnouncement(params: TenancyRequestDto<{ Id?: string }>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/announcement/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<AnnouncementDto>>(request);
  }

  public createAnnouncement(body: TenancyRequestDtoOfAnnouncementDto) {
    const request: Rest.Request<TenancyRequestDtoOfAnnouncementDto> = {
      method: 'POST',
      url: '/api/manage/announcement/create',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfAnnouncementDto,
      Rest.AbpResponse<AnnouncementDto>
    >(request);
  }

  public updateAnnouncement(body: TenancyRequestDtoOfAnnouncementDto) {
    const request: Rest.Request<TenancyRequestDtoOfAnnouncementDto> = {
      method: 'PUT',
      url: '/api/manage/announcement/update',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfAnnouncementDto,
      Rest.AbpResponse<AnnouncementDto>
    >(request);
  }

  public deleteAnnouncement(params: TenancyRequestDto<{ Id?: string }>) {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: '/api/manage/announcement/delete',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<AnnouncementDto>>(request);
  }

  public sendAnnouncement(body: TenancyRequestDtoOfSendAnnouncementDto) {
    const request: Rest.Request<TenancyRequestDtoOfSendAnnouncementDto> = {
      method: 'PUT',
      url: '/api/manage/announcement/send-announcement',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfSendAnnouncementDto,
      Rest.AbpResponse<SendAnnouncementDto>
    >(request);
  }
}
