import { Action, createReducer, on } from '@ngrx/store';
import { AsyncState } from '@ralba/shared';
import { SessionActions } from '../actions';
import { GetAll } from '../models';

export interface CoreState extends GetAll, AsyncState.IAsyncState {}

const iniitialSate: CoreState = {
  ...AsyncState.initialState,
  auth: {
    allPermissions: {},
    grantedPermissions: {},
  },
  localization: undefined,
};

const reducer = createReducer(
  iniitialSate,
  on(SessionActions.getAllLoadded, (state, { auth, localization }) => ({
    ...state,
    ...AsyncState.loadedState,
    auth,
    localization,
  }))
);

export function coreReducer(state: CoreState, action: Action) {
  return reducer(state, action);
}

export const selectAuth = (state: CoreState) => state.auth;

export const selectLocalization = (state: CoreState) => state.localization;
export const selectLocalizationValues = (state: CoreState) =>
  state.localization?.values;
export const selectLocalizationLanguages = (state: CoreState) =>
  state.localization?.languages;
export const selectLocalizationCurrentLanguage = (state: CoreState) =>
  state.localization?.currentLanguage;
