import { FormlyFieldConfig } from '@ngx-formly/core';
import {
    sharedTipHintConsultation,
    sharedTipHintNoresident,
    sharedTipHintResident
} from './complete-form';
import { OnlyTaxDeclarationForm } from './summary-common.form';

export const employeeFormTaxDeclaration = (
  personalData: boolean
): FormlyFieldConfig[] => [
  {
    key: 'taxDeclaration',
    expressionProperties: {
      // className: () => (!personalData ? 'p-col-12' : 'none'),
    },
    fieldGroup: [
      // {
      //   type: 'h2',
      //   props: {
      //     translate: true,
      //     // prefix: '3. ',
      //     label: 'TPA.Residenture',
      //   },
      // },

      {
        type: 'rezidentura',
        key: 'rezidentura',
        props: {
          translate: true,
          label: 'TPA.ResidentureRecognition',
        },
      },

      sharedTipHintResident(),
      sharedTipHintNoresident(),
      sharedTipHintConsultation(),
      {
        wrappers: ['panel-wrapper-pink'],
        className: 'p-col-12',
        props: {},
        key: 'taxDeclaration',
        fieldGroup: [...OnlyTaxDeclarationForm()],
      },
    ],
  },
];
