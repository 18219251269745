import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LetModule, PushModule } from '@ngrx/component';
import { AuthorizeHttpInterceptor } from '@ralba/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from '@tpa/shared';
import { AttachmentPreviewModalComponent } from './attachment-preview-modal.component';

import { TranslocoModule } from '@ngneat/transloco';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
  imports: [
    CommonModule,
    PdfViewerModule,
    ToolbarModule,
    DialogModule,
    LetModule,
    PushModule,
    ButtonModule,
    SharedModule,
    TranslocoModule,
  ],
  declarations: [AttachmentPreviewModalComponent],
  exports: [AttachmentPreviewModalComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeHttpInterceptor,
      multi: true,
    },
  ],
})
export class AttachmentPreviewModalModule {}
