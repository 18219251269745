import { Platform } from '@angular/cdk/platform';
import { Directive, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AdminContainerBase } from '@ralba/shared';
import { fromEvent } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil
} from 'rxjs/operators';
import { outsideZone } from '../../helpers/outsideZone';
import { CoreActions } from '../actions';
import { NGXCoreSelectors } from '../selectors';
@Directive()
export abstract class AppBaseComponent
  extends AdminContainerBase
  implements OnInit
{
  hover$ = this.store$.pipe(select(NGXCoreSelectors.selectHover));

  constructor(store$: Store<never>, protected platform: Platform) {
    super(store$);
  }

  ngOnInit() {
    this.store$.dispatch(CoreActions.appLoadedInBrowser());

    if (this.platform.isBrowser) {
      const loading = document.getElementById('loading');
      if (loading) loading.parentElement.removeChild(loading);

      fromEvent(window, 'resize')
        .pipe(
          outsideZone(),
          takeUntil(this.destroy$),
          debounceTime(200),
          map(
            (p: { target: { innerWidth: number; innerHeight: number } }) => ({
              width: p.target.innerWidth,
              height: p.target.innerHeight,
            }),
            distinctUntilChanged(
              (
                prev: {
                  width: number;
                  height: number;
                },
                curr
              ) => prev.width === curr.height
            )
          )
        )
        .subscribe((p) => {
          this.store$.dispatch(CoreActions.dimensions(p));
        });
    }
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   console.log(event.target.innerWidth);
  // }
}
