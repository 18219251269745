// tslint:disable: no-bitwise

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

export enum MonthFlags {
  None = 0,
  January = 1 << 0,
  February = 1 << 1,
  March = 1 << 2,
  April = 1 << 3,
  May = 1 << 4,
  June = 1 << 5,
  July = 1 << 6,
  August = 1 << 7,
  September = 1 << 8,
  October = 1 << 9,
  November = 1 << 10,
  December = 1 << 11,
  All = ~(~0 << 12),
}

@Component({
  selector: 'tpa-month-select-custom',
  templateUrl: 'month-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthSelecetComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .month-wrapper {
        padding-bottom: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthSelecetComponent implements ControlValueAccessor {
  flags = MonthFlags;

  value = MonthFlags.None;

  @Input()
  disabledMask: MonthFlags | null = null;

  @Input()
  allYear: MonthFlags | null = null;

  options = [
    { text: '1', value: MonthFlags.January },
    { text: '2', value: MonthFlags.February },
    { text: '3', value: MonthFlags.March },
    { text: '4', value: MonthFlags.April },
    { text: '5', value: MonthFlags.May },
    { text: '6', value: MonthFlags.June },
    { text: '7', value: MonthFlags.July },
    { text: '8', value: MonthFlags.August },
    { text: '9', value: MonthFlags.September },
    { text: '10', value: MonthFlags.October },
    { text: '11', value: MonthFlags.November },
    { text: '12', value: MonthFlags.December },
    // { text: '1 - 12', value: MonthFlags.All },
  ];

  onChange: (p: MonthFlags) => void | undefined;
  onTouch: (p: MonthFlags) => void | undefined;

  constructor(private cde: ChangeDetectorRef) {

  }

  writeValue(obj: MonthFlags): void {
    if (
      typeof obj === 'number' &&
      (MonthFlags.All >= obj || obj <= MonthFlags.None)
    ) {
      this.value = obj;
    } else {
      this.value = 0;
    }
    this.cde.markForCheck();
  }
  registerOnChange(fn: (p: MonthFlags) => void | undefined): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: (p: MonthFlags) => void | undefined): void {
    this.onTouch = fn;
  }
  setDisabledState?() {
    this.disabledMask = MonthFlags.All;
  }

  hasFlag(values: MonthFlags, flag: MonthFlags) {
    return values & flag;
  }

  flagChange(value: MonthFlags) {
    this.value ^= value;
    this.onChange(this.value);
    this.onTouch(this.value);
  }

  toogleAll() {
    this.value =
      this.value === MonthFlags.All ? MonthFlags.None : MonthFlags.All;
    this.onChange(this.value);
    this.onTouch(this.value);
    this.cde.markForCheck();
  }

  toBinary(value: MonthFlags) {
    return Number.parseInt(value + '', 10).toString(2);
  }

  // currentMonthMask = (n: number = 10) => ((((1 << (n - 1)) - 1) << 1) | 1);
}
