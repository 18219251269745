import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AsyncState } from '@ralba/shared';
import { core } from '../reducers';

const coreFeatureSelect = createFeatureSelector<core.CoreState>('core');

const selectIsLoaded = createSelector(
  coreFeatureSelect,
  AsyncState.selectIsLoaded
);

const selectAuth = createSelector(coreFeatureSelect, core.selectAuth);

const selectLocalization = createSelector(
  coreFeatureSelect,
  core.selectLocalization
);

const selectLocalizationLanguages = createSelector(
  coreFeatureSelect,
  core.selectLocalizationLanguages
);

const selectLocalizationValues = createSelector(
  coreFeatureSelect,
  core.selectLocalizationValues
);

const selectLocalizationCurrentLanguage = createSelector(
  coreFeatureSelect,
  core.selectLocalizationCurrentLanguage
);

const selectTranslocoTranslations = createSelector(
  selectIsLoaded,
  selectLocalizationValues,
  selectLocalizationCurrentLanguage,
  (isLoaded, values, current) => ({ isLoaded, translations: values })
);

const selectTranslocoConfig = createSelector(
  selectIsLoaded,
  selectLocalizationLanguages,
  selectLocalizationCurrentLanguage,
  (isLoaded, languages, current) => ({ isLoaded, languages, current })
);

export const CoreSelectors = {
  selectIsLoaded,
  selectAuth,
  selectLocalization,
  selectLocalizationCurrentLanguage,
  selectLocalizationLanguages,
  selectLocalizationValues,
  selectTranslocoTranslations,
  selectTranslocoConfig,
};
