<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <!--<p>{{ model.mesto }}, {{ model.psc }}</p> -->

    <p class="description">
      {{ 'TPA.WantDeclaration' | transloco }}
    </p>
    <div
      *ngIf="
        model?.chciProhlaseni === undefined || model.chciProhlaseni === null;
        else notEmpty
      "
    >
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p>
        <mark>
          {{ model?.chciProhlaseni | yesOrNo | nullToDash }}
        </mark>
      </p>
    </ng-template>

    <div class="space-12"></div>
    <div *ngIf="model?.chciProhlaseni">
      <p class="description">
        {{ 'TPA.NoOtherDeclaration' | transloco }}
      </p>
      <p>
        <mark>
          {{ model?.prohlaseniKProhlaseni | yesOrNo | nullToDash }}
        </mark>
      </p>
    </div>
  </div>
</ng-template>
<!-- <pre>
    {{ this.model | json }}
  </pre
> -->
