import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-message',
  template: `
    <div>
      {{ to.label | transloco }}
    </div>
  `,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageField extends FieldType {
  defaultOptions = {
    props: {},
  };
}
