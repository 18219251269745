import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import { InsertJournalInputDto, UpdateJournalState } from '../dto';

@Injectable({ providedIn: 'root' })
export class EtlHttpApiService {
  constructor(private rest: RestService) { }

  public process() {
    const request: Rest.Request<void> = {
      method: 'POST',
      url: '/api/etl/process-etl',
    };
    return this.rest.request<void, Rest.AbpResponse<null>>(request);
  }

  public insertJournal(body: InsertJournalInputDto) {
    const request: Rest.Request<InsertJournalInputDto> = {
      method: 'POST',
      url: '/api/etl/insert-journal',
      body,
    };
    return this.rest.request<InsertJournalInputDto, Rest.AbpResponse<null>>(
      request
    );
  }

  public duplicateJournalRow(id: number) {
    const request: Rest.Request<void> = {
      method: 'POST',
      url: `/api/etl/${id}/duplicate-journal-row`,
    };
    return this.rest.request<void, Rest.AbpResponse<void>>(request);
  }

  public updateState(id: number, body: UpdateJournalState) {
    const request: Rest.Request<UpdateJournalState> = {
      method: 'PUT',
      url: `/api/etl/${id}/update-state`,
      body
    };
    return this.rest.request<UpdateJournalState, Rest.AbpResponse<void>>(request);
  }
}
