import { Directive, OnInit, ViewChild } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { AbpConsts, AdminContainerBase } from '@ralba/shared';
import {
  FilterSettingsModel,
  GridComponent,
  PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, tap } from 'rxjs/operators';
import { UserTenanciesSelectors } from '../selectors';

@Directive()
@UntilDestroy()
export abstract class GridBaseComponent
  extends AdminContainerBase
  implements OnInit
{
  abstract url: string;

  public dataManger: DataManager;
  public query: Query = new Query();

  @ViewChild(GridComponent, { static: false }) grid: GridComponent;

  //{ pageSizes: ['5', '10', 'All'], };

  //
  //
  // payrolls and allpayrolls
  //

  public pageSettings: PageSettingsModel = {
    pageSize: 15,
    pageSizes: [10, 15, 25, 50, 75, 100, 150],
    currentPage: 1,
  };

  public filterSettings: FilterSettingsModel = {
    type: 'Menu',
    operators: {
      stringOperator: [
        { value: 'contains', text: translate('TPA.FilterContains') },
        { value: 'startsWith', text: translate('TPA.FilterStartsWith') },
        { value: 'endsWith', text: translate('TPA.FilterEnds') },
      ],
    },
  };

  constructor(
    public store$: Store<never>,
    public oidcSecurityService: OidcSecurityService
  ) {
    super(store$);
    const value = parseInt(
      window.localStorage.getItem('gridGridPageSize') || '10'
    );
    this.pageSettings = {
      ...this.pageSettings,
      pageSize: value || 15,
    };
  }

  ngOnInit(): void {
    this.dataManger = new DataManager({
      url: AbpConsts.remoteServiceBaseUrl + this.url,
      adaptor: new UrlAdaptor(),
      headers: [
        { Authorization: `Bearer ${this.oidcSecurityService.getToken()}` },
      ],
    });
    this.store$
      .pipe(
        select(UserTenanciesSelectors.selectUserIdentifer),
        untilDestroyed(this),
        filter(
          ({ tenantId, userId }) =>
            tenantId !== undefined && userId !== undefined
        ),
        tap(({ tenantId, userId }) => {
          this.query
            .addParams('userId', userId.toString())
            .addParams('tenantId', tenantId.toString());
        })
      )
      .subscribe();
  }

  pageChanged() {
    if (this.grid) {
      window.localStorage.setItem(
        'gridGridPageSize',
        this.grid.pageSettings.pageSize.toString()
      );
    }
  }
}
