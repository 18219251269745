import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { SessionActions, SessionService } from '@ralba/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { exhaustMap, map, tap } from 'rxjs/operators';

// , CanActivateChild, CanLoad
@Injectable()
export class RepeatGetAllGuard implements CanActivate, CanActivateChild {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private sessionService: SessionService,
    private store$: Store<unknown>,
    private transloco: TranslocoService
  ) {}

  canActivateChild():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate();
  }

  canActivate(): Observable<boolean> {
    return this.sessionService.getAll().pipe(
      tap((response) => {
        const {
          localization: { languages, currentLanguage },
        } = response.result;
        this.transloco.setAvailableLangs(languages.map((x) => x.name));
        this.transloco.setDefaultLang(currentLanguage.name);
        this.store$.dispatch(SessionActions.getAllLoadded(response.result));
      }),
      exhaustMap(() => this.transloco.selectTranslation()),
      map(() => true)
    );
  }
}
