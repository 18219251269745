import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const sharedInsolvency = (
  question: FormlyFieldConfig = {
    key: 'exekuce',
    type: 'checkbox',
    className: 'p-col-12',
    props: {
      checkboxLabel: translate('TPA.InInsolvention'),
    },
  }
): FormlyFieldConfig[] => [
  question,
  {
    className: 'p-col-12',
    template: `<p class="description">
    <a class="link" href="/assets/docs/ex_osoby.xls" download>${translate(
      'TPA.DownloadInsolvantionQuestionaireLinkText'
    )}</a>${translate('TPA.DownloadInsolvantionQuestionaire')}
    </p>`,
    hideExpression: 'model?.exekuce != true',
  },
];
