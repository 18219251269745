import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { NavMenuActions } from '../actions';
import { NavMenuSelectors } from '../selectors';

@Injectable()
export class NavMenuEffects {
  onToggleNav$ = createEffect(() =>
    this.actions.pipe(
      ofType(NavMenuActions.toggleSideNav),
      withLatestFrom(
        this.store$.pipe(select(NavMenuSelectors.selectSideNavOpen))
      ),
      map(([, open]) =>
        open ? NavMenuActions.closeSideNav() : NavMenuActions.openSideNav()
      )
    )
  );

  // jen pro ukazku
  // onNavigeted$ = createEffect(
  //   () =>
  //     this.actions.pipe(
  //       ofType(ROUTER_NAVIGATED),
  //       withLatestFrom(this.store$.pipe(select(NGXCoreSelectors.selectWidth))),
  //       map((action) => NavMenuActions.closeSideNav())
  //     ),
  //   { dispatch: true }
  // );

  constructor(private actions: Actions, private store$: Store<never>) {}
}
