import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-date-picker',
  template: `
    <ejs-datepicker
      [formControl]="$any(formControl)"
      name="datepicker"
      [min]="to.min"
      [max]="to.max"
      [format]="to['format']"
    >
    </ejs-datepicker>

    <!-- {{ formControl.value }} -->
    <!-- <h1>format: {{ to['format'] }}</h1> -->

    <!-- [locale]="to.attributes.locale" -->
    <!-- [placeholder]="placeholder" -->
  `,
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldDatePicker extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        min: '',
        max: '',
        format: null, //'dd. MM. yyyy'
        //locale: this.locale,
      },
    },
  };
  //@Inject(LOCALE_ID) private locale

  constructor() {
    super();
  }

  ngOnInit() {
    if (
      this.formControl.value === null ||
      this.formControl.value === undefined
    ) {
      this.formControl.patchValue('');
    }
  }
}
