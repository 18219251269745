import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'tpa-field-yes-or-no',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <div class="p4-grid">
        <div class="p-col-fixed">
          <button
            class="e-btn e-lib"
            type="button"
            [class.e-primary]="formControl.value === true"
            (click)="yes()"
            [disabled]="to.disabled"
          >
            <i
              class="{{ to.attributes?.['yesIcon'] }}"
              *ngIf="to.attributes?.['yesIcon']"
            ></i>
            <span>
              {{
                to['yesTextT']
                  ? t(to['yesTextT']['key'], to['yesTextT']['params'])
                  : to['yesText']
              }}
            </span>
          </button>
        </div>
        <div class="p-col-fixed">
          <button
            class="e-btn e-lib"
            type="button"
            [class.e-primary]="formControl.value === false"
            (click)="no()"
            [disabled]="to.disabled"
          >
            <i
              class="{{ to.attributes?.['noIcon'] }}"
              *ngIf="to.attributes?.['noIcon']"
            ></i>
            <span>
              {{
                to['noTextT']
                  ? t(to['noTextT']['key'], to['noTextT']['params'])
                  : to['noText']
              }}
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styles: [],
})
export class FormlyFieldYesOrNo extends FieldType {
  defaultOptions = {
    props: {
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      translate: true,
      attributes: {
        yesIcon: 'fas fa-check-circle',
        noIcon: 'fas fa-do-not-enter',
      },
    },
  };
  constructor(protected cdf: ChangeDetectorRef) {
    super();
  }

  yes() {
    if (this.formControl.value === true) {
      this.formControl.setValue(null);
    } else {
      this.formControl.setValue(true);
    }
    this.cdf.markForCheck();
    if (this.to.change) this.to.change(this.field, null);
  }

  no() {
    if (this.formControl.value === false) {
      this.formControl.setValue(null);
    } else {
      this.formControl.setValue(false);
    }

    this.cdf.markForCheck();
    if (this.to.change) this.to.change(this.field, null);
  }
}
