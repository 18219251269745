import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';

@Injectable()
export class LocalizationHttpInterceptor implements HttpInterceptor {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modifiedHeaders = new HttpHeaders();
    modifiedHeaders = req.headers
      .set('Pragma', 'no-cache')
      .set('Cache-Control', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    modifiedHeaders = this.addAspNetCoreCultureHeader(modifiedHeaders);
    modifiedHeaders = this.addAcceptLanguageHeader(modifiedHeaders);
    return next.handle(
      req.clone({
        headers: modifiedHeaders,
      })
    );
  }

  protected addAspNetCoreCultureHeader(headers: HttpHeaders): HttpHeaders {
    if (this.locale && headers && !headers.has('.AspNetCore.Culture')) {
      headers = headers.set('.AspNetCore.Culture', 'en');
    }

    return headers;
  }

  protected addAcceptLanguageHeader(headers: HttpHeaders): HttpHeaders {
    if (this.locale && headers && !headers.has('Accept-Language')) {
      headers = headers.set('Accept-Language', 'en');
    }

    return headers;
  }
}
