import { FormKarta } from '@tpa/features/emp-data';
import { MonthFlags } from '@tpa/shared';
import moment from 'moment';

import { NULLZuctovani, VariantaNull, VariantaNullSettlement } from '../model/employee.data';
import {
  Duchody,
  KartaHeO,
  KartaPortal,
  RZDPart,
  Settlement,
  ZdravotniPojistovnaAStav,
} from '../model/employee.model';

/**
 * @deprecated The method should not be used
 */
export const mapDataFromKartaToHeo = (data: FormKarta): KartaHeO => {
  let datumNarozeni = data.personalPart?.personalInfo?.datumNarozeni;
  if (datumNarozeni) datumNarozeni = moment(datumNarozeni).format('yyyy-MM-DD');

  let datumExpirace = data.personalPart?.idcard?.datumExpirace;
  if (datumExpirace) datumExpirace = moment(datumExpirace).format('yyyy-MM-DD');

  let datumNastupu = data.taxDeclaration?.datumNastupu;
  if (datumNastupu) datumNastupu = moment(datumNastupu).format('yyyy-MM-DD');

  return JSON.parse(
    JSON.stringify({
      jmeno: data.personalPart?.personalInfo?.jmeno,
      prijmeni: data.personalPart?.personalInfo?.prijmeni,
      email: data.personalPart?.personalInfo?.email,
      permanentniAdresa: data.personalPart?.permanentniAdresa || {},
      kontaktniAdresa: data.personalPart?.kontaktniAdresa || {},
      prechodnaAdresa: data.personalPart?.prechodnaAdresa || {},
      titulpred: data.personalPart?.personalInfo?.titulpred,
      titulza: data.personalPart?.personalInfo?.titulza,
      rodnePrijmeni: data.personalPart?.personalInfo?.rodnePrijmeni,
      rodneCislo: data.personalPart?.personalInfo?.rodneCislo,
      datumNarozeni: datumNarozeni,
      mistoNarozeni: data.personalPart?.personalInfo?.mistoNarozeni,
      rodinnyStav: data.personalPart?.personalInfo?.rodinnyStav,
      pohlavi: data.personalPart?.personalInfo?.pohlavi,
      vzdelani: data.personalPart?.personalInfo?.vzdelani,
      pocetVychDeti: data.personalPart?.personalInfo?.pocetVychDeti || 0,
      statniPrislusnost: data.personalPart?.personalInfo?.statniPrislusnost,
      cisloUctu: data.personalPart?.wage?.cisloUctu,
      idUstavu: data.personalPart?.wage?.idUstavu,
      iban: data.personalPart?.wage?.iban,
      cisloDokladu: data.personalPart?.idcard?.cisloDokladu,
      datumExpirace: datumExpirace,
      chciProhlaseni:
        data.taxDeclaration?.taxDeclaration?.affidavit?.chciProhlaseni,
      datumNastupu: datumNastupu,
    })
  );
};
/**
 * @deprecated The method should not be used
 */
export const mapDataFromKartaToPortalModel = (data: FormKarta): KartaPortal => {
  let obj: KartaPortal = {
    rezidentura: data.taxDeclaration?.rezidentura?.rezidentura,
    rezidenturaVeState: data.taxDeclaration?.rezidentura?.rezidenturaVeState,
    rezidenturaText: data.taxDeclaration?.rezidentura?.rezidenturaText,
    rezidenturaVyhodnoceni:
      data.taxDeclaration?.rezidentura?.rezidenturaVyhodnoceni,

    zdrzovaniVCR: data.taxDeclaration?.rezidentura?.zdrzovaniVCR,
    studiumVCR: data.taxDeclaration?.rezidentura?.studiumVCR,
    identifikace: data.taxDeclaration?.rezidentura?.identifikace,

    doklad: data.personalPart?.idcard?.doklad,
    typDokladu: data.personalPart?.idcard?.typDokladu,
    vydalStat: data.personalPart?.idcard?.vydalStat,

    dalsiPracovniPomer:
      data.personalPart?.dalsiPracovniPomer?.dalsiPracovniPomer,
    nazevDalsihoZamestnavatele:
      data.personalPart?.dalsiPracovniPomer?.nazevDalsihoZamestnavatele,
    sidloDalsihoZamestnavatele:
      data.personalPart?.dalsiPracovniPomer?.sidloDalsihoZamestnavatele,

    exekuce: data.personalPart?.execution?.exekuce,
    // TODO proverit
    // jineProhlaseni: data.jineProhlaseni,

    salaryForm: data.personalPart?.wage?.salaryForm,
    pohlavi: data.personalPart?.personalInfo?.pohlavi,

    prohlaseniKProhlaseni: data.taxDeclaration?.taxDeclaration?.affidavit
      ?.prohlaseniKProhlaseni
      ? data.taxDeclaration?.taxDeclaration?.affidavit?.prohlaseniKProhlaseni
      : data.taxDeclaration?.taxDeclaration?.affidavit?.chciProhlaseni,

    soucasneNeuplatnuji:
      data.taxDeclaration?.taxDeclaration?.soucasneNeuplatnuji,
  };

  if (
    data.personalPart?.personalInfo?.druhaStatniPrislusnostCheck &&
    data.personalPart?.personalInfo?.druhaStatniPrislusnostCheck
  ) {
    obj = {
      ...obj,
      druhaStatniPrislusnost:
        data.personalPart?.personalInfo?.druhaStatniPrislusnost,
    };
  }

  if (data.personalPart?.health) {
    let zdravotniPojistovnaAStav: ZdravotniPojistovnaAStav = {
      zdravPojistovna: data.personalPart?.health?.zdravPojistovna,
      // TODO: remove if not zdravPojistovna "jina"
      jinaZdravPojistovna: data.personalPart?.health?.jinaZdravPojistovna,
      cisloPojistence: data.personalPart?.health?.cisloPojistence,
      zpsZtpp: data.personalPart?.health?.zpsZtpp,
      ztpp: data.personalPart?.health?.ztpp,
      a1: data.personalPart?.health?.a1,
      zdravotniZnevyhodneni: data.personalPart?.health?.zdravotniZnevyhodneni,
    };

    if (
      data.personalPart?.health?.duchody &&
      data.personalPart?.health?.duchody?.pobiramDuchod == true
    ) {
      const duchody: Duchody = {
        predcasny: data.personalPart?.health?.duchody?.predcasny,
        starobni: data.personalPart?.health?.duchody?.starobni,
        invalidni: data.personalPart?.health?.duchody?.invalidni,
        jiny: data.personalPart?.health?.duchody?.jinyDuchod
          ? data.personalPart?.health?.duchody?.jiny
          : null,
      };
      zdravotniPojistovnaAStav = {
        ...zdravotniPojistovnaAStav,
        duchody: duchody,
      };
    }

    obj = {
      ...obj,
      zdravotniPojistovnaAStav: zdravotniPojistovnaAStav,
    };
  }

  if (data.taxDeclaration?.taxDeclaration?.affidavit?.chciProhlaseni) {
    //Mesicni slevy
    obj = {
      ...obj,
      zakladniSlevaNaPoplatnika:
        data.taxDeclaration?.taxDeclaration?.monthDiscount
          ?.zakladniSlevaNaPoplatnika?.value,
      rozsirenaSlevaNaInvalidituMesice:
        data.taxDeclaration?.taxDeclaration?.monthDiscount
          ?.rozsirenaSlevaNaInvalidituMesice?.value,
      zakladniSlevaNaInvalidituMesice:
        data.taxDeclaration?.taxDeclaration?.monthDiscount
          ?.zakladniSlevaNaInvalidituMesice?.value,
      slevaNaDrziteleZTPPMesice:
        data.taxDeclaration?.taxDeclaration?.monthDiscount
          ?.slevaNaDrziteleZTPPMesice?.value,
      slevaNaStudentaMesice:
        data.taxDeclaration?.taxDeclaration?.monthDiscount
          ?.slevaNaStudentaMesice?.value,
    };

    if (data.taxDeclaration?.taxDeclaration?.children?.deti?.length > 0) {
      obj = {
        ...obj,
        deti: data.taxDeclaration?.taxDeclaration?.children?.deti,
        dalsiPoplatnik:
          data.taxDeclaration?.taxDeclaration?.partner?.dalsiPoplatnik,
      };

      if (
        data.taxDeclaration?.taxDeclaration?.partner?.dalsiPoplatnik == true
      ) {
        obj = {
          ...obj,
          jmenoPoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner?.jmenoPoplatnika,
          prijmeniPoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner?.prijmeniPoplatnika,
          rodneCisloPoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner?.rodneCisloPoplatnika,
          trvalyPobytPoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner?.trvalyPobytPoplatnika,
          uplatnujePoplatnik:
            data.taxDeclaration?.taxDeclaration?.partner?.uplatnujePoplatnik,
          zamestnaniPoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner?.zamestnaniPoplatnika,
          platceDanePoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner?.platceDanePoplatnika,
          adresaPlatceDanePoplatnika:
            data.taxDeclaration?.taxDeclaration?.partner
              ?.adresaPlatceDanePoplatnika,
        };
      }
    }
  }

  return JSON.parse(JSON.stringify(obj));
};
/**
 * @deprecated The method should not be used
 */
export const mapMargaUserData = (
  karta?: KartaHeO,
  app?: KartaPortal,
  dodatecneAtributy?: any,
  resetCausedByNewYear?: boolean
): FormKarta => {
  const helios = karta ? karta : mapDataFromKartaToHeo(VariantaNull);
  const portal = app ? app : mapDataFromKartaToPortalModel(VariantaNull);

  // check if rezidentura null, clear all data about rezidenci
  let rezidentura: string | boolean = portal.rezidentura;
  //console.log(typeof rezidentura,typeof rezidentura === 'boolean')
  if (typeof rezidentura === 'boolean' && rezidentura == true) {
    rezidentura = 'true';
  }
  if (typeof rezidentura === 'boolean' && rezidentura == false) {
    rezidentura = 'false';
  }

  let rezidenturaData = {
    rezidentura: rezidentura,
    rezidenturaVeState: portal.rezidenturaVeState,
    rezidenturaText: portal.rezidenturaText,
    rezidenturaVyhodnoceni: portal.rezidenturaVyhodnoceni,
    zdrzovaniVCR: portal.zdrzovaniVCR,
    studiumVCR: portal.studiumVCR,
  };
  if (rezidentura == null || rezidentura == undefined) {
    rezidenturaData = {
      rezidentura: null,
      rezidenturaVeState: null,
      rezidenturaText: null,
      rezidenturaVyhodnoceni: null,
      zdrzovaniVCR: null,
      studiumVCR: null,
    };
  }

  function parseDate (date: string) {
    function varA(date: string) {
      try {
        return moment(date, 'yyyy-MM-DD').toDate().toISOString()
      }
      catch {
        return  undefined
      }
    }

    function varB(date: string) {
      try {
        return moment(date, 'MM/DD/yyyy').toDate().toISOString()
      }
      catch {
        return  undefined
      }
    }

    return varA(date) || varB(date)
  }


  const datumNarozeni = parseDate(helios.datumNarozeni)
  const datumExpirace = parseDate(helios.datumExpirace)
  const datumNastupu = parseDate(helios.datumNastupu)

  const card: FormKarta = {
    personalPart: {
      personalInfo: {
        jmeno: helios.jmeno || null,
        prijmeni: helios.prijmeni || null,
        email: helios.email || null,

        titulpred: helios.titulpred || null,
        titulza: helios.titulza || null,
        rodnePrijmeni: helios.rodnePrijmeni || null,
        rodneCislo: helios.rodneCislo || null,
        datumNarozeni: datumNarozeni || null,
        mistoNarozeni: helios.mistoNarozeni || null,
        rodinnyStav: helios.rodinnyStav || null,

        vzdelani: helios.vzdelani || null,
        pocetVychDeti: helios.pocetVychDeti || 0,
        statniPrislusnost: helios.statniPrislusnost || null,
        druhaStatniPrislusnost: portal.druhaStatniPrislusnost || null,
        druhaStatniPrislusnostCheck: portal.druhaStatniPrislusnost
          ? true
          : false,

        // presunuti na zadost TPA\
        pohlavi: helios.pohlavi || null,
      },

      // allowContactAddress?: ,
      // allowTemporaryAddress?: ,

      permanentniAdresa: helios.permanentniAdresa || null,
      kontaktniAdresa: helios.kontaktniAdresa || null,
      prechodnaAdresa: helios.prechodnaAdresa || null,

      wage: {
        salaryForm: portal.salaryForm || null,
        cisloUctu: helios.cisloUctu || null,
        idUstavu: helios.idUstavu || null,
        iban: helios.iban || null,
      },
      health: {
        ...duchodyJineEvaluation(portal.zdravotniPojistovnaAStav),
      },
      idcard: {
        typDokladu: portal.typDokladu || null,
        vydalStat: portal.vydalStat || null,
        doklad: portal.doklad || null,
        cisloDokladu: helios.cisloDokladu || null,
        datumExpirace: datumExpirace || null,
      },
      execution: {
        exekuce: portal.exekuce || false,
      },
      dalsiPracovniPomer: {
        dalsiPracovniPomer: portal.dalsiPracovniPomer || null,
        nazevDalsihoZamestnavatele: portal.nazevDalsihoZamestnavatele,
        sidloDalsihoZamestnavatele: portal.sidloDalsihoZamestnavatele,
      },
    },
    taxDeclaration: {
      rezidentura: {
        rezidentura: portal.rezidentura || null,
        rezidenturaVeState: portal.rezidenturaVeState || null,
        rezidenturaText: portal.rezidenturaText || null,
        rezidenturaVyhodnoceni: portal.rezidenturaVyhodnoceni || null,
        identifikace: portal.identifikace || null,
        zdrzovaniVCR:
          portal.zdrzovaniVCR != undefined ? portal.zdrzovaniVCR : null,
        studiumVCR: portal.studiumVCR != undefined ? portal.studiumVCR : null,
      },
      taxDeclaration: {
        monthDiscount: {
          zakladniSlevaNaPoplatnika: {
            value:
              portal.zakladniSlevaNaPoplatnika != undefined
                ? portal.zakladniSlevaNaPoplatnika
                : null,
          },
          rozsirenaSlevaNaInvalidituMesice: {
            value: portal.rozsirenaSlevaNaInvalidituMesice || null,
          },
          zakladniSlevaNaInvalidituMesice: {
            value: portal.zakladniSlevaNaInvalidituMesice || null,
          },
          slevaNaDrziteleZTPPMesice: {
            value: portal.slevaNaDrziteleZTPPMesice || null,
          },
          slevaNaStudentaMesice: {
            value: portal.slevaNaStudentaMesice || null,
          },
        },
        children: {
          // TODO proverit
          // jineProhlaseni: portal.jineProhlaseni || null,
          deti: portal.deti || [],
        },

        affidavit: {
          prohlaseniKProhlaseni:
            portal.prohlaseniKProhlaseni != undefined
              ? portal.prohlaseniKProhlaseni
              : null,
          chciProhlaseni: helios.chciProhlaseni || false,
        },

        partner: {
          dalsiPoplatnik: portal.dalsiPoplatnik,

          jmenoPoplatnika: portal.jmenoPoplatnika || null,
          prijmeniPoplatnika: portal.prijmeniPoplatnika || null,
          rodneCisloPoplatnika: portal.rodneCisloPoplatnika || null,
          trvalyPobytPoplatnika: portal.trvalyPobytPoplatnika || null,
          uplatnujePoplatnik: portal.uplatnujePoplatnik,
          zamestnaniPoplatnika: portal.zamestnaniPoplatnika,
          platceDanePoplatnika: portal.platceDanePoplatnika || null,
          adresaPlatceDanePoplatnika: portal.adresaPlatceDanePoplatnika || null,
        },

        soucasneNeuplatnuji: portal.soucasneNeuplatnuji,
      },

      datumNastupu: datumNastupu || null,
    },
    pravdivostUdaju: false,
    dodatecneAtributy: {},
  };
  /// RESETOVANI pro novy rok
  if (resetCausedByNewYear) {
    card.taxDeclaration.taxDeclaration.affidavit = {
      chciProhlaseni: null,
      prohlaseniKProhlaseni: null,
    };
    card.taxDeclaration.taxDeclaration.monthDiscount = {
      zakladniSlevaNaPoplatnika: {
        value: null,
      },
      rozsirenaSlevaNaInvalidituMesice: {
        value: null,
      },
      zakladniSlevaNaInvalidituMesice: {
        value: null,
      },
      slevaNaDrziteleZTPPMesice: {
        value: null,
      },
      slevaNaStudentaMesice: {
        value: null,
      },
    };
    card.taxDeclaration.taxDeclaration.children.deti =
      card.taxDeclaration.taxDeclaration.children.deti.map((p) => ({
        ...p,
        uplatneni: [],
      }));
    card.taxDeclaration.taxDeclaration.partner.dalsiPoplatnik = null;
    card.taxDeclaration.taxDeclaration.partner.uplatnujePoplatnik = null;
    card.taxDeclaration.taxDeclaration.partner.zamestnaniPoplatnika = null;
    // card.taxDeclaration.taxDeclaration.soucasneNeuplatnuji = null
  }

  return JSON.parse(JSON.stringify(card));
};

export const mapMargeDTOForSettlement = (
  karta: KartaHeO,
  app: KartaPortal,
  rzdData: RZDPart
): Settlement => {
  let temp = {
    //karta: Karta,
    ...VariantaNullSettlement,
    ...mapMargaUserData(karta, app, {}, false),
    ...rzdData,
    dodetecneUplatneni: rzdData.dodetecneUplatneni == true,
  };

  if (rzdData && rzdData.zuctovani) {
    temp = {
      ...temp,
      zuctovani: {
        ...NULLZuctovani,
        ...rzdData.zuctovani,
        dalsiZamestnani:
          rzdData.zuctovani.dalsiZamestnavatele &&
          rzdData.zuctovani.dalsiZamestnavatele?.length > 0,
        dary: rzdData.zuctovani.daryCastka && rzdData.zuctovani.daryCastka != 0,
        manzelka:
          rzdData.zuctovani.manzelkaMesice &&
          rzdData.zuctovani.manzelkaMesice > MonthFlags.None,
        manzelkaZTP:
          rzdData.zuctovani.manzelkaZTPMesice &&
          rzdData.zuctovani.manzelkaZTPMesice > MonthFlags.None,
        exekuceDiscount:
          rzdData.zuctovani.exekuceDiscountCastka &&
          rzdData.zuctovani.exekuceDiscountCastka != 0,
        penzijni:
          rzdData.zuctovani.penzijniCastka &&
          rzdData.zuctovani.penzijniCastka != 0,
        prispevky:
          rzdData.zuctovani.prispevkyCastka &&
          rzdData.zuctovani.prispevkyCastka != 0,
        // skolkovne:
        //   rzdData.zuctovani.skolkovneDeti &&
        //   rzdData.zuctovani.skolkovneDeti.length > 0,

        zivotni:
          rzdData.zuctovani.zivotniCastka &&
          rzdData.zuctovani.zivotniCastka != 0,
        zkousky:
          rzdData.zuctovani.zkouskyCastka &&
          rzdData.zuctovani.zkouskyCastka != 0,
        uver: rzdData.zuctovani.uverCastka && rzdData.zuctovani.uverCastka != 0,
        uverJineOsobyAnoNe: rzdData?.zuctovani?.uverJineOsoby?.length
          ? true
          : rzdData?.zuctovani?.uverJineOsobyAnoNe,

        uverJineOsoby:
          rzdData?.zuctovani?.uverJineOsobyAnoNe == false
            ? []
            : rzdData?.zuctovani?.uverJineOsoby,
      },
    };
  }
  return JSON.parse(JSON.stringify(temp));
};

export const mapSettlementToDTO = (
  data: Settlement
): {
  karta: KartaHeO;
  app: KartaPortal;
  rzdData: any;
} => {
  let zuctovani = {};
  if (data.zada == true && data.zuctovani) {
    if (
      data.zuctovani.dalsiZamestnavatele &&
      data.zuctovani.dalsiZamestnavatele.length > 0
    ) {
      zuctovani = {
        ...zuctovani,
        dalsiZamestnavatele: data.zuctovani.dalsiZamestnavatele,
      };
    }
    if (data.zuctovani.dary) {
      zuctovani = { ...zuctovani, daryCastka: data.zuctovani.daryCastka };
    }
    if (data.zuctovani.manzelka) {
      zuctovani = {
        ...zuctovani,
        manzelka: data.zuctovani.manzelka,
        manzelkaJmeno: data.zuctovani.manzelkaJmeno,
        manzelkaMesice: data.zuctovani.manzelkaMesice,
        manzelkaPrijmeni: data.zuctovani.manzelkaPrijmeni,
        manzelkaRodneCislo: data.zuctovani.manzelkaRodneCislo,
      };

      if (data.zuctovani.manzelkaZTP) {
        zuctovani = {
          ...zuctovani,
          //manzelkaZTP: data.zuctovani.manzelkaZTP,
          manzelkaZTPMesice: data.zuctovani.manzelkaZTPMesice,
        };
      }
    }
    if (data.zuctovani.penzijni) {
      zuctovani = {
        ...zuctovani,
        penzijniCastka: data.zuctovani.penzijniCastka,
      };
    }
    if (data.zuctovani.prispevky) {
      zuctovani = {
        ...zuctovani,
        prispevkyCastka: data.zuctovani.prispevkyCastka,
      };
    }

    if (
      data.zuctovani.skolkovneDeti &&
      data.zuctovani.skolkovneDeti.length > 0
    ) {
      zuctovani = { ...zuctovani, skolkovneDeti: data.zuctovani.skolkovneDeti };
    }
    if (data.zuctovani.uver) {
      zuctovani = {
        ...zuctovani,

        uverAdresa: data.zuctovani.uverAdresa,
        uverCastka: data.zuctovani.uverCastka,
        // uverJinaOsoba: data.zuctovani.uverJinaOsoba,
        uverJineOsobyAnoNe: data?.zuctovani?.uverJineOsobyAnoNe,
        uverJineOsoby:
          data?.zuctovani?.uverJineOsobyAnoNe == false
            ? []
            : data?.zuctovani?.uverJineOsoby,
        uverUcel: data.zuctovani.uverUcel,
        prohlaseniUver: data.zuctovani.prohlaseniUver,
        prohlaseniUver2: data.zuctovani.prohlaseniUver2,
      };
    }
    if (data.zuctovani.exekuceDiscount) {
      zuctovani = {
        ...zuctovani,
        exekuceDiscountCastka: data.zuctovani.exekuceDiscountCastka,
      };
    }
    if (data.zuctovani.zivotni) {
      zuctovani = { ...zuctovani, zivotniCastka: data.zuctovani.zivotniCastka };
    }
    if (data.zuctovani.zkousky) {
      zuctovani = { ...zuctovani, zkouskyCastka: data.zuctovani.zkouskyCastka };
    }
  }

  const temp = {
    karta: mapDataFromKartaToHeo(data),
    app: mapDataFromKartaToPortalModel(data),
    rzdData: {
      zada: data.zada,
      potvrzeni: data.potvrzeni,
      dodetecneUplatneni: data.dodetecneUplatneni,
      zuctovani: zuctovani,
    },
  };

  return JSON.parse(JSON.stringify(temp));
};

export const duchodyJineEvaluation = (
  a: ZdravotniPojistovnaAStav
): ZdravotniPojistovnaAStav => {
  if (a && a.duchody) {
    const jinyDuchod = a.duchody.jiny && a.duchody.jiny !== '';

    if (
      a.duchody.predcasny ||
      a.duchody.invalidni ||
      a.duchody.starobni ||
      jinyDuchod
    ) {
      return {
        ...a,
        duchody: {
          ...a.duchody,
          pobiramDuchod: true,
          jinyDuchod: jinyDuchod,
        },
      };
    }
  }
  return a;
};
