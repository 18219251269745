import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PageService } from '@syncfusion/ej2-angular-grids';
import { ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { RAL_UI_COMPONENTS } from './components';
import { IMPORTS, UI_IMPORTS_EXPORTS } from './imports';

@NgModule({
  imports: [...IMPORTS, ...UI_IMPORTS_EXPORTS, ReactiveFormsModule],
  declarations: [...RAL_UI_COMPONENTS],
  exports: [...RAL_UI_COMPONENTS, ...UI_IMPORTS_EXPORTS],
  providers: [PageService, ToolbarService],
})
export class RalbaUiModule {}
