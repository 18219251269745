import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'ral-formly-read-only',
  styles: [':host { display: block; width: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <span>{{ formControl.value }}</span> `,
})
export class FormlyReadOnlyFieldInput extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        grid: 12,
      },
    },
  };

  onChange(value: any, checked: boolean) {
    this.formControl.markAsTouched();
  }
}
