import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  GetRzdInput,
  RzdDatesDto,
  RzdDto,
  RzdHistoryDto,
  RzdHistoryListDto,
  RzdMetadataDto,
  RzdOperationInputDto,
  SignRzdInputDto,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageTaxSettlementHttpApiService {
  constructor(private rest: RestService) {}

  public create(body: TenancyRequestDto<SignRzdInputDto>) {
    const request: Rest.Request<TenancyRequestDto<SignRzdInputDto>> = {
      method: 'POST',
      url: '/api/manage/tax-annual-settlement/create',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<SignRzdInputDto>,
      Rest.AbpResponse<any>
    >(request);
  }

  public getMetadata(params: TenancyRequestDto<GetRzdInput>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/tax-annual-settlement/get/metadata',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<RzdMetadataDto>>(request);
  }

  public get(params: TenancyRequestDto<GetRzdInput>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/tax-annual-settlement/get',
      params,
    };
    return this.rest.request<TenancyRequestDto<null>, Rest.AbpResponse<RzdDto>>(
      request
    );
  }

  public approve(body: TenancyRequestDto<RzdOperationInputDto>) {
    const request: Rest.Request<TenancyRequestDto<RzdOperationInputDto>> = {
      method: 'PUT',
      url: '/api/manage/tax-annual-settlement/approve',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<RzdOperationInputDto>,
      Rest.AbpResponse<RzdOperationInputDto>
    >(request);
  }

  public return(body: TenancyRequestDto<RzdOperationInputDto>) {
    const request: Rest.Request<TenancyRequestDto<RzdOperationInputDto>> = {
      method: 'PUT',
      url: '/api/manage/tax-annual-settlement/return',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<RzdOperationInputDto>,
      Rest.AbpResponse<RzdHistoryDto>
    >(request);
  }

  public getDates(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/tax-annual-settlement/get-dates',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<any>>(request);
  }

  public updateDates(body: TenancyRequestDto<RzdDatesDto>) {
    const request: Rest.Request<TenancyRequestDto<RzdDatesDto>> = {
      method: 'PUT',
      url: '/api/manage/tax-annual-settlement/update-dates',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<RzdDatesDto>,
      Rest.AbpResponse<RzdDatesDto>
    >(request);
  }

  public getHistory(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/tax-annual-settlement/get-history',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<RzdHistoryListDto[]>>(
      request
    );
  }

  public RZDPDFRequest(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'POST',
      url: '/api/manage/tax-annual-settlement/create-tax-settlement-pdf-request',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<RzdOperationInputDto>,
      Rest.AbpResponse<null>
    >(request);
  }

  public delete(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: '/api/manage/tax-annual-settlement/delete',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<void>>(request);
  }
}
