<ng-template tpaCheck [feature]="FEATURES.PrintPDF">
  <ng-container *ngIf="(handleBarsPreviewComponentStore.loading$ | async) else loading">

    <button class="e-btn e-lib e-primary" disabled>
      {{ 'TPA.Loading' | transloco}}
    </button>
  </ng-container>
  <ng-template #loading>
    <button class="e-btn e-lib e-primary" (click)="handleBarsPreviewComponentStore.htmlToPdf()">
      {{ printButtonKey | transloco}}
    </button>
  </ng-template>
</ng-template>
<!-- 
  preview
  <iframe
    #iframe
    src="data:text/html;charset=utf-8"
    [srcdoc]="handleBarsPreviewComponentStore.data$ | ngrxPush | sanitizeHtml"
    style="width: 960px; max-width: 42cm"
    scrolling="no"
    (load)="resizeIframe(iframe)"
    (resize)="resizeIframe(iframe)"
  ></iframe> -->
