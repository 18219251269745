export const environment = {
  production: true,
  appConfig: 'appconfig.json',
  intercomId: 'slc42dk6',
};

// eslint-disable-next-line no-global-assign
window.console = {
  ...console,
  log: () => {},
};
