<ng-container *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card">
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <p class="description">
      {{ 'TPA.NextTaxpayer' | transloco }}
    </p>
    <p>
      <mark>
        {{ model?.dalsiPoplatnik | yesOrNo | nullToDash }}
      </mark>
    </p>
    <div *ngIf="model?.dalsiPoplatnik">
      <div class="space-12"></div>
      <p class="description">
        {{ 'TPA.NextTaxpayerApplyDiscount' | transloco }}
      </p>
      <p>
        <mark>
          {{ model?.uplatnujePoplatnik | yesOrNo | nullToDash }}
        </mark>
      </p>
      <div class="space-12"></div>

      <p>
        {{ model.jmenoPoplatnika }} {{ model.prijmeniPoplatnika }} ({{
          model.rodneCisloPoplatnika
        }})
      </p>
      <p>
        {{ model.trvalyPobytPoplatnika }}
      </p>

      <div class="space-12"></div>
      <p class="description">
        {{ 'TPA.NextTaxpayerIsEmployed' | transloco }}
      </p>
      <p>
        <mark>
          {{ model?.zamestnaniPoplatnika | yesOrNo | nullToDash }}
        </mark>
      </p>
      <div class="space-12"></div>

      <p *ngIf="model.zamestnaniPoplatnika">
        {{ model.platceDanePoplatnika }} {{ model.adresaPlatceDanePoplatnika }}
      </p>
    </div>
  </div>
</ng-template>
