import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import {
  AppendMessagesInputDto,
  GetMessages,
  MessageDto,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageArtifactMessagesHttpApiService {
  constructor(private rest: RestService) {}

  public getMessages(params: TenancyRequestDto<GetMessages>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/artifact-message/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<MessageDto[]>>(request);
  }

  public appendMessage(body: TenancyRequestDto<AppendMessagesInputDto>) {
    const request: Rest.Request<TenancyRequestDto<AppendMessagesInputDto>> = {
      method: 'PUT',
      url: '/api/manage/artifact-message/append',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<AppendMessagesInputDto>,
      Rest.AbpResponse<MessageDto>
    >(request);
  }
}
