import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGurad, RepeatGetAllGuard, TpaAuthorizationGuard } from '@tpa/core';
import { LayoutRootComponent } from './root/layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGurad],
    children: [
      {
        path: '',
        component: LayoutRootComponent,
        canActivate: [RepeatGetAllGuard],
        canActivateChild: [TpaAuthorizationGuard],
        canLoad: [],
        children: [
          {
            path: '',
            redirectTo: 'tenancy',
            pathMatch: 'full',
          },
          {
            path: 'tenancy',
            loadChildren: () =>
              import('./features').then((m) => m.TenancyModule),
          },
          {
            path: 'role',
            loadChildren: () =>
              import('./features/role').then(({ RoleModule }) => RoleModule),
          },
          {
            path: 'user',
            loadChildren: () =>
              import('./features/user').then(({ UsersModule }) => UsersModule),
          },
          {
            path: 'company',
            loadChildren: () =>
              import('@tpa/features/company').then(
                (m) => m.FeaturesCompanyModule
              ),
          },
          {
            path: 'configuration',

            loadComponent: () =>
              import('@tpa/feature/app-configuration').then(
                (m) => m.FeatureAppConfigurationComponent
              ),
          },
          {
            path: 'etl',
            loadChildren: () =>
              import('./features/etl').then(({ ETLModule }) => ETLModule),
          },
          {
            path: 'announcements',
            loadChildren: () =>
              import('./features/announcements').then(
                ({ AnnouncementsModule }) => AnnouncementsModule
              ),
          },
          {
            path: 'system/profile',
            loadChildren: () =>
              import('./features/profile').then(
                ({ ProfileModule }) => ProfileModule
              ),
          },
          {
            path: '**',
            redirectTo: 'tenancy',
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
