export const cs = {
  cs: {
    tab: {
      closeButtonTitle: 'Zavřít',
    },
    pivotview: {
      grandTotal: 'Celkový součet',
      total: 'Celkový',
      value: 'Hodnota',
      noValue: 'Bez ceny',
      row: 'Řádek',
      column: 'Sloupec',
      collapse: 'Kolaps',
      expand: 'Rozšířit',
      rowAxisPrompt: 'Sem přetáhněte řádek',
      columnAxisPrompt: 'Sem přetáhněte sloupec',
      valueAxisPrompt: 'Zde přetáhněte hodnotu',
      filterAxisPrompt: 'Sem přetáhněte filtr',
      filter: 'Filtr',
      filtered: 'Filtrovaný',
      sort: 'Seřadit',
      filters: 'Filtry',
      rows: 'Řádky',
      columns: 'Sloupce',
      values: 'Hodnoty',
      close: 'Zavřít',
      cancel: 'zrušení',
      delete: 'Odstranit',
      CalculatedField: 'Vypočítané pole',
      createCalculatedField: 'Vytvořit vypočtené pole',
      fieldName: 'Zadejte název pole',
      error: 'Chyba',
      invalidFormula: 'Neplatný vzorec.',
      dropText: "Příklad: ('Součet (Order_Count)' + 'Součet (In_Stock)') * 250",
      dropTextMobile: 'Sem přidejte pole a upravte vzorec.',
      dropAction:
        'Vypočítané pole nemůže být umístěno v žádné jiné oblasti kromě hodnotové osy.',
      alert: 'Upozornění',
      warning: 'Varování',
      ok: 'OK',
      search: 'Vyhledávání',
      drag: 'Táhnout',
      remove: 'Odstranit',
      allFields: 'Všechna pole',
      formula: 'Vzorec',
      addToRow: 'Přidat do řádku',
      addToColumn: 'Přidat do sloupce',
      addToValue: 'Přidat k hodnotě',
      addToFilter: 'Přidat do filtru',
      emptyData: 'žádné záznamy k nahlédnutí',
      fieldExist: 'V tomto názvu již existuje pole. Zadejte jiné jméno.',
      confirmText:
        'V tomto názvu již existuje pole pro výpočet. Chceš to vyměnit?',
      noMatches: 'Žádné shody',
      format: 'Souhrny hodnot podle',
      edit: 'Upravit',
      clear: 'Vymazat',
      formulaField: 'Přetáhněte pole do vzorce',
      dragField: 'Přetáhněte pole do vzorce',
      clearFilter: 'Vymazat',
      by: 'podle',
      all: 'Všechno',
      multipleItems: 'Více položek',
      member: 'Člen',
      label: 'Označení',
      date: 'datum',
      enterValue: 'Zadejte hodnotu',
      chooseDate: 'Zadejte datum',
      Before: 'Před',
      BeforeOrEqualTo: 'Před nebo rovna',
      After: 'Po',
      AfterOrEqualTo: 'Po nebo stejné',
      labelTextContent: 'Zobrazit položky, pro které je štítek',
      dateTextContent: 'Zobrazit položky, pro které je datum',
      valueTextContent: 'Zobrazit položky, pro které',
      Equals: 'Rovná se',
      DoesNotEquals: 'Nerovná se',
      BeginWith: 'Začíná s',
      DoesNotBeginWith: 'Nezačíná',
      EndsWith: 'Končí s',
      DoesNotEndsWith: 'Nekončí',
      Contains: 'Obsahuje',
      DoesNotContains: 'Neobsahuje',
      GreaterThan: 'Větší než',
      GreaterThanOrEqualTo: 'Větší než nebo rovno',
      LessThan: 'Méně než',
      LessThanOrEqualTo: 'Méně než nebo rovno',
      Between: 'Mezi',
      NotBetween: 'Ne mezi',
      And: 'a',
      Sum: 'Součet',
      Count: 'Počet',
      DistinctCount: 'Výrazný počet',
      Product: 'Produkt',
      Avg: 'Prům',
      Min: 'Min',
      SampleVar: 'Ukázka Var',
      PopulationVar: 'Obyvatelstvo Var',
      RunningTotals: 'Běžné součty',
      Max: 'Max',
      Index: 'Index',
      SampleStDev: 'Ukázka StDev',
      PopulationStDev: 'Obyvatelstvo StDev',
      PercentageOfRowTotal: '% z řádku celkem',
      PercentageOfParentTotal: '% rodičů celkem',
      PercentageOfParentColumnTotal: '% z nadřazeného sloupce celkem',
      PercentageOfParentRowTotal: '% z nadřazeného řádku celkem',
      DifferenceFrom: 'Rozdíl od',
      PercentageOfDifferenceFrom: '% rozdílu od',
      PercentageOfGrandTotal: '% celkového součtu',
      PercentageOfColumnTotal: '% ze sloupce celkem',
      NotEquals: 'Není rovno',
      AllValues: 'Všechny hodnoty',
      conditionalFormating: 'Podmíněné formátování',
      apply: 'APLIKOVAT',
      condition: 'Přidat podmínku',
      formatLabel: 'Formát',
      valueFieldSettings: 'Nastavení hodnotového pole',
      baseField: 'Základní pole:',
      baseItem: 'Základní položka:',
      summarizeValuesBy: 'Sumarizujte hodnoty podle:',
      sourceName: 'Název pole:',
      sourceCaption: 'Titulek pole:',
      example: 'např:',
      editorDataLimitMsg: ' více položek. Hledání pro další upřesnění.',
      details: 'Podrobnosti',
      manageRecords: 'Správa záznamů',
      Years: 'Roky',
      Quarters: 'Čtvrtiny',
      Months: 'Měsíce',
      Days: 'Dny',
      Hours: 'Hodiny',
      Minutes: 'Minut',
      Seconds: 'Sekundy',
      save: 'Uložte zprávu',
      new: 'Vytvořte novou zprávu',
      load: 'Zatížení',
      saveAs: 'Uložit jako aktuální přehled',
      rename: 'Přejmenujte aktuální zprávu',
      deleteReport: 'Odstraňte aktuální přehled',
      export: 'Vývozní',
      subTotals: 'Dílčí součty',
      grandTotals: 'Velké součty',
      reportName: 'Název přehledu:',
      pdf: 'PDF',
      excel: 'Vynikat',
      csv: 'CSV',
      png: 'PNG',
      jpeg: 'JPEG',
      svg: 'SVG',
      mdxQuery: 'MDX Query',
      showSubTotals: 'Zobrazit mezisoučty',
      doNotShowSubTotals: 'Nezobrazovat mezisoučty',
      showSubTotalsRowsOnly: 'Zobrazit pouze řádky s mezisoučty',
      showSubTotalsColumnsOnly: 'Zobrazit pouze sloupce mezisoučtů',
      showGrandTotals: 'Zobrazit celkové součty',
      doNotShowGrandTotals: 'Nezobrazovat velké součty',
      showGrandTotalsRowsOnly: 'Zobrazit pouze řádky celkem',
      showGrandTotalsColumnsOnly: 'Zobrazit pouze sloupce s celkovými součty',
      fieldList: 'Zobrazit seznam polí',
      grid: 'Zobrazit tabulku',
      toolbarFormatting: 'Podmíněné formátování',
      chart: 'Zmapovat',
      reportMsg: 'Zadejte název vaild reportu !!!',
      reportList: 'Seznam hlášení',
      removeConfirm: 'Opravdu chcete tento přehled smazat?',
      emptyReport: 'Nebyly nalezeny žádné zprávy !!',
      bar: 'Bar',
      line: 'Řádek',
      area: 'Plocha',
      scatter: 'Rozptyl',
      polar: 'Polární',
      of: 'z',
      emptyFormat: 'Nebyl nalezen žádný formát !!!',
      emptyInput: 'Zadejte hodnotu',
      newReportConfirm: 'Chcete uložit změny, které chcete nahlásit?',
      emptyReportName: 'Zadejte název přehledu',
      qtr: 'Qtr',
      null: 'nula',
      undefined: 'nedefinováno',
      groupOutOfRange: 'Mimo dosah',
      fieldDropErrorAction:
        'Pole, které přesouváte, nelze umístit do této oblasti sestavy',
      MoreOption: 'Více...',
      aggregate: 'Agregát',
      drillThrough: 'Provrtat se',
      ascending: 'Vzestupně',
      descending: 'Klesající',
      number: 'Číslo',
      currency: 'Měna',
      percentage: 'Procento',
      formatType: 'Typ formátu',
      customText: 'Symbol měny',
      symbolPosition: 'Pozice symbolu',
      left: 'Vlevo, odjet',
      right: 'Že jo',
      grouping: 'Seskupení',
      true: 'Skutečný',
      false: 'Nepravdivé',
      decimalPlaces: 'Desetinná místa',
      numberFormat: 'Formátování čísel',
      memberType: 'Typ pole',
      formatString: 'Formát řetězce',
      expressionField: 'Výraz',
      customFormat: 'Zadejte řetězec vlastního formátu',
      selectedHierarchy: 'Hierarchie rodičů',
      olapDropText:
        'Příklad: [Opatření]. [Objednané množství] + ([Opatření]. [Objednané množství] * 0,10)',
      Percent: 'Procent',
      Custom: 'Zvyk',
      Measure: 'Opatření',
      Dimension: 'Dimenze',
      Standard: 'Standard',
      blank: '(Prázdný)',
      fieldTooltip:
        'Přetažením polí vytvořte výraz. A pokud chcete upravit stávající vypočtená pole! Toho lze dosáhnout pouhým výběrem pole v části Vypočtené členy.',
      QuarterYear: 'Čtvrtletní rok',
      fieldTitle: 'Název pole',
      drillError: 'Nelze zobrazit raw položky vypočtených polí.',
      caption: 'Polní titulek',
      copy: 'kopírovat',
      defaultReport: 'Výchozí přehled',
      customFormatString: 'Vlastní formát',
      invalidFormat: 'Nesprávný formát.',
      group: 'Skupina',
      unGroup: 'Oddělte se',
      invalidSelection: 'Tento výběr nelze seskupit.',
      groupName: 'Zadejte titulek, který chcete zobrazit v záhlaví',
      captionName: 'Zadejte titulek pro skupinu',
      selectedItems: 'Vybrané položky',
      groupFieldCaption: 'Polní titulek',
      groupTitle: 'Skupinové jméno',
      startAt: 'Počínaje',
      endAt: 'Končí v',
      groupBy: 'Interval do',
      selectGroup: 'Vyberte skupiny',
      numberFormatString: 'Příklad: C, P, 0000%, ### 0.. ## 0 # atd.',
      stackingcolumn: 'Skládaný sloupec',
      stackingbar: 'Skládaný pruh',
      stackingarea: 'Skládaný prostor',
      stepline: 'Kroková čára',
      steparea: 'Kroková oblast',
      splinearea: 'Spline Area',
      spline: 'Spline',
      stackingcolumn100: '100% skládaný sloupec',
      stackingbar100: '100% skládaný pruh',
      stackingarea100: '100% skládaná plocha',
      bubble: 'bublina',
      pareto: 'Pareto',
      radar: 'Radar',
      chartTypeSettings: 'Nastavení typu grafu',
      multipleAxes: 'Více os',
      sortAscending: 'Seřadit vzestupně',
      sortDescending: 'Seřadit sestupně',
      sortNone: 'Řadit údaje',
      clearCalculatedField: 'Vymazat upravené informace o poli',
      editCalculatedField: 'Upravit vypočtené pole',
      ChartType: 'Typ grafu',
      yes: 'Ano',
      no: 'Ne',
      numberFormatMenu: 'Formátování čísla ...',
      conditionalFormatingMenu: 'Podmíněné formátování...',
      removeCalculatedField: 'Opravdu chcete toto vypočtené pole smazat?',
      replaceConfirmBefore: 'Zpráva s názvem',
      replaceConfirmAfter: ' již existuje. Chceš to vyměnit?',
      pie: 'Koláč',
      funnel: 'Trychtýř',
      doughnut: 'Kobliha',
      pyramid: 'Pyramida',
      showLegend: 'Zobrazit legendu',
      exit: 'Výstup',
    },
    pivotfieldlist: {
      staticFieldList: 'Seznam kontingenčních polí',
      fieldList: 'Seznam polí',
      dropFilterPrompt: 'Sem přetáhněte filtr',
      dropColPrompt: 'Sem přetáhněte sloupec',
      dropRowPrompt: 'Sem přetáhněte řádek',
      dropValPrompt: 'Zde přetáhněte hodnotu',
      addPrompt: 'Sem přidejte pole',
      adaptiveFieldHeader: 'Vyberte pole',
      centerHeader: 'Přetáhněte pole mezi osami níže:',
      add: 'přidat',
      drag: 'Táhnout',
      filter: 'Filtr',
      filtered: 'Filtrovaný',
      sort: 'Seřadit',
      remove: 'Odstranit',
      filters: 'Filtry',
      rows: 'Řádky',
      columns: 'Sloupce',
      values: 'Hodnoty',
      CalculatedField: 'Vypočítané pole',
      createCalculatedField: 'Vytvořit vypočtené pole',
      fieldName: 'Zadejte název pole',
      error: 'Chyba',
      invalidFormula: 'Neplatný vzorec.',
      dropText:
        "Příklad: ('Součet (Order_Count)' + 'Součet (In_Stock)' ') * 250",
      dropTextMobile: 'Sem přidejte pole a upravte vzorec.',
      dropAction:
        'Vypočítané pole nemůže být umístěno v žádné jiné oblasti kromě hodnotové osy.',
      search: 'Vyhledávání',
      close: 'Zavřít',
      cancel: 'zrušení',
      delete: 'Odstranit',
      alert: 'Upozornění',
      warning: 'Varování',
      ok: 'OK',
      allFields: 'Všechna pole',
      formula: 'Vzorec',
      fieldExist: 'V tomto názvu již existuje pole. Zadejte jiné jméno.',
      confirmText:
        'V tomto názvu již existuje pole pro výpočet. Chceš to vyměnit?',
      noMatches: 'Žádné shody',
      format: 'Souhrny hodnot podle',
      edit: 'Upravit',
      clear: 'Vymazat',
      formulaField: 'Přetáhněte pole do vzorce',
      dragField: 'Přetáhněte pole do vzorce',
      clearFilter: 'Vymazat',
      by: 'podle',
      enterValue: 'Zadejte hodnotu',
      chooseDate: 'Zadejte datum',
      all: 'Všechno',
      multipleItems: 'Více položek',
      Equals: 'Rovná se',
      DoesNotEquals: 'Nerovná se',
      BeginWith: 'Začíná s',
      DoesNotBeginWith: 'Nezačíná',
      EndsWith: 'Končí s',
      DoesNotEndsWith: 'Nekončí',
      Contains: 'Obsahuje',
      DoesNotContains: 'Neobsahuje',
      GreaterThan: 'Větší než',
      GreaterThanOrEqualTo: 'Větší než nebo rovno',
      LessThan: 'Méně než',
      LessThanOrEqualTo: 'Méně než nebo rovno',
      Between: 'Mezi',
      NotBetween: 'Ne mezi',
      Before: 'Před',
      BeforeOrEqualTo: 'Před nebo rovna',
      After: 'Po',
      AfterOrEqualTo: 'Po nebo stejné',
      member: 'Člen',
      label: 'Označení',
      date: 'datum',
      value: 'Hodnota',
      labelTextContent: 'Zobrazit položky, pro které je štítek',
      dateTextContent: 'Zobrazit položky, pro které je datum',
      valueTextContent: 'Zobrazit položky, pro které',
      And: 'a',
      Sum: 'Součet',
      Count: 'Počet',
      DistinctCount: 'Výrazný počet',
      Product: 'Produkt',
      Avg: 'Prům',
      Min: 'Min',
      Max: 'Max',
      Index: 'Index',
      SampleStDev: 'Ukázka StDev',
      PopulationStDev: 'Obyvatelstvo StDev',
      SampleVar: 'Ukázka Var',
      PopulationVar: 'Obyvatelstvo Var',
      RunningTotals: 'Běžné součty',
      DifferenceFrom: 'Rozdíl od',
      PercentageOfDifferenceFrom: '% rozdílu od',
      PercentageOfGrandTotal: '% celkového součtu',
      PercentageOfColumnTotal: '% ze sloupce celkem',
      PercentageOfRowTotal: '% z řádku celkem',
      PercentageOfParentTotal: '% rodičů celkem',
      PercentageOfParentColumnTotal: '% z nadřazeného sloupce celkem',
      PercentageOfParentRowTotal: '% z nadřazeného řádku celkem',
      Years: 'Roky',
      Quarters: 'Čtvrtiny',
      Months: 'Měsíce',
      Days: 'Dny',
      Hours: 'Hodiny',
      Minutes: 'Minut',
      Seconds: 'Sekundy',
      apply: 'APLIKOVAT',
      valueFieldSettings: 'Nastavení hodnotového pole',
      sourceName: 'Název pole:',
      sourceCaption: 'Titulek pole:',
      summarizeValuesBy: 'Sumarizujte hodnoty podle:',
      baseField: 'Základní pole:',
      baseItem: 'Základní položka:',
      example: 'např:',
      editorDataLimitMsg: ' více položek. Hledání pro další upřesnění.',
      deferLayoutUpdate: 'Odložit aktualizaci rozložení',
      null: 'nula',
      undefined: 'nedefinováno',
      groupOutOfRange: 'Mimo dosah',
      fieldDropErrorAction:
        'Pole, které přesouváte, nelze umístit do této oblasti sestavy',
      MoreOption: 'Více...',
      memberType: 'Typ pole',
      selectedHierarchy: 'Hierarchie rodičů',
      formatString: 'Formát řetězce',
      expressionField: 'Výraz',
      olapDropText:
        'Příklad: [Opatření]. [Objednané množství] + ([Opatření]. [Objednané množství] * 0,10)',
      customFormat: 'Zadejte řetězec vlastního formátu',
      Measure: 'Opatření',
      Dimension: 'Dimenze',
      Standard: 'Standard',
      Currency: 'Měna',
      Percent: 'Procent',
      Custom: 'Zvyk',
      blank: '(Prázdný)',
      fieldTooltip:
        'Přetažením polí vytvořte výraz. A pokud chcete upravit stávající vypočtená pole! Toho lze dosáhnout pouhým výběrem pole v části „Vypočtení členů“.',
      fieldTitle: 'Název pole',
      QuarterYear: 'Čtvrtletní rok',
      caption: 'Polní titulek',
      copy: 'kopírovat',
      group: 'Skupina',
      numberFormatString: 'Příklad: C, P, 0000%, ### 0.. ## 0 # atd.',
      sortAscending: 'Seřadit vzestupně',
      sortDescending: 'Seřadit sestupně',
      sortNone: 'Řadit datové pořadí',
      clearCalculatedField: 'Vymazat upravené informace o poli',
      editCalculatedField: 'Upravit vypočtené pole',
      selectGroup: 'Vyberte skupiny',
      of: 'z',
      removeCalculatedField: 'Opravdu chcete toto vypočtené pole smazat?',
    },
    filemanager: {
      NewFolder: 'Nová složka',
      Upload: 'nahrát',
      Delete: 'Odstranit',
      Rename: 'Přejmenovat',
      Download: 'Stažení',
      Cut: 'Střih',
      Copy: 'kopírovat',
      Paste: 'Vložit',
      SortBy: 'Seřazeno podle',
      Refresh: 'Obnovit',
      'Item-Selection': 'vybraná položka',
      'Items-Selection': 'vybrané položky',
      View: 'Pohled',
      Details: 'Podrobnosti',
      SelectAll: 'Vybrat vše',
      Open: 'otevřeno',
      'Tooltip-NewFolder': 'Nová složka',
      'Tooltip-Upload': 'nahrát',
      'Tooltip-Delete': 'Odstranit',
      'Tooltip-Rename': 'Přejmenovat',
      'Tooltip-Download': 'Stažení',
      'Tooltip-Cut': 'Střih',
      'Tooltip-Copy': 'kopírovat',
      'Tooltip-Paste': 'Vložit',
      'Tooltip-SortBy': 'Seřazeno podle',
      'Tooltip-Refresh': 'Obnovit',
      'Tooltip-Selection': 'Vymazat výběr',
      'Tooltip-View': 'Pohled',
      'Tooltip-Details': 'Podrobnosti',
      'Tooltip-SelectAll': 'Vybrat vše',
      Name: 'název',
      Size: 'Velikost',
      DateModified: 'Upraveno',
      DateCreated: 'Datum vytvoření',
      Path: 'Cesta',
      Modified: 'Upraveno',
      Created: 'Vytvořeno',
      Location: 'Umístění',
      Type: 'Typ',
      Permission: 'Povolení',
      Ascending: 'Vzestupně',
      Descending: 'Klesající',
      None: 'Žádný',
      'View-LargeIcons': 'Velké ikony',
      'View-Details': 'Podrobnosti',
      Search: 'Vyhledávání',
      'Button-Ok': 'OK',
      'Button-Cancel': 'zrušení',
      'Button-Yes': 'Ano',
      'Button-No': 'Ne',
      'Button-Create': 'Vytvořit',
      'Button-Save': 'Uložit',
      'Header-NewFolder': 'Složka',
      'Content-NewFolder': 'Zadejte název složky',
      'Header-Rename': 'Přejmenovat',
      'Content-Rename': 'Zadejte své nové jméno',
      'Header-Rename-Confirmation': 'Přejmenovat potvrzení',
      'Content-Rename-Confirmation':
        'Pokud změníte příponu názvu souboru, může být soubor nestabilní. Opravdu to chcete změnit?',
      'Header-Delete': 'Smazat soubor',
      'Content-Delete': 'Opravdu chcete tento soubor odstranit?',
      'Header-Multiple-Delete': 'Odstraňte více souborů',
      'Content-Multiple-Delete': 'Opravdu chcete tyto {0} soubory smazat?',
      'Header-Duplicate': 'Soubor / složka existuje',
      'Content-Duplicate': '{0} již existuje. Chcete přejmenovat a vložit?',
      'Header-Upload': 'Nahrát soubory',
      Error: 'Chyba',
      'Validation-Empty': 'Název souboru nebo složky nesmí být prázdný.',
      'Validation-Invalid':
        'Název souboru nebo složky {0} obsahuje neplatné znaky. Použijte jiné jméno. Platný název souboru nebo složky nemůže končit tečkou nebo mezerou a nesmí obsahovat žádný z následujících znaků: \\ /: *? "<> |',
      'Validation-NewFolder-Exists':
        'Soubor nebo složka s názvem {0} již existuje.',
      'Validation-Rename-Exists':
        'Nelze přejmenovat {0} na {1}: cíl již existuje.',
      'Folder-Empty': 'Tato složka je prázdná',
      'File-Upload': 'Přetáhněte soubory sem a nahrajte',
      'Search-Empty': 'Nebyly nalezeny žádné výsledky',
      'Search-Key': 'Zkuste s různými klíčovými slovy',
      'Filter-Empty': 'Nebyly nalezeny žádné výsledky',
      'Filter-Key': 'Zkuste s jiným filtrem',
      'Sub-Folder-Error': 'Cílová složka je podsložkou zdrojové složky.',
      'Access-Denied': 'Přístup odepřen',
      'Access-Details': 'K přístupu do této složky nemáte oprávnění.',
      'Header-Retry': 'Soubor již existuje',
      'Content-Retry':
        'Soubor s tímto názvem již v této složce existuje. Co bys rád dělal?',
      'Button-Keep-Both': 'Nechat si oba',
      'Button-Replace': 'Nahradit',
      'Button-Skip': 'Přeskočit',
      'ApplyAll-Label': 'Udělejte to pro všechny aktuální položky',
      KB: 'KB',
      'Access-Message':
        '{0} není přístupný. K provedení akce {1} potřebujete povolení.',
      'Network-Error':
        'NetworkError: Nepodařilo se odeslat na XMLHTTPRequest: Nepodařilo se načíst',
      'Server-Error': 'ServerError: Neplatná odpověď od',
    },
    inplaceeditor: {
      save: 'Uložit',
      cancel: 'zrušení',
      loadingText: 'Načítání...',
      editIcon: 'Klepnutím upravte',
      editAreaClick: 'Klepnutím upravte',
      editAreaDoubleClick: 'Dvojitým kliknutím upravte',
    },
    treegrid: {
      Above: 'Výše',
      Below: 'Níže',
      AddRow: 'Přidat řádek',
      ExpandAll: 'Rozšířit vše',
      CollapseAll: 'Sbalit vše',
    },
    colorpicker: {
      Apply: 'Aplikovat',
      Cancel: 'zrušení',
      ModeSwitcher: 'Režim přepínání',
    },
    uploader: {
      Browse: 'Procházet...',
      Clear: 'Vymazat',
      Upload: 'nahrát',
      dropFilesHint: 'Nebo sem přetáhněte soubory',
      invalidMaxFileSize: 'Velikost souboru je příliš velká',
      invalidMinFileSize: 'Velikost souboru je příliš malá',
      invalidFileType: 'Typ souboru není povolen',
      uploadFailedMessage: 'Soubor se nepodařilo nahrát',
      uploadSuccessMessage: 'Soubor byl úspěšně nahrán',
      removedSuccessMessage: 'Soubor byl úspěšně odebrán',
      removedFailedMessage: 'Soubor nelze odstranit',
      inProgress: 'Nahrávání',
      readyToUploadMessage: 'Připraveno k nahrání',
      abort: 'Přerušit',
      remove: 'Odstranit',
      cancel: 'zrušení',
      delete: 'Smazat soubor',
      pauseUpload: 'Nahrávání souboru bylo pozastaveno',
      pause: 'Pauza',
      resume: 'Životopis',
      retry: 'Opakujte akci',
      fileUploadCancel: 'Nahrávání souboru bylo zrušeno',
    },
    numerictextbox: {
      incrementTitle: 'Hodnota přírůstku',
      decrementTitle: 'Hodnota snížení',
    },
    slider: {
      incrementTitle: 'Zvýšit',
      decrementTitle: 'Pokles',
    },
    formValidator: {
      required: 'Toto pole je povinné.',
      email: 'Prosím zadejte platnou emailovou adresu.',
      url: 'Zadejte prosím platnou adresu URL.',
      date: 'Prosím vložte správné datum.',
      dateIso: 'Zadejte platné datum (ISO).',
      creditcard: 'Zadejte platné číslo karty',
      number: 'Prosím zadejte platné číslo.',
      digits: 'Zadejte pouze číslice.',
      maxLength: 'Zadejte maximálně {0} znaků.',
      minLength: 'Zadejte alespoň {0} znaků.',
      rangeLength: 'Zadejte prosím hodnotu mezi {0} a {1} znaky.',
      range: 'Zadejte hodnotu mezi {0} a {1}.',
      max: 'Zadejte hodnotu menší nebo rovnou {0}.',
      min: 'Zadejte hodnotu větší nebo rovnou {0}.',
      regex: 'Zadejte správnou hodnotu.',
      tel: 'Vložte prosím platné telefonní číslo.',
      pattern: 'Zadejte správnou hodnotu vzoru.',
      equalTo: 'Zadejte prosím platný text shody',
    },
    richtexteditor: {
      alignments: 'Zarovnání',
      justifyLeft: 'Zarovnat doleva',
      justifyCenter: 'Zarovnat centrum',
      justifyRight: 'Zarovnat vpravo',
      justifyFull: 'Zarovnat zarovnání',
      fontName: 'Název písma',
      fontSize: 'Velikost písma',
      fontColor: 'Barva fontu',
      backgroundColor: 'Barva pozadí',
      bold: 'tučně',
      italic: 'Kurzíva',
      underline: 'Zdůraznit',
      strikethrough: 'Přeškrtněte',
      clearFormat: 'Vymazat formát',
      clearAll: 'Vymazat vše',
      cut: 'Střih',
      copy: 'kopírovat',
      paste: 'Vložit',
      unorderedList: 'Seznam s odrážkami',
      orderedList: 'Číslovaný seznam',
      indent: 'Zvýšit odsazení',
      outdent: 'Snižte odsazení',
      undo: 'vrátit',
      redo: 'Předělat',
      superscript: 'Horní index',
      subscript: 'Dolní index',
      createLink: 'Vložit hypertextový odkaz',
      openLink: 'Otevřít odkaz',
      editLink: 'Upravit odkaz',
      removeLink: 'Odebrat odkaz',
      image: 'Vložte obrázek',
      replace: 'Nahradit',
      align: 'Zarovnat',
      caption: 'Titulek obrázku',
      remove: 'Odstranit',
      insertLink: 'Vložit odkaz',
      display: 'Zobrazit',
      altText: 'Alternativní text',
      dimension: 'Změnit velikost',
      fullscreen: 'Maximalizovat',
      maximize: 'Maximalizovat',
      minimize: 'Minimalizovat',
      lowerCase: 'Malá písmena',
      upperCase: 'Velká písmena',
      print: 'Tisk',
      formats: 'Formáty',
      sourcecode: 'Zobrazení kódu',
      preview: 'Náhled',
      viewside: 'ViewSide',
      insertCode: 'Vložte kód',
      linkText: 'Zobrazit text',
      linkTooltipLabel: 'Titul',
      linkWebUrl: 'Webová adresa',
      linkTitle: 'Zadejte název',
      linkurl: 'http://example.com',
      linkOpenInNewWindow: 'Otevřete odkaz v novém okně',
      linkHeader: 'Vložit odkaz',
      dialogInsert: 'Vložit',
      dialogCancel: 'zrušení',
      dialogUpdate: 'Aktualizace',
      imageHeader: 'Vložte obrázek',
      imageLinkHeader: 'Můžete také poskytnout odkaz z webu',
      mdimageLink: 'Zadejte prosím adresu URL svého obrázku',
      imageUploadMessage: 'Sem přetáhněte obrázek nebo jej nahrajte',
      imageDeviceUploadMessage: 'Klepnutím sem nahrajte',
      imageAlternateText: 'Alternativní text',
      alternateHeader: 'Alternativní text',
      browse: 'Procházet',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Titulek',
      imageSizeHeader: 'Velikost obrázku',
      imageHeight: 'Výška',
      imageWidth: 'Šířka',
      textPlaceholder: 'Zadejte text',
      inserttablebtn: 'Vložte tabulku',
      tabledialogHeader: 'Vložte tabulku',
      tableWidth: 'Šířka',
      cellpadding: 'Čalounění buněk',
      cellspacing: 'Rozestup buněk',
      columns: 'Počet sloupců',
      rows: 'Počet řádků',
      tableRows: 'Řádky tabulky',
      tableColumns: 'Sloupce tabulky',
      tableCellHorizontalAlign: 'Horizontální zarovnání buňky tabulky',
      tableCellVerticalAlign: 'Svislé zarovnání buňky tabulky',
      createTable: 'Vytvořit tabulku',
      removeTable: 'Odebrat tabulku',
      tableHeader: 'Záhlaví tabulky',
      tableRemove: 'Tabulka odebrat',
      tableCellBackground: 'Pozadí buňky tabulky',
      tableEditProperties: 'Vlastnosti úprav tabulky',
      styles: 'Styly',
      insertColumnLeft: 'Vložit sloupec vlevo',
      insertColumnRight: 'Vložit sloupec vpravo',
      deleteColumn: 'Smazat sloupec',
      insertRowBefore: 'Vložte řádek před',
      insertRowAfter: 'Vložte řádek za',
      deleteRow: 'Smazat řádek',
      tableEditHeader: 'Upravit tabulku',
      TableHeadingText: 'Nadpis',
      TableColText: 'Col',
      imageInsertLinkHeader: 'Vložit odkaz',
      editImageHeader: 'Upravit obrázek',
      alignmentsDropDownLeft: 'Zarovnat doleva',
      alignmentsDropDownCenter: 'Zarovnat centrum',
      alignmentsDropDownRight: 'Zarovnat vpravo',
      alignmentsDropDownJustify: 'Zarovnat zarovnání',
      imageDisplayDropDownInline: 'V souladu',
      imageDisplayDropDownBreak: 'Přestávka',
      tableInsertRowDropDownBefore: 'Vložte řádek před',
      tableInsertRowDropDownAfter: 'Vložte řádek za',
      tableInsertRowDropDownDelete: 'Smazat řádek',
      tableInsertColumnDropDownLeft: 'Vložit sloupec vlevo',
      tableInsertColumnDropDownRight: 'Vložte sloupec vpravo',
      tableInsertColumnDropDownDelete: 'Smazat sloupec',
      tableVerticalAlignDropDownTop: 'Zarovnat nahoru',
      tableVerticalAlignDropDownMiddle: 'Zarovnat střední',
      tableVerticalAlignDropDownBottom: 'Zarovnat dno',
      tableStylesDropDownDashedBorder: 'Přerušované hranice',
      tableStylesDropDownAlternateRows: 'Alternativní řádky',
      pasteFormat: 'Vložit formát',
      pasteFormatContent: 'Vyberte akci formátování',
      plainText: 'Prostý text',
      cleanFormat: 'Čistý',
      keepFormat: 'Držet',
      pasteDialogOk: 'OK',
      pasteDialogCancel: 'zrušení',
    },
    diagram: {
      Copy: 'kopírovat',
      Cut: 'Střih',
      Paste: 'Vložit',
      Undo: 'vrátit',
      Redo: 'Předělat',
      SelectAll: 'Vybrat vše',
      Grouping: 'Seskupení',
      Group: 'Skupina',
      UnGroup: 'Oddělte se',
      Order: 'Objednat',
      BringToFront: 'Přiveďte dopředu',
      MoveForward: 'Pohyb vpřed',
      SendToBack: 'Odeslat nazpátek',
      SendBackward: 'Odeslat zpět',
    },
    DocumentEditor: {
      Table: 'Stůl',
      Row: 'Řádek',
      Cell: 'Buňka',
      Ok: 'OK',
      Cancel: 'zrušení',
      Size: 'Velikost',
      'Preferred Width': 'Upřednostňovaná šířka',
      Points: 'Body',
      Percent: 'Procent',
      'Measure in': 'Měření v',
      Alignment: 'Zarovnání',
      Left: 'Vlevo, odjet',
      Center: 'Centrum',
      Right: 'Že jo',
      Justify: 'Ospravedlnit',
      'Indent from left': 'Odsazení zleva',
      'Borders and Shading': 'Hranice a stínování',
      Options: 'Možnosti',
      'Specify height': 'Určete výšku',
      'At least': 'Alespoň',
      Exactly: 'Přesně',
      'Row height is': 'Výška řádku je',
      'Allow row to break across pages':
        'Povolit, aby se řádek prolomil mezi stránkami',
      'Repeat as header row at the top of each page':
        'Opakujte jako řádek záhlaví v horní části každé stránky',
      'Vertical alignment': 'Vertikální zarovnání',
      Top: 'Horní',
      Bottom: 'Dno',
      'Default cell margins': 'Výchozí okraje buněk',
      'Default cell spacing': 'Výchozí rozestup buněk',
      'Allow spacing between cells': 'Povolit mezery mezi buňkami',
      'Cell margins': 'Okraje buněk',
      'Same as the whole table': 'Stejné jako celá tabulka',
      Borders: 'Hranice',
      None: 'Žádný',
      Style: 'Styl',
      Width: 'Šířka',
      Height: 'Výška',
      Letter: 'Dopis',
      Tabloid: 'Bulvár',
      Legal: 'Právní',
      Statement: 'Tvrzení',
      Executive: 'Výkonný',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Vlastní velikost',
      'Different odd and even': 'Různé liché a sudé',
      'Different first page': 'Různé první stránky',
      'From edge': 'Od okraje',
      Header: 'Záhlaví',
      Footer: 'Zápatí',
      Margin: 'Okraje',
      Paper: 'Papír',
      Layout: 'Rozložení',
      Orientation: 'Orientace',
      Landscape: 'Krajina',
      Portrait: 'Portrét',
      'Table Of Contents': 'Obsah',
      'Show page numbers': 'Zobrazit čísla stránek',
      'Right align page numbers': 'Zarovnat čísla stránek vpravo',
      Nothing: 'Nic',
      'Tab leader': 'Tab leader',
      'Show levels': 'Zobrazit úrovně',
      'Use hyperlinks instead of page numbers':
        'Místo čísel stránek použijte hypertextové odkazy',
      'Build table of contents from': 'Sestavte obsah z',
      Styles: 'Styly',
      'Available styles': 'Dostupné styly',
      'TOC level': 'Úroveň TOC',
      Heading: 'Nadpis',
      'List Paragraph': 'Seznam odstavců',
      Normal: 'Normální',
      'Outline levels': 'Obrysové úrovně',
      'Table entry fields': 'Pole pro zadání tabulky',
      Modify: 'Modifikovat',
      Color: 'Barva',
      Setting: 'Nastavení',
      Box: 'Box',
      All: 'Všechno',
      Custom: 'Zvyk',
      Preview: 'Náhled',
      Shading: 'Stínování',
      Fill: 'Vyplnit',
      'Apply To': 'Platit pro',
      'Table Properties': 'Vlastnosti tabulky',
      'Cell Options': 'Možnosti buňky',
      'Table Options': 'Možnosti tabulky',
      'Insert Table': 'Vložte tabulku',
      'Number of columns': 'Počet sloupců',
      'Number of rows': 'Počet řádků',
      'Text to display': 'Text k zobrazení',
      Address: 'Adresa',
      'Insert Hyperlink': 'Vložit hypertextový odkaz',
      'Edit Hyperlink': 'Upravit hypertextový odkaz',
      Insert: 'Vložit',
      General: 'Všeobecné',
      Indentation: 'Odsazení',
      'Before text': 'Před textem',
      Special: 'Speciální',
      'First line': 'První řada',
      Hanging: 'Závěsný',
      'After text': 'Po textu',
      By: 'Podle',
      Before: 'Před',
      'Line Spacing': 'Řádkování',
      After: 'Po',
      At: 'Na',
      Multiple: 'Násobek',
      Spacing: 'Vzdálenost',
      'Define new Multilevel list': 'Definujte nový víceúrovňový seznam',
      'List level': 'Úroveň seznamu',
      'Choose level to modify': 'Vyberte úroveň, kterou chcete upravit',
      Level: 'Úroveň',
      'Number format': 'Formát čísla',
      'Number style for this level': 'Styl číslování pro tuto úroveň',
      'Enter formatting for number': 'Zadejte formátování čísla',
      'Start at': 'Začátek v',
      'Restart list after': 'Po restartu restartujte seznam',
      Position: 'Pozice',
      'Text indent at': 'Textová odrážka na',
      'Aligned at': 'Zarovnáno na',
      'Follow number with': 'Sledujte číslo pomocí',
      'Tab character': 'Znak tabulátoru',
      Space: 'Prostor',
      Arabic: 'arabština',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'LowLetter',
      Number: 'Číslo',
      'Leading zero': 'Vedoucí nula',
      Bullet: 'Kulka',
      Ordinal: 'Pořadový',
      'Ordinal Text': 'Pořadový text',
      'For East': 'Pro východ',
      'No Restart': 'Žádný restart',
      Font: 'Písmo',
      'Font style': 'Styl fontu',
      'Underline style': 'Podtržení styl',
      'Font color': 'Barva fontu',
      Effects: 'Efekty',
      Strikethrough: 'Přeškrtněte',
      Superscript: 'Horní index',
      Subscript: 'Dolní index',
      'Double strikethrough': 'Dvojité přeškrtnutí',
      Regular: 'Pravidelný',
      Bold: 'tučně',
      Italic: 'Kurzíva',
      Cut: 'Střih',
      Copy: 'kopírovat',
      Paste: 'Vložit',
      Hyperlink: 'Hyperlink',
      'Open Hyperlink': 'Otevřete hypertextový odkaz',
      'Copy Hyperlink': 'Kopírovat hypertextový odkaz',
      'Remove Hyperlink': 'Odebrat hypertextový odkaz',
      Paragraph: 'Odstavec',
      'Merge Cells': 'Spojit buňky',
      'Insert Above': 'Vložit výše',
      'Insert Below': 'Vložit níže',
      'Insert Left': 'Vložit doleva',
      'Insert Right': 'Vložit vpravo',
      Delete: 'Odstranit',
      'Delete Table': 'Smazat tabulku',
      'Delete Row': 'Smazat řádek',
      'Delete Column': 'Smazat sloupec',
      'File Name': 'Název souboru',
      'Format Type': 'Typ formátu',
      Save: 'Uložit',
      Navigation: 'Navigace',
      Results: 'Výsledek',
      Replace: 'Nahradit',
      'Replace All': 'Vyměnit vše',
      'We replaced all': 'Nahradili jsme vše',
      Find: 'Nalézt',
      'No matches': 'Žádné shody',
      'All Done': 'Vše hotovo',
      Result: 'Výsledek',
      of: 'z',
      instances: 'příklady',
      with: 's',
      'Click to follow link': 'Kliknutím přejdete na odkaz',
      'Continue Numbering': 'Pokračujte v číslování',
      'Bookmark name': 'Název záložky',
      Close: 'Zavřít',
      'Restart At': 'Restartovat o',
      Properties: 'Vlastnosti',
      Name: 'název',
      'Style type': 'Typ stylu',
      'Style based on': 'Styl založený na',
      'Style for following paragraph': 'Styl pro následující odstavec',
      Formatting: 'Formátování',
      'Numbering and Bullets': 'Číslování a odrážky',
      Numbering: 'Číslování',
      'Update Field': 'Pole aktualizace',
      'Edit Field': 'Upravit pole',
      Bookmark: 'Záložka do knihy',
      'Page Setup': 'Nastavení stránky',
      'No bookmarks found': 'Nebyly nalezeny žádné záložky',
      'Number format tooltip information':
        'Formát čísla jedné úrovně: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Například kapitola% 1. zobrazí číslování jako </br> Kapitola 1. Položka </br> Kapitola 2. Položka </br> ... </br> Kapitola N. Položka </br> </br> Víceúrovňový formát čísla: </ br > [PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Například% 1.% 2. zobrazí číslování jako </br> 1.1. Položka </br> 1.2. Položka </br>… </br> 1.N. Položka',
      Format: 'Formát',
      'Create New Style': 'Vytvořit nový styl',
      'Modify Style': 'Upravit styl',
      New: 'Nový',
      Bullets: 'Kulky',
      'Use bookmarks': 'Používejte záložky',
      'Table of Contents': 'Obsah',
    },
    barcode: {},
    datamatrix: {},
    qrcode: {},
    drawing: {},
    schedule: {
      day: 'Den',
      week: 'Týden',
      workWeek: 'Pracovní týden',
      month: 'Měsíc',
      agenda: 'Denní program',
      weekAgenda: 'Týdenní agenda',
      workWeekAgenda: 'Agenda pracovního týdne',
      monthAgenda: 'Měsíční agenda',
      today: 'Dnes',
      noEvents: 'Žádné události',
      emptyContainer: 'V tento den nejsou naplánovány žádné události.',
      allDay: 'Celý den',
      start: 'Start',
      end: 'Konec',
      more: 'více',
      close: 'Zavřít',
      cancel: 'zrušení',
      noTitle: '(Bez názvu)',
      delete: 'Odstranit',
      deleteEvent: 'Tato událost',
      deleteMultipleEvent: 'Smazat více událostí',
      selectedItems: 'Vybrané položky',
      deleteSeries: 'Celá řada',
      edit: 'Upravit',
      editSeries: 'Celá řada',
      editEvent: 'Tato událost',
      createEvent: 'Vytvořit',
      subject: 'Předmět',
      addTitle: 'Přidat titulek',
      moreDetails: 'Více informací',
      save: 'Uložit',
      editContent: 'Jak byste chtěli změnit schůzku v seriálu?',
      deleteContent: 'Opravdu chcete tuto událost smazat?',
      deleteMultipleContent: 'Opravdu chcete vybrané události smazat?',
      newEvent: 'Nová událost',
      title: 'Titul',
      location: 'Umístění',
      description: 'Popis',
      timezone: 'Časové pásmo',
      startTimezone: 'Spusťte časové pásmo',
      endTimezone: 'Ukončete časové pásmo',
      repeat: 'Opakovat',
      saveButton: 'Uložit',
      cancelButton: 'zrušení',
      deleteButton: 'Odstranit',
      recurrence: 'Opakování',
      wrongPattern: 'Vzor opakování není platný.',
      seriesChangeAlert:
        'Chcete zrušit změny provedené v konkrétních případech této řady a znovu je porovnat s celou řadou?',
      createError:
        'Trvání události musí být kratší, než jak často se vyskytuje. Zkraťte dobu trvání nebo změňte opakování v editoru událostí opakování.',
      sameDayAlert: 'Ve stejný den nemohou nastat dvě výskyty stejné události.',
      editRecurrence: 'Upravit opakování',
      repeats: 'Opakuje se',
      alert: 'Upozornění',
      startEndError: 'Vybrané datum ukončení nastane před datem zahájení.',
      invalidDateError: 'Zadaná hodnota data je neplatná.',
      blockAlert: 'Události nelze naplánovat v blokovaném časovém období.',
      ok: 'OK',
      yes: 'Ano',
      no: 'Ne',
      occurrence: 'Výskyt',
      series: 'Série',
      previous: 'Předchozí',
      next: 'další',
      timelineDay: 'Den časové osy',
      timelineWeek: 'Časová osa týden',
      timelineWorkWeek: 'Časová osa pracovní týden',
      timelineMonth: 'Časová osa měsíc',
      timelineYear: 'Časová osa Rok',
      editFollowingEvent: 'Následující události',
      deleteTitle: 'Smazat událost',
      editTitle: 'Upravit událost',
      beginFrom: 'Začněte od',
      endAt: 'Konec v',
    },
    recurrenceeditor: {
      none: 'Žádný',
      daily: 'Denně',
      weekly: 'Týdně',
      monthly: 'Měsíční',
      month: 'Měsíc',
      yearly: 'Roční',
      never: 'Nikdy',
      until: 'Dokud',
      count: 'Počet',
      first: 'za prvé',
      second: 'Druhý',
      third: 'Třetí',
      fourth: 'Čtvrtý',
      last: 'Poslední',
      repeat: 'Opakovat',
      repeatEvery: 'Opakujte každý',
      on: 'Opakujte zapnuto',
      end: 'Konec',
      onDay: 'Den',
      days: 'Den (dny)',
      weeks: 'Týden (s)',
      months: 'Měsíce',
      years: 'Rok (y)',
      every: 'každý',
      summaryTimes: 'čas (y)',
      summaryOn: 'na',
      summaryUntil: 'dokud',
      summaryRepeat: 'Opakuje se',
      summaryDay: 'den (dny)',
      summaryWeek: 'týden (s)',
      summaryMonth: 'měsíc (s)',
      summaryYear: 'rok (roky)',
      monthWeek: 'Měsíční týden',
      monthPosition: 'Pozice měsíce',
      monthExpander: 'Expander měsíce',
      yearExpander: 'Rok Expander',
      repeatInterval: 'Interval opakování',
    },
    spreadsheet: {
      InsertingEmptyValue: 'Referenční hodnota není platná.',
      FindValue: 'Najděte hodnotu',
      ReplaceValue: 'Vyměňte hodnotu',
      FindReplaceTooltip: 'Najít a nahradit',
      ByRow: ' Řádky',
      ByColumn: 'Sloupci',
      MatchExactCellElements: 'Porovnejte přesný obsah buněk',
      EntercellAddress: 'Zadejte adresu buňky',
      FindAndReplace: 'Najít a nahradit',
      ReplaceAllEnd: ' zápasy nahrazeny',
      FindNextBtn: 'Najdi další',
      FindPreviousBtn: 'Najít předchozí',
      ReplaceBtn: 'Nahradit',
      ReplaceAllBtn: 'Vyměnit vše',
      GotoHeader: 'Jít do',
      GotoSpecialHeader: 'Přejít na speciální',
      Sheet: 'Prostěradlo',
      SearchWithin: 'Hledejte uvnitř',
      SearchBy: 'Hledat pomocí',
      Reference: 'Odkaz',
      Workbook: 'pracovní sešit',
      NoElements:
        'Nemohli jsme najít, co jste hledali. Kliknutím na možnosti zobrazíte další způsob vyhledávání',
      FindWhat: 'Najít co',
      ReplaceWith: 'Nahraďte jej',
      EnterValue: 'Zadejte hodnotu',
      Cut: 'Střih',
      Copy: 'kopírovat',
      Paste: 'Vložit',
      PasteSpecial: 'Vložit jinak',
      All: 'Všechno',
      Values: 'Hodnoty',
      Formats: 'Formáty',
      Font: 'Písmo',
      FontSize: 'Velikost písma',
      Bold: 'tučně',
      Italic: 'Kurzíva',
      Underline: 'Zdůraznit',
      Strikethrough: 'Přeškrtněte',
      TextColor: 'Barva textu',
      FillColor: 'Vyplň barvu',
      HorizontalAlignment: 'Horizontální zarovnání',
      AlignLeft: 'Zarovnat doleva',
      AlignCenter: 'Centrum',
      AlignRight: 'Zarovnat vpravo',
      VerticalAlignment: 'Vertikální zarovnání',
      AlignTop: 'Zarovnat nahoru',
      AlignMiddle: 'Zarovnat střední',
      AlignBottom: 'Zarovnat dno',
      MergeCells: 'Spojit buňky',
      MergeAll: 'Sloučit vše',
      MergeHorizontally: 'Sloučit vodorovně',
      MergeVertically: 'Sloučit svisle',
      Unmerge: 'Zrušit',
      UnmergeCells: 'Odpojit buňky',
      SelectMergeType: 'Vyberte Sloučit typ',
      MergeCellsAlert:
        'Sloučení buněk zachová pouze hodnotu vlevo nahoře (Nejvyšší). Sloučit přesto?',
      PasteMergeAlert: 'To nemůžeme udělat pro sloučení buňky.',
      Borders: 'Hranice',
      TopBorders: 'Nejlepší hranice',
      LeftBorders: 'Levé okraje',
      RightBorders: 'Pravý okraj',
      BottomBorders: 'Spodní hranice',
      AllBorders: 'Všechny hranice',
      HorizontalBorders: 'Horizontální hranice',
      VerticalBorders: 'Svislé ohraničení',
      OutsideBorders: 'Mimo hranice',
      InsideBorders: 'Vnitřní hranice',
      NoBorders: 'Žádné hranice',
      BorderColor: 'Barva okraje',
      BorderStyle: 'Hraniční styl',
      InsertFunction: 'Funkce vložení',
      Insert: 'Vložit',
      Delete: 'Odstranit',
      Rename: 'Přejmenovat',
      Hide: 'Skrýt',
      Unhide: 'Odkrýt',
      NameBox: 'Jmenovka',
      ShowHeaders: 'Zobrazit záhlaví',
      HideHeaders: 'Skrýt záhlaví',
      ShowGridLines: 'Zobrazit mřížky',
      HideGridLines: 'Skrýt mřížky',
      AddSheet: 'Přidat list',
      ListAllSheets: 'Seznam všech listů',
      FullScreen: 'Celá obrazovka',
      CollapseToolbar: 'Sbalit panel nástrojů',
      ExpandToolbar: 'Rozbalte panel nástrojů',
      CollapseFormulaBar: 'Sbalit vzorec',
      ExpandFormulaBar: 'Rozbalte lištu vzorců',
      File: 'Soubor',
      Home: 'Domov',
      Formulas: 'Vzorce',
      View: 'Pohled',
      New: 'Nový',
      Open: 'otevřeno',
      SaveAs: 'Uložit jako',
      ExcelXlsx: 'Microsoft Excel',
      ExcelXls: 'Microsoft Excel 97-2003',
      CSV: 'Hodnoty oddělené čárkami',
      FormulaBar: 'Vzorec Bar',
      Sort: 'Seřadit',
      SortAscending: 'Vzestupně',
      SortDescending: 'Klesající',
      CustomSort: 'Vlastní řazení',
      AddColumn: 'Přidat sloupec',
      ContainsHeader: 'Data obsahují záhlaví',
      CaseSensitive: 'Rozeznává velká a malá písmena',
      SortBy: 'Seřazeno podle',
      ThenBy: 'Pak do',
      SelectAColumn: 'Vyberte sloupec',
      SortEmptyFieldError:
        'Všechna kritéria řazení musí mít specifikovaný sloupec. Zkontrolujte vybraná kritéria řazení a akci opakujte.',
      SortDuplicateFieldError:
        ' je tříděn podle hodnot více než jednou. Odstraňte duplicitní kritéria řazení a akci opakujte.',
      SortOutOfRangeError:
        'Vyberte buňku nebo rozsah uvnitř použitého rozsahu a akci opakujte.',
      HideRow: 'Skrýt řádek',
      HideRows: 'Skrýt řádky',
      UnHideRows: 'Odkrýt řádky',
      HideColumn: 'Skrýt sloupec',
      HideColumns: 'Skrýt sloupce',
      UnHideColumns: 'Odkrýt sloupce',
      InsertRow: 'Vložit řádek',
      InsertRows: 'Vložit řádky',
      Above: 'Výše',
      Below: 'Níže',
      InsertColumn: 'Vložit sloupec',
      InsertColumns: 'Vložit sloupce',
      Before: 'Před',
      After: 'Po',
      DeleteRow: 'Smazat řádek',
      DeleteRows: 'Odstranit řádky',
      DeleteColumn: 'Smazat sloupec',
      DeleteColumns: 'Odstranit sloupce',
      Ok: 'OK',
      Close: 'Zavřít',
      Cancel: 'zrušení',
      Apply: 'Aplikovat',
      MoreColors: 'Více barev',
      StandardColors: 'Standardní barvy',
      General: 'Všeobecné',
      Number: 'Číslo',
      Currency: 'Měna',
      Accounting: 'Účetnictví',
      ShortDate: 'Krátké datum',
      LongDate: 'Dlouhé datum',
      Time: 'Čas',
      Percentage: 'Procento',
      Fraction: 'Zlomek',
      Scientific: 'Vědecký',
      Text: 'Text',
      NumberFormat: 'Formát čísla',
      MobileFormulaBarPlaceHolder: 'Zadejte hodnotu nebo vzorec',
      PasteAlert:
        'Toto nelze vložit zde, protože oblast kopírování a oblast vložení nejsou ve stejné velikosti. Zkuste vložit do jiného rozsahu.',
      DestroyAlert:
        'Opravdu chcete zničit aktuální sešit bez uložení a vytvoření nového sešitu?',
      SheetRenameInvalidAlert: 'Název listu obsahuje neplatný znak.',
      SheetRenameEmptyAlert: 'Název listu nesmí být prázdný.',
      SheetRenameAlreadyExistsAlert:
        'Název listu již existuje. Zadejte jiné jméno.',
      DeleteSheetAlert: 'Opravdu chcete tento list odstranit?',
      DeleteSingleLastSheetAlert:
        'Sešit musí obsahovat alespoň jeden viditelný list.',
      PickACategory: 'Vyberte kategorii',
      Description: 'Popis',
      UnsupportedFile: 'Nepodporovaný soubor',
      InvalidUrl: 'neplatná URL',
      SUM: 'Přidá řadu čísel a / nebo buněk.',
      SUMIF: 'Přidá buňky na základě zadané podmínky.',
      SUMIFS: 'Přidá buňky na základě zadaných podmínek.',
      ABS: 'Vrátí hodnotu čísla bez jeho znaménka.',
      RAND: 'Vrací náhodné číslo mezi 0 a 1.',
      RANDBETWEEN: 'Vrátí náhodné celé číslo na základě zadaných hodnot.',
      FLOOR: 'Zaokrouhlí číslo na nejbližší násobek daného faktoru.',
      CEILING: 'Zaokrouhlí číslo na nejbližší násobek daného faktoru.',
      PRODUCT: 'Násobí řadu čísel a / nebo buněk.',
      AVERAGE: 'Vypočítá průměr pro řadu čísel a / nebo buněk bez textu.',
      AVERAGEIF: 'Vypočítá průměr buněk na základě zadaného kritéria.',
      AVERAGEIFS: 'Vypočítá průměr buněk na základě zadaných podmínek.',
      AVERAGEA:
        'Vypočítá průměr buněk hodnotících PRAVDA jako 1, text a FALSE jako 0.',
      COUNT: 'Počítá buňky, které obsahují číselné hodnoty v rozsahu.',
      COUNTIF: 'Počítá buňky na základě zadaných podmínek.',
      COUNTIFS: 'Počítá buňky na základě zadaných podmínek.',
      COUNTA: 'Počítá buňky, které obsahují hodnoty v rozsahu.',
      MIN: 'Vrací nejmenší počet zadaných argumentů.',
      MAX: 'Vrací největší počet zadaných argumentů.',
      DATE: 'Vrátí datum podle daného roku, měsíce a dne.',
      DAY: 'Vrátí den od daného data.',
      DAYS: 'Vrátí počet dní mezi dvěma daty.',
      IF: 'Vrací hodnotu na základě daného výrazu.',
      IFS: 'Vrací hodnotu na základě daných více výrazů.',
      CalculateAND:
        'Vrací TRUE, pokud jsou všechny argumenty TRUE, jinak vrací FALSE.',
      CalculateOR:
        'Vrací TRUE, pokud jsou některé z argumentů TRUE, jinak vrací FALSE.',
      IFERROR:
        'Vrací hodnotu, pokud nebyla nalezena žádná chyba, vrátí zadanou hodnotu.',
      CHOOSE: 'Vrátí hodnotu ze seznamu hodnot na základě čísla indexu.',
      INDEX:
        'Vrátí hodnotu buňky v daném rozsahu na základě čísla řádku a sloupce.',
      FIND:
        'Vrací pozici řetězce v jiném řetězci, který rozlišuje velká a malá písmena',
      CONCATENATE: 'Kombinuje dva nebo více řetězců dohromady.',
      CONCAT: 'Zřetězí seznam nebo řadu textových řetězců.',
      SUBTOTAL: 'Vrací mezisoučet rozsahu pomocí daného čísla funkce.',
      RADIANS: 'Převádí stupně na radiány.',
      MATCH: 'Vrací relativní polohu zadané hodnoty v daném rozsahu.',
      SLOPE: 'Vrátí sklon čáry z lineární regrese datových bodů.',
      INTERCEPT: 'Vypočítá bod přímky Y pomocí lineární regrese.',
      DefineNameExists: 'Toto jméno již existuje, zkuste jiné jméno.',
      CircularReference:
        'Pokud vzorec odkazuje na jednu nebo více kruhových odkazů, může to vést k nesprávnému výpočtu.',
      ShowRowsWhere: 'Zobrazit řádky, kde:',
      OR: 'NEBO',
      AND: 'A',
      CustomFilterDatePlaceHolder: 'Vyberte datum',
      CustomFilterPlaceHolder: 'Zadejte hodnotu',
      CustomFilter: 'Vlastní filtr',
      Between: 'Mezi',
      MatchCase: 'Shodný případ',
      DateTimeFilter: 'Filtry DateTime',
      Undo: 'vrátit',
      Redo: 'Předělat',
      DateFilter: 'Filtry data',
      TextFilter: 'Textové filtry',
      NumberFilter: 'Filtry čísel',
      ClearFilter: 'Vymazat filtr',
      NoResult: 'Nebyly nalezeny žádné zápasy',
      FilterFalse: 'Nepravdivé',
      FilterTrue: 'Skutečný',
      Blanks: 'Blanks',
      SelectAll: 'Vybrat vše',
      GreaterThanOrEqual: 'Větší než stejné',
      GreaterThan: 'Větší než',
      LessThanOrEqual: 'Méně než stejné nebo stejné',
      LessThan: 'Méně než',
      NotEqual: 'Ne rovné',
      Equal: 'Rovnat se',
      Contains: 'Obsahuje',
      EndsWith: 'Končí s',
      StartsWith: 'Začíná s',
      ClearButton: 'Vymazat',
      FilterButton: 'Filtr',
      CancelButton: 'zrušení',
      OKButton: 'OK',
      Search: 'Vyhledávání',
      DataValidation: 'Ověření dat',
      CLEARALL: 'VYMAZAT VŠE',
      APPLY: 'APLIKOVAT',
      CellRange: 'Rozsah buněk',
      Allow: 'Dovolit',
      Data: 'Data',
      Minimum: 'Minimální',
      Maximum: 'Maximum',
      IgnoreBlank: 'Ignorovat prázdné',
      WholeNumber: 'Celé číslo',
      Decimal: 'Desetinný',
      Date: 'datum',
      TextLength: 'Délka textu',
      List: 'Seznam',
      NotBetween: 'Ne mezi',
      EqualTo: 'Rovná',
      NotEqualTo: 'Nerovná se',
      Greaterthan: 'Větší než',
      Lessthan: 'Méně než',
      GreaterThanOrEqualTo: 'Větší nebo rovno',
      LessThanOrEqualTo: 'Méně než nebo rovno',
      InCellDropDown: 'Rozbalovací nabídka v buňce',
      Sources: 'Zdroje',
      Value: 'Hodnota',
      Retry: 'Opakujte akci',
      DialogError:
        'Zdrojem seznamu musí být odkaz na jeden řádek nebo sloupec.',
      ValidationError:
        'Tato hodnota neodpovídá omezením ověření dat definovaným pro buňku.',
      ListLengthError: 'Hodnoty seznamu umožňují pouze 256 znaků',
      ProtectSheet: 'Chraňte list',
      UnprotectSheet: 'Odemknout list',
      SelectCells: 'Vyberte buňky',
      FormatCells: 'Formátování buněk',
      FormatRows: 'Formátování řádků',
      'Format Columns': 'Formátovat sloupce',
      InsertLinks: 'Vložte odkazy',
      ProtectContent: 'Chraňte obsah uzamčených buněk',
      ProtectAllowUser: 'Povolit všem uživatelům tohoto listu:',
      EditAlert:
        'Buňka, kterou se pokoušíte změnit, je chráněna. Chcete-li provést změnu, nechráňte list.',
      ClearValidation: 'Vymazat ověření',
      ISNUMBER:
        'Vrací hodnotu true, když je hodnota analyzována jako číselná hodnota.',
      Round: 'Zaokrouhlí číslo na zadaný počet číslic.',
      GEOMEAN: 'Vrací geometrický průměr pole nebo rozsahu pozitivních dat.',
      POWER: 'Vrátí výsledek čísla zvýšeného k napájení',
      LOG: 'Vrátí logaritmus čísla na základnu, kterou určíte.',
      TRUNC: 'Vrátí zkrácenou hodnotu čísla na zadaný počet desetinných míst.',
      EXP: 'Vrací e zvýšené na sílu daného čísla.',
      SelectBorderOption: 'Vyberte možnost ohraničení',
    },
    pdfviewer: {
      PdfViewer: 'Prohlížeč PDF',
      Cancel: 'zrušení',
      'Download file': 'Stáhnout soubor',
      Download: 'Stažení',
      'Enter Password':
        'Tento dokument je chráněn heslem. Prosím, vložte heslo.',
      'File Corrupted': 'Soubor byl poškozen',
      'File Corrupted Content': 'Soubor je poškozen a nelze jej otevřít.',
      'Fit Page': 'Přizpůsobit stránku',
      'Fit Width': 'Přizpůsobit šířku',
      Automatic: 'Automatický',
      'Go To First Page': 'Zobrazit první stránku',
      'Invalid Password': 'Nesprávné heslo. Prosím zkuste to znovu.',
      'Next Page': 'Zobrazit další stránku',
      OK: 'OK',
      Open: 'Otevřít soubor',
      'Page Number': 'Aktuální číslo stránky',
      'Previous Page': 'Zobrazit předchozí stránku',
      'Go To Last Page': 'Zobrazit poslední stránku',
      Zoom: 'Zvětšení',
      'Zoom In': 'Přiblížit',
      'Zoom Out': 'Oddálit',
      'Page Thumbnails': 'Miniatury stránky',
      Bookmarks: 'Záložky',
      Print: 'Tisk souboru',
      'Password Protected': 'Vyžadováno heslo',
      Copy: 'kopírovat',
      'Text Selection': 'Nástroj pro výběr textu',
      Panning: 'Režim Pan',
      'Text Search': 'Najděte text',
      'Find in document': 'Najít v dokumentu',
      'Match case': 'Shodný případ',
      Apply: 'Aplikovat',
      GoToPage: 'Jdi na stránku',
      'No matches':
        'Prohlížeč dokončil prohledávání dokumentu. Nebyly nalezeny žádné další zápasy',
      'No Text Found': 'Nebyl nalezen žádný text',
      Undo: 'vrátit',
      Redo: 'Předělat',
      Annotation: 'Přidat nebo upravit anotace',
      Highlight: 'Zvýraznit text',
      Underline: 'Podtržení textu',
      Strikethrough: 'Přeškrtnutý text',
      Delete: 'Odstranit anotaci',
      Opacity: 'Neprůhlednost',
      'Color edit': 'Změnit barvu',
      'Opacity edit': 'Změňte krytí',
      'Highlight context': 'Zvýraznit',
      'Underline context': 'Zdůraznit',
      'Strikethrough context': 'Projděte se',
      'Server error':
        'Webová služba neposlouchá. Prohlížeč PDF závisí na všech jeho funkcích na webové službě. Chcete-li pokračovat, spusťte webovou službu.',
      'Open text': 'otevřeno',
      'First text': 'První strana',
      'Previous text': 'Předchozí stránka',
      'Next text': 'Další strana',
      'Last text': 'Poslední strana',
      'Zoom in text': 'Přiblížit',
      'Zoom out text': 'Oddálit',
      'Selection text': 'Výběr',
      'Pan text': 'Pánev',
      'Print text': 'Tisk',
      'Search text': 'Vyhledávání',
      'Annotation Edit text': 'Upravit anotaci',
      'Line Thickness': 'Tloušťka čáry',
      'Line Properties': 'Vlastnosti čáry',
      'Start Arrow': 'Spusťte šipku',
      'End Arrow': 'Konec šipky',
      'Line Style': 'Styl čáry',
      'Fill Color': 'Vyplň barvu',
      'Line Color': 'Barva čáry',
      None: 'Žádný',
      'Open Arrow': 'otevřeno',
      'Closed Arrow': 'Zavřeno',
      'Round Arrow': 'Kolo',
      'Square Arrow': 'Náměstí',
      'Diamond Arrow': 'diamant',
      Cut: 'Střih',
      Paste: 'Vložit',
      'Delete Context': 'Odstranit',
      Properties: 'Vlastnosti',
      'Add Stamp': 'Přidat razítko',
      'Add Shapes': 'Přidat tvary',
      'Stroke edit': 'Změnit barvu tahu',
      'Change thickness': 'Změňte tloušťku okraje',
      'Add line': 'Přidat řádek',
      'Add arrow': 'Přidat šipku',
      'Add rectangle': 'Přidat obdélník',
      'Add circle': 'Přidat kruh',
      'Add polygon': 'Přidejte polygon',
      'Add Comments': 'Přidat komentáře',
      Comments: 'Komentáře',
      'No Comments Yet': 'Zatím žádné komentáře',
      Accepted: 'Přijato',
      Completed: 'Dokončeno',
      Cancelled: 'Zrušeno',
      Rejected: 'Odmítnuto',
      'Leader Length': 'Délka vůdce',
      'Scale Ratio': 'Měřítko',
      Calibrate: 'Kalibrovat',
      'Calibrate Distance': 'Kalibrace vzdálenosti',
      'Calibrate Perimeter': 'Kalibrace obvodu',
      'Calibrate Area': 'Kalibrace oblasti',
      'Calibrate Radius': 'Kalibrujte poloměr',
      'Calibrate Volume': 'Kalibrace hlasitosti',
    },
    querybuilder: {
      StartsWith: 'Začíná s',
      EndsWith: 'Končí s',
      Contains: 'Obsahuje',
      Equal: 'Rovnat se',
      NotEqual: 'Ne rovné',
      LessThan: 'Méně než',
      LessThanOrEqual: 'Méně než stejné nebo stejné',
      GreaterThan: 'Větší než',
      GreaterThanOrEqual: 'Větší než stejné',
      Between: 'Mezi',
      NotBetween: 'Ne mezi',
      Empty: 'Prázdný',
      NotEmpty: 'Není prázdný',
      In: 'v',
      NotIn: 'Ne v',
      NotContains: 'Neobsahuje',
      Remove: 'ODSTRANIT',
      SelectField: 'Vyberte pole',
      SelectOperator: 'Vyberte operátora',
      DeleteRule: 'Odstraňte tuto podmínku',
      DeleteGroup: 'Smazat skupinu',
      AddGroup: 'Přidat skupinu',
      AddCondition: 'Přidat podmínku',
      Edit: 'UPRAVIT',
      ValidationMessage: 'Toto pole je povinné',
      SummaryViewTitle: 'Souhrnné zobrazení',
      OtherFields: 'Další pole',
      AND: 'A',
      OR: 'NEBO',
      SelectValue: 'Zadejte hodnotu',
    },
    grid: {
      EmptyRecord: 'žádné záznamy k nahlédnutí',
      True: 'Ano',
      False: 'Ne',
      InvalidFilterMessage: 'Neplatná data filtru',
      GroupDropArea: 'Přetažením záhlaví sloupce zde seskupíte sloupec',
      UnGroup: 'Klepnutím sem zrušíte seskupení',
      GroupDisable: 'Seskupování je v tomto sloupci zakázáno',
      FilterbarTitle: 'buňka filtru',
      EmptyDataSourceError:
        'DataSource nesmí být prázdný při prvotním načtení, protože sloupce jsou generovány z dataSource v AutoGenerate Column Grid',
      Add: 'Přidat',
      Edit: 'Upravit',
      Cancel: 'Zrušit',
      Update: 'Aktualizace',
      Delete: 'Odstranit',
      Print: 'Tisk',
      Pdfexport: 'Export do PDF',
      Excelexport: 'Export do Excelu',
      Wordexport: 'Word export',
      Csvexport: 'Export CSV',
      Search: 'Vyhledávání',
      Columnchooser: 'Sloupce',
      Save: 'Uložit',
      Item: 'položka',
      Items: 'položek',
      EditOperationAlert: 'Pro operace úprav nebyly vybrány žádné záznamy',
      DeleteOperationAlert:
        'Pro operaci odstranění nebyly vybrány žádné záznamy',
      SaveButton: 'Uložit',
      OKButton: 'OK',
      CancelButton: 'Zrušit',
      EditFormTitle: 'Detaily',
      AddFormTitle: 'Přidat nový záznam',
      BatchSaveConfirm: 'Opravdu chcete uložit změny?',
      BatchSaveLostChanges:
        'Neuložené změny budou ztraceny. Jste si jistý, že chcete pokračovat?',
      ConfirmDelete: 'Opravdu chcete smazat záznam?',
      CancelEdit: 'Opravdu chcete změny zrušit?',
      ChooseColumns: 'Zvolte sloupec',
      SearchColumns: 'vyhledávací sloupce',
      Matchs: 'Nebyly nalezeny žádné zápasy',
      FilterButton: 'Filtr',
      ClearButton: 'Vymazat',
      StartsWith: 'Začíná s',
      EndsWith: 'Končí s',
      Contains: 'Obsahuje',
      Equal: 'Rovnat se',
      NotEqual: 'Ne rovné',
      LessThan: 'Méně než',
      LessThanOrEqual: 'Méně než stejné nebo stejné',
      GreaterThan: 'Větší než',
      GreaterThanOrEqual: 'Větší než stejné',
      ChooseDate: 'Vyberte datum',
      EnterValue: 'Zadejte hodnotu',
      Copy: 'kopírovat',
      Group: 'Seskupit podle tohoto sloupce',
      Ungroup: 'Oddělte se podle tohoto sloupce',
      autoFitAll: 'Automaticky přizpůsobit všechny sloupce',
      autoFit: 'Automaticky přizpůsobit tento sloupec',
      Export: 'Vývozní',
      FirstPage: 'První strana',
      LastPage: 'Poslední strana',
      PreviousPage: 'Předchozí stránka',
      NextPage: 'Další strana',
      SortAscending: 'Seřadit vzestupně',
      SortDescending: 'Seřadit sestupně',
      EditRecord: 'Upravit záznam',
      DeleteRecord: 'Smazat záznam',
      FilterMenu: 'Filtr',
      SelectAll: 'Vybrat vše',
      Blanks: 'Blanks',
      FilterTrue: 'Skutečný',
      FilterFalse: 'Nepravdivé',
      NoResult: 'Nebyly nalezeny žádné zápasy',
      ClearFilter: 'Vymazat filtr',
      NumberFilter: 'Filtry čísel',
      TextFilter: 'Textové filtry',
      DateFilter: 'Filtry data',
      DateTimeFilter: 'Filtry DateTime',
      MatchCase: 'Shodný případ',
      Between: 'Mezi',
      CustomFilter: 'Vlastní filtr',
      CustomFilterPlaceHolder: 'Zadejte hodnotu',
      CustomFilterDatePlaceHolder: 'Vyberte datum',
      AND: 'A',
      OR: 'NEBO',
      ShowRowsWhere: 'Zobrazit řádky, kde:',
    },
    pager: {
      currentPageInfo: '{0} z {1} stránek',
      totalItemsInfo: '({0} položek)',
      totalItemInfo: '({0} položka)',
      firstPageTooltip: 'Přejít na první stránku',
      lastPageTooltip: 'Přejít na poslední stránku',
      nextPageTooltip: 'Přejít na další stránku',
      previousPageTooltip: 'Přejít na předchozí stránku',
      nextPagerTooltip: 'Přejít na další stránku',
      previousPagerTooltip: 'Přejít na předchozí stránku',
      pagerDropDown: 'Položek na stránce',
      pagerAllDropDown: 'Položky',
      All: 'Všechno',
    },
    calendar: {
      today: 'Dnes',
    },
    datepicker: {
      today: 'Dnes',
      placeholder: 'Vyberte datum',
    },
    daterangepicker: {
      placeholder: 'Vyberte časové období',
      startLabel: 'Datum zahájení',
      endLabel: 'Datum ukončení',
      applyText: 'Aplikovat',
      cancelText: 'zrušení',
      selectedDays: 'Vybrané dny',
      days: 'Dny',
      customRange: 'Vlastní rozsah',
    },
    timepicker: {
      placeholder: 'Vyberte si čas',
    },
    datetimepicker: {
      today: 'Dnes',
      placeholder: 'Vyberte datum a čas',
    },
    gantt: {
      emptyRecord: 'žádné záznamy k nahlédnutí',
      id: 'ID',
      name: 'název',
      startDate: 'Datum zahájení',
      endDate: 'Datum ukončení',
      duration: 'Doba trvání',
      progress: 'Pokrok',
      dependency: 'Závislost',
      notes: 'Poznámky',
      baselineStartDate: 'Základní datum zahájení',
      baselineEndDate: 'Základní datum ukončení',
      taskMode: 'Režim úloh',
      changeScheduleMode: 'Změnit režim plánování',
      subTasksStartDate: 'Datum zahájení dílčího úkolu',
      subTasksEndDate: 'Datum ukončení dílčích titulů',
      scheduleStartDate: 'Datum zahájení plánu',
      scheduleEndDate: 'Datum ukončení plánu',
      auto: 'Auto',
      manual: 'Manuál',
      type: 'Typ',
      offset: 'Offset',
      resourceName: 'Zdroje',
      resourceID: 'ID zdroje',
      day: 'den',
      hour: 'hodina',
      minute: 'minuta',
      days: 'dny',
      hours: 'hodin',
      minutes: 'minut',
      generalTab: 'Všeobecné',
      customTab: 'Vlastní sloupce',
      writeNotes: 'Zápis poznámek',
      addDialogTitle: 'Nová úloha',
      editDialogTitle: 'Informace o úkolu',
      saveButton: 'Uložit',
      add: 'Přidat',
      edit: 'Upravit',
      update: 'Aktualizace',
      delete: 'Odstranit',
      cancel: 'zrušení',
      search: 'Vyhledávání',
      task: ' úkol',
      tasks: ' úkoly',
      zoomIn: 'Přiblížit',
      zoomOut: 'Oddálit',
      zoomToFit: 'Přiblížit, aby se vešly',
      excelExport: 'Excel export',
      csvExport: 'Export CSV',
      expandAll: 'Rozšířit vše',
      collapseAll: 'Sbalit vše',
      nextTimeSpan: 'Další čas',
      prevTimeSpan: 'Předchozí časový rozvrh',
      okText: 'OK',
      confirmDelete: 'Opravdu chcete smazat záznam?',
      from: 'Z',
      to: 'Na',
      taskLink: 'Úkol Odkaz',
      lag: 'Zpoždění',
      start: 'Start',
      finish: 'Dokončit',
      enterValue: 'Zadejte hodnotu',
      taskBeforePredecessor_FS:
        "Přesunuli jste '{0}', abyste mohli začít před dokončením '{1}' a tyto dvě úkoly jsou propojeny. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskAfterPredecessor_FS:
        "Přesunuli jste '{0}' z '{1}' a oba úkoly jsou propojeny. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskBeforePredecessor_SS:
        "Přesunuli jste '{0}', abyste začali před spuštěním '{1}', a tyto dvě úkoly jsou propojeny. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskAfterPredecessor_SS:
        "Po spuštění '{1}' jste přesunuli '{0}' a začali jste oba úkoly spojené. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskBeforePredecessor_FF:
        "Přesunuli jste '{0}' před dokončením '{1}' a obě úkoly jsou propojeny. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskAfterPredecessor_FF:
        "Po dokončení {1} jste přesunuli '{0}' a dokončili jste oba úkoly. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskBeforePredecessor_SF:
        "Přesunuli jste '{0}' z '{1}' na začátek a oba úkoly jsou propojeny. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskAfterPredecessor_SF:
        "Po spuštění {1} jste přesunuli '{0}' a dokončili jste oba úkoly. V důsledku toho nelze odkazy ctít. Vyberte jednu akci níže",
      taskInformation: 'Informace o úkolu',
      deleteTask: 'Smazat úlohu',
      deleteDependency: 'Odstranit závislost',
      convert: 'Konvertovat',
      save: 'Uložit',
      above: 'Výše',
      below: 'Níže',
      child: 'Dítě',
      milestone: 'Milník',
      toTask: 'Úkol',
      toMilestone: 'Do milníku',
      eventMarkers: 'Značky událostí',
      leftTaskLabel: 'Štítek levého úkolu',
      rightTaskLabel: 'Správný štítek úlohy',
      timelineCell: 'Buňka časové osy',
      confirmPredecessorDelete:
        'Are you sure you want to remove dependency link?',
      unit: 'Unit',
      work: 'Work',
      taskType: 'Task Type',
      unassignedTask: 'Unassigned Task',
      group: 'Group',
      indent: 'Odrážka',
      outdent: 'Outdent',
    },
    dropdowns: {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
      overflowCountTemplate: '+${count} další ..',
      selectAllText: 'Vybrat vše',
      unSelectAllText: 'Odznačit vše',
      totalCountTemplate: 'Vybráno ${count}',
    },
    'drop-down-list': {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
    },
    'combo-box': {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
    },
    'auto-complete': {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
    },
    'multi-select': {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
      overflowCountTemplate: '+${count} další ..',
      selectAllText: 'Vybrat vše',
      unSelectAllText: 'Odznačit vše',
      totalCountTemplate: 'Vybráno ${count}',
    },
    listbox: {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
      selectAllText: 'Vybrat vše',
      unSelectAllText: 'Odznačit vše',
      moveUp: 'Posunout nahoru',
      moveDown: 'Posunout dolů',
      moveTo: 'Přesunout do',
      moveFrom: 'Odstěhovat se',
      moveAllTo: 'Přesunout vše do',
      moveAllFrom: 'Přesunout vše od',
    },
    dialog: {
      close: 'Zavřít',
    },
    'rich-text-editor': {
      alignments: 'zarovnání',
      justifyleft: 'ospravedlnit doleva',
      justifycenter: 'justifyCenter',
      justifyright: 'justifyRight',
      justifyfull: 'justifyFull',
      fontname: 'fontName',
      fontsize: 'velikost písma',
      fontcolor: 'barva fontu',
      backgroundcolor: 'barva pozadí',
      clearformat: 'clearFormat',
      clearall: 'vymazat vše',
      unorderedlist: 'unorderedList',
      orderedlist: 'orderList',
      createlink: 'createLink',
      openlink: 'otevřít odkaz',
      editlink: 'editLink',
      removelink: 'removeLink',
      openimagelink: 'otevřít odkaz',
      editimagelink: 'editLink',
      removeimagelink: 'removeLink',
      image: 'obraz',
      replace: 'nahradit',
      align: 'zarovnat',
      caption: 'titulek',
      remove: 'odstranit',
      insertlink: 'insertLink',
      display: 'Zobrazit',
      alttext: 'altText',
      dimension: 'dimenze',
      fullscreen: 'celá obrazovka',
      maximize: 'maximalizovat',
      minimize: 'minimalizovat',
      lowercase: 'LowerCase',
      uppercase: 'upperCase',
      print: 'tisk',
      formats: 'formáty',
      sourcecode: 'zdrojový kód',
      preview: 'náhled',
      viewside: 'výhled',
      insertcode: 'vložte kód',
      justifyLeft: 'Zarovnat doleva',
      justifyCenter: 'Zarovnat centrum',
      justifyRight: 'Zarovnat vpravo',
      justifyFull: 'Zarovnat zarovnání',
      fontName: 'Název písma',
      fontSize: 'Velikost písma',
      fontColor: 'Barva fontu',
      backgroundColor: 'Barva pozadí',
      bold: 'tučně',
      italic: 'kurzíva',
      underline: 'zdůraznit',
      strikethrough: 'přeškrtnuto',
      cut: 'střih',
      copy: 'kopírovat',
      paste: 'vložit',
      indent: 'Odrážka',
      outdent: 'outdent',
      undo: 'vrátit',
      redo: 'předělat',
      superscript: 'horní index',
      subscript: 'index',
      createLink: 'Vložit hypertextový odkaz',
      openLink: 'Otevřít odkaz',
      editLink: 'Upravit odkaz',
      removeLink: 'Odebrat odkaz',
      insertLink: 'Vložit odkaz',
      altText: 'Alternativní text',
      lowerCase: 'Malá písmena',
      upperCase: 'Velká písmena',
      insertCode: 'Vložte kód',
      linkText: 'Zobrazit text',
      linkTooltipLabel: 'Popis',
      linkWebUrl: 'Webová adresa',
      linkurl: 'http://example.com',
      linkOpenInNewWindow: 'Otevřete odkaz v novém okně',
      linkHeader: 'Vložit odkaz',
      dialogInsert: 'Vložit',
      dialogCancel: 'zrušení',
      dialogUpdate: 'Aktualizace',
      imageHeader: 'Vložte obrázek',
      imageLinkHeader: 'Můžete také poskytnout odkaz z webu',
      mdimageLink: 'Zadejte prosím adresu URL svého obrázku',
      imageUploadMessage: 'Sem přetáhněte obrázek nebo jej nahrajte',
      imageDeviceUploadMessage: 'Klepnutím sem nahrajte',
      imageAlternateText: 'Alternativní text',
      alternateHeader: 'Alternativní text',
      browse: 'Procházet',
      imageUrl: 'http://example.com/image.png',
      imageCaption: 'Titulek',
      imageSizeHeader: 'Velikost obrázku',
      imageHeight: 'Výška',
      imageWidth: 'Šířka',
      textPlaceholder: 'Zadejte text',
    },
    'inplace-editor': {
      editIcon: 'Klepnutím upravte',
      save: 'Uložit',
      cancel: 'zrušení',
    },
    chart: {
      Zoom: 'Zvětšení',
      ZoomIn: 'Přiblížit',
      ZoomOut: 'Oddálit',
      Reset: 'Resetovat',
      Pan: 'Pánev',
      ResetZoom: 'Reset Zoom',
    },
    'drop-down-base': {
      noRecordsTemplate: 'Nenalezeny žádné záznamy',
      actionFailureTemplate: 'Žádost selhala',
    },
    maps: {
      Zoom: 'Zvětšení',
      ZoomIn: 'Přiblížit',
      ZoomOut: 'Oddálit',
      Reset: 'Resetovat',
      Pan: 'Pánev',
      ResetZoom: 'Reset Zoom',
    },
    PdfViewer: {
      PdfViewer: 'Prohlížeč PDF',
      Cancel: 'zrušení',
      'Download file': 'Stáhnout soubor',
      Download: 'Stažení',
      'Enter Password':
        'Tento dokument je chráněn heslem. Prosím, vložte heslo.',
      'File Corrupted': 'Soubor byl poškozen',
      'File Corrupted Content': 'Soubor je poškozen a nelze jej otevřít.',
      'Fit Page': 'Přizpůsobit stránku',
      'Fit Width': 'Přizpůsobit šířku',
      Automatic: 'Automatický',
      'Go To First Page': 'Zobrazit první stránku',
      'Invalid Password': 'Nesprávné heslo. Prosím zkuste to znovu.',
      'Next Page': 'Zobrazit další stránku',
      OK: 'OK',
      Open: 'Otevřít soubor',
      'Page Number': 'Aktuální číslo stránky',
      'Previous Page': 'Zobrazit předchozí stránku',
      'Go To Last Page': 'Zobrazit poslední stránku',
      Zoom: 'Zvětšení',
      'Zoom In': 'Přiblížit',
      'Zoom Out': 'Oddálit',
      'Page Thumbnails': 'Miniatury stránky',
      Bookmarks: 'Záložky',
      Print: 'Tisk souboru',
      'Password Protected': 'Vyžadováno heslo',
      Copy: 'kopírovat',
      'Text Selection': 'Nástroj pro výběr textu',
      Panning: 'Režim Pan',
      'Text Search': 'Najděte text',
      'Find in document': 'Najít v dokumentu',
      'Match case': 'Shodný případ',
      Apply: 'Aplikovat',
      GoToPage: 'Jdi na stránku',
      'No matches':
        'Prohlížeč dokončil prohledávání dokumentu. Nebyly nalezeny žádné další zápasy',
      'No Text Found': 'Nebyl nalezen žádný text',
      Undo: 'vrátit',
      Redo: 'Předělat',
      Annotation: 'Přidat nebo upravit anotace',
      Highlight: 'Zvýraznit text',
      Underline: 'Podtržení textu',
      Strikethrough: 'Přeškrtnutý text',
      Delete: 'Odstranit anotaci',
      Opacity: 'Neprůhlednost',
      'Color edit': 'Změnit barvu',
      'Opacity edit': 'Změňte krytí',
      'Highlight context': 'Zvýraznit',
      'Underline context': 'Zdůraznit',
      'Strikethrough context': 'Projděte se',
      'Server error':
        'Webová služba neposlouchá. Prohlížeč PDF závisí na všech jeho funkcích na webové službě. Chcete-li pokračovat, spusťte webovou službu.',
      'Open text': 'otevřeno',
      'First text': 'První strana',
      'Previous text': 'Předchozí stránka',
      'Next text': 'Další strana',
      'Last text': 'Poslední strana',
      'Zoom in text': 'Přiblížit',
      'Zoom out text': 'Oddálit',
      'Selection text': 'Výběr',
      'Pan text': 'Pánev',
      'Print text': 'Tisk',
      'Search text': 'Vyhledávání',
      'Annotation Edit text': 'Upravit anotaci',
      'Line Thickness': 'Tloušťka čáry',
      'Line Properties': 'Vlastnosti čáry',
      'Start Arrow': 'Spusťte šipku',
      'End Arrow': 'Konec šipky',
      'Line Style': 'Styl čáry',
      'Fill Color': 'Vyplň barvu',
      'Line Color': 'Barva čáry',
      None: 'Žádný',
      'Open Arrow': 'otevřeno',
      'Closed Arrow': 'Zavřeno',
      'Round Arrow': 'Kolo',
      'Square Arrow': 'Náměstí',
      'Diamond Arrow': 'diamant',
      Cut: 'Střih',
      Paste: 'Vložit',
      'Delete Context': 'Odstranit',
      Properties: 'Vlastnosti',
      'Add Stamp': 'Přidat razítko',
      'Add Shapes': 'Přidat tvary',
      'Stroke edit': 'Změnit barvu tahu',
      'Change thickness': 'Změňte tloušťku okraje',
      'Add line': 'Přidat řádek',
      'Add arrow': 'Přidat šipku',
      'Add rectangle': 'Přidat obdélník',
      'Add circle': 'Přidat kruh',
      'Add polygon': 'Přidejte polygon',
      'Add Comments': 'Přidat komentáře',
      Comments: 'Komentáře',
      'No Comments Yet': 'Zatím žádné komentáře',
      Accepted: 'Přijato',
      Completed: 'Dokončeno',
      Cancelled: 'Zrušeno',
      Rejected: 'Odmítnuto',
      'Leader Length': 'Délka vůdce',
      'Scale Ratio': 'Měřítko',
      Calibrate: 'Kalibrovat',
      'Calibrate Distance': 'Kalibrace vzdálenosti',
      'Calibrate Perimeter': 'Kalibrace obvodu',
      'Calibrate Area': 'Kalibrace oblasti',
      'Calibrate Radius': 'Kalibrujte poloměr',
      'Calibrate Volume': 'Kalibrace hlasitosti',
    },
    documenteditor: {
      Table: 'Stůl',
      Row: 'Řádek',
      Cell: 'Buňka',
      Ok: 'OK',
      Cancel: 'zrušení',
      Size: 'Velikost',
      'Preferred Width': 'Upřednostňovaná šířka',
      Points: 'Body',
      Percent: 'Procent',
      'Measure in': 'Měření v',
      Alignment: 'Zarovnání',
      Left: 'Vlevo, odjet',
      Center: 'Centrum',
      Right: 'Že jo',
      Justify: 'Ospravedlnit',
      'Indent from left': 'Odsazení zleva',
      'Borders and Shading': 'Hranice a stínování',
      Options: 'Možnosti',
      'Specify height': 'Určete výšku',
      'At least': 'Alespoň',
      Exactly: 'Přesně',
      'Row height is': 'Výška řádku je',
      'Allow row to break across pages':
        'Povolit, aby se řádek prolomil mezi stránkami',
      'Repeat as header row at the top of each page':
        'Opakujte jako řádek záhlaví v horní části každé stránky',
      'Vertical alignment': 'Vertikální zarovnání',
      Top: 'Horní',
      Bottom: 'Dno',
      'Default cell margins': 'Výchozí okraje buněk',
      'Default cell spacing': 'Výchozí rozestup buněk',
      'Allow spacing between cells': 'Povolit mezery mezi buňkami',
      'Cell margins': 'Okraje buněk',
      'Same as the whole table': 'Stejné jako celá tabulka',
      Borders: 'Hranice',
      None: 'Žádný',
      Style: 'Styl',
      Width: 'Šířka',
      Height: 'Výška',
      Letter: 'Dopis',
      Tabloid: 'Bulvár',
      Legal: 'Právní',
      Statement: 'Tvrzení',
      Executive: 'Výkonný',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Vlastní velikost',
      'Different odd and even': 'Různé liché a sudé',
      'Different first page': 'Různé první stránky',
      'From edge': 'Od okraje',
      Header: 'Záhlaví',
      Footer: 'Zápatí',
      Margin: 'Okraje',
      Paper: 'Papír',
      Layout: 'Rozložení',
      Orientation: 'Orientace',
      Landscape: 'Krajina',
      Portrait: 'Portrét',
      'Show page numbers': 'Zobrazit čísla stránek',
      'Right align page numbers': 'Zarovnat čísla stránek vpravo',
      Nothing: 'Nic',
      'Tab leader': 'Tab leader',
      'Show levels': 'Zobrazit úrovně',
      'Use hyperlinks instead of page numbers':
        'Místo čísel stránek použijte hypertextové odkazy',
      'Build table of contents from': 'Sestavte obsah z',
      Styles: 'Styly',
      'Available styles': 'Dostupné styly',
      'TOC level': 'Úroveň TOC',
      Heading: 'Nadpis',
      'Heading 1': 'Okruh 1',
      'Heading 2': 'Okruh 2',
      'Heading 3': 'Okruh 3',
      'Heading 4': 'Okruh 4',
      'Heading 5': 'Okruh 5',
      'Heading 6': 'Okruh 6',
      'List Paragraph': 'Seznam odstavců',
      Normal: 'Normální',
      'Outline levels': 'Obrysové úrovně',
      'Table entry fields': 'Pole pro zadání tabulky',
      Modify: 'Modifikovat',
      Color: 'Barva',
      Setting: 'Nastavení',
      Box: 'Box',
      All: 'Všechno',
      Custom: 'Zvyk',
      Preview: 'Náhled',
      Shading: 'Stínování',
      Fill: 'Vyplnit',
      'Apply To': 'Platit pro',
      'Table Properties': 'Vlastnosti tabulky',
      'Cell Options': 'Možnosti buňky',
      'Table Options': 'Možnosti tabulky',
      'Insert Table': 'Vložte tabulku',
      'Number of columns': 'Počet sloupců',
      'Number of rows': 'Počet řádků',
      'Text to display': 'Text k zobrazení',
      Address: 'Adresa',
      'Insert Hyperlink': 'Vložit hypertextový odkaz',
      'Edit Hyperlink': 'Upravit hypertextový odkaz',
      Insert: 'Vložit',
      General: 'Všeobecné',
      Indentation: 'Odsazení',
      'Before text': 'Před textem',
      Special: 'Speciální',
      'First line': 'První řada',
      Hanging: 'Závěsný',
      'After text': 'Po textu',
      By: 'Podle',
      Before: 'Před',
      'Line Spacing': 'Řádkování',
      After: 'Po',
      At: 'Na',
      Multiple: 'Násobek',
      Spacing: 'Vzdálenost',
      'Define new Multilevel list': 'Definujte nový víceúrovňový seznam',
      'List level': 'Úroveň seznamu',
      'Choose level to modify': 'Vyberte úroveň, kterou chcete upravit',
      Level: 'Úroveň',
      'Number format': 'Formát čísla',
      'Number style for this level': 'Styl číslování pro tuto úroveň',
      'Enter formatting for number': 'Zadejte formátování čísla',
      'Start at': 'Začátek v',
      'Restart list after': 'Po restartu restartujte seznam',
      Position: 'Pozice',
      'Text indent at': 'Textová odrážka na',
      'Aligned at': 'Zarovnáno na',
      'Follow number with': 'Sledujte číslo pomocí',
      'Tab character': 'Znak tabulátoru',
      Space: 'Prostor',
      Arabic: 'arabština',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'LowLetter',
      Number: 'Číslo',
      'Leading zero': 'Vedoucí nula',
      Bullet: 'Kulka',
      Ordinal: 'Pořadový',
      'Ordinal Text': 'Pořadový text',
      'For East': 'Pro východ',
      'No Restart': 'Žádný restart',
      Font: 'Písmo',
      'Font style': 'Styl fontu',
      'Underline style': 'Podtržení styl',
      'Font color': 'Barva fontu',
      Effects: 'Efekty',
      Strikethrough: 'Přeškrtněte',
      Superscript: 'Horní index',
      Subscript: 'Dolní index',
      'Double strikethrough': 'Dvojité přeškrtnutí',
      Regular: 'Pravidelný',
      Bold: 'tučně',
      Italic: 'Kurzíva',
      Cut: 'Střih',
      Copy: 'kopírovat',
      Paste: 'Vložit',
      Hyperlink: 'Hyperlink',
      'Open Hyperlink': 'Otevřete hypertextový odkaz',
      'Copy Hyperlink': 'Kopírovat hypertextový odkaz',
      'Remove Hyperlink': 'Odebrat hypertextový odkaz',
      Paragraph: 'Odstavec',
      'Linked Style': 'Propojený (odstavec a znak)',
      Character: 'Charakter',
      'Merge Cells': 'Spojit buňky',
      'Insert Above': 'Vložit výše',
      'Insert Below': 'Vložit níže',
      'Insert Left': 'Vložit doleva',
      'Insert Right': 'Vložit vpravo',
      Delete: 'Odstranit',
      'Delete Table': 'Smazat tabulku',
      'Delete Row': 'Smazat řádek',
      'Delete Column': 'Smazat sloupec',
      'File Name': 'Název souboru',
      'Format Type': 'Typ formátu',
      Save: 'Uložit',
      Navigation: 'Navigace',
      Results: 'Výsledek',
      Replace: 'Nahradit',
      'Replace All': 'Vyměnit vše',
      'We replaced all': 'Nahradili jsme vše',
      Find: 'Nalézt',
      'No matches': 'Žádné shody',
      'All Done': 'Vše hotovo',
      Result: 'Výsledek',
      of: 'z',
      instances: 'příklady',
      with: 's',
      'Click to follow link': 'Kliknutím přejdete na odkaz',
      'Continue Numbering': 'Pokračujte v číslování',
      'Bookmark name': 'Název záložky',
      Close: 'Zavřít',
      'Restart At': 'Restartovat o',
      Properties: 'Vlastnosti',
      Name: 'název',
      'Style type': 'Typ stylu',
      'Style based on': 'Styl založený na',
      'Style for following paragraph': 'Styl pro následující odstavec',
      Formatting: 'Formátování',
      'Numbering and Bullets': 'Číslování a odrážky',
      Numbering: 'Číslování',
      'Update Field': 'Pole aktualizace',
      'Edit Field': 'Upravit pole',
      Bookmark: 'Záložka do knihy',
      'Page Setup': 'Nastavení stránky',
      'No bookmarks found': 'Nebyly nalezeny žádné záložky',
      'Number format tooltip information':
        "Formát čísla jedné úrovně: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Například „Kapitola% 1“. zobrazí číslování jako </br> Kapitola 1. Položka </br> Kapitola 2. Položka </br>… </br> Kapitola N. Položka </br> </br> Formát víceúrovňového čísla: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Například '% 1.% 2.' zobrazí číslování jako </br> 1.1. Položka </br> 1.2. Položka </br>… </br> 1.N. Položka",
      Format: 'Formát',
      'Create New Style': 'Vytvořit nový styl',
      'Modify Style': 'Upravit styl',
      New: 'Nový',
      Bullets: 'Kulky',
      'Use bookmarks': 'Používejte záložky',
      'Table of Contents': 'Obsah',
      AutoFit: 'Automatické přizpůsobení',
      'AutoFit to Contents': 'Automatické přizpůsobení obsahu',
      'AutoFit to Window': 'Automatické přizpůsobení k oknu',
      'Fixed Column Width': 'Pevná šířka sloupce',
      Reset: 'Resetovat',
      'Match case': 'Shodný případ',
      'Whole words': 'Celá slova',
      Add: 'Přidat',
      'Go To': 'Jít do',
      'Search for': 'Hledat',
      'Replace with': 'Nahraďte jej',
      'TOC 1': 'TOC 1',
      'TOC 2': 'TOC 2',
      'TOC 3': 'TOC 3',
      'TOC 4': 'TOC 4',
      'TOC 5': 'TOC 5',
      'TOC 6': 'TOC 6',
      'TOC 7': 'TOC 7',
      'TOC 8': 'TOC 8',
      'TOC 9': 'TOC 9',
      'Right-to-left': 'Zprava doleva',
      'Left-to-right': 'Zleva do prava',
      Direction: 'Směr',
      'Table direction': 'Směr stolu',
      'Indent from right': 'Odsadit zprava',
      'Contextual Spacing': 'Nepřidávejte mezeru mezi odstavce stejných stylů',
      'Password Mismatch': 'Heslo se neshoduje',
      'Restrict Editing': 'Omezit úpravy',
      'Formatting restrictions': 'Omezení formátování',
      'Allow formatting': 'Povolit formátování',
      'Editing restrictions': 'Úpravy omezení',
      'Read only': 'Pouze ke čtení',
      'Exceptions Optional': 'Výjimky (volitelné)',
      'Select Part Of Document And User':
        'Vyberte části dokumentu a vyberte uživatele, kteří je mohou volně upravovat.',
      Everyone: 'Každý',
      'More users': 'Více uživatelů',
      'Add Users': 'Přidat uživatele',
      'Enforcing Protection': 'Ano, začněte vynucovat ochranu',
      'Start Enforcing Protection': 'Začněte vynucovat ochranu',
      'Enter User': 'Zadejte uživatele',
      Users: 'Uživatelé',
      'Enter new password': 'Zadejte nové heslo',
      'Reenter new password to confirm':
        'Znovu zadejte nové heslo pro potvrzení',
      'Your permissions': 'Vaše oprávnění',
      'Protected Document':
        'Tento dokument je chráněn před neúmyslnými úpravami. V této oblasti můžete upravovat.',
      FormFieldsOnly:
        'Tento dokument je chráněn před neúmyslnými úpravami. V této oblasti můžete vyplnit pouze formuláře.',
      'You may format text only with certain styles':
        'Text můžete formátovat pouze s určitými styly.',
      'Stop Protection': 'Zastavte ochranu',
      Password: 'Heslo',
      'Spelling Editor': 'Editor pravopisu',
      Spelling: 'Pravopis',
      'Spell Check': 'Kontrola pravopisu',
      'Underline errors': 'Podtržení chyb',
      Ignore: 'Ignorovat',
      'Ignore all': 'Ignorovat vše',
      'Add to Dictionary': 'Přidat do slovníku',
      Change: 'Změna',
      'Change All': 'Změnit vše',
      Suggestions: 'Návrhy',
      'The password is incorrect': 'Heslo je nesprávné',
      'Error in establishing connection with web server':
        'Chyba při navazování spojení s webovým serverem',
      'Highlight the regions I can edit':
        'Zvýrazněte oblasti, které mohu upravit',
      'Show All Regions I Can Edit':
        'Zobrazit všechny oblasti, které mohu upravit',
      'Find Next Region I Can Edit': 'Najít další oblast, kterou mohu upravit',
      'Keep source formatting': 'Zachovat formátování zdroje',
      'Match destination formatting': 'Shoda cílového formátování',
      'Text only': 'Pouze text',
      Comments: 'Komentáře',
      'Type your comment': 'Zadejte svůj komentář',
      Post: 'Pošta',
      Reply: 'Odpověď',
      'New Comment': 'Nový komentář',
      Edit: 'Upravit',
      Resolve: 'Odhodlání',
      Reopen: 'Znovu otevřít',
      'No comments in this document':
        'V tomto dokumentu nejsou žádné komentáře',
      more: 'více',
      'Type your comment here': 'Sem zadejte svůj komentář',
      'Next Comment': 'Další komentář',
      'Previous Comment': 'Předchozí komentář',
      'Un-posted comments': 'Nezveřejněné komentáře',
      'Discard Comment':
        'Přidané komentáře nebyly zveřejněny. Pokud budete pokračovat, bude tento komentář zahozen.',
      'No Headings': 'Nebyl nalezen žádný nadpis!',
      'Add Headings':
        'Tento dokument nemá nadpisy. Přidejte nadpisy a zkuste to znovu.',
      'More Options': 'Více možností',
      'Click to see this comment': 'Kliknutím zobrazíte tento komentář',
      'Drop Down Form Field': 'Rozevírací pole formuláře',
      'Drop-down items': 'Rozevírací položky',
      'Items in drop-down list': 'Položky v rozevíracím seznamu',
      ADD: 'PŘIDAT',
      REMOVE: 'ODSTRANIT',
      'Field settings': 'Nastavení pole',
      Tooltip: 'Popis',
      'Drop-down enabled': 'Rozbalovací nabídka povolena',
      'Check Box Form Field': 'Zaškrtávací pole pole formuláře',
      'Check box size': 'Zaškrtněte velikost políčka',
      Auto: 'Auto',
      'Default value': 'Výchozí hodnota',
      'Not checked': 'Není zaškrtnuto',
      Checked: 'Kontrolovány',
      'Check box enabled': 'Zaškrtávací políčko povoleno',
      'Text Form Field': 'Pole textového formuláře',
      Type: 'Typ',
      'Default text': 'Výchozí text',
      'Maximum length': 'Maximální délka',
      'Text format': 'Textový formát',
      'Fill-in enabled': 'Vyplňování povoleno',
      'Default number': 'Výchozí číslo',
      'Default date': 'Výchozí datum',
      'Date format': 'Formát data',
      'Merge Track': 'Tato akce nebude označena jako změna. Chceš pokračovat?',
      UnTrack: 'Nelze sledovat',
      Accept: 'Přijmout',
      Reject: 'Odmítnout',
      'Previous Changes': 'Předchozí změny',
      'Next Changes': 'Další změny',
      Inserted: 'Vloženo',
      Deleted: 'Vymazáno',
      Changes: 'Změny',
      'Accept all': 'Přijmout vše',
      'Reject all': 'Odmítnout vše',
      'No changes': 'Žádné změny',
      'Accept Changes': 'Přijmout změny',
      'Reject Changes': 'Odmítnout změny',
      User: 'Uživatel',
      View: 'Pohled',
    },
    documenteditorcontainer: {
      New: 'Nový',
      Open: 'otevřeno',
      Undo: 'vrátit',
      Redo: 'Předělat',
      Image: 'obraz',
      Table: 'Stůl',
      Link: 'Odkaz',
      Bookmark: 'Záložka do knihy',
      'Table of Contents': 'Obsah',
      'HEADING - - - - 1': 'OKRUH - - - - 1',
      'HEADING - - - - 2': 'OKRUH - - - - 2',
      'HEADING - - - - 3': 'OKRUH - - - - 3',
      Header: 'Záhlaví',
      Footer: 'Zápatí',
      'Page Setup': 'Nastavení stránky',
      'Page Number': 'Číslo stránky',
      Break: 'Přestávka',
      Find: 'Nalézt',
      'Local Clipboard': 'Místní schránka',
      'Restrict Editing': 'Omezit úpravy',
      'Upload from computer': 'Nahrávání z počítače',
      'By URL': 'Podle URL',
      'Page Break': 'Konec stránky',
      'Section Break': 'Konec sekce',
      'Header And Footer': 'Záhlaví zápatí',
      Options: 'Možnosti',
      Levels: 'Úrovně',
      'Different First Page': 'Různé první stránky',
      'Different header and footer for odd and even pages':
        'Různé záhlaví a zápatí pro liché a sudé stránky.',
      'Different Odd And Even Pages': 'Různé liché a sudé stránky',
      'Different header and footer for first page':
        'Různé záhlaví a zápatí pro první stránku.',
      Position: 'Pozice',
      'Header from Top': 'Záhlaví shora',
      'Footer from Bottom': 'Zápatí zdola',
      'Distance from top of the page to top of the header':
        'Vzdálenost od horní části stránky k horní části záhlaví.',
      'Distance from bottom of the page to bottom of the footer':
        'Vzdálenost od dolní části stránky k dolní části zápatí.',
      'Aspect ratio': 'Poměr stran',
      W: 'W',
      H: 'H',
      Width: 'Šířka',
      Height: 'Výška',
      Text: 'Text',
      Paragraph: 'Odstavec',
      Fill: 'Vyplnit',
      'Fill color': 'Vyplň barvu',
      'Border Style': 'Hraniční styl',
      'Outside borders': 'Vnější hranice',
      'All borders': 'Všechny hranice',
      'Inside borders': 'Vnitřní hranice',
      'Left border': 'Levý okraj',
      'Inside vertical border': 'Vnitřní svislé ohraničení',
      'Right border': 'Pravá hranice',
      'Top border': 'Horní okraj',
      'Inside horizontal border': 'Vnitřní vodorovné ohraničení',
      'Bottom border': 'Dolní okraj',
      'Border color': 'Barva okraje',
      'Border width': 'Šířka okraje',
      Cell: 'Buňka',
      'Merge cells': 'Spojit buňky',
      'Insert Or Delete': 'Vložit / Smazat',
      'Insert columns to the left': 'Vložit sloupce doleva',
      'Insert columns to the right': 'Vložit sloupce doprava',
      'Insert rows above': 'Vložte řádky nad',
      'Insert rows below': 'Vložte řádky níže',
      'Delete rows': 'Odstranit řádky',
      'Delete columns': 'Odstranit sloupce',
      'Cell Margin': 'Marže buněk',
      Top: 'Horní',
      Bottom: 'Dno',
      Left: 'Vlevo, odjet',
      Right: 'Že jo',
      'Align Text': 'Zarovnat text',
      'Align top': 'Zarovnejte horní část',
      'Align bottom': 'Zarovnejte dno',
      'Align center': 'Zarovnat střed',
      'Number of heading or outline levels to be shown in table of contents':
        'Počet úrovní nadpisu nebo osnovy, které mají být uvedeny v obsahu.',
      'Show page numbers': 'Zobrazit čísla stránek',
      'Show page numbers in table of contents':
        'Zobrazit čísla stránek v obsahu.',
      'Right align page numbers': 'Zarovnat čísla stránek vpravo',
      'Right align page numbers in table of contents':
        'Zarovnejte čísla stránek vpravo v obsahu.',
      'Use hyperlinks': 'Použijte hypertextové odkazy',
      'Use hyperlinks instead of page numbers':
        'Místo čísel stránek použijte hypertextové odkazy.',
      Font: 'Písmo',
      'Font Size': 'Velikost písma',
      'Font color': 'Barva fontu',
      'Text highlight color': 'Barva zvýraznění textu',
      'Clear all formatting': 'Vymazat veškeré formátování',
      'Bold Tooltip': 'Tučné (Ctrl + B)',
      'Italic Tooltip': 'Kurzíva (Ctrl + I)',
      'Underline Tooltip': 'Podtržení (Ctrl + U)',
      Strikethrough: 'Přeškrtněte',
      'Superscript Tooltip': 'Horní index (Ctrl + Shift ++)',
      'Subscript Tooltip': 'Dolní index (Ctrl + =)',
      'Align left Tooltip': 'Zarovnat doleva (Ctrl + L)',
      'Center Tooltip': 'Střed (Ctrl + E)',
      'Align right Tooltip': 'Zarovnat doprava (Ctrl + R)',
      'Justify Tooltip': 'Zarovnat (Ctrl + J)',
      'Decrease indent': 'Zmenšit odsazení',
      'Increase indent': 'Zvětšit odsazení',
      'Line spacing': 'Řádkování',
      Bullets: 'Kulky',
      Numbering: 'Číslování',
      Styles: 'Styly',
      'Manage Styles': 'Správa stylů',
      Page: 'Stránka',
      of: 'z',
      'Fit one page': 'Přizpůsobit jednu stránku',
      'Spell Check': 'Kontrola pravopisu',
      'Underline errors': 'Podtržení chyb',
      'Fit page width': 'Přizpůsobit šířku stránky',
      Update: 'Aktualizace',
      Cancel: 'zrušení',
      Insert: 'Vložit',
      'No Border': 'Žádná hranice',
      'Create a new document': 'Vytvořte nový dokument.',
      'Open a document': 'Otevřete dokument.',
      'Undo Tooltip': 'Vrátit zpět poslední operaci (Ctrl + Z).',
      'Redo Tooltip': 'Opakujte poslední operaci (Ctrl + Y).',
      'Insert inline picture from a file':
        'Vložení vloženého obrázku ze souboru.',
      'Insert a table into the document': 'Vložte tabulku do dokumentu',
      'Create Hyperlink':
        'Vytvořte odkaz v dokumentu pro rychlý přístup k webovým stránkám a souborům (Ctrl + K).',
      'Insert a bookmark in a specific place in this document':
        'Vložte záložku na konkrétní místo v tomto dokumentu.',
      'Provide an overview of your document by adding a table of contents':
        'Poskytněte přehled svého dokumentu přidáním obsahu.',
      'Add or edit the header': 'Přidejte nebo upravte záhlaví.',
      'Add or edit the footer': 'Přidat nebo upravit zápatí.',
      'Open the page setup dialog':
        'Otevřete dialogové okno nastavení stránky.',
      'Add page numbers': 'Přidejte čísla stránek.',
      'Find Text': 'Vyhledejte text v dokumentu (Ctrl + F).',
      'Toggle between the internal clipboard and system clipboard':
        'Přepínání mezi interní schránkou a systémovou schránkou. </br> Přístup k systémové schránce skriptem je kvůli bezpečnostní politice prohlížečů odepřen. Místo toho, </br> 1. Můžete povolit vnitřní schránku pro vyjmutí, zkopírování a vložení uvnitř komponenty. </br> 2. Pro oříznutí můžete použít klávesové zkratky (Ctrl + X, Ctrl + C a Ctrl + V). , zkopírujte a vložte do systémové schránky.',
      'Current Page Number':
        'Aktuální číslo stránky v dokumentu. Klepnutím nebo klepnutím můžete procházet konkrétní stránku.',
      'Read only': 'Pouze ke čtení',
      Protections: 'Ochrana',
      'Error in establishing connection with web server':
        'Chyba při navazování spojení s webovým serverem',
      Single: 'Singl',
      Double: 'Dvojnásobek',
      'New comment': 'Nový komentář',
      Comments: 'Komentáře',
      'Print layout': 'Vzhled při tisku',
      'Web layout': 'Webové rozvržení',
      'Text Form': 'Textová forma',
      'Check Box': 'Zaškrtávací políčko',
      DropDown: 'Rozevírací seznam',
      'Update Fields': 'Aktualizovat pole',
      'Update cross reference fields': 'Aktualizujte pole křížových odkazů',
    },
    toast: {
      close: 'Zavřít',
    },
    kanban: {
      items: 'položek',
      min: 'Min',
      max: 'Max',
      cardsSelected: 'Vybrané karty',
      addTitle: 'Přidat novou kartu',
      editTitle: 'Upravit podrobnosti karty',
      deleteTitle: 'Smazat kartu',
      deleteContent: 'Opravdu chcete tuto kartu smazat?',
      save: 'Uložit',
      delete: 'Odstranit',
      cancel: 'zrušení',
      yes: 'Ano',
      no: 'Ne',
      close: 'Zavřít',
    },
  },
};
