import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <input
        *ngIf="to.type !== 'number'; else numberTmp"
        class="e-input"
        [type]="to.type || 'text'"
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        [class.ng-dirty]="showError"
        (onChange)="to.change && to.change(field, $event)"
        [placeholder]="
          to.placeholderT
            ? t(to.placeholderT['key'], to.placeholderT['params'])
            : to.placeholder
        "
        [attr.autocomplete]="to.attributes.autocomplete"
      />
    </ng-container>
    <ng-template #numberTmp> koukni do console </ng-template>
  `,
})
export class FormlyFieldInput extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        autocomplete: null,
      },
    },
  };

  ngOnInit() {
    // TODO: [RAL-25] cerate number component
    if (this.to.type == 'number') {
      console.log(
        'number component id not implemented yet -> need to by added number field component'
      );
    }
  }
}
