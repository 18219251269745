<div #ChangePasswordCard class="e-card">
  <div class="e-card-header">
    <label>{{ 'TPA.2FA' | transloco | firstToUpperCase }}</label>
  </div>
  <div class="e-card-content">
    <tpa-loading [loaded]="state.isLoaded$ | ngrxPush">
      <div *ngrxLet="state.profile$; let profile">
        <ng-template #enableAuthenticator>
          <div class="p-grid">
            <div class="p-col-12">
              <p>
                {{ 'TPA.Activate2FAText' | transloco | firstToUpperCase }}
              </p>
            </div>
            <div class="p-col-12">
              <button (click)="enableGoogleAuthenticator()" class="e-lib e-btn e-primary">
                {{ 'TPA.Activate2FA' | transloco | firstToUpperCase }}
              </button>
            </div>
          </div>
        </ng-template>

        <ng-container *ngIf="
            profile.isGoogleAuthenticatorConfiremed;
            else enableAuthenticator
          ">
          <button (click)="disableGoogleAuthenticator()" class="e-lib e-btn">
            {{ 'TPA.Deactivate2FA' | transloco | firstToUpperCase }}
          </button>
        </ng-container>

        <ejs-dialog #ejDialog (overlayClick)="tryToActivateClose()" *ngIf="state.openActivateModal$ | async" [closeOnEscape]="false" [isModal]="true"
          [visible]="true" width="500px">
          <ng-template #content>
            <div class="dialogContent">
              <ejs-tab id="element">
                <div class="e-tab-header" style="display: none !important">
                  <div #id1>1</div>
                  <div #id2>2</div>
                  <div #id2>3</div>
                </div>
                <div class="e-content">
                  <div>
                    <h2 class="h2--big">
                      {{ 'TPA.2FASetting' | transloco | firstToUpperCase }}
                    </h2>
                    <div class="p-grid">
                      <div class="p-col-12 p-lg-fixed">
                        <p class="modal-text">
                          {{ 'TPA.PairApp' | transloco | firstToUpperCase }}
                        </p>
                        <div class="code">
                          <!--<img alt="Loading" class="loading-img" src="/assets/images/laoding.gif" />-->
                          <ng-container *ngIf="state.qrCode$ | async; let qrCode">
                            <ngx-qrcode-styling [config]="qrCode"></ngx-qrcode-styling>
                          </ng-container>
                        </div>

                        <p class="modal-text">
                          {{
                          'TPA.PairAppByCode' | transloco | firstToUpperCase
                          }}
                        </p>

                        <p class="modal-text" style="text-align: center">
                          <strong class="entrycode">{{
                            profile.manualEntryKey
                            }}</strong>
                        </p>
                        <p class="modal-text">
                          {{
                          'TPA.DoNotHaveApp' | transloco | firstToUpperCase
                          }}
                        </p>
                        <div class="p-grid p-justify-center">
                          <div class="p-col-fixed">
                            <p class="modal-text">
                              <a class="e-btn e-lib" href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                target="_blank">
                                <i class="fab fa-app-store"></i>
                                <span> App Store </span>
                              </a>
                            </p>
                          </div>
                          <div class="p-col-fixed">
                            <p class="modal-text">
                              <a class="e-btn e-lib"
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                target="_blank">
                                <i class="fab fa-google-play"></i>
                                <span> Google Play </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 class="h2--big">
                      {{ 'TPA.SetupSMS' | transloco }}
                    </h2>
                    <div class="p-grid">
                      <div class="p-col-12">
                        <p class="modal-p">
                          {{ 'TPA.SetupSMSText' | transloco }}
                        </p>
                        <form (ngSubmit)="sendSmsCode(model)" [formGroup]="form">
                          <formly-form [fields]="fields" [form]="form" [model]="model" [options]="options">
                          </formly-form>
                          <button style="display: none" type="submit"></button>
                          <!-- || (isLoading$ | async) -->
                        </form>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 class="h2--big">
                      {{ 'TPA.Finishing2FASetting' | transloco }}
                    </h2>
                    <div class="p-grid">
                      <div class="p-col-12">
                        <p class="modal-p">
                          {{ 'TPA.Finishing2FAEnterCode' | transloco }}
                        </p>

                        <form (ngSubmit)="confirm2FA(model)" [formGroup]="form">
                          <formly-form [fields]="fields" [form]="form" [model]="model" [options]="options">
                          </formly-form>
                          <button style="display: none" type="submit"></button>
                          <!-- || (isLoading$ | async) -->
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ejs-tab>
            </div>
          </ng-template>
          <ng-template #footerTemplate>
            <!-- <div *ngIf="tab && tab.selectedItem"> -->
            <button (click)="back()" class="e-btn e-lib" type="button">
              <span *ngIf="tab && tab.selectedItem != 0">
                {{ 'TPA.Back' | transloco | firstToUpperCase }}
              </span>
              <span *ngIf="tab && tab.selectedItem == 0">
                {{ 'TPA.Close' | transloco | firstToUpperCase }}
              </span>
            </button>
            <button (click)="next(model)" class="e-btn e-lib e-primary" type="button">
              <span *ngIf="tab && tab.selectedItem != 2">
                {{ 'TPA.Next' | transloco | firstToUpperCase }}
              </span>
              <span *ngIf="tab && tab.selectedItem == 2">
                {{ 'TPA.FinishAndLogOut' | transloco }}
              </span>
            </button>
            <!-- </div> -->
          </ng-template>
        </ejs-dialog>

        <ejs-dialog #ejDialog (overlayClick)="tryToDactivateClose()" *ngIf="state.openDeactivedModal$ | async" [closeOnEscape]="false" [isModal]="true"
          [visible]="true" width="500px">
          <ng-template #content>
            <div class="dialogContent">
              <div>
                <h2 class="h2--big">
                  {{ 'TPA.Deactivation2FA' | transloco | firstToUpperCase }}
                </h2>
                <div class="p-grid">
                  <div class="p-col-12">
                    <p class="modal-text">
                      {{
                      'TPA.Deactivation2FAText' | transloco | firstToUpperCase
                      }}
                    </p>

                    <form (ngSubmit)="deactivate2FA(modelDeactivate)" [formGroup]="form">
                      <formly-form [fields]="fieldsDeactivate" [form]="formDeactivate" [model]="modelDeactivate"
                        [options]="optionsDeactivate"></formly-form>
                      <button style="display: none" type="submit"></button>
                    </form>
                    <a (click)="setProvider('GoogleAuthenticator')" *ngIf="modelDeactivate?.provider === 'Phone'"
                      class="link">
                      {{
                      'TPA.DeactivateByCode' | transloco | firstToUpperCase
                      }}
                    </a>
                    <a (click)="setProvider('Phone')" *ngIf="modelDeactivate?.provider !== 'Phone'" class="link">
                      {{ 'TPA.DeactivateBySMS' | transloco | firstToUpperCase }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #footerTemplate>
            <button (click)="tryToDactivateClose()" class="e-btn e-lib" type="button">
              <span>
                {{ 'TPA.Close' | transloco | firstToUpperCase }}
              </span>
            </button>
            <button (click)="deactivate2FA(modelDeactivate)" class="e-btn e-lib e-primary" type="button">
              <span>
                {{ 'TPA.Odebrat2FA' | transloco }}
              </span>
            </button>
          </ng-template>
        </ejs-dialog>
      </div>
    </tpa-loading>
  </div>
</div>
