import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from '@ralba/shared';

@Component({
  selector: 'ral-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent {
  @Input() menuItems: MenuItem[] = [];
}
