import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel-pink',
  template: `
    <ral-card >
      <div class="e-card-header">
        <h3 *ngIf="to.label" class="h4">{{ to.label }}</h3>
        <p *ngIf="to.description" class="description">
          {{ to.description }}
        </p>
      </div>
      <div class="e-card-content">
        <ng-container #fieldComponent></ng-container>
      </div>
    </ral-card>
  `,
  styles: [
    `
      ::ng-deep formly-wrapper-panel-pink > ral-card > .e-card {
        background: #fde2df !important;
        border-color: #fde2df !important;
      }
    `,
  ],
})
export class FormlyPanelWrapperPinkComponent extends FieldWrapper {}
