import { createAction, props } from '@ngrx/store';
import { IDataState } from './models';

export function createNgrxDataActions<T>(dataGridName: string) {
  return {
    initialize: createAction(
      `[${dataGridName}] initialize`,
      props<{
        dataState: IDataState;
      }>()
    ),
    getFilterOptions: createAction(`[${dataGridName}] get filter options`),
    getFilterOptionsSuccess: createAction(
      `[${dataGridName}] get filter options success`,
      props<{
        result: any;
      }>()
    ),
    getDataSuccess: createAction(
      `[${dataGridName}] get data success`,
      props<{ result: any }>()
    ),
    dataStateChange: createAction(
      `[${dataGridName}] dataStateChange`,
      props<{
        dataState: IDataState;
      }>()
    ),
  };
}

export type IGridActions = ReturnType<typeof createNgrxDataActions>;
