import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  sharedTipHintChildDivorced,
  sharedTipHintChildNextTaxpayer,
  sharedTipHintChildNextTaxpayerUnemployed,
  sharedTipHintChildStudent,
  sharedTipHintChildZTPP,
  sharedTipHintMarridWithoutSecond,
  sharedTipHintNewChild,
  sharedTipHintStudentDiscount,
  sharedTipHintZTPPProProhlaseni,
  sharedTipHintZZProProhlaseni
} from './complete-form';

export const OnlyTaxDeclarationForm = (): FormlyFieldConfig[] => [
  {
    type: 'h2',
    props: {
      label: 'TPA.TaxpayerDeclaration',
    },
  },
  {
    type: 'affidavit',
    key: 'affidavit',
    wrappers: ['always-open-wrapper'],
    props: {
      translate: true,
      label: 'TPA.StartingStatements',
    },
  },
  {
    type: 'h2',
    props: {
      label: 'TPA.BasicDiscounts',
    },
    expressionProperties: {
      hide: (model, formState, field) => {
        const affidavit = field?.parent?.fieldGroup?.find(
          (p) => p.key == 'affidavit'
        )?.model;
        return !(affidavit?.chciProhlaseni && affidavit?.prohlaseniKProhlaseni);
      },
    },
  },

  {
    type: 'checkbox-discount',
    wrappers: ['always-open-wrapper'],
    props: {
      translate: true,
    },
    expressionProperties: {
      hide: (model, formState, field) => {
        const affidavit = field?.parent?.fieldGroup?.find(
          (p) => p.key == 'affidavit'
        )?.model;

        return !(affidavit?.chciProhlaseni && affidavit?.prohlaseniKProhlaseni);
      },
    },
  },

  {
    className: 'p-col-12',
    key: 'monthDiscount',
    wrappers: ['error-wrapper'],
    validators: {
      validation: [
        {
          name: 'validateDiscountForInvalidityCollision',
        },
      ],
    },
    validation: {
      show: true,
    },
    fieldGroup: [
      {
        type: 'month-discount',
        key: 'zakladniSlevaNaInvalidituMesice',
        wrappers: ['always-open-wrapper'],

        props: {
          translate: true,
          label: 'TPA.BasicDisabilitySiscount',
          attributes: {
            discountDescription: 'TPA.BasicDisabilityInfo',
          },
        },
      },

      {
        type: 'month-discount',
        key: 'rozsirenaSlevaNaInvalidituMesice',
        wrappers: ['always-open-wrapper'],
        props: {
          translate: true,
          label: 'TPA.EnhancedDisabilitySiscount',
          attributes: {
            discountDescription: 'TPA.EnhancedDisabilityInfo',
          },
        },
      },
      sharedTipHintZZProProhlaseni(),
      {
        type: 'month-discount',
        key: 'slevaNaDrziteleZTPPMesice',

        props: {
          translate: true,
          label: 'TPA.ZtppDiscount',
          attributes: {
            discountDescription: 'TPA.ZtppInfo',
          },
        },
      },
      sharedTipHintZTPPProProhlaseni(),

      {
        type: 'month-discount',
        key: 'slevaNaStudentaMesice',

        props: {
          translate: true,
          label: 'TPA.StudentDiscount',
          attributes: {
            discountDescription: 'TPA.StudentInfo',
          },
        },
        expressionProperties: {
          hide: 'formState.versionV2',
        }
      },
      sharedTipHintStudentDiscount(),
    ],
    expressionProperties: {
      hide: (model, formState, field) => {
        const affidavit = field?.parent?.fieldGroup?.find(
          (p) => p.key == 'affidavit'
        )?.model;

        return !(affidavit?.chciProhlaseni && affidavit?.prohlaseniKProhlaseni);
      },
    },
  },
  {
    type: 'h2',
    props: {
      label: 'TPA.ChildDiscount',
    },
    expressionProperties: {
      hide: (model, formState, field) => {
        const affidavit = field?.parent?.fieldGroup?.find(
          (p) => p.key == 'affidavit'
        )?.model;

        return !(affidavit?.chciProhlaseni && affidavit?.prohlaseniKProhlaseni);
      },
    },
  },
  {
    className: 'p-col-12',
    key: 'children',
    fieldGroup: [
      {
        type: 'children',
        // key: 'deti',
        props: {
          translate: true,
          label: 'TPA.ChildDiscount',
        },
      },
    ],
    expressionProperties: {
      hide: (model, formState, field) => {
        const affidavit = field?.parent?.fieldGroup?.find(
          (p) => p.key == 'affidavit'
        )?.model;

        return !(affidavit?.chciProhlaseni && affidavit?.prohlaseniKProhlaseni);
      },
    },
  },
  sharedTipHintNewChild(),
  sharedTipHintChildStudent(),
  sharedTipHintChildZTPP(),
  {
    className: 'p-col-12',
    key: 'partner',
    type: 'partner',
    props: {
      translate: true,
      label: 'TPA.Partner',
    },
    expressionProperties: {
      hide: 'formState.hideBasedOnProhlaseniKProhlaseni() || formState.hideBasedOnChildrenArray()',
    },
  },
  sharedTipHintChildNextTaxpayer(),
  sharedTipHintChildNextTaxpayerUnemployed(),
  sharedTipHintChildDivorced(),
  sharedTipHintMarridWithoutSecond(),
];
