import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface IDataState<T> {
  currentMapped: T;
  lastApprovedMapped: T;
}

export abstract class DataCoreStore<
  T extends IDataState<K>,
  K
> extends ComponentStore<T> {
  abstract currentMapped$: Observable<K>;
  abstract lastApprovedMapped$: Observable<K>
}
