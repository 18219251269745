import { FormlyFieldConfig } from '@ngx-formly/core';
import { countryOptions } from '@tpa/features/emp-data';
import { specialCharsParser } from './utils';

export const sharedAnotherCompany = (
  question: FormlyFieldConfig = {
    key: 'dalsiPracovniPomer',
    type: 'checkbox',
    className: 'p-col-12',
    props: {
      checkboxLabel: 'TPA.HasOtherEmployer',
      translate: true,
    },
  }
): FormlyFieldConfig[] => [
  question,
  {
    key: 'nazevDalsihoZamestnavatele',
    type: 'input',
    className: 'p-col-12 p-md-6',
    props: {
      label: 'TPA.OtherEmployer',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.dalsiPracovniPomer != true)',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
  },
  {
    key: 'sidloDalsihoZamestnavatele',
    type: 'select',
    className: 'p-col-12 p-md-6',
    props: {
      placeholder: 'TPA.SelectPlaceholder',
      label: 'TPA.OtherEmployerState',
      required: true,
      options: countryOptions(),
      translate: true,
      translateOptions: true,
    },
    hideExpression: '(model?.dalsiPracovniPomer != true)',
  },
];
