import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import {
  DropDownListModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { RouterModule } from '@angular/router';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { TranslocoModule } from '@ngneat/transloco';

export const UI_IMPORTS_EXPORTS = [
  // SYNCFUSION
  MultiSelectModule,
  SwitchModule,
  TabModule,
  ButtonModule,
  DropDownListModule,
  DialogModule,
  TextBoxModule,
  NumericTextBoxModule,
  RadioButtonModule,
  CheckBoxModule,
  RichTextEditorAllModule,
  DatePickerModule,
  GridModule,
  TreeViewModule,
  TooltipModule,
  RadioButtonModule,
  RouterModule,
  // END SYNCFUSION
  //ButtonModule,
  //FateModule,
  ProgressBarModule,
];

export const IMPORTS = [
  CommonModule,
  ReactiveFormsModule,
  FormlyModule,
  FormlySelectModule,
  TranslocoModule,
];
