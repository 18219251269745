import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  DataManagerRequest,
  DocumentDto,
  DocumentTagDto,
  EmployeeUploadDocument,
  TenancyRequestDto,
  TenancyRequestDtoOfEntityDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class EmployeeDocumentHttpApiService {
  constructor(private rest: RestService) {}

  public uploadDocument(body: TenancyRequestDto<EmployeeUploadDocument>) {
    const request: Rest.Request<TenancyRequestDto<EmployeeUploadDocument>> = {
      method: 'POST',
      url: '/api/employee/document/upload-document',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EmployeeUploadDocument>,
      Rest.AbpResponse<null>
    >(request);
  }

  public getDocument(params: TenancyRequestDto<{ Id?: number }>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/document/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<DocumentDto>>(request);
  }

  public getDocuments(
    params: TenancyRequestDto<{
      tagId?: string;
      ownerId: number;
      IsDeleted: boolean;
      validTo?: string;
      validFrom?: string;
    }>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/document/get-list',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<DocumentDto[]>>(request);
  }

  public deleteDocument(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: '/api/employee/document/delete',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<null>>(request);
  }

  public confirmationOfAcceptance(body: TenancyRequestDtoOfEntityDto) {
    const request: Rest.Request<TenancyRequestDtoOfEntityDto> = {
      method: 'POST',
      url: '/api/employee/document/confirmation-of-acceptance',
      body,
    };
    return this.rest.request<
      TenancyRequestDtoOfEntityDto,
      Rest.AbpResponse<DocumentDto>
    >(request);
  }

  public getDocumentsTags(params: TenancyRequestDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/document/get-tags',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<DocumentTagDto[]>>(request);
  }

  public getDocumentsByDataManager(
    body: DataManagerRequest & {
      userId: number;
      tenantId: number;
    }
  ) {
    const request: Rest.Request<{
      userId: number;
      tenantId: number;
    }> = {
      method: 'POST',
      url: '/api/employee/document/datamanager',
      body,
    };
    return this.rest.request<
      DataManagerRequest & {
        userId: number;
        tenantId: number;
      },
      Rest.AbpResponse<{
        count: number;
        result: DocumentDto[];
      }>
    >(request);
  }
}
