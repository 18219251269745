import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'ral-formly-rzd-uver-osoby',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .floatRemove {
         position: absolute;
        top: 16px;
        right: 16px;
      }
      .p-grid {
        position: relative;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <!-- <div *ngIf="!(model?.length > 0)">
        <ejs-checkbox
          [label]="t('TPA.LoanInterestsOtherPersons')"
          [value]="model?.length > 0 || false"
          cssClass="e-primary"
          (click)="add(null, to.initialModel)"
        >
        </ejs-checkbox>
        <br>
      </div> -->
      <div *ngFor="let field of field.fieldGroup; let i = index" class="p-grid">
        <formly-field class="p-col" [field]="field"></formly-field>
        <div
          class="p-col-fixed {{ to.attributes.removeClass }}"
          *ngIf="allowRemove"
        >
          <!-- <div class="fake-label"> -->
          <button
            class="e-btn e-lib e-danger"
            type="button"
            (click)="remove(i)"
            title="Remove"
          >
            <i class="fal fa-times"></i>
          </button>
          <!-- </div> -->
        </div>
      </div>
      <div class="ui-messages-error" *ngIf="showError">
        <div class="p-grid" *ngIf="allowAdd">
          <div class="p-col">
            <formly-validation-message
              [field]="field"
            ></formly-validation-message>
          </div>
        </div>
      </div>
      <div class="p-grid" *ngIf="allowAdd && model?.length > 0">
        <div class="p-col">
          <button
            class="e-btn e-lib {{ to.attributes.addClass }}"
            type="button"
            (click)="add(null, to.initialModel)"
          >
            {{
              to.addTextT
                ? t(to.addTextT['key'], to.addTextT['params'])
                : to.addText
            }}
          </button>
        </div>
      </div>
    </ng-container>
  `,
})
export class RZDUverOsobyFieldInput extends FieldArrayType {
  // TODO: add explicit constructor

  defaultOptions = {
    props: {
      initialModel: {},
      options: [],
      addText: 'Add',
      attributes: {
        grid: 12,
        allowAdd: 'false',
        allowRemove: 'false',
        addClass: 'e-primary',
        removeClass: '', // floatRemove
      },
    },
  };
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value?.length == 0) {
      this.add(null, this.to.initialModel);
    }
  }

  get allowAdd() {
    if (this.to.change) this.to.change(this.field, 'add');
    return this.to.attributes.allowAdd === 'true';
  }

  get allowRemove() {
    if (this.to.change) this.to.change(this.field, 'remove');
    return this.to.attributes.allowRemove === 'true';
  }

  onChange(value: any, checked: boolean) {
    this.formControl.markAsTouched();
  }
}
