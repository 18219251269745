import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { uplatneniForm } from './complete-form';
import { specialCharsParser } from './utils';

export const kid = (): FormlyFieldConfig => ({
  className: 'p-col-12',
  wrappers: ['panel-wrapper-pink'],
  props: {
    label: 'TPA.ChildData',
    translate: true,
  },
  fieldGroup: [
    {
      key: 'jmeno',
      type: 'input',
      className: 'p-col-12 p-md-6',
      modelOptions: {
        updateOn: 'blur',
      },
      props: {
        label: 'TPA.FirstName',
        translate: true,
        required: true,
      },
      parsers: [specialCharsParser],
    },
    {
      key: 'prijmeni',
      type: 'input',
      className: 'p-col-12 p-md-6',
      modelOptions: {
        updateOn: 'blur',
      },
      props: {
        label: 'TPA.Surname',
        translate: true,
        required: true,
      },
      parsers: [specialCharsParser],
    },
    {
      type: 'input',
      props: {
        label: 'TPA.BirthNumber',
        placeholder: 'XXXXXX/XXXX',
        translate: true,
      },
      validators: {
        validation: [{ name: 'validateRodneCislo' }],
      },
      key: 'rodneCislo',
      className: 'p-col-12',
      parsers: [specialCharsParser],
      modelOptions: {
        updateOn: 'blur',
      },
    },
    {
      key: 'uplatneni',
      type: 'repeat',
      validators: {
        validation: [
          { name: 'validateOneChildApplyInAMonth' },
          { name: 'validationAtLeastOneItem' },
        ],
      },
      className: 'p-col-12',
      /* hideExpression: (m, formState, field) => {
        return field.parent.model?.mamdeti != true;
      }, */
      props: {
        label: 'TPA.Applying',
        translate: true,
        addTextT: { key: 'TPA.AddChildRecord' },
        attributes: {
          allowAdd: 'true',
          allowRemove: 'true',
          removeClass: 'floatRemove',
        },
      },
      defaultValue: [{}],
      fieldArray: {
        fieldGroup: [uplatneniForm()],
      },
    },
  ],
});

/**
 *
 * @param hideLegalTexts write false in case for always show legal text
 * @returns
 */
export const deti = (guide = false): FormlyFieldConfig[] => [
  // Tento pomocny checkbox byl odstranen v ramci guide
  // {
  //   key: 'mamdeti',
  //   type: 'checkbox',
  //   className: 'p-col-12',

  //   props: {
  //     checkboxLabel: translate('TPA.ApplyChildren'),
  //     change: (field?: FormlyFieldConfig) => {
  //       if (field?.formControl?.value != true) {
  //         const f = field?.parent?.fieldGroup?.find((p) => p.key == 'deti');
  //         if (f && f.model.length == 0) {
  //           f.model.length = 1;
  //         }
  //       }
  //     },
  //   },
  // },

  {
    key: 'deti',
    type: 'repeat',
    validators: {
      validation: [{ name: 'validateMoreOfOneChildInChildren' }],
    },
    props: {
      addTextT: { key: 'TPA.AddChild' },
      initialModel: {
        uplatneni: [{}],
      },
      // change: (field, event) => {
      //   if (field.model && field.model?.length == 0) {
      //     field.parent.fieldGroup
      //       .find((p) => p.key == 'mamdeti')
      //       .formControl.setValue(false);
      //   }
      // },
      attributes: {
        allowAdd: 'true',
        allowRemove: 'true',
        removeClass: 'floatRemove',
      },
    },

    fieldArray: {
      fieldGroup: [kid()],
    },
  },
  {
    className: 'p-col-12',
    template: `<p class="description">
    ${translate(
      guide ? 'TPA.ChildrenDiscountAdditionalText1' : 'TPA.ChildrenInfo'
    )}
    </p>`,
    // TODO: toto bylo v puvodní verzi
    //hideExpression: 'model?.mamdeti != true',
  },
  {
    className: 'p-col-12',
    template: `<p class="description">
    ${translate('TPA.ChildrenZtppInfo')}
    </p>`,
    // TODO: toto bylo v puvodní verzi
    //hideExpression: 'model?.mamdeti != true',
  },
];
