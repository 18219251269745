import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'tpa-always-open-wrapper',
  templateUrl: './always-open-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .label-wrapper {
        width: 100%;
      }
      ::ng-deep .description {
        font-weight: 400;
        color: #8181a5 !important;
        color: var(--primary-gray, #8181a5) !important;
      }
    `,
  ],
})
export class AlwaysOpenWrapperComponent extends FieldWrapper {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
    },
  };

  toggle() {
    if (this.formState['readonly'] == false) this.to['open'] = !this.to['open'];
  }

  open($event: boolean | undefined) {
    // console.log($event)
    if (!$event && this.formState['readonly'] == false) {
      this.to['open'] = true;
    }
  }

  action() {
    if (this.to['actionPathKey']) {
      const value = JSON.parse(
        JSON.stringify(
          this.field?.parent?.fieldGroup?.find(
            (p) => p.key == this.to['actionPathKey']
          )?.model
        )
      );

      this.formControl.patchValue(value);
    }
  }
}
