import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Observable } from 'rxjs';

/**
 * Interface that a class can implement to be a guard deciding if a route can be deactivated.
 * If all guards return true, navigation continues. If any guard returns false, navigation is cancelled.
 * If any guard returns a UrlTree, current navigation is cancelled and a new navigation begins to the UrlTree returned from the guard.
 *
 * TODO: move to a shared library
 */
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  private shouldBypassGuard = false;

  canDeactivate(component: CanComponentDeactivate) {
    if (this.shouldBypassGuard) {
      this.shouldBypassGuard = false;
      return true;
    }

    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
