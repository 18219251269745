export class AbpConsts {
  static remoteServiceBaseUrl = '';
  static appBaseUrl = '';
  static appBaseHref = ''; // returns angular's base-href parameter value if used during the publish

  static localeMappings: any = [];

  static readonly userManagement = {
    defaultAdminUserName: 'admin',
  };

  static readonly localization = {
    defaultLocalizationSourceName: 'Skimondo',
  };

  static readonly authorization = {
    encryptedAuthTokenName: 'enc_auth_token',
  };
}

export const BinaryFileUrl = (guid: string) =>
  `${AbpConsts.remoteServiceBaseUrl}/File/${{ guid }}`;
