import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-h2',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h2 *ngIf="to.label" class="h2-form">
      {{ to.prefix }}
      {{ to.label | transloco }}
    </h2>
    <p class="description" *ngIf="to.description">
      {{ to.description | transloco }}
    </p>
  `,
})
export class FormlyFieldH2 extends FieldType {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
    },
  };
}
