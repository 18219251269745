import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PermissionChecker } from '@ralba/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserTenanciesSelectors } from '../selectors';
import { TenancyFeatureChecker } from '../utils';

type authGuardCheckType = ReturnType<typeof UserTenanciesSelectors.authorizationGuard>
type hostCheckType = Pick<authGuardCheckType, 'host'>
type tenantCheckType = Pick<authGuardCheckType, 'tenant'>


@Injectable()
export class TpaAuthorizationGuard implements CanActivate, CanActivateChild {

  /**
   *
   */
  constructor(private store$: Store, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const feature = route.data.feature;

    const permissions: string[] = route.data?.authorization?.permissions || null;
    const requireAll: boolean = route.data?.authorization?.requireAll || false
    return this.store$.pipe(
      select(UserTenanciesSelectors.authorizationGuard),
      map(({ isHost, ...model }) => isHost ? this.checkHostSidePermissions(model, permissions, requireAll) : this.checkTenancySideFeatures(model, feature) && this.checkTenancySidePermissions(model, permissions, requireAll))
    )
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }


  private checkHostSidePermissions({ host: { allPermissions, grantedPermissions } }: hostCheckType, permissions: string[], requireAll: boolean) {
    return permissions ? PermissionChecker.isGranted(permissions, allPermissions, grantedPermissions, requireAll) : true;
  }

  private checkTenancySidePermissions({ tenant: { permissions: { allPermissions, grantedPermissions } } }: tenantCheckType, permissions: string[], requireAll: boolean) {
    return permissions ? PermissionChecker.isGranted(permissions, allPermissions, grantedPermissions, requireAll) : true;
  }

  private checkTenancySideFeatures({ tenant: { features } }: tenantCheckType, feature: string) {
    return feature ? TenancyFeatureChecker.isEnabled(feature, features) : true;
  }
}