<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div *ngIf="!model?.salaryForm; else notEmpty">
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p>{{ model?.salaryForm | salary }}</p>
      <p *ngIf="model?.salaryForm === 'bankcr'">
        {{ 'TPA.BankAccount' | transloco }}: {{ model.cisloUctu }} /
        {{ model.idUstavu }}
      </p>
      <p *ngIf="model?.salaryForm === 'bankabroad'">
        {{ 'TPA.IBAN' | transloco }}:
        {{ model.iban }}
      </p>
    </ng-template>
  </div>
</ng-template>
