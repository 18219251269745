import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastModule as SyncToastModule } from '@syncfusion/ej2-angular-notifications';
import { RalToastComponent } from './toast.component';

@NgModule({
  imports: [CommonModule, SyncToastModule],
  exports: [RalToastComponent],
  declarations: [RalToastComponent],
  providers: [],
})
export class ToastModule {}
