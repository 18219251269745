import { NameValueDto } from '@tpa/api';

export function mapKeyValueToObject(input: NameValueDto[]): {
  [key: string]: any;
} {
  const obj = {};
  input.forEach((e) => {
    obj[e.name] = e.value;
  });
  return obj;
}
