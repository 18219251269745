<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div *ngIf="!model || !(model?.zdravPojistovna !== 0 && model?.zdravPojistovna); else notEmpty">
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p>
        <span *ngIf="model?.zdravPojistovna !== 0 && model?.zdravPojistovna">
          {{ model?.zdravPojistovna }}
        </span>
        <span *ngIf="model?.zdravPojistovna === 0">{{
          model?.jinaZdravPojistovna
        }}</span>
        <span *ngIf="model?.cisloPojistence">
          ({{ model?.cisloPojistence }})</span
        >
      </p>
      <p *ngIf="model?.zdravotniZnevyhodneni">
        {{ 'TPA.HealthDisadvantage' | transloco }}:
        {{ model?.zdravotniZnevyhodneni | healthDisadvantage }}
      </p>
      <p *ngIf="model?.ztpp">
        {{ 'TPA.Ztpp' | transloco }}: {{ model?.ztpp | yesOrNo }}
      </p>
      <p *ngIf="model?.a1">
        {{ 'TPA.A1' | transloco }}: {{ model?.a1 | yesOrNo }}
      </p>
      <p>
        <span *ngIf="model?.duchody?.pobiramDuchod === true">
          {{ 'TPA.Pensions' | transloco }}:
        </span>
        <span *ngIf="model?.duchody?.predcasny">
          {{ 'TPA.EarlyRetirementPension' | transloco }},
        </span>
        <span *ngIf="model?.duchody?.starobni">
          {{ 'TPA.RetirementPension' | transloco }},
        </span>
        <span *ngIf="model?.duchody?.invalidni">
          {{ 'TPA.DisabilityPension' | transloco }},
        </span>
        <span *ngIf="model?.duchody?.jinyDuchod">
          {{ 'TPA.OtherPension' | transloco }}: {{ model?.duchody?.jiny }}
        </span>
      </p>
    </ng-template>
  </div>
</ng-template>
