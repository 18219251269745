import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { RalbaUiModule } from '@ralba/ui';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { SharedModule } from '@tpa/shared';
import { COMPONENTS } from './components';

const EJS = [ButtonModule, ProgressBarModule];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    RalbaUiModule,
    TranslocoLocaleModule,
    TranslocoModule,
    ...EJS,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, ...EJS, RalbaUiModule],
})
export class TpaUiModule {}
