import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { select, Store } from '@ngrx/store';
import { LocalizationSelectors, ToastActions } from '@ralba/core';
import { ChangeUserLanguageDto, ProfileHttpApiService } from '@tpa/api';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

export interface ChangeLanguageState {
  type: 'DROPDOWN' | 'CARD';
}

@Injectable()
export class ChangeLanguageStore extends ComponentStore<ChangeLanguageState> {
  readonly currentLanguage$: Observable<{ name: string; displayName: string }> =
    this.select(
      this.store$.pipe(
        select(LocalizationSelectors.selectLocalizationCurrentLanguage)
      ),
      ({ displayName, name }) => ({ displayName, name })
    );

  readonly langauges$ = this.select(
    this.store$.pipe(select(LocalizationSelectors.selectLocalizationLanguages)),
    (language) => language
  );
  readonly isCard$ = this.select(({ type }) => type === 'CARD');
  readonly setType = this.updater(
    (state, input: { type: 'DROPDOWN' | 'CARD' }) => ({
      ...state,
      ...input,
    })
  );
  readonly changeLanguage = this.effect(
    (input: Observable<ChangeUserLanguageDto>) => {
      return input.pipe(
        exhaustMap((model) =>
          this.profileService.changeLanguage(model).pipe(
            map(() => [window.location.reload()]),
            catchError(({ error }) => {
              this.store$.dispatch(ToastActions.showToastApiError(error.error));
              return of(null);
            })
          )
        )
      );
    }
  );

  constructor(
    public store$: Store<never>,
    private profileService: ProfileHttpApiService
  ) {
    super({
      type: 'DROPDOWN',
    });
  }
}
