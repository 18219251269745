import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-month-slect',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <tpa-month-select
      [formControl]="$any(formControl)"
      (onChange)="to.change && to.change(field, $event)"
      [allYear]="to.attributes['allYear']"
    ></tpa-month-select>
  `,
})
export class FormlyFieldMonthlySelect extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        allYear: '1 - 12 (Celý rok)',
      },
    },
  };

}
