import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {ComponentCanDeactivate} from '../components/component-can-deactivate';

@Injectable()
export class CanDeactivateUnsavedDataGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean {

    if(!component.canDeactivate()){

        //You have unsaved changes! If you leave, your changes will be lost.
        if (confirm(translate("TPA.UnsavedChanges"))) {
            return true;
        } else {
            return false;
        }
    }
    return true;
  }
}