import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  ApproveOrReturnEmployeeCard,
  CreateEmployeeCardDto,
  EmployeeCardHistoryListDto,
  EmployeeCardMetadataDto,
  ExtendedEmployeeCardDto,
  TenancyRequestDto,
  UpdateEmployeeCardNoteInput,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class ManageEmployeeCardHttpApiService {
  constructor(private rest: RestService) {}

  public get(params: TenancyRequestDto<{ id: number; versionId: number }>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/employee-card/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<ExtendedEmployeeCardDto>>(
      request
    );
  }

  public getMetadata(
    params: TenancyRequestDto<{ id: number; versionId?: number }>
  ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/employee-card/get/metadata',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<EmployeeCardMetadataDto>>(
      request
    );
  }

  public getHistory(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/employee-card/get-history',
      params,
    };
    return this.rest.request<
      null,
      Rest.AbpResponse<EmployeeCardHistoryListDto[]>
    >(request);
  }

  public getSummary(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/employee-card/get-summary',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<any>>(request);
  }

  public create(body: TenancyRequestDto<CreateEmployeeCardDto>) {
    const request: Rest.Request<TenancyRequestDto<CreateEmployeeCardDto>> = {
      method: 'POST',
      url: '/api/manage/employee-card/create',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<CreateEmployeeCardDto>,
      Rest.AbpResponse<any>
    >(request);
  }

  public approve(body: TenancyRequestDto<ApproveOrReturnEmployeeCard>) {
    const request: Rest.Request<
      TenancyRequestDto<ApproveOrReturnEmployeeCard>
    > = {
      method: 'PUT',
      url: '/api/manage/employee-card/approve',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<ApproveOrReturnEmployeeCard>,
      Rest.AbpResponse<ExtendedEmployeeCardDto>
    >(request);
  }

  public return(body: TenancyRequestDto<ApproveOrReturnEmployeeCard>) {
    const request: Rest.Request<
      TenancyRequestDto<ApproveOrReturnEmployeeCard>
    > = {
      method: 'PUT',
      url: '/api/manage/employee-card/return',
      body,
    };
    return this.rest.request<
      TenancyRequestDto,
      Rest.AbpResponse<ExtendedEmployeeCardDto>
    >(request);
  }

  public taxDeclarationPDFRequest(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'POST',
      url: '/api/manage/employee-card/request-pdf-tax-declaration',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<ApproveOrReturnEmployeeCard>,
      Rest.AbpResponse<null>
    >(request);
  }

  public toggleWarning(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'PUT',
      url: '/api/manage/employee-card/toggle-warning',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<EntityDto>,
      Rest.AbpResponse<boolean>
    >(request);
  }

  public delete(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: '/api/manage/employee-card/delete',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<void>>(request);
  }

  public getNote(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/manage/employee-card/get/note',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<string>>(request);
  }

  public updateNote(body: TenancyRequestDto<UpdateEmployeeCardNoteInput>) {
    const request: Rest.Request<
      TenancyRequestDto<UpdateEmployeeCardNoteInput>
    > = {
      method: 'PUT',
      url: '/api/manage/employee-card/update/note',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<UpdateEmployeeCardNoteInput>,
      Rest.AbpResponse<void>
    >(request);
  }
}
