import { Platform } from '@angular/cdk/platform';
import { Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppBaseComponent } from '@ralba/toolbox';
// import { Intercom } from 'ng-intercom';
import { CookieService } from 'ngx-cookie-service';


declare const window: Window & { onUsersnapCXLoad?: (api: any) => void };

@Component({
  selector: 'tpa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends AppBaseComponent {
  /**
   *
   */
  private script: HTMLScriptElement | null = null;
  public usersnapApi: any | null = null;
  cookieName = 'TPA-HRPortal-CookieBar';

  constructor(
    private cookieService: CookieService,
    //private intercom: Intercom,
    platform: Platform,
    store$: Store<never>
  ) {
    super(store$, platform);

    if (!this.showCookie) {
      // show cookiebar?
    } else {
      this.afterAllowed();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.cookieService.delete('.AspNetCore.Culture', '/');
  }

  @HostListener('document:keydown.<', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    window.onUsersnapCXLoad = (api: any) => {
      api.init({});
      this.usersnapApi = api;
    };
    this.script = document.createElement('script');
    this.script.defer = false;
    this.script.type = 'text/javascript';
    this.script.src = `https://widget.usersnap.com/global/load/${'c97a412f-81db-4160-aedb-67fdede190e3'}?onload=onUsersnapCXLoad`;
    document.body.appendChild(this.script);
  }

  get showCookie(): any {
    return this.cookieService.get(this.cookieName) == '1' ? true : null;
  }

  afterAllowed() {
    // SetupGTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cookieSet',
    });
  }
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}
