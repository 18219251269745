import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SessionActions } from '@ralba/core';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { map, tap } from 'rxjs/operators';
import { cs } from '../utils/syncfusion-translations';

import { TranslocoLocaleService } from '@ngneat/transloco-locale';

declare let require: any;
// function gridTranslation(values: any) {
//   return {
//     EmptyRecord: values['Grid.EmptyRecord'],
//     TRUE: values['Grid.TRUE'],
//     FALSE: values['Grid.FALSE'],
//     InvalidFilterMessage: values['Grid.InvalidFilterMessage'],
//     GroupDropArea: values['Grid.GroupDropArea'],
//     UnGroup: values['Grid.UnGroup'],
//     GroupDisable: values['Grid.GroupDisable'],
//     FilterbarTitle: values['Grid.FilterbarTitle'],
//     EmptyDataSourceError: values['Grid.EmptyDataSourceError'],
//     Add: values['Grid.Add'],
//     Edit: values['Grid.Edit'],
//     Cancel: values['Grid.Cancel'],
//     Update: values['Grid.Update'],
//     Delete: values['Grid.Delete'],
//     Print: values['Grid.Print'],
//     Pdfexport: values['Grid.Pdfexport'],
//     Excelexport: values['Grid.Excelexport'],
//     Wordexport: values['Grid.Wordexport'],
//     Csvexport: values['Grid.Csvexport'],
//     Search: values['Grid.Search'],
//     Columnchooser: values['Grid.Columnchooser'],
//     Save: values['Grid.Save'],
//     Item: values['Grid.Item'],
//     Items: values['Grid.Items'],
//     EditOperationAlert: values['Grid.EditOperationAlert'],
//     DeleteOperationAlert: values['Grid.DeleteOperationAlert'],
//     SaveButton: values['Grid.SaveButton'],
//     OKButton: values['Grid.OKButton'],
//     CancelButton: values['Grid.CancelButton'],
//     EditFormTitle: values['Grid.EditFormTitle'],
//     AddFormTitle: values['Grid.AddFormTitle'],
//     BatchSaveConfirm: values['Grid.BatchSaveConfirm'],
//     BatchSaveLostChanges: values['Grid.BatchSaveLostChanges'],
//     ConfirmDelete: values['Grid.ConfirmDelete'],
//     CancelEdit: values['Grid.CancelEdit'],
//     ChooseColumns: values['Grid.ChooseColumns'],
//     SearchColumns: values['Grid.SearchColumns'],
//     Matchs: values['Grid.Matchs'],
//     FilterButton: values['Grid.FilterButton'],
//     ClearButton: values['Grid.ClearButton'],
//     StartsWith: values['Grid.StartsWith'],
//     EndsWith: values['Grid.EndsWith'],
//     Contains: values['Grid.Contains'],
//     Equal: values['Grid.Equal'],
//     NotEqual: values['Grid.NotEqual'],
//     LessThan: values['Grid.LessThan'],
//     LessThanOrEqual: values['Grid.LessThanOrEqual'],
//     GreaterThan: values['Grid.GreaterThan'],
//     GreaterThanOrEqual: values['Grid.GreaterThanOrEqual'],
//     ChooseDate: values['Grid.ChooseDate'],
//     EnterValue: values['Grid.EnterValue'],
//     Copy: values['Grid.Copy'],
//     Group: values['Grid.Group'],
//     Ungroup: values['Grid.Ungroup'],
//     autoFitAll: values['Grid.autoFitAll'],
//     autoFit: values['Grid.autoFit'],
//     Export: values['Grid.Export'],
//     FirstPage: values['Grid.FirstPage'],
//     LastPage: values['Grid.LastPage'],
//     PreviousPage: values['Grid.PreviousPage'],
//     NextPage: values['Grid.NextPage'],
//     SortAscending: values['Grid.SortAscending'],
//     SortDescending: values['Grid.SortDescending'],
//     EditRecord: values['Grid.EditRecord'],
//     DeleteRecord: values['Grid.DeleteRecord'],
//     FilterMenu: values['Grid.FilterMenu'],
//     SelectAll: values['Grid.SelectAll'],
//     Blanks: values['Grid.Blanks'],
//     FilterTrue: values['Grid.FilterTrue'],
//     FilterFalse: values['Grid.FilterFalse'],
//     NoResult: values['Grid.NoResult'],
//     ClearFilter: values['Grid.ClearFilter'],
//     NumberFilter: values['Grid.NumberFilter'],
//     TextFilter: values['Grid.TextFilter'],
//     DateFilter: values['Grid.DateFilter'],
//     MatchCase: values['Grid.MatchCase'],
//     Between: values['Grid.Between'],
//     CustomFilter: values['Grid.CustomFilter'],
//     CustomFilterPlaceHolder: values['Grid.CustomFilterPlaceHolder'],
//     CustomFilterDatePlaceHolder: values['Grid.CustomFilterDatePlaceHolder'],
//     AND: values['Grid.AND'],
//     OR: values['Grid.OR'],
//     ShowRowsWhere: values['Grid.ShowRowsWhere'],
//     currentPageInfo: values['Grid.currentPageInfo'],
//     totalItemsInfo: values['Grid.totalItemsInfo'],
//     firstPageTooltip: values['Grid.firstPageTooltip'],
//     lastPageTooltip: values['Grid.lastPageTooltip'],
//     nextPageTooltip: values['Grid.nextPageTooltip'],
//     previousPageTooltip: values['Grid.previousPageTooltip'],
//     nextPagerTooltip: values['Grid.nextPagerTooltip'],
//     previousPagerTooltip: values['Grid.previousPagerTooltip'],
//     pagerDropDown: values['Grid.pagerDropDown'],
//     pagerAllDropDown: values['Grid.pagerAllDropDown'],
//     All: values['Grid.All'],
//   }
// }

// function paggerTranslation(values: any) {
//   return {
//     currentPageInfo: values['Pager.currentPageInfo'],
//     totalItemsInfo: values['Pager.totalItemsInfo'],
//     firstPageTooltip: values['Pager.firstPageTooltip'],
//     lastPageTooltip: values['Pager.lastPageTooltip'],
//     nextPageTooltip: values['Pager.nextPageTooltip'],
//     previousPageTooltip: values['Pager.previousPageTooltip'],
//     nextPagerTooltip: values['Pager.nextPagerTooltip'],
//     previousPagerTooltip: values['Pager.previousPagerTooltip'],
//   }
// }

@Injectable()
export class CoreEffects {
  setSFLocalization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.getAllLoadded),
        map(({ localization }) => localization),
        tap(({ currentCulture, currentLanguage, values }) => {
          this.transloco.setActiveLang(currentCulture.name);
          setCulture(
            currentCulture.name == 'cs-CZ' ? 'cs' : currentCulture.name
          );
          this.service.setLocale(currentCulture.name);
          //const translation = {};
          // translation[currentCulture.name] = {
          //   grid: gridTranslation(values['Syncfusion']),
          //   pager: paggerTranslation(values['Syncfusion'])
          // };
          L10n.load(cs);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<never>,
    private transloco: TranslocoService,
    private service: TranslocoLocaleService
  ) {
    loadCldr(
      require('cldr-data/main/cs/numbers.json'),
      require('cldr-data/main/cs/ca-gregorian.json'),
      require('cldr-data/main/cs/dateFields.json'),
      require('cldr-data/main/cs/delimiters.json'),
      require('cldr-data/main/cs/localeDisplayNames.json'),
      require('cldr-data/supplemental/numberingSystems.json'),
      require('cldr-data/main/cs/timeZoneNames.json'),
      require('cldr-data/supplemental/weekData.json') // To load the culture based first day of week
    );
  }
}
