import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'formly-field-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <ejs-dropdownlist
        [class.ng-dirty]="showError"
        [formControl]="$any(formControl)"
        [fields]="fields"
        [dataSource]="to.options | formlySelectOptions: field | async"
        (change)="to.change && to.change(field, $event)"
        [showClearButton]="true"
        [placeholder]="
          to.placeholderT
            ? t(to.placeholderT['key'], to.placeholderT['params'])
            : to.placeholder
        "
      ></ejs-dropdownlist>
    </ng-container>
  `,
})
export class FormlyFieldSelect
  extends FieldType
  implements AfterViewInit, OnDestroy
{
  public fields: Object = { text: 'label', value: 'value' };

  defaultOptions = {
    props: {
      options: [],
      placeholder: 'Vyberte z možností',
    },
  };

  convertUndefinedToNull: Subscription;
  ngAfterViewInit() {
    this.convertUndefinedToNull = this.formControl.valueChanges.subscribe(
      (p) => {
        if (p === undefined) {
          this.formControl.patchValue(null);
        }
      }
    );
  }
  ngOnDestroy() {
    if (this.convertUndefinedToNull) this.convertUndefinedToNull.unsubscribe();
  }
}
