import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  CompanyDto,
  GetTenantFeaturesEditOutput,
  UpdateCompanyInput,
  UpdateTenantFeaturesInput,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class CompanyHttpApiService {
  constructor(private rest: RestService) {}

  public getCompany(params: EntityDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/companies/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<CompanyDto>>(request);
  }

  public getCompanyFeaturesForEdit(params: EntityDto) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/companies/get/features',
      params,
    };
    return this.rest.request<
      null,
      Rest.AbpResponse<GetTenantFeaturesEditOutput>
    >(request);
  }

  public updateCompanyFeatures(body: UpdateTenantFeaturesInput) {
    const request: Rest.Request<UpdateTenantFeaturesInput> = {
      method: 'PUT',
      url: '/api/companies/update/features',
      body,
    };
    return this.rest.request<UpdateTenantFeaturesInput, Rest.AbpResponse<null>>(
      request
    );
  }

  public updateCompany(body: UpdateCompanyInput) {
    const request: Rest.Request<UpdateCompanyInput> = {
      method: 'PUT',
      url: '/api/companies/update',
      body,
    };
    return this.rest.request<UpdateCompanyInput, Rest.AbpResponse<null>>(
      request
    );
  }
}
