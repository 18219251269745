import { TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
export class TranslateExtension {
  constructor(private translate: TranslocoService) {}
  prePopulate(field: FormlyFieldConfig) {
    const to = field.props || {};
    if (!to.translate || to._translated) {
      return;
    }

    let paramsForTranslations = {};

    if (to.label) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.label': this.translate.selectTranslate(to.label),
      };
    }

    if (to.description) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.description': this.translate.selectTranslate(
          to.description
        ),
      };
    }

    if (to.linkText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.linkText': this.translate.selectTranslate(
          to.linkText
        ),
      };
    }
    if (to.link) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.link': this.translate.selectTranslate(
          to.link
        ),
      };
    }
    if (to.secondaryText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.secondaryText': this.translate.selectTranslate(
          to.secondaryText
        ),
      };
    }
    if (to.tercialyText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.tercialyText': this.translate.selectTranslate(
          to.tercialyText
        ),
      };
    }

    if (to.placeholder) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.placeholder': this.translate.selectTranslate(
          to.placeholder
        ),
      };
    }

    if (to.tooltip) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.tooltip': this.translate.selectTranslate(to.tooltip),
      };
    }

    if (to.checkboxLabel) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.checkboxLabel': this.translate.selectTranslate(
          to.checkboxLabel
        ),
      };
    }

    if (to.checkboxHtml) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.checkboxHtml': this.translate.selectTranslate(
          to.checkboxHtml
        ),
      };
    }

    if (to.addText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.addText': this.translate.selectTranslate(to.addText),
      };
    }

    if (field.template) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        template: this.translate.selectTranslate(field.template),
      };
    }

    if (to.removeTextTitle) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.removeTextTitle': this.translate.selectTranslate(
          to.removeTextTitle
        ),
      };
    }

    if (to.yesText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.yesText': this.translate.selectTranslate(to.yesText),
      };
    }

    if (to.noText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.noText': this.translate.selectTranslate(to.noText),
      };
    }

    if (to.anotherAddress) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.anotherAddress': this.translate.selectTranslate(
          to.anotherAddress
        ),
      };
    }

    if (to.actionText) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.actionText': this.translate.selectTranslate(
          to.actionText
        ),
      };
    }

    if (to.options && to.translateOptions) {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.options': combineLatest([
          Array.isArray(to.options)
            ? of(to.options as { label: string; value: string | number }[])
            : to.options,
          this.translate.selectTranslation(),
        ]).pipe(
          map(([options, translations]) =>
            options.map((p) => ({
              label: translations[p.label] || p.label,
              value: p.value,
            }))
          )
        ),
      };
    }

    if (field.type == 'date-picker') {
      to._translated = true;
      paramsForTranslations = {
        ...paramsForTranslations,
        'props.dateFormat':
          this.translate.selectTranslate('dateformat'),
      };
    }

    field.expressionProperties = {
      ...(field.expressionProperties || {}),
      ...paramsForTranslations,
    };
  }
}

export function registerTranslateExtension(translate: TranslocoService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message() {
          return translate.translate('TPA.FieldRequired');
        },
      },
    ],
    extensions: [
      {
        name: 'translate',
        extension: new TranslateExtension(translate),
      },
    ],
  };
}
