import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  template: `
    <div
      id="loading"
      style="font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; font-size: 20px; position:absolute; top: 50%;left:50%; transform: translate(-50%,-50%)"
    >
      <img
        src="/assets/images/laoding.gif"
        alt="Loading"
        style="width: 25px; display: inline-block; vertical-align: middle;"
      />
      <span style="vertical-align: middle; display: inline-block;">
        Authentication check...
      </span>
    </div>
  `,
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    public router: Router,
    public oidcSecurityService: OidcSecurityService
  ) {
    // this.oidcSecurityService
    //   .checkAuth()
    //   .pipe(
    //     tap((x) => {
    //       console.log('is authorized', x);
    //       if (!x) {
    //         this.oidcSecurityService.authorize();
    //       } else {
    //         const route = sessionStorage.getItem('redirect_uri') || '/';
    //         console.log('UnauthorizedComponent', route);
    //         this.router.navigate([route]);
    //       }
    //     }),
    //     first()
    //   )
    //   .subscribe();
  }
  ngOnInit(): void {
    this.oidcSecurityService.authorize();
  }
}

@Component({
  template: ` <div
    id="loading"
    style="font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; font-size: 20px; position:absolute; top: 50%;left:50%; transform: translate(-50%,-50%)"
  >
    <img
      src="/assets/images/laoding.gif"
      alt="Loading"
      style="width: 25px; display: inline-block; vertical-align: middle;"
    />
    <span style="vertical-align: middle; display: inline-block;">
      Unauthorized, redirecting...
    </span>
  </div>`,
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    public router: Router,
    public oidcSecurityService: OidcSecurityService
  ) {}

  //ref
  ngOnInit(): void {
    //console.log()
    this.oidcSecurityService
      .checkAuthIncludingServer()
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          //this.ref = setTimeout(() => {
          this.router.navigate(['/', 'auth', 'unauthorized']);
          //}, 2500);
        }
        if (isAuthenticated) {
          //clearTimeout(this.ref)
          const route = sessionStorage.getItem('redirect_uri') || '/';
          window.location.replace(route);
        }
      });
  }
}
