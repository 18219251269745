import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { SessionService } from '../services';

@Injectable()
export class AuthenticationGurad implements CanActivate, CanActivateChild {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private sessionService: SessionService,
    private router: Router
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      exhaustMap((isAuthorized: boolean) => {
        if (!isAuthorized) {
          sessionStorage.setItem('redirect_uri', state.url);
          this.router.navigate(['/', 'auth', 'unauthorized']);
          return of(false);
        }

        return this.sessionService
          .getCurrentLoginInformationsAndStoreResult()
          .pipe(map(() => true));
      })
    );
  }
}
