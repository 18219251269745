import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'ral-heading',
  template: `
    <ng-container *transloco="let t">
      <div class="heading-form-wrapper">
        <h2 *ngIf="to.label && to.hideLabel !== true">
          {{ to.labelT ? t(to.labelT['key'], to.labelT['params']) : to.label }}
        </h2>
        <p class="heading-form__p" *ngIf="to.description">
          {{
            to.descriptionT
              ? t(to.descriptionT['key'], to.descriptionT['params'])
              : to.description
          }}
        </p>
      </div>
    </ng-container>
  `,
  styles: ['h2{padding-top: 24px;padding-bottom: 8px}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent extends FieldType {
  ngOnInit() {}
}
