import { combineReducers } from '@ngrx/store';
import * as userTenancies from './usertenancies.reducer';

export const FEATURE_KEY = 'tpaCore';

export interface ITpaCoreState {
  userTenancies: userTenancies.State;
}

export function reducers(state, action) {
  return combindedReducers(state, action);
}

const combindedReducers = combineReducers({
  userTenancies: userTenancies.reducer,
});

export { userTenancies };
