import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { LetModule, PushModule } from '@ngrx/component';
import { ApiModule } from '@tpa/api';
import { AttachmentPreviewModalModule } from '@tpa/features/misc';
import { SharedModule } from '@tpa/shared';
import { TpaUiModule } from '@tpa/ui';
import { DocumentsTipsListContainer } from './documents-tips-list.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LetModule,
    PushModule,
    SharedModule,
    TpaUiModule,
    ApiModule,
    TranslocoLocaleModule,
    AttachmentPreviewModalModule,
    TranslocoModule,
  ],
  declarations: [DocumentsTipsListContainer],
  exports: [DocumentsTipsListContainer],
  providers: [],
})
export class FeatureDocumentsTipsListModule {}
