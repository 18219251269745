import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { DocumentWithTenant, DocumentsTipsListStore } from './documents-tips-list.store.service';

@Component({
  selector: 'tpa-documents-tips-list',
  templateUrl: 'documents-tips-list.component.html',
  styleUrls: ['./documents-tips-list.component.scss'],
  providers: [DocumentsTipsListStore],
})
@UntilDestroy()
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DocumentsTipsListContainer {
  @Input() hideHeading = false;
  @Output() linkClick = new EventEmitter<DocumentWithTenant>();

  constructor(public state: DocumentsTipsListStore) { }

  @Input() set userId(value: number) {
    this.state.updateUserId({ userId: value });
  }

  @Input() set tagId(value: string) {
    this.state.updateTagId({ tagId: value });
  }

  @Input() set validFrom(value: string | null) {
    if (value)
      this.state.updateValidFrom({ validFrom: value });
  }
}
