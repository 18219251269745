import { createFeatureSelector, createSelector } from '@ngrx/store';
import { navMenu } from '../reducers';
import { CoreSelectors } from './core.selectors';
import { SessionSelectors } from './session.selectors';
const sessionFeatureSelect =
  createFeatureSelector<navMenu.NavMenuState>('navMenu');

const selectNavMenu = createSelector(
  sessionFeatureSelect,
  navMenu.selectNavMenu
);

const selectSideNavOpen = createSelector(
  sessionFeatureSelect,
  navMenu.selectSideNavOpen
);

const selectFNavMenu = createSelector(
  CoreSelectors.selectAuth,
  selectNavMenu,
  SessionSelectors.selectIsLoaded,
  (permissions, navMenuItems, IsLoaded) => ({
    permissions,
    navMenuItems,
    IsLoaded,
  })
);

export const NavMenuSelectors = {
  selectNavMenu,
  selectFNavMenu,
  selectSideNavOpen,
};
