import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class OpenEverythingService {
  private _openEverything = new BehaviorSubject<number>(1);
  public openEverything$ = this._openEverything.asObservable();
  
  openEverything(){
    this._openEverything.next(new Date().getUTCMilliseconds())
  }
}