<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div *ngIf="!(model && (model.jmeno || model.prijmeni)); else notEmpty">
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p>
        {{ model?.titulpred }}
        {{ model?.jmeno }}
        {{ model?.prijmeni }}
        {{ model?.titulza }}
        <span *ngIf="model?.rodnePrijmeni">
          {{ '(' + model?.rodnePrijmeni + ')' }}
        </span>
      </p>
    </ng-template>

    <!-- <p>{{ 'TPA.MaidenName' | transloco }}: {{ model?.rodnePrijmeni }}</p> -->
    <p>
      <!-- {{ 'TPA.DateOfBirth' | transloco }}: -->
      <span *ngIf="isValid(parseISO(model?.datumNarozeni)); else stringVersion">{{ model?.datumNarozeni | translocoDate: { dateStyle: 'medium' } }}</span>
      <ng-template #stringVersion>{{ model?.datumNarozeni }}</ng-template>
      <!-- {{ 'TPA.inSpot' | transloco }}  -->
      <span *ngIf="model?.mistoNarozeni">, {{ model?.mistoNarozeni }} </span>
      <span *ngIf="model?.rodneCislo"> - {{ model?.rodneCislo }} </span>
    </p>
    <!-- <p>{{ 'TPA.BirthNumber' | transloco }}: {{ model?.rodneCislo }}</p> -->
    <p>
      {{ model?.pohlavi | gender }}
      <span *ngIf="model?.rodinnyStav"
        >, {{ model?.rodinnyStav | maritalStatus }}</span
      >
      <span *ngIf="model?.vzdelani"
        >, {{ model?.vzdelani | educationLevel }}</span
      >
    </p>
    <p *ngIf="model?.pocetVychDeti">
      {{ 'TPA.NumberOfChildren' | transloco }}: {{ model?.pocetVychDeti }}
    </p>
    <p *ngIf="model?.statniPrislusnost">
      {{ 'TPA.Nationality' | transloco }}:
      {{ 'TPA.' + model?.statniPrislusnost | transloco }}
    </p>
    <p *ngIf="model?.druhaStatniPrislusnost">
      {{ 'TPA.SecondNationality' | transloco }}:
      {{ 'TPA.' + model?.druhaStatniPrislusnost | transloco }}
    </p>
  </div>
</ng-template>
<!-- <pre>
    {{ this.model? | json }}
  </pre
> -->
