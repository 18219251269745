<ng-container *ngIf="loaded; else loadingTemplate">
  <ng-content></ng-content>
</ng-container>

<ng-template #loadingTemplate>
  <ejs-progressbar
    [id]="id"
    type="Circular"
    height="60"
    value="20"
    [isIndeterminate]="true"
    [animation]="animation"
  >
  </ejs-progressbar>
</ng-template>
