import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'salary',
})
export class SalaryPipe implements PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform, PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText = '';
    switch (value) {
      case 'cash': {
        returnedText = translate('TPA.Cash');
        break;
      }
      case 'bankcr': {
        returnedText = translate('TPA.BankAccountCzech');
        break;
      }
      case 'bankabroad': {
        returnedText = translate('TPA.BankAccountForeign');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
