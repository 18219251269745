import { Injectable } from '@angular/core';
import { AbpConsts, EntityDto, Rest, RestService } from '@ralba/shared';
import {
  AppendMessagesInputDto,
  AttachmentDto,
  CreatePayrollRecapInputDto,
  GetArtifactAttachments,
  GetMessages,
  MessageDto,
  PayrollRecapDto,
  PayrollRecapOperationInputDto,
  TenancyRequestDto,
  UploadArtifactAttachment,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class PayrollRecapHttpApiService {
  constructor(private rest: RestService) {}

  public create(body: TenancyRequestDto<CreatePayrollRecapInputDto>) {
    const request: Rest.Request<TenancyRequestDto<CreatePayrollRecapInputDto>> =
      {
        method: 'POST',
        url: '/api/payroll-recap/create',
        body,
      };

    return this.rest.request<
      TenancyRequestDto<CreatePayrollRecapInputDto>,
      Rest.AbpResponse<number>
    >(request);
  }

  public get(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/payroll-recap/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<PayrollRecapDto>>(request);
  }

  public approve(body: TenancyRequestDto<PayrollRecapOperationInputDto>) {
    const request: Rest.Request<
      TenancyRequestDto<PayrollRecapOperationInputDto>
    > = {
      method: 'PUT',
      url: '/api/payroll-recap/approve',
      body,
    };

    return this.rest.request<
      TenancyRequestDto<PayrollRecapOperationInputDto>,
      Rest.AbpResponse<PayrollRecapDto>
    >(request);
  }

  public invalidate(body: TenancyRequestDto<PayrollRecapOperationInputDto>) {
    const request: Rest.Request<
      TenancyRequestDto<PayrollRecapOperationInputDto>
    > = {
      method: 'PUT',
      url: '/api/payroll-recap/invalidate',
      body,
    };

    return this.rest.request<
      TenancyRequestDto<PayrollRecapOperationInputDto>,
      Rest.AbpResponse<PayrollRecapDto>
    >(request);
  }

  public reject(body: TenancyRequestDto<PayrollRecapOperationInputDto>) {
    const request: Rest.Request<
      TenancyRequestDto<PayrollRecapOperationInputDto>
    > = {
      method: 'PUT',
      url: '/api/payroll-recap/reject',
      body,
    };

    return this.rest.request<
      TenancyRequestDto<PayrollRecapOperationInputDto>,
      Rest.AbpResponse<PayrollRecapDto>
    >(request);
  }

  /* UTILS */
  public getAttachmentUrl(id: string, userId: number, tenantId: number) {
    let url = `${AbpConsts.remoteServiceBaseUrl}/PayrollRecapAttachemnt/${id}`;
    url +=
      tenantId !== null || tenantId !== undefined
        ? `/${tenantId}/${userId}`
        : '';
    return url;
  }

  public getServiceUrl(userId: number, tenantId: number) {
    let url = `${AbpConsts.remoteServiceBaseUrl}/PayrollRecapPdf`;
    url +=
      tenantId !== null || tenantId !== undefined
        ? `/${tenantId}/${userId}`
        : '';
    return url;
  }

  public getAttachments(params: TenancyRequestDto<GetArtifactAttachments>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/payroll-recap/get/attachments',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<AttachmentDto[]>>(request);
  }

  public uploadAttachment(body: TenancyRequestDto<UploadArtifactAttachment>) {
    const request: Rest.Request<TenancyRequestDto<UploadArtifactAttachment>> = {
      method: 'POST',
      url: '/api/payroll-recap/upload/attachments',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<UploadArtifactAttachment>,
      Rest.AbpResponse<null>
    >(request);
  }

  public getMessages(params: TenancyRequestDto<GetMessages>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/payroll-recap/get/messages',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<MessageDto[]>>(request);
  }

  public appendMessage(body: TenancyRequestDto<AppendMessagesInputDto>) {
    const request: Rest.Request<TenancyRequestDto<AppendMessagesInputDto>> = {
      method: 'PUT',
      url: '/api/payroll-recap/append/messages',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<AppendMessagesInputDto>,
      Rest.AbpResponse<MessageDto>
    >(request);
  }
}
