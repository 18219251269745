import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as _ from '../reducers';

const selectState = createFeatureSelector<_.CookieBarState>(_.FEATURE_KEY);

const selectCookieDetactions = createSelector(
  selectState,
  _.selectCookieDetactions
);

const selectCookieEnable = createSelector(selectState, _.selectCookieEnable);

const selectCookieOpen = createSelector(selectState, (a) => {
  return a.enable === null || a.enable === undefined;
});

export const CookieBarSelectors = {
  selectCookieDetactions,
  selectCookieEnable,
  selectCookieOpen,
};
