import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { specialCharsParser } from './utils';

export const sharedSalary = (): FormlyFieldConfig[] => [
  {
    key: 'salaryForm',
    type: 'select',
    className: 'p-col-12',
    props: {
      label: 'TPA.SalaryForm',
      placeholder: 'TPA.SelectPlaceholder',
      required: true,
      translate: true,
      translateOptions: true,
      options: [
        { label: 'TPA.BankAccountCzech', value: 'bankcr' },
        { label: 'TPA.BankAccountForeign', value: 'bankabroad' },
        { label: 'TPA.Cash', value: 'cash' },
      ],
    },
  },
  {
    template: `<p>${translate('TPA.Guide.Step5CzechAccount')}</p>`,
    className: 'p-col-12',
    hideExpression: '(model?.salaryForm !== "bankcr")',
  },
  {
    key: 'cisloUctu',
    type: 'input',
    className: 'p-col',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.BankAccount',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.salaryForm !== "bankcr")',
  },
  {
    className: 'p-fixed grow-0',
    template: '<span class="fake-label block"> </span><span class="py-2 block">/</span>',
    hideExpression: '(model?.salaryForm !== "bankcr")',
  },
  {
    key: 'idUstavu',
    type: 'input',
    className: 'p-col-4',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.Bank',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.salaryForm !== "bankcr")',
  },
  {
    template: `<p>${translate('TPA.Guide.Step5AbroadAccount')}</p>`,
    className: 'p-col-12',
    hideExpression: '(model?.salaryForm !== "bankabroad")',
  },
  {
    key: 'iban',
    type: 'input',
    className: 'p-col-12',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.IBAN',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.salaryForm !== "bankabroad")',
  },
];
