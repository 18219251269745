import {
  RouterReducerState,
  SerializedRouterStateSnapshot,
  getSelectors,
  routerReducer,
} from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { Data, Params } from '@angular/router';
import * as core from './core.reducer';
import * as navMenu from './nav-manu.reducer';
import * as session from './session.reducer';

export * from './core.reducer';
export * from './nav-manu.reducer';
export * from './session.reducer';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
  histroy: [];
}

export interface RootState {
  router: RouterReducerState<SerializedRouterStateSnapshot>;
  core: core.CoreState;
  session: session.SessionState;
  navMenu: navMenu.NavMenuState;
}

export const rootReducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  core: core.coreReducer,
  session: session.sessionReducer,
  navMenu: navMenu.navMenuReducer,
};

export const selectRouter = createFeatureSelector<
  RootState,
  RouterReducerState<any>
>('router');

export const {
  selectCurrentRoute,
  selectQueryParam,
  selectRouteParam,
  selectQueryParams, // select the current route query params
  selectRouteParams, // select the current route params
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors(selectRouter);

export { core, navMenu, session };
