<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    class="mt-2"
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div class="mt-2">
      <div class="e-card-header p-0" style="padding: 0">
        {{ 'TPA.TaypayerDiscount' | transloco }}
      </div>
    </div>
    <div [ngSwitch]="model?.monthDiscount?.zakladniSlevaNaPoplatnika?.value" class="pt-2">
      <p *ngSwitchCase="true">
        {{ 'TPA.Applied' | transloco }}
      </p>
      <p *ngSwitchCase="false">
        {{ 'TPA.NotApplied' | transloco }}
      </p>
      <p *ngSwitchDefault>{{ 'TPA.NotFilled' | transloco }}</p>
    </div>

    <div class="space-12"></div>
    <div class="e-card-header p-0" style="padding: 0">
      {{ 'TPA.soucasneNeuplatnuji' | transloco }}
    </div>
    <div [ngSwitch]="model?.soucasneNeuplatnuji" class="pt-2">
      <p *ngSwitchCase="true">
        {{ 'TPA.soucasneNeuplatnujiYes' | transloco }}
      </p>
      <p *ngSwitchCase="false">
        {{ 'TPA.soucasneNeuplatnujiNo' | transloco }}
      </p>
      <p *ngSwitchDefault>{{ 'TPA.NotFilled' | transloco }}</p>
    </div>
  </div>
</ng-template>
