import { Component, HostBinding } from '@angular/core';
import { translate } from '@ngneat/transloco';
import {
  FieldType,
  FormlyExtension,
  FormlyFieldConfig
} from '@ngx-formly/core';
import { countryOptions } from '@tpa/features/emp-data';
import { sharedQuestion } from '../../form-parts-definition';

@Component({
  selector: 'tpa-rezidentura',
  templateUrl: './rezidentura.component.html',
  // TODO: TPA : Uncomment this
  // host: { class: 'tpa-wrapper__control' },
})
export class RezidenturaComponent extends FieldType implements FormlyExtension {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: false,
    },
  };

  @HostBinding('class.p-grid') someField = true;

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = [
      // {
      //   template: `
      //     <h3 class="h4">${translate('TPA.ResidentureQ1')}</h3>
      //     <p class="description">${translate('TPA.ResidentureQ1Desc')}</p>
      //   `,
      // },
      // {
      //   key: 'zdrzovaniVCR',
      //   type: 'yes-or-no',
      //   className: 'p-col-12',
      //   props: {
      //     required: true,
      //     translate: true,
      //     yesText: 'TPA.Yes',
      //     noText: 'TPA.No',
      //   },
      //   expressionProperties: {
      //     'props.disabled': (m, formState, field) => {
      //       return field?.model?.rezidentura != null;
      //     },
      //   },
      // },
      {...sharedQuestion({
        key: 'zdrzovaniVCR',
        className: 'p-col-12',
        props: {
          required: true,
          translate: true,
          label: 'TPA.ResidentureQ1',
          description: 'TPA.ResidentureQ1Desc',
          yesText: 'TPA.Yes',
          noText: 'TPA.No',
        },
        expressionProperties: {
          'props.disabled': (m, formState, field) => {
            return field?.model?.rezidentura != null;
          },
        },
      })},
      // {
      //   template: `
      //   <h3 class="h4">${translate('TPA.ResidentureQ2')}</h3>
      //     <p class="description">${translate('TPA.ResidentureQ2Desc')}</p>
      //   `,
      //   hideExpression:
      //     /* '(model?.prvniZamestnaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))', */
      //     '(model?.zdrzovaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))',
      // },
      // {
      //   key: 'studiumVCR',
      //   type: 'yes-or-no',
      //   className: 'p-col-12',
      //   props: {
      //     required: true,
      //     translate: true,
      //     yesText: 'TPA.Yes',
      //     noText: 'TPA.No',
      //     // change: (field) => {
      //     //   field.parent.fieldGroup?//   .find((p) => p.key == 'rezidentura')?//   .formControl?.patchValue("cz");
      //     // },
      //   },
      //   hideExpression:
      //     /* '(model?.prvniZamestnaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))', */
      //     '(model?.zdrzovaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))',
      //   expressionProperties: {
      //     'props.disabled': (m, formState, field) =>
      //       field?.model?.rezidentura != null,
      //   },
      // },
      {...sharedQuestion({
        key: 'studiumVCR',
        props: {
          label: 'TPA.ResidentureQ2',
          description: 'TPA.ResidentureQ2Desc',
          required: true,
          translate: true,
          yesText: 'TPA.Yes',
          noText: 'TPA.No',
        },
        hideExpression:
          /* '(model?.prvniZamestnaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))', */
          '(model?.zdrzovaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))',
        expressionProperties: {
          'props.disabled': (m, formState, field) =>
            field?.model?.rezidentura != null,
        },
      })},
      {
        className: 'p-col-12',
        template: `
          <h3 class="h4">${translate('TPA.Evaluation')}</h3>
          <p class="">${translate('TPA.EvaluationN')}</p>
        `,
        hideExpression: '(model?.rezidenturaVyhodnoceni != "false")',
      },
      {
        className: 'p-col-12',
        template: `
          <h3 class="h4">${translate('TPA.Evaluation')}</h3>
          <p class="">${translate('TPA.EvaluationR')}</p>
        `,
        hideExpression: '(model?.rezidenturaVyhodnoceni != "true")',
      },
      {
        className: 'p-col-12',
        template: `
          <h3 class="h4">${translate('TPA.Evaluation')}</h3>
          <p class="">${translate('TPA.EvaluationC')}</p>
        `,
        hideExpression: '(model?.rezidenturaVyhodnoceni != "konzultace")',
      },

      {
        key: 'rezidenturaVyhodnoceni',
        type: 'select',
        className: 'p-col-12 p-md-6',
        props: {
          placeholder: 'TPA.SelectPlaceholder',
          translate: true,
          translateOptions: true,
          options: [
            { label: 'TPA.Consultation', value: 'konzultace' },
            { label: 'TPA.Rezident', value: 'true' },
            { label: 'TPA.Nerezident', value: 'false' },
          ],
          label: 'TPA.ResidentureRecognition',
        },
        hideExpression: true,
      },
      {
        key: 'rezidentura',
        type: 'select',
        className: 'p-col-12 p-xl',
        props: {
          required: true,
          translate: true,
          translateOptions: true,
          placeholder: 'TPA.SelectPlaceholder',
          options: [
            {
              label: 'TPA.Consultation',
              value: 'konzultace',
            },
            { label: 'TPA.Rezident', value: 'true' },
            { label: 'TPA.Nerezident', value: 'false' },
          ],
          label: 'TPA.ResidentureDeclaration',
        },
        hideExpression: '(model?.rezidentura == null)',
      },
      {
        key: 'rezidenturaVeState',
        type: 'select',
        className: 'p-col',
        props: {
          required: true,
          placeholder: 'TPA.SelectPlaceholder',
          translate: true,
          translateOptions: true,
          options: [...countryOptions()],
          label: 'TPA.ResidentureCountry',
        },
        validators: {
          validation: [{ name: 'validateNonRezidentHasToBeOutsideCzech' }],
        },
        hideExpression:
          '(model?.rezidentura == null) || (model?.rezidentura == "true") || (model?.rezidentura == "konzultace")',
      },
      {
        key: 'identifikace',
        type: 'input',
        className: 'p-col',
        props: {
          label: 'TPA.ResidentureIdentification',
          translate: true,
        },
        hideExpression:
          '(model?.rezidenturaVeState == null) || (model?.rezidenturaVeState == "CZ" || model?.rezidentura === "true") ',
      },
      {
        className: 'p-col-12',
        template: `
          <p class="font-bold">${translate('TPA.RezidentInfo')}</p>
        `,
        hideExpression:
          '(model?.rezidenturaVyhodnoceni == null) || (model?.rezidentura != "true")',
      },
      {
        className: 'p-col-12',
        template: `
          <p class="font-bold">${translate('TPA.NerezidentInfo')}</p>
        `,
        hideExpression:
          '(model?.rezidenturaVyhodnoceni == null) || (model?.rezidentura != "false")',
      },
      {
        className: 'p-col-12',
        template: `
          <p class="font-bold">${translate('TPA.ConsultationInfo')}</p>
        `,
        hideExpression:
          '(model?.rezidenturaVyhodnoceni == null) || (model?.rezidentura != "konzultace")',
      },
      {
        type: 'tigger-button',
        className: 'p-col-12',
        props: {
          label: 'TPA.ResidentureBtn',
          translate: true,
          change: (field) => {
            field?.parent?.fieldGroup
              ?.find((p) => p.key == 'rezidenturaVyhodnoceni')
              ?.formControl?.patchValue(null);
            field?.parent?.fieldGroup
              ?.find((p) => p.key == 'studiumVCR')
              ?.formControl?.patchValue(null);
            field?.parent?.fieldGroup
              ?.find((p) => p.key == 'zdrzovaniVCR')
              ?.formControl?.patchValue(null);
            field?.parent?.fieldGroup
              ?.find((p) => p.key == 'rezidenturaVeState')
              ?.formControl?.patchValue(null);

            field?.parent?.fieldGroup
              ?.find((p) => p.key == 'rezidentura')
              ?.formControl?.patchValue(null);
          },
        },
        hideExpression: '(model?.rezidentura == null)',
      },
    ];
  }
}
