import { Injectable, NgModule } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TranslocoLoader,
  TranslocoModule,
  translocoConfig,
} from '@ngneat/transloco';
import { Store, select } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { CoreSelectors } from './selectors/core.selectors';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private store: Store<never>) {}

  getTranslation(lang: string) {
    return this.store.pipe(
      select(CoreSelectors.selectTranslocoTranslations),
      filter((f) => f.isLoaded),
      map(({ translations }) => translations)
    );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['fr'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: false,
        interpolation: ['{', '}'],
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
