import { AdminComponentBase } from './base.component';
import { Store, Action } from '@ngrx/store';
import { Directive } from '@angular/core';

@Directive()
export abstract class AdminContainerBase extends AdminComponentBase {
  constructor(protected store$: Store<never>) {
    super();
  }

  protected dispatch(action: Action) {
    this.store$.dispatch(action);
  }
}
