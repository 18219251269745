import { Karta, KartaHeO, Settlement, Zuctovani } from './employee.model';

export const addressNull = {
  ulice: null,
  popCislo: null,
  orCislo: null,
  mesto: null,
  psc: null,
  zeme: null,
};

export const HeONULL: KartaHeO = {
  jmeno: undefined,
  prijmeni: undefined,
  email: undefined,
  permanentniAdresa: {
    ...addressNull,
  },
  kontaktniAdresa: {
    ...addressNull,
  },
  prechodnaAdresa: {
    ...addressNull,
  },
  titulpred: undefined,
  titulza: undefined,
  rodnePrijmeni: undefined,
  rodneCislo: undefined,
  datumNarozeni: undefined,
  mistoNarozeni: undefined,
  rodinnyStav: undefined,
  vzdelani: undefined,
  pocetVychDeti: undefined,
  statniPrislusnost: undefined,
  cisloUctu: undefined,
  idUstavu: undefined,
  iban: undefined,
  cisloDokladu: undefined,
  datumExpirace: undefined,
  chciProhlaseni: undefined,
  datumNastupu: undefined,
};

export const VariantaNull: Karta = {
  ...HeONULL,
  dodatecneAtributy: {},
  deti: [
    // {
    //   jmeno: undefined,
    //   prijmeni: undefined,
    //   rodneCislo: undefined,
    //   uplatneni: [
    //     {
    //       narokVeVysi: undefined,
    //       ztpp: undefined,
    //       zletileDite: undefined,
    //     },
    //   ],
    // },
  ],

  permanentniAdresa: {
    ...addressNull
  },
  kontaktniAdresa: {
   ...addressNull
  },
  prechodnaAdresa: {
    ...addressNull
  },
  zdravotniPojistovnaAStav: {
    duchody: {
      pobiramDuchod: undefined,
      predcasny: undefined,
      starobni: undefined,
      invalidni: undefined,
      jiny: undefined,
      jinyDuchod: undefined,
    },
    zdravPojistovna: undefined,
    jinaZdravPojistovna: undefined,
    cisloPojistence: undefined,
    zdravotniZnevyhodneni: undefined,
    ztpp: undefined,
    a1: undefined,
  },
  rezidentura: null,
  rezidenturaVyhodnoceni: null,
  jmeno: undefined,
  prijmeni: undefined,
  email: undefined,
  titulpred: undefined,
  titulza: undefined,
  rodnePrijmeni: undefined,
  pohlavi: undefined,
  datumExpirace: undefined,
  datumNarozeni: undefined,
  mistoNarozeni: undefined,
  rodneCislo: undefined,
  rodinnyStav: undefined,
  vzdelani: undefined,
  pocetVychDeti: undefined,
  statniPrislusnost: undefined,
  druhaStatniPrislusnostCheck: undefined,
  allowContactAddress: undefined,
  zdrzovaniVCR: undefined,
  /* prvniZamestnaniVCR: undefined, */
  studiumVCR: undefined,
  salaryForm: undefined,
  cisloUctu: undefined,
  idUstavu: undefined,
  exekuce: undefined,
  prohlaseniKProhlaseni: undefined,
  chciProhlaseni: undefined,
  zakladniSlevaNaPoplatnika: undefined,
  mamdeti: undefined,
  dalsiPoplatnik: undefined,
  jmenoPoplatnika: undefined,
  prijmeniPoplatnika: undefined,
  rodneCisloPoplatnika: undefined,
  trvalyPobytPoplatnika: undefined,
  uplatnujePoplatnik: undefined,
  zamestnaniPoplatnika: undefined,
  platceDanePoplatnika: undefined,
  adresaPlatceDanePoplatnika: undefined,
  soucasneNeuplatnuji: undefined,
  pravdivostUdaju: undefined,
  iban: undefined,
  cisloDokladu: undefined,
  datumNastupu: undefined,
};

export const NULLZuctovani: Zuctovani = {
  dalsiZamestnani: null,
  dalsiZamestnavatele: [],
  manzelka: null,
  manzelkaJmeno: null,
  manzelkaPrijmeni: null,
  manzelkaRodneCislo: null,
  manzelkaMesice: null,
  manzelkaZTP: null,
  manzelkaZTPMesice: null,
  // skolkovne: null,
  skolkovneDeti: [],
  dary: null,
  daryCastka: null,
  uver: null,
  uverCastka: null,
  uverAdresa: null,
  uverUcel: null,
  // uverJinaOsoba: null,
  uverJineOsoby: [],
  prohlaseniUver: null,
  prohlaseniUver2: null,
  penzijni: null,
  penzijniCastka: null,
  exekuceDiscount: null,
  zivotni: null,
  zivotniCastka: null,
  prispevky: null,
  prispevkyCastka: null,
  zkousky: null,
  zkouskyCastka: null,
};

export const VariantaNullSettlement: Settlement = {
  // ...VariantaNull,
  zuctovani: NULLZuctovani,
  zada: null,
  potvrzeni: null,
  dodetecneUplatneni: null,
};
