import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Dite, FormKarta } from '@tpa/features/emp-data';
import { Observable } from 'rxjs';
import { DataCoreStore } from './core-data.state';

export interface ITipsState<T> {
  currentMapped: T;
  lastApprovedMapped: T;
}

@Injectable()
export abstract class TipsCoreState<
  T extends ITipsState<K>,
  K
> extends ComponentStore<T> {
  constructor(public data: DataCoreStore<T, K>) {
    super({
      currentMapped: null,
      lastApprovedMapped: null,
    } as T);
  }

  readonly displayTooltipA1$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayTooltipA1({ currentMapped, lastApprovedMapped })
  );

  readonly displayZTPP$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayZTPP({ currentMapped, lastApprovedMapped })
  );
  readonly displayZZ$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayZZ({ currentMapped, lastApprovedMapped })
  );
  readonly displayInvalidniDuchod$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayInvalidniDuchod({ currentMapped, lastApprovedMapped })
  );
  readonly displayStarobniDuchod$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayStarobniDuchod({ currentMapped, lastApprovedMapped })
  );
  readonly displayPredcasnyDuchod$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayPredcasnyDuchod({ currentMapped, lastApprovedMapped })
  );

  readonly displayJinyDuchod$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayJinyDuchod({ currentMapped, lastApprovedMapped })
  );
  readonly displayRezident$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayRezident({ currentMapped, lastApprovedMapped })
  );
  readonly displayNerezident$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayNerezident({ currentMapped, lastApprovedMapped })
  );
  readonly displayRezidenturaPoradit$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayRezidenturaPoradit({ currentMapped, lastApprovedMapped })
  );
  readonly displayExekuce$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayExekuce({ currentMapped, lastApprovedMapped })
  );
  readonly displaySlevaNaStudenta$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displaySlevaNaStudenta({ currentMapped, lastApprovedMapped })
  );
  readonly displayNoveDite$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayNoveDite({ currentMapped, lastApprovedMapped })
  );
  readonly displayDalsiPoplatnikPracujici$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayDalsiPoplatnikPracujici({ currentMapped, lastApprovedMapped })
  );
  readonly displayDalsiPoplatnikNezamestnany$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayDalsiPoplatnikNezamestnany({
        currentMapped,
        lastApprovedMapped,
      })
  );
  readonly displayrozvedenySDetma$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayrozvedenySDetma({ currentMapped, lastApprovedMapped })
  );
  readonly displayZenatyBezPartneraVDomacnosti$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayZenatyBezPartneraVDomacnosti({
        currentMapped,
        lastApprovedMapped,
      })
  );
  readonly displaydetiStudent$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displaydetiStudent({ currentMapped, lastApprovedMapped })
  );
  readonly displaydetiZTP$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displaydetiZTP({ currentMapped, lastApprovedMapped })
  );
  readonly displayZTPPProProhlaseni$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayZTPPProProhlaseni({ currentMapped, lastApprovedMapped })
  );
  readonly displayZZProProhlaseni$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayZZProProhlaseni({ currentMapped, lastApprovedMapped })
  );
  readonly displayedEmpAll$ = this.data.select(
    ({ currentMapped, lastApprovedMapped }) =>
      this.displayedEmpAll({ currentMapped, lastApprovedMapped })
  );

  protected displayedEmpAll(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    return (
      this.displayTooltipA1(input) ||
      this.displayZTPP(input) ||
      this.displayZZ(input) ||
      this.displayInvalidniDuchod(input) ||
      this.displayStarobniDuchod(input) ||
      this.displayPredcasnyDuchod(input) ||
      this.displayJinyDuchod(input) ||
      this.displayRezident(input) ||
      this.displayNerezident(input) ||
      this.displayRezidenturaPoradit(input) ||
      this.displayExekuce(input) ||
      this.displaySlevaNaStudenta(input) ||
      this.displayNoveDite(input) ||
      this.displayDalsiPoplatnikPracujici(input) ||
      this.displayDalsiPoplatnikNezamestnany(input) ||
      this.displayrozvedenySDetma(input) ||
      this.displayZenatyBezPartneraVDomacnosti(input) ||
      this.displaydetiStudent(input) ||
      this.displaydetiZTP(input)
    );
  }

  private displayTooltipA1(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const current = input.currentMapped?.personalPart?.health?.a1;
    // const last = input.lastApprovedMapped?.personalPart?.health?.a1;

    if (
      current //&& !(last == true)
    ) {
      return true;
    }
    return false;
  }

  public displayZTPP(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const current = input.currentMapped?.personalPart?.health?.ztpp;
    // const last = input.lastApprovedMapped?.personalPart?.health?.ztpp;
    if (
      current // && !(last == true)
    ) {
      return true;
    }
    return false;
  }
  public displayZTPPProProhlaseni(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;

    const current =
      input.currentMapped?.taxDeclaration.taxDeclaration.monthDiscount
        .slevaNaDrziteleZTPPMesice?.value;

    const last =
      input.lastApprovedMapped?.taxDeclaration.taxDeclaration.monthDiscount
        .slevaNaDrziteleZTPPMesice?.value;

    if (currentApply && current > 0 && !(current == last)) {
      return true;
    }
    return false;
  }

  public displayZZ(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    //Nefunguje správně
    const current = input.currentMapped?.personalPart?.health;
    // const last = input.lastApprovedMapped?.personalPart?.health;
    if (
      current &&
      current.zdravotniZnevyhodneni != null &&
      current.zdravotniZnevyhodneni != 'no'
      //&&
      // !(last && last.zdravotniZnevyhodneni == current.zdravotniZnevyhodneni)
    ) {
      return true;
    }
    return false;
  }

  public displayZZProProhlaseni(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;

    const currentZI =
      input.currentMapped?.taxDeclaration.taxDeclaration.monthDiscount
        .rozsirenaSlevaNaInvalidituMesice?.value;
    const currentRI =
      input.currentMapped?.taxDeclaration.taxDeclaration.monthDiscount
        .zakladniSlevaNaInvalidituMesice?.value;
    const lastZI =
      input.lastApprovedMapped?.taxDeclaration.taxDeclaration.monthDiscount
        .rozsirenaSlevaNaInvalidituMesice?.value;
    const lastRI =
      input.lastApprovedMapped?.taxDeclaration.taxDeclaration.monthDiscount
        .zakladniSlevaNaInvalidituMesice?.value;
    if (
      currentApply &&
      (currentZI > 0 || currentRI > 0) &&
      !(currentZI == lastZI && currentRI == lastRI)
    ) {
      return true;
    }
    return false;
  }

  public displayInvalidniDuchod(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentHealth = input.currentMapped?.personalPart?.health?.duchody;
    //const lastHealth = input.lastApprovedMapped?.personalPart?.health?.duchody;
    if (
      currentHealth &&
      currentHealth.pobiramDuchod == true &&
      currentHealth.invalidni
      //&&
      //!(lastHealth && lastHealth.invalidni == true)
    ) {
      return true;
    }
    return false;
  }

  public displayStarobniDuchod(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentHealth = input.currentMapped?.personalPart?.health?.duchody;
    // const lastHealth = input.lastApprovedMapped?.personalPart?.health?.duchody;
    if (
      currentHealth &&
      currentHealth.pobiramDuchod == true &&
      currentHealth.starobni
      // &&
      //!(lastHealth && lastHealth.starobni == true)
    ) {
      return true;
    }
    return false;
  }

  public displayPredcasnyDuchod(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentHealth = input.currentMapped?.personalPart?.health?.duchody;
    // const lastHealth = input.lastApprovedMapped?.personalPart?.health?.duchody;
    if (
      currentHealth &&
      currentHealth.pobiramDuchod == true &&
      currentHealth.predcasny
      // &&
      // !(lastHealth && lastHealth.predcasny == true)
    ) {
      return true;
    }
    return false;
  }

  public displayJinyDuchod(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentHealth = input.currentMapped?.personalPart?.health?.duchody;
    //const lastHealth = input.lastApprovedMapped?.personalPart?.health?.duchody;
    if (
      currentHealth &&
      currentHealth.pobiramDuchod == true &&
      currentHealth.jinyDuchod
      // &&
      //!(lastHealth && lastHealth.jinyDuchod == true)
    ) {
      return true;
    }
    return false;
  }

  public displayRezident(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentRezidentura = input.currentMapped?.taxDeclaration?.rezidentura;
    const lastRezidentura =
      input.lastApprovedMapped?.taxDeclaration?.rezidentura;

    if (
      currentRezidentura &&
      (currentRezidentura.rezidenturaVyhodnoceni == 'false' ||
        currentRezidentura.rezidenturaVyhodnoceni == 'konzultace') &&
      currentRezidentura.rezidentura == 'true' &&
      !(lastRezidentura && lastRezidentura.rezidentura == 'true')
    ) {
      return true;
    }
    return false;
  }

  public displayNerezident(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentRezidentura = input.currentMapped?.taxDeclaration?.rezidentura;
    const lastRezidentura =
      input.lastApprovedMapped?.taxDeclaration?.rezidentura;
    if (
      currentRezidentura &&
      currentRezidentura.rezidenturaVyhodnoceni &&
      (currentRezidentura.rezidenturaVyhodnoceni == 'true' ||
        currentRezidentura.rezidenturaVyhodnoceni == 'konzultace') &&
      currentRezidentura.rezidentura == 'false' &&
      !(lastRezidentura && lastRezidentura.rezidentura == 'false')
    ) {
      return true;
    }
    return false;
  }

  public displayRezidenturaPoradit(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentRezidentura =
      input.currentMapped?.taxDeclaration?.rezidentura?.rezidentura;
    const lastRezidentura =
      input.lastApprovedMapped?.taxDeclaration?.rezidentura?.rezidentura;
    if (
      currentRezidentura &&
      currentRezidentura == 'konzultace' &&
      !(lastRezidentura && lastRezidentura == 'konzultace')
    ) {
      return true;
    }
    return false;
  }

  public displayExekuce(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentExecution =
      input.currentMapped?.personalPart?.execution?.exekuce;
    // const lastExecution =
    //   input.lastApprovedMapped?.personalPart?.execution?.exekuce;

    if (
      currentExecution
      //&& !(lastExecution == true)
    ) {
      return true;
    }
    return false;
  }

  public displaySlevaNaStudenta(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const currentDiscount =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.monthDiscount
        ?.slevaNaStudentaMesice;

    const lastApprovedMapped =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.monthDiscount
        ?.slevaNaStudentaMesice;

    if (
      currentApply &&
      currentDiscount &&
      currentDiscount.value != 0 &&
      JSON.stringify(lastApprovedMapped) != JSON.stringify(currentDiscount)
    ) {
      return true;
    }
    return false;
  }

  public displayNoveDite(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    let flag = false;
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const lastAprrovedChildren: Dite[] | undefined =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.children?.deti;
    const currentChildren: Dite[] | undefined =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.children?.deti;

    // vyhodnocuje podle rodnych čísel
    if (
      currentApply &&
      lastAprrovedChildren &&
      lastAprrovedChildren.length >= 0 &&
      currentChildren &&
      currentChildren.length >= 0
    ) {
      const last = lastAprrovedChildren
        .map((p) => p?.rodneCislo)
        .filter((v, i, a) => a.indexOf(v) === i); // uniq value

      const current = currentChildren
        .map((p) => p?.rodneCislo)
        .filter((v, i, a) => a.indexOf(v) === i); // uniq value

      current.forEach((p) => {
        if (last.find((l) => l == p) == undefined) {
          flag = true;
          return;
        }
      });
    }
    return flag;
  }

  public displayDalsiPoplatnikPracujici(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const current =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.partner;
    const last =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.partner;

    if (
      currentApply &&
      current &&
      current.dalsiPoplatnik &&
      current.zamestnaniPoplatnika == true &&
      !(last && last.zamestnaniPoplatnika == true)
    ) {
      return true;
    }
    return false;
  }

  public displayDalsiPoplatnikNezamestnany(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const current =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.partner;
    const last =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.partner;

    if (
      currentApply &&
      current &&
      current.dalsiPoplatnik &&
      current.zamestnaniPoplatnika == false &&
      !(last && last.zamestnaniPoplatnika == false)
    ) {
      return true;
    }
    return false;
  }

  public displayrozvedenySDetma(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const currentCildren =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.children?.deti;
    const current =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.partner;
    const roddinyStav =
      input.currentMapped?.personalPart?.personalInfo?.rodinnyStav;
    const last =
      input.lastApprovedMapped?.personalPart?.personalInfo?.rodinnyStav;

    if (
      currentApply &&
      currentCildren &&
      currentCildren.length > 0 &&
      // current?.dalsiPoplatnik && // pozdavek prosinec 2022
      roddinyStav == 'divorced' &&
      !(last && last == 'divorced')
    ) {
      return true;
    }
    return false;
  }

  public displayZenatyBezPartneraVDomacnosti(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const currentChildren =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.children?.deti;
    const currentPartner =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.partner;
    const currentRodinnyStav =
      input.currentMapped?.personalPart?.personalInfo?.rodinnyStav;

    const lastChildren =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.children?.deti;
    const lastRodinnyStav =
      input.lastApprovedMapped?.personalPart?.personalInfo?.rodinnyStav;
    const lastPartner =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.partner;

    if (
      currentApply &&
      currentChildren &&
      currentChildren.length > 0 &&
      currentPartner &&
      currentPartner.dalsiPoplatnik == false &&
      currentRodinnyStav == 'married' &&
      !(
        lastRodinnyStav &&
        lastRodinnyStav == 'marrid' &&
        lastPartner &&
        lastPartner.dalsiPoplatnik == false &&
        lastChildren &&
        lastChildren.length > 0
      )
    ) {
      return true;
    }
    return false;
  }

  public displaydetiStudent(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    let flag = false;
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const lastAprrovedChildren: Dite[] | undefined =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.children?.deti;
    const currentChildren: Dite[] | undefined =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.children?.deti;

    if (currentApply && currentChildren && currentChildren.length > 0) {
      // pokud mam zletile dite
      const currentChildrenOlderThan18 = currentChildren
        .map((p) => ({
          rodneCislo: p.rodneCislo,
          zletileDite: p.uplatneni?.find((i) => i.zletileDite),
        }))
        .filter((p) => p.zletileDite); // uniq value

      // pokud mam zletile dite, ale neni s cim porovnat
      if (
        currentChildrenOlderThan18.length > 0 &&
        !(lastAprrovedChildren && lastAprrovedChildren.length > 0)
      ) {
        return true;
      }
      // pokud je nove zletile dite oproti schvalenemu

      currentChildrenOlderThan18.forEach((p) => {
        if (
          !lastAprrovedChildren
            .filter((k) => k.rodneCislo == p.rodneCislo)
            .find((l) => l.uplatneni?.find((i) => i.zletileDite))
        ) {
          flag = true;
          //return forech
          return true;
        }
      });
    }
    return flag;
  }

  public displaydetiZTP(input: {
    currentMapped: FormKarta;
    lastApprovedMapped: FormKarta;
  }) {
    let flag = false;
    const currentApply =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.affidavit
        .prohlaseniKProhlaseni;
    const lastAprrovedChildren: Dite[] | undefined =
      input.lastApprovedMapped?.taxDeclaration?.taxDeclaration?.children?.deti;
    const currentChildren: Dite[] | undefined =
      input.currentMapped?.taxDeclaration?.taxDeclaration?.children?.deti;

    if (currentApply && currentChildren && currentChildren.length > 0) {
      const currentChildrenWithZTPP = currentChildren
        .map((p) => ({
          rodneCislo: p.rodneCislo,
          ztpp: p.uplatneni?.find((i) => i.ztpp),
        }))
        .filter((p) => p.ztpp); // uniq value

      // pokud mam zletile dite, ale neni s cim porovnat
      if (
        currentChildrenWithZTPP.length > 0 &&
        !(lastAprrovedChildren && lastAprrovedChildren.length > 0)
      ) {
        return true;
      }

      // pokud je nove ZPT dite oproti schvalenemu

      currentChildrenWithZTPP.forEach((p) => {
        if (
          !lastAprrovedChildren
            .filter((k) => k.rodneCislo == p.rodneCislo)
            .find((l) => l.uplatneni?.find((i) => i.ztpp))
        ) {
          flag = true;
          return;
        }
      });
    }

    return flag;
  }
}
