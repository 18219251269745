import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-month-slect',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <button
        class="e-btn e-lib {{ to.attributes }}"
        type="button"
        (click)="to.change && to.change(field, $event)"
      >
        <span>
          {{ to.labelT ? t(to.labelT['key'], to.labelT['params']) : to.label }}
        </span>
      </button>
    </ng-container>
  `,
})
export class FormlyFieldButtonTrigger extends FieldType {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        buttonType: 'e-primary',
      },
    },
  };
  ngOnInit() {}
}
