import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { specialCharsParser } from './utils';

export const sharedHealthInsurer = (guide = false): FormlyFieldConfig[] => [
  {
    key: 'zdravPojistovna',
    type: 'select',
    className: 'p-col-12 p-sm-8',
    props: {
      placeholder: 'TPA.SelectPlaceholder',
      translate: true,
      label: 'TPA.HealthInsurance',
      translateOptions: true,
      required: true,
      options: [
        { label: 'Všeobecná zdravotní pojišťovna - 111', value: 111 },
        { label: 'Vojenská zdravotní pojišťovna - 201', value: 201 },
        { label: 'Česká průmyslová zdravotní pojišťovna - 205', value: 205 },
        {
          label: 'Oborová ZP zaměstnanců bank a pojišťoven - 207',
          value: 207,
        },
        { label: 'Zaměstnanecká pojišťovna Škoda - 209', value: 209 },
        {
          label: 'Zdravotní pojišť. Ministerstva vnitra ČR - 211',
          value: 211,
        },
        { label: 'Revírní bratrská pokladna - 213', value: 213 },
        { label: 'Zdravotní pojišťovna METAL-ALIANCE - 217', value: 217 },
        { label: 'Zdravotní pojišťovna MÉDIA - 228', value: 228 },
        { label: 'TPA.Other', value: 0 },
      ],
      change: (field, event) => {
        if (
          event.itemData &&
          event.itemData.value &&
          event.itemData.value != 0
        ) {
          // TPACHANGES: this part was rewrited
          // this issue
          // const rc =
          //   this.formControl.root.value.personalPart?.personalInfo.rodneCislo;
          // const cisloPojistence = field?.parent?.fieldGroup?.find(
          //   (p) => p.key == 'cisloPojistence'
          // );
          // if (rc && cisloPojistence?.formControl)
          //   cisloPojistence.formControl.patchValue(
          //     (rc as string).replace('/', '')
          //   );
        }
      },
    },
    expressionProperties: {
      'props.change': 'formState.changeZdravPojistovna',
    },
  },
  {
    key: 'jinaZdravPojistovna', //V případě "Jiná" pojišťovna string input pro další specifikaci pojišťovny.
    type: 'input',
    className: 'p-col-12 p-sm-4',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.OtherHealthInsurance',
      translate: true,
      required: true,
    },
    hideExpression: '(model?.zdravPojistovna != 0)',
  },
  {
    key: 'cisloPojistence', //V případě ČR je to RČ
    type: 'input',
    className: 'p-col',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.InsuranceNumber',
      translate: true,
      required: true,
      tooltip: 'TPA.InsuranceNumberTooltip',
    },
  },
  {
    key: 'a1',
    type: 'checkbox',
    className: 'p-col-12',
    props: {
      checkboxLabel: 'TPA.A1',
      translate: true,
      ...(!guide ? { tooltip: 'TPA.A1Tooltip' } : {}),
    },
  },
  ...(guide
    ? [
        {
          template: `<p class="description">${translate(
            'TPA.Guide.Step6A1Description'
          )} </p>`,
        },
      ]
    : []),
];

export const sharedHealthCondition = (): FormlyFieldConfig[] => [
  {
    key: 'zdravotniZnevyhodneni',
    type: 'select',
    className: 'p-col-12 p-md-8',
    props: {
      placeholder: 'TPA.SelectPlaceholder',
      translate: true,
      label: 'TPA.HealthDisadvantage',
      required: true,
      translateOptions: true,
      defaultValue: null,
      options: [
        { label: 'TPA.No', value: 'no' },

        { label: 'TPA.Ozz', value: 'ozz' },

        {
          label: 'TPA.InvalidityOneTwo',
          value: 'firstorseconddegreeinvalidity',
        },
        {
          label: 'TPA.InvalidityThree',

          value: 'thirddegreeinvalidity',
        },
      ],
    },
  },
  {
    key: 'ztpp',
    type: 'select',
    className: 'p-col',
    props: {
      placeholder: 'TPA.SelectPlaceholder',
      label: 'TPA.Ztpp',
      translate: true,
      translateOptions: true,
      required: true,
      options: [
        { label: 'TPA.No', value: false },

        { label: 'TPA.Yes', value: true },
      ],
    },
  },
];
/**
 *
 * @param question question to be displayed
 * @param duplicateQuestion question to be displayed when answer is true
 * @returns
 */
export const sharedRetirement = (
  question: FormlyFieldConfig = {
    key: 'pobiramDuchod',
    type: 'checkbox',
    className: 'p-col-12',
    props: {
      checkboxLabel: 'TPA.GotPension',
      translate: true,
    },
  },
  duplicateQuestion: FormlyFieldConfig = {
    key: 'pobiramDuchod',
    type: 'checkbox',
    className: 'p-col-12',
    props: {
      checkboxLabel: 'TPA.GotPension',
      translate: true,
    },
    hideExpression: '(model?.pobiramDuchod == true)',
  }
): FormlyFieldConfig => ({
  className: 'p-col-12',
  key: 'duchody',
  fieldGroup: [
    question,
    {
      wrappers: ['panel-wrapper'],
      hideExpression: '(model?.pobiramDuchod != true)',
      fieldGroup: [
        duplicateQuestion,
        {
          key: 'predcasny',
          type: 'checkbox',
          className: 'p-col-12',
          props: {
            checkboxLabel: 'TPA.EarlyRetirementPension',
            translate: true,
          },
        },
        {
          key: 'starobni',
          type: 'checkbox',
          className: 'p-col-12',
          props: {
            checkboxLabel: 'TPA.RetirementPension',
            translate: true,
          },
        },
        {
          key: 'invalidni',
          type: 'checkbox',
          className: 'p-col-12',
          props: {
            checkboxLabel: 'TPA.DisabilityPension',
            translate: true,
          },
        },
        {
          className: 'p-col-12',
          fieldGroup: [
            {
              key: 'jinyDuchod',
              type: 'checkbox',
              className: 'p-col-12',
              props: {
                checkboxLabel: 'TPA.OtherPension',
                translate: true,
              },
            },
            {
              key: 'jiny',
              type: 'input',
              className: 'p-col-12',
              parsers: [specialCharsParser],
              modelOptions: {
                updateOn: 'blur',
              },
              props: {
                label: 'TPA.OtherPensionDescription',
                translate: true,
              },
              hideExpression: (m, formState, field) => {
                return !(
                  m != null &&
                  m.jinyDuchod == true &&
                  m.pobiramDuchod == true
                );
              },
            },
          ],
        },
      ],
    },
  ],
});
