<div class="relative">
  <div class="tenancy-card__badges">
    <div 
    class="tenancy-card__badge"
    [class.tenancy-card__badge--favourite]="isFavourite"
    
    (click)="toggleFavorite.emit(tenancyId)">
      <i class="fa fa-heart"></i>
    </div>
  </div>
  <a [routerLink]="link">
    <div class="tenancy-card">
      <!-- <div class="tenancy-card__badges">
        <div class="tenancy-card__badge tenancy-card__badge--tasks" *ngIf="tasks > 0" title="tasks">
          {{ tasks }}
        </div>
        <div class="tenancy-card__badge" *ngIf="notifications > 0" title="notifications">
          {{ notifications }}
        </div>
        

      </div> -->
      <div class="tenancy-card__avatar">
        {{ _avatar }}
        <img
          *ngIf="img"
          [src]="img"
          [alt]="_tenancyName"
          class="tenancy-card__avatar-img"
        />
        <div class="tenancy-card__bar_wrapper">
          <div
            class="tenancy-card__bar tenancy-card__bar--tasks"
            *ngIf="tasks > 0"
            title="tasks"
          >
            {{ 'TPA.Tasks' | transloco }}: {{ tasks }}
          </div>
          <div
            class="tenancy-card__bar"
            *ngIf="notifications > 0"
            title="notifications"
          >
            {{ 'TPA.Notifications' | transloco }}: {{ notifications }}
          </div>
        </div>
      </div>
      <span class="tenancy-card__text">
        {{ _tenancyName }}
        <span class="tenancy-card__text__subtext" *ngIf="tasks > 0">
          {{ 'TPA.Tasks' | transloco }}: {{ tasks }}
        </span>
        <span class="tenancy-card__text__subtext" *ngIf="notifications > 0">
          {{ 'TPA.Notifications' | transloco }}: {{ notifications }}</span
        >
      </span>
    </div>
  </a>
</div>
