import { AbpConsts, AppConfig } from '../consts';
import { XmlHttpRequestHelper } from '../helpers/xml-http-request.helper';
import { HttpClient } from '@angular/common/http';

export function getUserConfiguration(
  callback: (res: any) => void,
  customHeaders: any
) {
  const type = 'GET';
  XmlHttpRequestHelper.ajax(
    type,
    `${getRemoteServiceBaseUrl()}/AbpUserConfiguration/GetAll`,
    customHeaders,
    null,
    (result) => callback(result)
  );
}

export function setApplicationConfig(
  httpClient: HttpClient,
  configUrl: string,
  callback: () => void
) {
  return httpClient
    .get<any>(configUrl)
    .toPromise()
    .then((result) => {
      AppConfig.config = result;
      AbpConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl;
      AbpConsts.appBaseUrl = result.appBaseUrl;
      callback();
    });
}

export function getApplicationConfig(configUrl: string, callback: () => void) {
  const type = 'GET';
  const customHeaders = [];
  XmlHttpRequestHelper.ajax(type, configUrl, customHeaders, null, (result) => {
    AppConfig.config = result;
    AbpConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl;
    AbpConsts.appBaseUrl = result.appBaseUrl;
    callback();
  });
}

export function getRemoteServiceBaseUrl(): string {
  return AbpConsts.remoteServiceBaseUrl;
}

export function getDocumentOrigin() {
  return document.baseURI;
  // if (!document.location.origin) {
  //   return (
  //     document.location.protocol +
  //     '//' +
  //     document.location.hostname +
  //     (document.location.port ? ':' + document.location.port : '')
  //   );
  // }

  // return document.location.origin;
}
