import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { CoreActions } from '../actions';

import { Platform } from '@angular/cdk/platform';

@Injectable()
export class NGXCoreEffects {
  /// For index.html
  ///
  /// <link rel="preload" href="assets/prime-ng.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'">
  ///
  /// rel="preload" not works in firefox and IE
  FixFirefoxShit$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CoreActions.appLoadedInBrowser),
        tap((action) => {
          if (this.platform.isBrowser) {
            if (
              /Trident|MSIE|Fire|BlackBerry|IEMobile|Opera/i.test(
                navigator.userAgent
              )
            ) {
              document.querySelectorAll("[as='style']").forEach((element) => {
                (element as any).rel = 'stylesheet';
              });
            }
          }
        })
      ),
    { dispatch: false }
  );

  getHover$ = createEffect(() =>
    this.actions.pipe(
      ofType(CoreActions.appLoadedInBrowser),
      map((action) => CoreActions.hoverState({ hover: this.hover() }))
    )
  );

  getDimension$ = createEffect(() =>
    this.actions.pipe(
      ofType(CoreActions.appLoadedInBrowser),
      map((action) => CoreActions.dimensions(this.dimension()))
    )
  );

  hover() {
    if (this.platform.isBrowser) {
      return !(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        window.innerWidth < 600 ||
        this.isIpadOS()
      );
    }
    return false;
  }

  isIpadOS() {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  }

  dimension(): { width: number; height: number } {
    if (this.platform.isBrowser) {
      return { width: window.innerWidth, height: window.innerHeight };
    }
    return { width: 0, height: 0 };
  }

  constructor(
    private actions: Actions,
    protected platform: Platform,
    private store$: Store<never> //private cookieService: CookieService
  ) {}
}
