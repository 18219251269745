import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  GetRzdInput,
  RzdDto,
  RzdMetadataDto,
  RzdOperationInputDto,
  SignRzdInputDto,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class EmployeeTaxSettlementHttpApiService {
  constructor(private rest: RestService) {}

  public get(params: TenancyRequestDto<GetRzdInput>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/tax-annual-settlement/get',
      params,
    };
    return this.rest.request<TenancyRequestDto<null>, Rest.AbpResponse<RzdDto>>(
      request
    );
  }

  public getMetadata(params: TenancyRequestDto<GetRzdInput>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/tax-annual-settlement/get/metadata',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<RzdMetadataDto>>(request);
  }

  public signRzd(body: TenancyRequestDto<SignRzdInputDto>) {
    const request: Rest.Request<TenancyRequestDto<SignRzdInputDto>> = {
      method: 'PUT',
      url: '/api/employee/tax-annual-settlement/sign-tax-annual-settlement',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<SignRzdInputDto>,
      Rest.AbpResponse<any>
    >(request);
  }

  public RZDPDFRequest(body: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<TenancyRequestDto<EntityDto>> = {
      method: 'POST',
      url: '/api/employee/tax-annual-settlement/create-tax-settlement-pdf-request',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<RzdOperationInputDto>,
      Rest.AbpResponse<null>
    >(request);
  }
}
