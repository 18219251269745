import { Injectable } from '@angular/core';
import {
  DataManagerResult,
  EntityDto,
  IDataState,
  Rest,
  RestService,
} from '@ralba/shared';
import {
  CreateOrUpdateRoleInput,
  GetRoleForEditOutput,
  GetRolesInput,
  ListResultDtoOfRoleListDto,
  RoleListDto,
  TenancyRequestDto,
} from '../dto';

@Injectable({ providedIn: 'root' })
export class RoleHttpApiService {
  constructor(private rest: RestService) {}

  dataManager(body: TenancyRequestDto<IDataState>) {
    const request: Rest.Request<TenancyRequestDto<IDataState>> = {
      method: 'POST',
      url: `/api/role/datamanager`,
      body,
    };

    return this.rest.request<
      TenancyRequestDto<IDataState>,
      Rest.AbpResponse<DataManagerResult<RoleListDto>>
    >(request);
  }

  public getRoles(params: TenancyRequestDto<GetRolesInput>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/role/get-list',
      params,
    };
    return this.rest.request<
      null,
      Rest.AbpResponse<ListResultDtoOfRoleListDto>
    >(request);
  }

  public get(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/role/get',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<GetRoleForEditOutput>>(
      request
    );
  }

  public createOrUpdate(body: TenancyRequestDto<CreateOrUpdateRoleInput>) {
    const request: Rest.Request<TenancyRequestDto<CreateOrUpdateRoleInput>> = {
      method: 'POST',
      url: '/api/role/create-or-update',
      body,
    };
    return this.rest.request<
      TenancyRequestDto<CreateOrUpdateRoleInput>,
      Rest.AbpResponse<null>
    >(request);
  }

  public delete(params: TenancyRequestDto<EntityDto>) {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: '/api/role/delete',
      params,
    };
    return this.rest.request<null, Rest.AbpResponse<null>>(request);
  }
}
