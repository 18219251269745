import { Component } from '@angular/core';
import {
    FieldType,
    FormlyExtension,
    FormlyFieldConfig
} from '@ngx-formly/core';
import { TargetBlankService } from '@tpa/shared';


@Component({
  selector: 'tpa-tip',
  templateUrl: 'tip.component.html',
  styles: [
    `
      :host {
        width: 100%;
      }
      ::ng-deep .tip .e-card {
        background: #ffe6a3 !important;
        border: none;
      }
      ::ng-deep .e-header-bottom-space {
        height: 14px;
      }
      .h4 {
        position: relative;
        top: 10px;
      }
      tpa-user-upload-document {
        margin-top: 8px;
        display: block;
      }
      ::ng-deep tpa-tip .p-card {
        background: var(--surface-b) !important;
      }
    `,
  ],
})
export class TipComponent extends FieldType  {
  defaultOptions = {
    props: {
      full: true,
      open: false,
      userId: null,
      valitFrom: null,
    },
  };

  constructor(public targetBlankService: TargetBlankService) {
    super();
  }

  navigateToDocument(document: {
    tenant: number;
    id: number;
  }) {
    this.targetBlankService.targetBlank([
      '/',
      'tenancy',
      document.tenant,
      'documents',
      document.id,
    ]);
  }
}
