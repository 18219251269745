import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LetModule, PushModule} from '@ngrx/component';
import {FormlyModule} from '@ngx-formly/core';
import {ButtonModule} from '@syncfusion/ej2-angular-buttons';
import {ManageUser2FaComponent} from './manage-user-2fa.component';

import {TranslocoModule} from '@ngneat/transloco';
import {RalbaUiModule} from '@ralba/ui';
import {TabModule} from '@syncfusion/ej2-angular-navigations';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {SharedModule} from '@tpa/shared';
import {TpaUiModule} from '@tpa/ui';
import {NgxQrcodeStylingModule} from "ngx-qrcode-styling";

@NgModule({
  imports: [
    CommonModule,
    RalbaUiModule,
    FormlyModule,
    RouterModule,
    TpaUiModule,
    SharedModule,
    LetModule,
    PushModule,
    ReactiveFormsModule,
    TranslocoModule,
    TabModule,
    DialogModule,
    ButtonModule,
    NgxQrcodeStylingModule
  ],
  exports: [ManageUser2FaComponent],
  declarations: [ManageUser2FaComponent],
  providers: [],
})
export class ManageUser2faModule {}
