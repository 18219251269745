<ng-container *ngIf="to && to.open; else card">
  <formly-field *ngFor="let field of field.fieldGroup" [field]="field"></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div *ngIf="!model || !model?.cisloDokladu; else notEmpty">
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p>
        {{ model?.cisloDokladu }}
        <span *ngIf="model?.typDokladu"> ({{ model.typDokladu }}) </span>
      </p>
      <p *ngIf="model?.vydalStat">
        {{ 'TPA.Issued' | transloco }}
        {{ 'TPA.' + model.vydalStat | transloco }}
      </p>
      <p *ngIf="model?.datumExpirace">
        {{ 'TPA.ExpirationDate' | transloco }}:
        {{ model.datumExpirace | translocoDate:  { dateStyle: 'medium' } }}
      </p>
    </ng-template>
  </div>
</ng-template>