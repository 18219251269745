import { SelectWithFilterFormlyField } from '../components/select-with-filter/select-with-filter.component';
import { DalsiZamestnavateleFieldInput } from './dalsi-zamestnavatele/dalsi-zamestnavatele.component';
import { FormlyFieldDateTimePicker } from './date-time-picker/date-picker.component';
import { FileuploadFieldType } from './file-upload.field';
import { MessageField } from './message/message.componets';
import { RZDSkolkovneFieldInput } from './rzd-skolkovne/rzd-skolkovne.component';
import { RZDUverOsobyFieldInput } from './rzd-uver-osoby/rzd-uver-osoby.component';

export const FORMLY_FIELDS_DECLARATION = [
  FileuploadFieldType,
  SelectWithFilterFormlyField,
  FormlyFieldDateTimePicker,
  RZDSkolkovneFieldInput,
  RZDUverOsobyFieldInput,
  DalsiZamestnavateleFieldInput,
  MessageField
];

export const FormlyFieldsConfig = [
  {
    name: 'file-upload',
    component: FileuploadFieldType,
  },
  {
    name: 'select-with-filter',
    wrappers: ['form-field'],
    component: SelectWithFilterFormlyField,
  },
  {
    name: 'datetimepicker',
    component: FormlyFieldDateTimePicker,
    wrappers: ['form-field'],
  },
  {
    name: 'rzd-skolkovne',
    component: RZDSkolkovneFieldInput,
  },
  {
    name: 'rzd-skolkovne',
    component: RZDSkolkovneFieldInput,
  },
  {
    name: 'dalsi-zamestnavatele',
    component: DalsiZamestnavateleFieldInput,
  },
  {
    name: 'rzd-uver-osoby',
    component: RZDUverOsobyFieldInput,
  },
  {
    name: 'message',
    component: MessageField,
  },
  // {
  //   name: 'tree-check-box',
  //   component: TreeCheckBoxFieldType,
  // },
];

export {
  FileuploadFieldType,
  FormlyFieldDateTimePicker,
  SelectWithFilterFormlyField,
  RZDSkolkovneFieldInput,
  RZDUverOsobyFieldInput,
  DalsiZamestnavateleFieldInput,
  MessageField,
};
