import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CoreSelectors } from '../selectors/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class PermissionChecker {
  permissions$ = this.store$.pipe(select(CoreSelectors.selectAuth), first());

  constructor(private store$: Store<never>) {}

  private static checkIfPermissionIsDefined(
    permissionName: string,
    allPermissions: { [key: string]: string }
  ) {
    const included = allPermissions[permissionName];
    if (included) {
      return true;
    } else {
      console.warn(`Permission "${permissionName}" is not defined`);
    }
  }

  static isGranted(
    permissionName: string,
    allPermissions: { [key: string]: string },
    grantedPermissions: { [key: string]: string }
  );
  static isGranted(
    permissionNames: string[],
    allPermissions: { [key: string]: string },
    grantedPermissions: { [key: string]: string },
    requireAll?: boolean
  );

  static isGranted(
    perm: string | string[],
    allPermissions: { [key: string]: string },
    grantedPermissions: { [key: string]: string },
    requireAll: boolean = false
  ) {
    if (typeof perm === 'string') {
      if (PermissionChecker.checkIfPermissionIsDefined(perm, allPermissions)) {
        return grantedPermissions[perm] === 'true';
      } else {
        return false;
      }
    } else if (Array.isArray(perm)) {
      const permExists = perm.map((f) =>
        PermissionChecker.checkIfPermissionIsDefined(f, allPermissions)
      );
      if (requireAll) {
        if (permExists.filter((permissionName) => !permissionName).length > 0) {
          return (
            perm.filter(
              (permissionName) => grantedPermissions[permissionName] === 'true'
            ).length > perm.length
          );
        }
      } else {
        return (
          perm.filter(
            (permissionName) => grantedPermissions[permissionName] === 'true'
          ).length > 0
        );
      }
    }
  }

  isGranted$(permissionName: string): Observable<boolean>;
  isGranted$(
    permissionNames: string[],
    requireAll?: boolean
  ): Observable<boolean>;
  isGranted$(
    perm: string | string[],
    requireAll: boolean = false
  ): Observable<boolean> {
    return this.permissions$.pipe(
      map(({ allPermissions, grantedPermissions }) => {
        if (typeof perm === 'string') {
          return PermissionChecker.isGranted(
            perm,
            allPermissions,
            grantedPermissions
          );
        } else
          return PermissionChecker.isGranted(
            perm,
            allPermissions,
            grantedPermissions,
            requireAll
          );
      })
    );
  }
}
