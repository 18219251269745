import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'maritalStatus',
})
export class MaritalStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText = '';

    switch (value) {
      case 'single': {
        returnedText = translate('TPA.Single');
        break;
      }
      case 'married': {
        returnedText = translate('TPA.Married');
        break;
      }
      case 'divorced': {
        returnedText = translate('TPA.Divorced');
        break;
      }
      case 'widowed': {
        returnedText = translate('TPA.Widowed');
        break;
      }
      case 'fiancee': {
        returnedText = translate('TPA.Fiancee');
        break;
      }
      case 'registered': {
        returnedText = translate('TPA.Registered');
        break;
      }
      case 'unidentified': {
        returnedText = translate('TPA.Unidentified');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
