<ng-container
  *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card"
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
  ></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <div *ngIf="!model || !model?.dalsiPracovniPomer; else notEmpty">
      <p>
        {{ 'TPA.NotFilled' | transloco }}
      </p>
    </div>
    <ng-template #notEmpty>
      <p *ngIf="model?.dalsiPracovniPomer">
        <span *ngIf="model?.nazevDalsihoZamestnavatele !== ''"
          >{{ model?.nazevDalsihoZamestnavatele }},</span
        >
        <span *ngIf="model?.sidloDalsihoZamestnavatele != null">{{
          'TPA.' + model?.sidloDalsihoZamestnavatele | transloco
        }}</span>
      </p> </ng-template
    >
  </div>
</ng-template>
