<div
  class="root-layout"
  *ngIf="userName$ | async; else empty"
  [class.root-layout--open]="sideNavOpen$ | async"
>
  <div class="root-layout__top-bar">
    <ral-admin-top-bar
      [displayName]="userName$ | async"
      [title]="'asdf ls fůaksd fůkajsdůf ůas dfůkasd fasdlkfj ůas asdf'"
      (logout)="this.logout()"
      (toggle)="toggleSideNav()"
    >
    </ral-admin-top-bar>
  </div>

  <div class="root-layout__side-bar">
    <ral-side-logo-header (toggle)="toggleSideNav()"></ral-side-logo-header>
    <ral-side-menu [menuItems]="menuItems"> </ral-side-menu>
  </div>

  <div class="root-layout__main">
    <div class="root-layout__main__inner">
      <ng-content></ng-content>
    </div>
  </div>

  <!-- <div class="root-layout__bottom-bar"></div> -->
</div>

<ral-toast> </ral-toast>

<ng-template #empty>
  <div
    style="
      font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <img
      src="/assets/images/laoding.gif"
      alt="Loading"
      style="width: 25px; display: inline-block; vertical-align: middle"
    />
    <span style="vertical-align: middle; display: inline-block">
      Loading...</span
    >
  </div>
</ng-template>
