import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetModule, PushModule } from '@ngrx/component';
import { FormlyModule } from '@ngx-formly/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

import { SharedModule } from '@tpa/shared';
import { TranslocoModule } from '@ngneat/transloco';
import { TpaUiModule } from '@tpa/ui';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { TwoFaAdsComponent } from './two-fa-ads.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TpaUiModule,
    SharedModule,
    LetModule,
    PushModule,
    ReactiveFormsModule,
    TranslocoModule,
    TabModule,
    DialogModule,
    FormlyModule.forChild({
      //validators: PASSWORD_VALIDATORS, translation issue
      //validationMessages: PASSWORD_VALIDATION_MESSAGES,
    }),
    ButtonModule,
  ],
  exports: [TwoFaAdsComponent],
  declarations: [TwoFaAdsComponent],
  providers: [],
})
export class FeaturesTwoFaAdsModule {}
