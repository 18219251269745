import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { AbpConsts, AdminContainerBase } from '@ralba/shared';
import {
  ActionCompleteEventArgs,
  AsyncSettingsModel,
  UploaderComponent,
  UploaderModule,
  UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import { L10n } from '@syncfusion/ej2-base';
import { FeatureEmployeeDocumentUploadStore } from './feature-employee-document-upload.store';

@Component({
  selector: 'tpa-feature-employee-document-upload',
  standalone: true,
  imports: [CommonModule, UploaderModule],
  templateUrl: './feature-employee-document-upload.component.html',
  styles: [
    `
      ::ng-deep tpa-user-upload-document .e-upload {
        border: none !important;
      }
      ::ng-deep .e-upload-files {
        display: none;
      }
    `,
  ],
  providers: [FeatureEmployeeDocumentUploadStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureEmployeeDocumentUploadComponent
  extends AdminContainerBase
  implements OnInit
{
  @Output() uploadCompleted = this.employeeDocumentUploadState.uploadCompleted;

  @ViewChild(UploaderComponent, { static: true }) uploader!: UploaderComponent;
  onUploadSuccess = ($event: Object) =>
    this.employeeDocumentUploadState.handleOnUploadSuccess($event);
  uploading = ($event: UploadingEventArgs) =>
    this.employeeDocumentUploadState.handleUploading($event);
  actionComplete = ($event: ActionCompleteEventArgs) =>
    this.employeeDocumentUploadState.handleActionComplete({
      args: $event,
      uploader: this.uploader,
    });

  public path: AsyncSettingsModel = {
    saveUrl: `${AbpConsts.remoteServiceBaseUrl}/TpaFiles/Upload`,
  };

  constructor(
    store$: Store<never>,
    @Inject(EmployeeDocumentUploadHideInjectionToken)
    @Optional()
    public readonly shouldHideUpload: boolean,
    private readonly employeeDocumentUploadState: FeatureEmployeeDocumentUploadStore
  ) {
    super(store$);
    if (shouldHideUpload === null) this.shouldHideUpload = false;
  }

  @Input() set tagId(value: string) {
    this.employeeDocumentUploadState.setTagId({ tagId: value });
  }

  ngOnInit() {
    L10n.load({
      'cs-Cz': {
        uploader: {
          Browse: translate('TPA.NewDocument'),
        },
      },
    });
  }
}

export const EmployeeDocumentUploadHideInjectionToken =
  new InjectionToken<boolean>('EmployeeDocumentUploadHideInjectionToken');
