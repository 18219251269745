import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'educationLevel',
})
export class EducationLevelPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText = '';
    switch (value) {
      case 'A': {
        returnedText = translate('TPA.EduA');
        break;
      }
      case 'B': {
        returnedText = translate('TPA.EduB');
        break;
      }
      case 'C': {
        returnedText = translate('TPA.EduC');
        break;
      }
      case 'D': {
        returnedText = translate('TPA.EduD');
        break;
      }
      case 'E': {
        returnedText = translate('TPA.EduE');
        break;
      }
      case 'H': {
        returnedText = translate('TPA.EduH');
        break;
      }
      case 'J': {
        returnedText = translate('TPA.EduJ');
        break;
      }
      case 'K': {
        returnedText = translate('TPA.EduK');
        break;
      }
      case 'L': {
        returnedText = translate('TPA.EduL');
        break;
      }
      case 'M': {
        returnedText = translate('TPA.EduM');
        break;
      }
      case 'N': {
        returnedText = translate('TPA.EduN');
        break;
      }
      case 'R': {
        returnedText = translate('TPA.EduR');
        break;
      }
      case 'T': {
        returnedText = translate('TPA.EduT');
        break;
      }
      case 'V': {
        returnedText = translate('TPA.EduV');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
