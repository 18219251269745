import { Action, createReducer, on } from '@ngrx/store';
import { AsyncState } from '@ralba/shared';
import { SessionActions } from '../actions';
import { CurrentLoginInformations } from '../models';

export interface SessionState
  extends CurrentLoginInformations,
    AsyncState.IAsyncState {}

const iniitialSate: SessionState = {
  ...AsyncState.initialState,
  application: {
    features: {},
    releaseDate: null,
    version: null,
  },
  tenant: {
    id: null,
    name: null,
    tenancyName: null,
  },
  user: {
    emailAddress: null,
    id: null,
    name: null,
    surname: null,
    userName: null,
  },
};

const reducer = createReducer(
  iniitialSate,
  on(
    SessionActions.currentLoginInformationsLoaded,
    (state, { application, tenant, user }) => ({
      ...state,
      ...AsyncState.loadedState,
      user: user ? user : state.user,
      application,
      tenant,
    })
  )
);

export function sessionReducer(state: SessionState, action: Action) {
  return reducer(state, action);
}

export const selectUserName = (state: SessionState) => state.user.userName;

export const selcetFullUserName = (state: SessionState) =>
  `${state.tenant.tenancyName}/${state.user.userName}`;
