import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetModule, PushModule } from '@ngrx/component';
import { FormlyModule } from '@ngx-formly/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ChangePasswordComponent } from './change-password.component';

import { SharedModule } from '@tpa/shared';
import { TranslocoModule } from '@ngneat/transloco';
import { TpaUiModule } from '@tpa/ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TpaUiModule,
    SharedModule,
    LetModule,
    PushModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormlyModule.forChild({
      //validators: PASSWORD_VALIDATORS, translation issue
      //validationMessages: PASSWORD_VALIDATION_MESSAGES,
    }),
    ButtonModule,
  ],
  exports: [ChangePasswordComponent],
  declarations: [ChangePasswordComponent],
  providers: [],
})
export class ChangePasswordModule {}
