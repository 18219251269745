import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'ral-right-sticky-part',
  templateUrl: 'right-sticky-part.component.html',
  styleUrls: ['./right-sticky-part.component.scss'],
  host: { class: 'p-col right-side-sticky-wrapper' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightStickyPartComponent implements OnInit {
  open = false;
  @Input() sideToggleIconOpen = 'fal fa-comment-alt';
  @Input() sideToggleIconClose = 'fal fa-times';
  constructor() {}

  ngOnInit() {}

  toggle() {
    this.open = !this.open;
  }
}
