import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnText;
    switch (value) {
      case 'man': {
        returnText = translate('TPA.Man');
        break;
      }
      case 'woman': {
        returnText = translate('TPA.Woman');
        break;
      }
      case 'undefined': {
        returnText = translate('TPA.Undefined');
        break;
      }
      default: {
        returnText = value;
        break;
      }
    }
    return returnText;
  }
}
