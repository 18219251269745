import { createAction, props } from '@ngrx/store';
import { CurrentLoginInformations, GetAll } from '../models';

const currentLoginInformationsLoaded = createAction(
  '[Session] Current login information loaded',
  props<CurrentLoginInformations>()
);

const getAllLoadded = createAction('[Core] get all loaded', props<GetAll>());

export const SessionActions = {
  currentLoginInformationsLoaded,
  getAllLoadded,
};
