import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createSelector, select, Store } from '@ngrx/store';
import { CoreSelectors, PermissionChecker } from '@ralba/core';
import { TenancyFeatureChecker, UserTenanciesSelectors } from '@tpa/core';
import { finalize, tap } from 'rxjs/operators';

export const selecetAuthInformations = createSelector(
  UserTenanciesSelectors.side,
  CoreSelectors.selectAuth,
  UserTenanciesSelectors.selectPermissions,
  UserTenanciesSelectors.selecetFeatures,
  (side, hostAuth, tenatnAuth, features) => ({
    side,
    hostAuth,
    tenatnAuth,
    features,
  })
);

@UntilDestroy()
@Directive({ selector: '[tpaCheck]', exportAs: 'tpaCheck' })
export class HasPermissionDirective implements OnInit {
  tenancyPermissions$ = this.store$.pipe(
    select(UserTenanciesSelectors.selectPermissions)
  );
  hostPermissions$ = this.store$.pipe(select(CoreSelectors.selectAuth));

  _permissions: string[] | null = null;
  _feature: string | null = null;
  _reqAllPermissions: boolean = false;
  _hideForSide: boolean = true;
  _side: 'HOST' | 'TENANT' | null = null;
  _usePermSide: 'HOST' | 'TENANT' | null = null;
  _ownerOverride = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store$: Store<never>
  ) {}

  @Input()
  set permissions(val: string[]) {
    this._permissions = val;
  }

  @Input()
  set feature(val: string) {
    this._feature = val;
  }

  @Input()
  set permissionsFor(val: 'HOST' | 'TENANT') {
    this._usePermSide = val;
  }

  @Input()
  set side(val: 'HOST' | 'TENANT') {
    this._side = val;
  }

  @Input()
  set ownerOverride(val: boolean) {
    this._ownerOverride = val;
  }

  ngOnInit(): void {
    this.store$
      .pipe(
        select(selecetAuthInformations),
        untilDestroyed(this),
        tap((auth) => {
          this.viewContainer.clear();
          if (this._side && this._side !== auth.side) {
            return;
          }
          if (
            (this._usePermSide === 'HOST' || auth.side === 'HOST') &&
            (this._permissions === null ||
              PermissionChecker.isGranted(
                this._permissions,
                auth.hostAuth.allPermissions,
                auth.hostAuth.grantedPermissions,
                this._reqAllPermissions
              ))
          ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else if (
            this._usePermSide !== 'HOST' &&
            (this._usePermSide === 'TENANT' || auth.side === 'TENANT') &&
            (this._feature === null ||
              TenancyFeatureChecker.isEnabled(this._feature, auth.features)) &&
            (this._permissions === null ||
              PermissionChecker.isGranted(
                this._permissions,
                auth.tenatnAuth.allPermissions,
                auth.tenatnAuth.grantedPermissions,
                this._reqAllPermissions
              ))
          ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else if (this._permissions && this._permissions.length === 0) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        }),
        finalize(() => this.viewContainer.clear())
      )
      .subscribe();
  }
}
