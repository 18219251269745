//import * as Sentry from '@sentry/browser';
import { ajax } from 'rxjs/ajax';
import { retry, map } from 'rxjs/operators';

function createXHR() {
  return new XMLHttpRequest();
}

export class XmlHttpRequestHelper {
  static ajax(
    type: string,
    url: string,
    customHeaders: any,
    data: any,
    success: any
  ) {
    url += (url.indexOf('?') >= 0 ? '&' : '?') + 'd=' + new Date().getTime();
    const request = ajax.getJSON(url, customHeaders);
    // const request = ajax({
    //   createXHR,
    //   url,
    //   crossDomain: true,
    //   withCredentials: false,
    //   method: 'GET',
    //   headers: customHeaders
    // });

    request.pipe(retry(3)).subscribe(
      (res) => {
        success(res);
      }
      // err =>
      // window.setTimeout(function() {
      //   window.location.replace('/assets/error-pages/server-down.html');
      // }, 1200)
    );
  }
}
