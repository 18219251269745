import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

export type ActionCretorAlias<A> = ActionCreator<
  string,
  (props: A) => A & TypedAction<string>
>;



interface IActionRequest<T> {
  params?: T;
}

interface IActionResponse<T> {
  result: T;
}

interface IHttpActions<TParams, TResponse> {
  request: ActionCretorAlias<IActionRequest<TParams>>;
  success: ActionCretorAlias<IActionResponse<TResponse>>;
  error: ActionCretorAlias<{ error: any }>;
}

function httpRequestActionFactory<T>(actionName: string) {
  return createAction(`${actionName} request`, props<IActionRequest<T>>());
}

function httpSuccessActionFactory<T>(actionName: string) {
  return createAction(
    `${actionName} success`,
    props<IActionResponse<T>>()
  );
}

function httpErrorActionFactory<T>(actionName: string) {
  return createAction(
    `${actionName}  error`,
    props<{ error: any }>()
  );
}

export function httpActionsFactory<TParams, TResponse>(
  actionName: string,
): IHttpActions<TParams, TResponse> {
  return {
    request: httpRequestActionFactory<TParams>(actionName),
    success: httpSuccessActionFactory<TResponse>(actionName),
    error: httpErrorActionFactory(actionName)
  };
}

export type HttpActions = IHttpActions<any, any>;
