<div
  class="root-layout"
  *ngIf="isCoreLoaded$ | async; else empty"
  [class.root-layout--open]="sideNavOpen$ | async"
>
  <div class="root-layout__top-bar">
    <ral-admin-top-bar
      [displayName]="userName$ | async"
      [title]="tenancyName$ | async"
      (logout)="this.logout()"
      (toggle)="toggleSideNav()"
    >
      <div class="p-col-fixed" class="language-wrapper">
        <tpa-change-language></tpa-change-language>
      </div>
    </ral-admin-top-bar>
    <!-- BAR
      <button (click)="logOut()">Logout</button> -->
  </div>
  <div
    class="root-layout__side-bar color-primary bg-secondaryno no-borders grid-88"
  >
    <a [routerLink]="['/']">
      <ral-side-logo-header [title]="appTitle"></ral-side-logo-header>
    </a>
    <tpa-tenancy-navigation> </tpa-tenancy-navigation>
  </div>
  <div class="root-layout__main">
    <div class="root-layout__main__inner">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="root-layout__bottom-bar"></div>

  <!-- <tpa-two-fa-ads></tpa-two-fa-ads> -->
</div>
<ral-toast></ral-toast>

<ng-template #empty>
  <div
    style="
      font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <img
      src="/assets/images/laoding.gif"
      alt="Loading"
      style="width: 25px; display: inline-block; vertical-align: middle"
    />
    <span style="vertical-align: middle; display: inline-block">
      Loading...</span
    >
  </div>
</ng-template>
