import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NodeCheckEventArgs, TreeViewComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'ral-formly-tree-check-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ejs-treeview
      [fields]="to.options ?? []"
      [showCheckBox]="true"
      [autoCheck]="to['autoCheck'] || false"
      (nodeChecked)="nodeChecked($event)"
    ></ejs-treeview>
  `,
})
export class FormlyTreeCheckboxFieldInput
  extends FieldType
  implements AfterViewInit {
  @ViewChild(TreeViewComponent) tree!: TreeViewComponent;

  defaultOptions = {
    props: { autoCheck: true },
  };


  ngAfterViewInit(): void {
    // this.tree.checkedNodes = [...this.formControl.value]
  }

  nodeChecked(event: NodeCheckEventArgs) {

    this.formControl.setValue([...this.tree.checkedNodes]);
    this.formControl.markAsDirty();
  }
}
