import { createAction, props } from '@ngrx/store';

const initGa = createAction('[Gtm] init ga');

const pushEvent = createAction(
  '[Gtm] push action',
  props<{ category: string; action?: string; label?: string; value?: number }>()
);

export const GtmActions = {
  initGa,
  pushEvent,
};
