import { Component } from '@angular/core';
import {
    FieldType,
    FormlyExtension,
    FormlyFieldConfig
} from '@ngx-formly/core';
import { sharedInsolvency } from '../../form-parts-definition';

@Component({
  selector: 'tpa-executions',
  templateUrl: './executions.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tpa-wrapper__control' },
})
export class ExecutionsComponent extends FieldType implements FormlyExtension {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: false,
    },
  };

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = sharedInsolvency();
  }
}
