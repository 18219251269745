import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'tpa-error-wrapper',
  templateUrl: './error-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorWrapperComponent extends FieldWrapper {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
    },
  };
}
