<ral-card class="changes-box" *ngIf="to?.lastApprovedData">
  <div class="e-card-header" style="width: 100%">
    <h3 class="h3">{{ to.label }}</h3>
    <!-- <div class="e-header-bottom-space" *ngIf="!to.tagIds"></div> -->
  </div>
  <div class="e-card-content">
    <!-- {{ model | json }} -->
    <!-- soudn be used -->

    <!-- <tpa-my-card-changes
      [lastApproved]="to.lastApprovedData"
      [current]="model"
    ></tpa-my-card-changes> -->
  </div>
</ral-card>
