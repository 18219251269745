import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FEATURE_KEY, NGXCoreState, height } from '../reducers';
import { AsyncState } from '@ralba/shared';

const selectNGXCoreStateSelector =
  createFeatureSelector<NGXCoreState>(FEATURE_KEY);

const selectCurrentDimentions = createSelector(
  selectNGXCoreStateSelector,
  (s) => ({ width: s.width, height: s.height })
);

const selectHover = createSelector(selectNGXCoreStateSelector, (s) => s.hover);
const selectWidth = createSelector(selectNGXCoreStateSelector, (s) => s.width);

export const NGXCoreSelectors = {
  selectCurrentDimentions,
  selectHover,
  selectWidth,
};
