import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'residentureEvaluation',
})
export class ResidentureEvaluationPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let returnedText;
    switch (value) {
      case 'true': {
        returnedText = translate('TPA.Rezident');
        break;
      }
      case 'false': {
        returnedText = translate('TPA.Nerezident');
        break;
      }
      case 'konzultace': {
        returnedText = translate('TPA.Consultation');
        break;
      }
      default: {
        returnedText = value;
      }
    }
    return returnedText;
  }
}
