import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createAction, createReducer, createSelector, DefaultProjectorFn, MemoizedSelector, on, props } from '@ngrx/store';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { httpActionsFactory } from '../utils';
import { PagedResult } from './models';
export * from './models';

export namespace NgrxGrid {

  export interface GridState extends Pick<DataStateChangeEventArgs, 'skip' | 'take' | 'sorted'> {
    pageSize?: number
    pageSizes?: boolean
  }

  function reducerGetData(state: IDataGridState<any>, data: PagedResult<any> | any[], adapter: EntityAdapter<any>) {

    if ("totalCount" in data) {
      return adapter.setAll(data.items, {
        ...state,
        totalCount: data.totalCount
      })
    }
    else if (data instanceof Array) {
      return adapter.setAll(data, {
        ...state,
        totalCount: data.length
      })
    }
  }


  export function createNgrxDataActions<T>(dataGridName: string) {
    return {
      getData: httpActionsFactory<{}, T[] | PagedResult<T>>(`[${dataGridName}] get data`),
      dataStateChange: createAction(`[${dataGridName}] dataStateChange`, props<{
        gridState: GridState
      }>())
    }
  }

  export interface IDataGridState<T = any> extends EntityState<T> {
    totalCount: number;
    gridState: GridState;
  }

  type DataGridStateType = MemoizedSelector<object, IDataGridState, DefaultProjectorFn<IDataGridState>>;

  export function createSelectors(featureSelector: DataGridStateType, adapter: EntityAdapter<any>) {
    const { selectAll } = adapter.getSelectors();

    const selecetAllData = createSelector(
      featureSelector,
      selectAll
    )

    const selecetGridState = createSelector(
      featureSelector,
      (x) => x.gridState
    )

    const selectTotalCount = createSelector(
      featureSelector,
      (s) => s.totalCount
    )
    return {
      selecetData: createSelector(
        selecetAllData,
        selectTotalCount,
        selecetGridState,
        (result, count, grid) => ({
          result: result,
          count
        })
      ),
      gridState: selecetGridState
    };
  }


  export function createNgrxData<T>(dataGridName: string, gridInitialState?: GridState) {
    const adapter: EntityAdapter<T> = createEntityAdapter<T>();
    const initialState: IDataGridState<T> = adapter.getInitialState({
      totalCount: 0,
      gridState: {
        skip: gridInitialState.skip || 0,
        take: gridInitialState.take || 10,
        pageSizes: gridInitialState.pageSizes || true,
        sorted: gridInitialState.sorted || []
      }
    });
    const actions = createNgrxDataActions<T>(dataGridName);

    const reducer = createReducer(initialState,
      on(actions.getData.success, (state, { result }) => reducerGetData(state, result, adapter)),
      on(actions.dataStateChange, (state, { gridState }) => ({
        ...state, gridState: {
          ...state.gridState,
          ...gridState
        }
      }))
    );

    return {
      state: initialState,
      reducer,
      actions,
      adapter,
    }
  }

}