import { Component } from '@angular/core';
import {
    FieldType,
    FormlyExtension,
    FormlyFieldConfig
} from '@ngx-formly/core';
import { sharedQuestion } from '../../form-parts-definition';
import { MonthFlags } from '../month-select/month-select.component';

@Component({
  selector: 'tpa-checkbox-discount',
  templateUrl: './checkbox-discount.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tpa-wrapper__control' },
})
export class CheckboxDiscountComponent
  extends FieldType
  implements FormlyExtension
{
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: true,
    },
  };

  MonthFlags = MonthFlags;

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = [
      //TODO: prekopirovat formly a upravit podle modelu
      {
        key: 'monthDiscount',
        props: {
          translate: true,
        },
        fieldGroup: [
          {
            key: 'zakladniSlevaNaPoplatnika',
            fieldGroup: [
              sharedQuestion({
                key: 'value',
                props: {
                  label: 'TPA.TaypayerDiscount',
                  // description: 'TPA.TaypayerDiscountDescription',
                  translate: true,
                  required: true,
                },
                // defaultValue: true,
                hideExpression: 'formState.hideBasedOnProhlaseniKProhlaseni()',
              }),
            ],
          },
        ],
      },

      sharedQuestion({
        key: 'soucasneNeuplatnuji',
        props: {
          label: 'TPA.soucasneNeuplatnuji',
          // description: 'TPA.soucasneNeuplatnujiDescription',
          yesText: 'TPA.soucasneNeuplatnujiYes',
          noText: 'TPA.soucasneNeuplatnujiNo',
        },
        hideExpression: '(model?.monthDiscount?.zakladniSlevaNaPoplatnika?.value != true)',
      }),
    ];
  }
}
