import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import {
  FilteringEventArgs,
  MultiSelectComponent
} from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'ral-formly-multiselect',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ejs-multiselect
      [formControl]="$any(formControl)"
      [fields]="to.fields"
      [dataSource]="to.options ?? []"
      [allowFiltering]="true"
      (filtering)="onFiltering($event)"
    >
    </ejs-multiselect>
  `,
})
export class FormlyMultiselectFieldInput extends FieldType {
  @ViewChild(MultiSelectComponent) cmp?: MultiSelectComponent;

  defaultOptions = {
    props: {
      options: [],
      onFilter: (e: FilteringEventArgs, dataSource: any[]) => { },
    },
  };

  public onFiltering(e: FilteringEventArgs) {
    this.props['onFilter'] && this.cmp && this.props['onFilter'](e, this.cmp.dataSource);
  }

  // onChange(value: any, checked: boolean) {
  //   this.formControl.markAsTouched();
  // }
}
