import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AccountActions, ToastActions } from '@ralba/core';
import { UserTenancyDto, UserTenancyHttpApiService } from '@tpa/api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { GetUserTenanciesActions, UserTenancyActions } from '../actions';
import { ITenancyFeaturesAndPermissionns } from '../model';
import { UserTenanciesSelectors } from '../selectors';
import { checkUserIdentifer, mapKeyValueToObject } from '../utils';

@Injectable()
export class UserTenanciesEffects {
  getUserTenancies$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GetUserTenanciesActions.request),
        switchMap((action) =>
          this.oidcSecurityService.isAuthenticated$.pipe(
            filter((isAuthenticated) => isAuthenticated),
            exhaustMap((isAuthenticated) =>
              this.userTenancyService.getTenancies().pipe(
                map(({ result }) =>
                  GetUserTenanciesActions.success({ result })
                ),
                catchError(({ error }) =>
                  of(GetUserTenanciesActions.error(error))
                )
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  getUserTenanciesErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GetUserTenanciesActions.error),
        map((error) => ToastActions.showToastApiError(error?.error))
      ),
    { dispatch: true }
  );

  getUserTenanciesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GetUserTenanciesActions.error),
        map(() => AccountActions.logOut())
      ),
    { dispatch: true }
  );

  onCheckTenancyAccount = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTenancyActions.checkTenancyAccount),
        withLatestFrom(
          this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer)),
          this.store$.pipe(select(UserTenanciesSelectors.selectAccounts)),
          this.store$.pipe(select(UserTenanciesSelectors.selecetGuard))
        ),
        filter(([_, identifer, accounts, guard]) => guard.loaded),
        map(([_, identifer, accounts]) =>
          checkUserIdentifer(identifer, accounts)
            ? UserTenancyActions.checkTenancyAccountOK()
            : UserTenancyActions.determineUserTenancyAccount()
        )
      ),
    { dispatch: true }
  );

  onDetermineTenancyAccount = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTenancyActions.determineUserTenancyAccount),
        withLatestFrom(
          this.store$.pipe(select(UserTenanciesSelectors.selectAccounts)),
          this.store$.pipe(select(UserTenanciesSelectors.selectTenantId))
        ),
        map(([_, accounts, tenantId]) =>
          this.determineTenancyAccount(accounts, tenantId)
        )
      ),
    { dispatch: true }
  );

  setUserTenancyAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTenancyActions.setUserTenancyAccount),
        withLatestFrom(
          this.store$.pipe(select(UserTenanciesSelectors.selectUserIdentifer))
        ),
        exhaustMap(([action, identifer]) =>
          this.userTenancyService
            .getTenancyPermissionsAndFeatures(identifer)
            .pipe(
              map(
                ({ result }) =>
                  <ITenancyFeaturesAndPermissionns>{
                    features: mapKeyValueToObject(result.features),
                    grantedPermissions: mapKeyValueToObject(
                      result.grantedPermissions
                    ),
                    allPermissions: mapKeyValueToObject(result.allPermissions),
                    hasEmployeeCard: result.hasEmployeeCard,
                    hasSignedEmployeeCard: result.hasSignedEmployeeCard,
                  }
              ),
              map((result) => {
    

                return UserTenancyActions.getUserTenancyFeatureAndPermissionsActions.success(
                  { result }
                );
              }),
              catchError(({ error }) =>
                of(
                  UserTenancyActions.getUserTenancyFeatureAndPermissionsActions.error(
                    error
                  )
                )
              )
            )
        )
      ),
    { dispatch: true }
  );

  noAccountFound = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTenancyActions.noAccountsFound),
        tap(() => this.router.navigate(['/tenancy']))
      ),
    { dispatch: false }
  );

  twoFARedirect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserTenancyActions.getUserTenancyFeatureAndPermissionsActions.error
        ),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private userTenancyService: UserTenancyHttpApiService,
    private actions$: Actions,
    private store$: Store<never>,
    public oidcSecurityService: OidcSecurityService
  ) {}

  private determineTenancyAccount(
    accounts: UserTenancyDto[],
    tenantId: number
  ) {
    // const impersonate = localStorage.getItem('impersonate');
    // if (impersonate) {
    //   return UserTenancyActions.setUserTenancyAccount({
    //     userId: parseInt(impersonate),
    //   });
    // }

    accounts = accounts.filter((f) => f.tenantId === tenantId);
    if (accounts.length === 0) {
      return UserTenancyActions.noAccountsFound();
    } else if (accounts.length === 1) {
      return UserTenancyActions.setUserTenancyAccount({
        userId: accounts[0].id,
      });
    } else {
      return UserTenancyActions.redirectToAccountSelect();
    }
  }
}
