import { Injectable } from '@angular/core';
import { Rest, RestService } from '@ralba/shared';
import { TenancyRequestDto } from '../dto';

@Injectable({ providedIn: 'root' })
export class AuthorizationHttpApiService {
  constructor(private rest: RestService) {}

  public requestVerificationCode(body: TenancyRequestDto) {
    const request: Rest.Request<TenancyRequestDto> = {
      method: 'POST',
      url: '/api/authorization/request-verification-code',
      body,
    };
    return this.rest.request<TenancyRequestDto, Rest.AbpResponse<null>>(
      request
    );
  }
}
