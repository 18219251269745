import { createAction, props } from '@ngrx/store';
import { NavMenuItem } from '../models';

const registerMenuItems = createAction(
  '[NavMenu] register menu items',
  props<{ menuItems: NavMenuItem[] }>()
);

const toggleSideNav = createAction('[NavMenu] toggle side nav');

const openSideNav = createAction('[NavMenu] open side nav');

const closeSideNav = createAction('[NavMenu] close side nav');

export const NavMenuActions = {
  registerMenuItems,
  toggleSideNav,
  openSideNav,
  closeSideNav,
};
