import { Injectable } from '@angular/core';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { ToastActions } from '@ralba/core';
import { AbpConsts } from '@ralba/shared';
import { HandleBarsService } from './handlebars.service';



export function isObjectNotNullOrUndefined(
  input: unknown
): input is Record<string, never> {
  return input !== null && input !== undefined && typeof input === 'object';
}

export interface HandleBarsPreviewComponentState {
  template: string;
  data: unknown;
  forYear: number;
  signatureMetadata: unknown;
  loading: boolean;
}
@Injectable()
export class HandleBarsPreviewComponentStore extends ComponentStore<HandleBarsPreviewComponentState> {
  url = `${AbpConsts.remoteServiceBaseUrl}/HtmlToPdf/HtmlToPdf/`

  loading$ = this.select((s) => s.loading);
  data$ = this.select((state) => {
    if (isObjectNotNullOrUndefined(state.data) && isObjectNotNullOrUndefined(state.signatureMetadata)) {
      const { data, forYear, signatureMetadata } = state;
      const renderObject = {
        ...data,
        forYear,
        signatureMetadata

      };
      return this.handleBarsService.convert(state.template, renderObject);
    }
    return "";
  }
  );

  private readonly _updateData = this.updater(
    (state, input: { data: unknown }) => ({
      ...state,
      data: input.data,
    })
  );

  private readonly _updateTemplate = this.updater(
    (state, input: { template: string }) => ({
      ...state,
      template: input.template,
    })
  );


  private readonly _updateForYear = this.updater(
    (state, input: { forYear: number }) => ({
      ...state,
      forYear: input.forYear,
    })
  );

  private readonly _updateLoading = this.updater(
    (state, input: { loading: boolean }) => ({
      ...state,
      loading: input.loading,
    })
  );

  private readonly _updateSignatureMetadata = this.updater(
    (state, input: { signatureMetadata: unknown }) => ({
      ...state,
      signatureMetadata: input.signatureMetadata,
    })
  );

  constructor(
    private store$: Store<never>,
    public http: HttpClient,
    public sanitized: DomSanitizer,
    public handleBarsService: HandleBarsService,
    public translocoService: TranslocoLocaleService
  ) {
    super({
      template: '',
      data: {},
      forYear: 0,
      signatureMetadata: {},
      loading: false,
    });
  }

  updateByDownloadFromUrl = this.effect((input: Observable<string>) => {
    // 'assets/tax-declaration.html'
    return input.pipe(
      exhaustMap((p) =>
        this.http
          .get(p, { responseType: 'text' })
          .pipe(tap((data) => this.updateTemplate(data))
            , catchError(({ error }) => {
              this.store$.dispatch(ToastActions.showToastApiError(error.error));
              return of(null);
            }),
          )
      )
    );
  });

  updateTemplate = this.effect((input: Observable<string>) => {
    //TODO fix
    return input.pipe(tap((p) => this._updateTemplate({ template: p + '' })));
  });

  updateData = this.effect((input: Observable<unknown>) => {
    return input.pipe(map((p) => this._updateData({ data: p })));
  });

  updateSignatureMetadata = this.effect((input: Observable<unknown>) => {
    return input.pipe(map((p) => this._updateSignatureMetadata({ signatureMetadata: p })));
  });

  updateForYear = this.effect((input: Observable<number>) => {
    return input.pipe(map((p) => this._updateForYear({ forYear: p })));
  });

  startLoading = this.effect((input: Observable<never>) => {
    return input.pipe(tap((p) => this._updateLoading({ loading: true })));
  });
  stopLoading = this.effect((input: Observable<never>) => {
    return input.pipe(tap((p) => this._updateLoading({ loading: false })));
  });


  htmlToPdf = this.effect((input: Observable<never>) => {
    return input.pipe(
      withLatestFrom(this.state$),
      filter((p): p is [never, (HandleBarsPreviewComponentState & {
        data: (Record<string, never>)
      })] => !p[1].loading && !!p[1].template && isObjectNotNullOrUndefined(p[1].data) && isObjectNotNullOrUndefined(p[1].signatureMetadata)),
      tap(() => this.startLoading()),
      map(([, state]) => {
        const { data, forYear, signatureMetadata } = state;
        const renderObject = {
          ...data,
          forYear,
          signatureMetadata

        };
        return this.handleBarsService.convert(state.template, renderObject)

      }),
      exhaustMap((html) =>
        this.http.post(this.url, {
          html,
          fileName: "form"
        }, { responseType: 'blob' }).pipe(
          map(response => URL.createObjectURL(response)),
          tap((blobUrl) => window.open(blobUrl, '_blank')),
          catchError(({ error }) => {
            this.store$.dispatch(ToastActions.showToastApiError(error?.error));
            return of(null);
          })
        )
      ),
      catchError((error) => {
        // for sentry
        console.error(error)
        this.store$.dispatch(ToastActions.showToastApiError(error?.error));
        return of(null);
      }),
      tap(() => this.stopLoading())

    )
  });
}



